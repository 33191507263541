<template>
  <div class="zoom-lunar-phases native-pinch zoom-page">
    <div class="top-tool-nav-wrap">
      <a @click="$router.go(-1)" class="btn-close-circle" aria-hidden="true"><span class="visuallyhidden">close Zoom View</span></a>
    </div>
    <div class="zoomed-img-wrap">
      <svg preserveAspectRatio="xMaxYMin meet" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 2168 2168" class="zoomed-img__img" :style="'height:' + imageHeightLive">
        <defs>
          <clipPath id="clip_1" v-html="this.lunarslides[this.activeId].svgpath"></clipPath>
        </defs>
        <image clip-path="url(#clip_1)" :xlink:href="'/assets/img/lunar-phases/'+this.lunarslides[this.activeId].img" width="2168" height="2168" />
      </svg>
    </div>
  </div>
</template>

<script>
  import lunas from '../../data/luna'
  export default {
    name: 'zoomLunarPhasesComponent',
    data() {
      return {
        lunarslides:lunas.data()['slides'],
        imageHeightLive: 'auto'
      }
    },
    created() {
      this.activeId = this.$route.params.id;
    },
    beforeRouteLeave (to, from, next) {
      document.body.classList.remove('zoomed-opened');
      document.body.style.height = '';
      localStorage.setItem('lunaIndex', this.activeId);
      next();
    },
    methods:{
      setHeight: function (){
        var winH = window.innerHeight;
        document.body.style.height = winH + 'px';
        document.querySelector('.zoom-lunar-phases').style.height = winH + 'px';
        document.querySelector('.zoomed-img-wrap').style.height = winH + 'px';
        var _this = this;
        var newHeight = winH - this.$el.querySelector('.btn-close-circle').clientHeight - 10;

        if(window.innerWidth >= 768){
          _this.imageHeightLive = newHeight + 'px';
          document.body.classList.remove('zoomed-overflow');
        } else {
          _this.imageHeightLive = 'auto';
          setTimeout(function (){
            if(document.querySelector('.zoomed-img__img').clientHeight < window.innerHeight){
              document.body.classList.add('zoomed-overflow');
            }
          },5);
        }

        setTimeout(function (){
          if(window.innerWidth >= 768){
            var gap = 80;
          } else {
            gap = 40;
          }
          if(document.querySelector('.zoomed-img__img').clientWidth > window.innerWidth - gap){
            document.querySelector('.zoomed-img__img').style.width = window.innerWidth - gap + 'px';
            _this.imageHeightLive = 'auto';
            _this.$el.querySelector('.zoomed-img-wrap').classList.add('centered');
          } else {
            document.querySelector('.zoomed-img__img').style.width = 'auto';
            _this.imageHeightLive = newHeight + 'px';
            _this.$el.querySelector('.zoomed-img-wrap').classList.remove('centered');
          }
        },10);
      }
    },
    mounted() {
      var _this = this;
      document.body.classList.add('zoomed-opened');

      this.setHeight();

      window.onresize = () => {
        setTimeout(function (){
          _this.setHeight();
        },10)
      }
    }
  }
</script>
