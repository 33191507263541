<template>
  <div class="home2screen-info-overlay">
    <a class="btn-close" @click="$emit('close')" aria-hidden="true"><span class="visuallyhidden">close home to screen info</span></a>
    <div class="home2screen-info__container">
      <section class="section-iphone" v-if=isIphone>
        <h2 class="home2screen-info__title">Wie füge ich AstroRara zum  Home-Bildschirm meines iPhones hinzu?</h2>
        <ol>
          <li>
            <p>Drücken Sie den Share-Button in Safari.</p>
            <img :src="$t('home2screen.ios_01')" :srcset="$t('home2screen.ios_01x2')" :alt="$t('home2screen.ios_01_alt')">
          </li>
          <li>
            <p>Scrollen Sie nach unten und drücken Sie auf «Zum Home-Bildschirm»</p>
            <img :src="$t('home2screen.ios_02')" :srcset="$t('home2screen.ios_02x2')" :alt="$t('home2screen.ios_02_alt')">
          </li>
          <li>
            <p>Drücken Sie oben rechts auf «Hinzufügen».</p>
            <img :src="$t('home2screen.ios_03')" :srcset="$t('home2screen.ios_03x2')" :alt="$t('home2screen.ios_03_alt')">
          </li>
        </ol>
      </section>
      <section class="section-android" v-else>
        <h2 class="home2screen-info__title">Wie füge ich AstroRara zum Startbildschirm meines Androids hinzu?</h2>
        <ol>
          <li>
            <p>Drücken Sie den Option-Button in Chrome.</p>
            <img :src="$t('home2screen.android_01')" :srcset="$t('home2screen.android_01x2')" :alt="$t('home2screen.android_01_alt')">
          </li>
          <li>
            <p>Drücken Sie auf «Zum Startbildschirm zufügen»</p>
            <img :src="$t('home2screen.android_02')" :srcset="$t('home2screen.android_02x2')" :alt="$t('home2screen.android_02_alt')">
          </li>
          <li>
            <p>Drücken Sie unten rechts auf «Hinzufügen».</p>
            <img :src="$t('home2screen.android_03')" :srcset="$t('home2screen.android_03x2')" :alt="$t('home2screen.android_03_alt')">
          </li>
        </ol>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: 'home2screenInfo',
  props: {
    isIphone: Boolean
  }
}
</script>
