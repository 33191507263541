<script>
  export default {
    name: 'Slides',
    data: function (){
      return {
        Slides: [
          {
            // headline: this.$t('Volvelle'),
            headline: 'Volvelle',
            subheadline: 'Organum Uranicum <br>(Sebastian Münster, 1536)',
            img: '/assets/img/volvelle/thumb.jpg',
            thumb: '/assets/img/volvelle/thumb--small.jpg',
            style: '',
            url: 'volvelle',
            headline_en: 'Volvelle',
          },
          {
            // headline: this.$t('Saturnmond Titan'),
            headline: 'Saturnmond Titan',
            subheadline: 'Systema Saturnium <br>(Christian Huygens, 1659)',
            img: '/assets/img/saturnium/thumb.jpg',
            thumb: '/assets/img/saturnium/thumb--small.jpg',
            style: '',
            url: 'systema-saturnium',
            headline_en: 'Saturnmond Titan',
          },

          {
            // headline: this.$t('Christliche Sternbilder'),
            headline: 'Christliche Sternbilder',
            subheadline: 'Coelum stellatum christianum <br>(Schiller, 1627)',
            img: '/assets/img/coelum-stellatum/thumb.jpg',
            thumb: '/assets/img/coelum-stellatum/thumb--small.jpg',
            style: '',
            url: 'coelum-stellatum',
            headline_en: 'Christliche Sternbilder',
          },
          {
            // headline: this.$t('Inkunabel'),
            headline: 'Moon Passing Jupiter',
            subheadline: 'Selenographia  <br>(Hevelius, 1647)',
            img: '/assets/img/moon-passing/thumb.jpg',
            thumb: '/assets/img/moon-passing/thumb--small.png',
            style: '',
            url: 'moon-passing',
            headline_en: 'moon-passing',
          },
          {
            // headline: this.$t('Inkunabel'),
            headline: 'Inkunabel',
            subheadline: 'Theoricae novae planetarum <br>(Peuerbach, 1485)',
            img: '/assets/img/inkunabel/thumb.jpg',
            thumb: '/assets/img/inkunabel/thumb--small.jpg',
            style: '',
            url: 'inkunabel',
            headline_en: 'Inkunabel',
          },
          {
            // headline: this.$t('Schleifenbewegung'),
            headline: 'Schleifenbewegung',
            subheadline: 'Atlas Novus Coelestis <br>(Doppelmayr, 1742)',
            img: '/assets/img/atlas-novus/thumb.jpg',
            thumb: '/assets/img/atlas-novus/thumb--small.jpg',
            style: '',
            url: 'atlas-novus',
            headline_en: 'Retrograde motion',
          },
          {
            headline: 'Halleyscher Komet',
            subheadline: 'Astronomicum Caesareum <br>(Apian, 1540)',
            img: '/assets/img/halley-comet/thumb.jpg',
            thumb: '/assets/img/halley-comet/thumb--small.jpg',
            style: '',
            url: 'halley-comet',
            headline_en: 'Comet tail',
          },
          {
            // headline: this.$t('Harmonia'),
            headline: 'Kosmologien',
            subheadline: 'Harmonia Macrocosmica <br>(Cellarius, 1708)',
            img: '/assets/img/harmonia/thumb.jpg',
            thumb: '/assets/img/harmonia/thumb--small.jpg',
            style: '',
            url: 'harmonia',
            headline_en: 'Kosmologien',
          },
          {
            // headline: this.$t('Tierkreiszeichen'),
            headline: 'Tierkreiszeichen',
            subheadline: 'Uranometria <br />(Bayer, 1603)',
            img: '/assets/img/uranometria/thumb.jpg',
            thumb: '/assets/img/uranometria/thumb--small.jpg',
            style: '',
            url: 'uranometria',
            headline_en: 'Tierkreiszeichen',
          },
          {
            // headline: this.$t('Astrolab'),
            headline: 'Planisphaerium',
            subheadline: 'Opera mathematica <br>(Schöner, 1551)',
            img: '/assets/img/astrolab/thumb.jpg',
            thumb: '/assets/img/astrolab/thumb--small.jpg',
            style: '',
            url: 'astrolab',
            headline_en: 'Planisphaerium',
          },
          {
            // headline: this.$t('Jupitermonde'),
            headline: 'Jupitermonde',
            subheadline: 'Sidereus Nuncius <br />(Galilei, 1610)',
            img: '/assets/img/jupiters-moons/thumb.jpg',
            thumb: '/assets/img/jupiters-moons/thumb--small.jpg',
            style: '',
            url: 'jupiters-moons',
            headline_en: 'The Galilean Moons',
          },
          {
            // headline: this.$t('Sonnenflecken'),
            headline: 'Sonnenflecken',
            subheadline: 'Rosa Ursina <br />(Scheiner, 1630)',
            img: '/assets/img/sun-spots/thumb.jpg',
            thumb: '/assets/img/sun-spots/thumb--small.jpg',
            style: '',
            url: 'sun-spots',
            headline_en: 'Sun Spots',
          },
          {
            // headline: this.$t('Sternenkarte'),
            headline: 'Sternenkarte',
            subheadline: 'Astronomicum Caesareum <br>(Apian, 1540)',
            img: '/assets/img/stars-map/thumb.jpg',
            thumb: '/assets/img/stars-map/thumb--small.jpg',
            style: '',
            url: 'star-map',
            headline_en: 'Star Map',
          },
          {
            // headline: this.$t('Mondphasen'),
            headline: 'Mondphasen',
            subheadline: 'Selenographia <br />(Hevelius, 1647)',
            img: '/assets/img/lunar-phases/thumb.jpg',
            thumb: '/assets/img/lunar-phases/thumb--small.jpg',
            style: '',
            url: 'lunar-phases',
            headline_en: 'Lunar Phases',
          }
        ]
      }
    }
  }
</script>

