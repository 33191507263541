<template>
  <div>
    <div class="landing__bg">
      <transition name="landing__bg-animation" appear>
        <div class="landing__bg-animation"></div>
      </transition>
    </div>
    <div id="landing" v-touch="onTap">
      <transition name="landing__intro-1" appear>
        <div class="landing__intro-1">
          <div class="landing__center">
            <h1 class="landing__logo-wrap">
              <span class="visuallyhidden">ETH ASTRORARA</span>
            </h1>
            <picture class="intro__circle intro__circle--front">
              <source media="(min-width: 768px)" srcset="/assets/img/intro/circle-front.png">
              <img src="/assets/img/intro/circle-front-mobile.png" alt="">
            </picture>
            <picture class="intro__circle intro__circle--behind">
              <source media="(min-width: 768px)" srcset="/assets/img/intro/circle-behind.png">
              <img src="/assets/img/intro/circle-behind-mobile.png" alt="">
            </picture>
          </div>
        </div>
      </transition>
      <transition name="landing__intro-2" appear>
        <div :class="{ 'ready' : initialized }" class="landing__intro-2">
          <div :class="{ 'intro__silhouette--mobile' : showAddToHome }" class="intro__silhouette">
            <img class="intro-silhouette__img" src="/assets/img/intro/silhouette.svg" alt="">
          </div>
          <div :class="{ 'intro__text-wrap--center' : showAddToHome }" class="intro__text-wrap">
            <div class="intro__text-inner">
              <img class="landing__logo" src="/assets/img/layout/logo_astrorara.svg" alt="ETH ASTRORARA Logo" title="ETH ASTRORARA Logo">
              <p class="intro__text">{{ $t('Explore historical astronomical illustrations interactively thanks to digitized rare books from ETH Library.') }}
              <router-link to="overview" append class="link link--bold">
                {{ $t('Experience starten') }}
              </router-link>
              </p>
              <!-- Common Add to Homescreen Template -->
              <div class="home2screen-banner" v-if="showAddToHome">
                <div class="home2screen-link js-disable-tap" v-if="isAddToHomeNative" @click="openAddToHomeNative">&nbsp;</div>
                <div class="home2screen-link js-disable-tap" v-else @click="openHome2screenInfo">&nbsp;</div>
                <span class="sicon sicon__info-circle"></span>
                <div class="home2screen-banner--right js-disable-tap">
                  <strong>Für ein optimales Erlebnis,</strong> fügen Sie diese Web-App zu Ihrem Home-Bildschirm hinzu.
                  <span class="home2screen-banner__cta native js-disable-tap" v-if="isAddToHomeNative">Install</span>
                  <span class="home2screen-banner__cta not-native js-disable-tap" v-else>Mehr erfahren</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <transition name="home2screen-info-mask">
      <div class="home2screen-info-mask" v-if="showInstruction" @click="closeHome2screenInfo"></div>
    </transition>
    <transition name="home2screen-info-overlay">
      <home2screeninfo v-if="showInstruction" @close="closeHome2screenInfo" v-bind:isIphone=isIphone></home2screeninfo>
    </transition>
  </div>
</template>

<script>

  import home2screenInfo from '../components/home2screen-info';

  export default {
    name: 'introComponent',
    components: {
      'home2screeninfo': home2screenInfo
    },
    data() {
      return {
        bg: false,
        initialized: false,
        isAddToHomeNative: false,
        isAppInstalled: false,
        showAddToHome: false,
        deferredPrompt: null,
        showInstruction: false,
        isIphone: false
      }
    },
    created() {
      this.isMobile = this.mobileAndTabletcheck();
      window.addEventListener('beforeinstallprompt', (e) => {
        // Prevent Chrome 67 and earlier from automatically showing the prompt
        e.preventDefault();
        // Stash the event so it can be triggered later.
        this.deferredPrompt = e;
        this.isAddToHomeNative = true;
      });
      if (navigator.userAgent.match(/iphone|ipod/i)) {
        this.isIphone = true;
      } else {
        this.isIphone = false;
      }
    },
    methods: {
      onTap: function (e) {
        if(!e.target.classList.contains('js-disable-tap') && !e.target.classList.contains('btn-close')){
          document.querySelector('.link').click();
        }
      },
      beforeLeave: function(to, from, next) {
        setTimeout(next, 1000);
      },
      openAddToHomeNative: function () {
        this.deferredPrompt.prompt();
        this.deferredPrompt.userChoice.then((choiceResult) => {
          if (choiceResult.outcome === 'accepted') {
            //console.log('User accepted the add to home prompt');
          } else {
            //console.log('User dismissed the add to home prompt');
          }
        });
        //this.deferredPrompt = null;
      },
      openHome2screenInfo: function () {
        this.showInstruction = true;
        document.body.classList.add('scrollable');
      },
      closeHome2screenInfo: function () {
        this.showInstruction = false;
        document.body.classList.remove('scrollable');
      },
      viewportHeightFix: function (){
        if(this.isMobile){
          setTimeout(function (){
            if(window.innerWidth >= 768){
              if(window.innerWidth < 1180 && window.orientation == 0 || window.innerWidth < 1180 && window.orientation == 180) {
                document.querySelector('.intro__text-wrap ').style.height = window.innerHeight * 0.5 + 'px';
              } else if(window.innerWidth < 1400 && window.orientation == 270 || window.innerWidth < 1400 && window.orientation == 90) {
                document.querySelector('.intro__text-wrap ').style.height = window.innerHeight * 0.45 + 'px';
              } else {
                document.querySelector('.intro__text-wrap ').style.height = window.innerHeight * 0.25 + 'px';
              }


            } else {
              if(window.innerWidth > 660 && window.orientation == 270 || window.innerWidth > 660 && window.orientation == 90) {
                document.querySelector('.intro__text-wrap ').style.height = window.innerHeight * 0.55 + 'px';
              } else {
                document.querySelector('.intro__text-wrap ').style.height = window.innerHeight * 0.588 + 'px';
              }
            }
            document.querySelector('.landing__intro-2').style.maxHeight = window.innerHeight + 'px';
          },10);
        } else {
          document.querySelector('.landing__intro-2').style.maxHeight = '';
          document.querySelector('.intro__text-wrap ').style.height = '';
        }
      }
    },
    mounted() {
      this.initialized = true;

      //home2screen-banner hide, if user open the app from standalone with iphone or chrome
      //firefox & samsung show always
      //let displayMode = 'browser tab';
      if (navigator.standalone) {
        //displayMode = 'standalone-ios';
        this.isAppInstalled = true;
      }
      if (window.matchMedia('(display-mode: standalone)').matches) {
       // displayMode = 'standalone';
        this.isAppInstalled = true;
      }
      if(this.isMobile && !this.isAppInstalled) {
        this.showAddToHome = true;
      }

      this.viewportHeightFix();

      window.onresize = () => {
        this.windowHeight = window.innerHeight
        this.windowWidth = window.innerWidth

        if(this.isMobile && this.lastWindowW == window.innerWidth  && this.isDetailVisible) {
          return false
        }

        this.viewportHeightFix();
        this.lastWindowW = window.innerWidth
        this.lastWindowH = window.innerHeight
      }

    },
  };
</script>
