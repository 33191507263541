<template>
  <div class="zoom-moon-passing native-pinch zoom-page">
    <div class="top-tool-nav-wrap">
      <a @click="$router.go(-1)" class="btn-close-circle" aria-hidden="true"><span class="visuallyhidden">close Zoom View</span></a>
    </div>
    <div class="zoomed-img-wrap">
      <div class="mask-layer-wrap">
        <svg preserveAspectRatio="xMaxYMin meet" viewBox="0 0 2000 1756" xml:space="preserve" class="mask-layer__top" :style="'height:'+ imageHeightLive">
          <image  xlink:href="/assets/img/moon-passing/mask-bg.jpg" width="2000" height="1756"  />
          <g v-html="this.thisSlides[this.activeId].clipath"></g>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
  import moonPassingData from "../../data/moon-passing";

  export default {
  name: 'zoomMoonPassingComponent',
    data() {
      return {
        thisSlides: moonPassingData.data()['slides'],
        imageHeightLive: 'auto'
      }
    },
    created() {
      this.activeId = this.$route.params.id;
      localStorage.setItem('moonPassingIndex', this.activeId)
    },
    beforeRouteLeave (to, from, next) {
      document.body.classList.remove('zoomed-opened');
      next();
    },
    methods:{
      setHeight: function (){
        var winH = window.innerHeight;
        document.body.style.height = winH + 'px';
        document.querySelector('.zoom-moon-passing').style.height = winH + 'px';
        document.querySelector('.zoomed-img-wrap').style.height = winH + 'px';
        var _this = this;

        if(window.innerWidth >= 768){
          var newHeight = winH - this.$el.querySelector('.btn-close-circle').clientHeight - 60;
          _this.imageHeightLive = newHeight + 'px';
          document.body.classList.remove('zoomed-overflow');
        } else {
          _this.imageHeightLive = 'auto';
          setTimeout(function (){
            if(document.querySelector('.mask-layer-wrap').clientHeight < window.innerHeight){
              document.body.classList.add('zoomed-overflow');
            }
          },5);
        }

      }
    },
    mounted() {
      var _this = this;
      document.body.classList.add('zoomed-opened');
      this.setHeight();

      window.onresize = () => {
        setTimeout(function (){
          _this.setHeight();
        },20)
      }
    }
  }
</script>
