<template>
  <div id="sun" class="work-page" :class="{ 'slider-active' : isSliderActive, 'detail-loaded' : isDetailVisible, 'work-page-loaded': isPageLoaded, 'work-library-modal': showModal}" @click="closeWorkShare">
    <div class="work__bg-wrap">
      <div class="work__bg">
        <img src="/assets/img/layout/bg-overview.jpg" class="work__bg-img"  alt="work background image">
      </div>
    </div>
    <div class="detail__bg" style="background-image: url('/assets/img/sun-spots/bg-info.jpg')"></div>
    <div class="container container--sun">
      <div class="page__top">
        <h1 class="page-top__title">{{ $t('sun-spots.title') }}</h1>
        <p class="page-top__text">Rosa Ursina <br />
          (Scheiner, 1630) </p>
      </div>
      <main class="main main--sun">
        <div class="work-content work-intro-ani-in">
          <div class="work__scaleable " :style="'height:'+ rangeWidthLive +'px; width:'+ rangeWidthLive +'px; margin-top:'+ workPosLive +'px'">
            <div class="sun__slider-wrap scale-item">
              <transition tag="div" name="sun__slide-img" mode="out-in">
                <div class="sun__slider" :style="'height:'+ slideWidthLive +'px; width:'+ slideWidthLive +'px'">
                  <template v-for="(slide, index) in sunslides" >
                    <svg preserveAspectRatio="xMaxYMin meet" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 2168 2168" :key="'key'+index" v-if="index == activeSlide" class="sun__slide-img">
                      <defs>
                        <clipPath :id="'clip_'+index" v-html="slide.clipath"></clipPath>
                      </defs>
                      <image :clip-path="'url(#clip_'+index+')'" xlink:href="/assets/img/sun-spots/sun.jpg" width="2168" height="2168" />
                    </svg>

                    <div v-if="index == activeSlide" :key="index" class="work__legend"><span class="sun__legend-span" :style="'bottom:'+ legendBottom +'px'">{{ slide.legend }}</span> </div>
                  </template>
                </div>
              </transition>
              <div class="sun-slider-bg" :style="'height:'+ slideWidthLive +'px; width:'+ slideWidthLive +'px'">
                <svg preserveAspectRatio="xMaxYMin meet" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 2168 2168" class="sun-slider-bg__img">
                  <defs>
                    <clipPath id="clip_bg">
                      <path class="st2" d="M1059,101.2c0,0-912.2-0.3-940,982.8c0,0-58.1,890.6,940,985.7c0,0,911.6,17.2,986.9-984.2C2045.9,1085.5,2057.8,172.6,1059,101.2z"/>
                    </clipPath>
                  </defs>
                  <image clip-path="url(#clip_bg)" xlink:href="/assets/img/sun-spots/sun.jpg" width="2168" height="2168" />
                </svg>
                <svg preserveAspectRatio="xMaxYMin meet" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 2168 2168" class="sun-slider-bg__img img--blur">
                  <defs>
                    <clipPath id="clip_bg-blur">
                      <path class="st2" d="M1059,101.2c0,0-912.2-0.3-940,982.8c0,0-58.1,890.6,940,985.7c0,0,911.6,17.2,986.9-984.2C2045.9,1085.5,2057.8,172.6,1059,101.2z"/>
                    </clipPath>
                  </defs>
                  <image clip-path="url(#clip_bg-blur)" xlink:href="/assets/img/sun-spots/sun.jpg" width="2168" height="2168" />
                </svg>
                <svg preserveAspectRatio="xMaxYMin meet" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 2168 2168" class="sun-slider-bg__img sun-mask-bg">
                  <path fill="rgba(255,255,255,0.8)" d="M1059,101.2c0,0-912.2-0.3-940,982.8c0,0-58.1,890.6,940,985.7c0,0,911.6,17.2,986.9-984.2C2045.9,1085.5,2057.8,172.6,1059,101.2z"/>
                </svg>
              </div>
            </div>
            <div class="range-slider scale-item" :style="'height:'+ rangeWidthLive +'px; width:'+ rangeWidthLive +'px'">
              <div class="range-slider__handle-track" :style="'transform: rotate(' + currentDeg + 'deg)'" ref="handle-track">
                <div class="range-slider__handle">
                  <span class="sicon sicon__slide-handle" aria-hidden="true"></span>
                  <span class="visuallyhidden">Slide Handle</span>
                </div>
              </div>
              <div class="range-slider__track">
                <svg width="376px" height="376px" viewBox="0 0 376 376" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <defs>
                    <rect id="path-1" x="-3.81469727e-06" y="-3.81469727e-06" width="271" height="92"></rect>
                  </defs>
                  <g id="Artboard" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" opacity="0.300000012">
                    <g id="Oval" transform="translate(52.000000, 289.000000)">
                      <mask id="mask-2" fill="white">
                        <use xlink:href="#path-1"></use>
                      </mask>
                      <g id="Mask"></g>
                      <circle stroke="#FFFFFF" mask="url(#mask-2)" cx="136" cy="-102" r="188"></circle>
                    </g>
                  </g>
                </svg>
              </div>
            </div>
            <div id="range-interact"></div>
          </div>
        </div>
        <detail v-on:show-modal="showModalMsg"></detail>
      </main>
    </div>
    <menuprimary current-nav="sun-spots" :needNavprojects=true  v-on:toggle-detail="toggleDetail"></menuprimary>
    <div class="top-tool-nav-wrap">
      <div @click="toggleDetail" v-if="isDetailVisible" class="btn-close-circle" aria-hidden="true">
        <span class="visuallyhidden">close detail content</span>
      </div>
      <share-section v-if="isDetailVisible"></share-section>
      <router-link to="overview" class="btn-close-circle" aria-hidden="true" v-else>
        <span class="visuallyhidden">go to overview page</span>
      </router-link>
      <router-link :to="'zoom-sun-spots/'+this.activeSlide" class="zoom-btn">
        <span class="sicon sicon__zoom-more" aria-hidden="true"></span>
        <span class="visuallyhidden">Link zu zoomed img</span>
      </router-link>
    </div>
    <modal v-if="showModal" url="https://www.e-rara.ch/zut/content/pageview/161337" @close="showModal=false"></modal>
  </div>
</template>

<script>
  import suns from '../../data/sun'
  import iSunSpots from '../info/i-sun-spots'
  import Modal from '../../components/modal';
  import MenuPrimary from '../../components/menu-primary';
  import Slides from '../../data/planet-slides';
  import ShareSection from '../../components/share';

  import anime from 'animejs';
  import Hammer from '../../assets/js/hammer.min';

  export default {
    name: 'sunspotsComponent',
    components: {
      'detail': iSunSpots,
      'modal': Modal,
      'menuprimary': MenuPrimary,
      'share-section': ShareSection
    },
    data() {
      return {
        activeSlide: 0,
        printIndex:1,
        bg: false,
        isSliderActive: false,
        sunslides:suns.data()['slides'],
        degRange : [45,135],
        currentDeg : '',
        showModal: false,
        isDetailVisible: false,
        windowWidth:'',
        rangeWidthLive:'',
        slideWidthLive:'',
        slides: Slides.data()['Slides'],
        legendBottom:'',
        workPosLive:'',
        zoomEl:'',
        mc:'',
        pinchTimmer:0,
        isPageLoaded: false,
        instAniInterval: null,
        instAniLoadTimer: null,
        pageLoadTimer: null,
      }
    },
    created() {
      this.currentDeg = this.degRange[0];
      this.isMobile = this.mobileAndTabletcheck()
    },
    methods: {
      instractionAni: function () {
        var step = 0;
        var _this = this;
        this.instAniInterval = setInterval(frame, 150);

        function frame() {
          if (step == 12 || _this.isDetailVisible) {
            _this.clearIntervalInstAni()
          } else {
            if((step >= 3 && step < 6)|| (step >= 9 && step < 12)) {
              //3,4,5, 9, 10,11
              _this.stepDown()
            } else {
              //0,1,2, 6,7,8
              _this.stepUp()
            }
          }
          step++;
        }
      },
      clearIntervalInstAni : function() {
        if(this.instAniInterval) {
          clearInterval(this.instAniInterval);
        }
        if(this.instAniLoadTimer) {
          clearTimeout(this.instAniLoadTimer);
        }
      },
      stepUp: function () {
        this.activeSlide++
        if(this.activeSlide > this.sunslides.length -1) {
          this.activeSlide = this.sunslides.length -1
        }
        this.getHandlePos()
      },
      stepDown: function () {
        this.activeSlide--
        if(this.activeSlide < 0) {
          this.activeSlide = 0
        }
        this.getHandlePos()
      },
      getHandlePos: function () {
        var sunslidesLength = this.sunslides.length -1
        var totalSlideDeg = this.degRange[1] - this.degRange[0]
        var stepDeg = totalSlideDeg / sunslidesLength
        this.currentDeg = this.activeSlide * stepDeg + this.degRange[0]
        this.isSliderActive = true
      },
      handleOnPress: function() {
      // eslint-disable-next-line no-undef
       gtag('event', 'rangeSlider', {
          'event_category' : 'sun-spots',
          'event_label' : 'sun-spots',
        });
        this.isSliderActive = true
      },
      handleOnPressup: function() {
        this.isSliderActive = false
      },
      rangeHandleOnPan: function (e) {
        //console.log(e);
        if(!this.isSliderActive ) {
          this.isSliderActive = true
        }
        var sunslidesLength = this.sunslides.length -1
        var totalSlideDeg = this.degRange[1] - this.degRange[0]
        var stepDeg = totalSlideDeg / sunslidesLength
        var rangeInteract = this.$el.querySelector('#range-interact').getBoundingClientRect()
        var radius = rangeInteract.height / 2
        var _y = e.center.y + (rangeInteract.top * -1)
        var _x = e.center.x + (rangeInteract.left * -1)
        var atan = Math.atan2(_y - radius, _x - radius)
        var deg = (180 * Math.abs(atan)) / Math.PI

        if( deg >= this.degRange[1] ) {
          deg = this.degRange[1]
        }
        if( deg <= this.degRange[0] ) {
          deg = this.degRange[0]
        }

        this.currentDeg = deg
        var _curSlideIndex = Math.round((deg - this.degRange[0]) / stepDeg)
        this.activeSlide = _curSlideIndex

        if(e.isFinal) {
          if(this.activeSlide == 0 || this.activeSlide == sunslidesLength) {
            this.isSliderActive = false
          }
        }
      },
      showModalMsg : function() {
        this.showModal = true
      },
      toggleDetail:function () {
        if(!this.isDetailVisible) {
          // eslint-disable-next-line no-undef
          gtag('config', this.uaId, {'page_path': this.$route.path + '-i-detail'});
        } else {
          // eslint-disable-next-line no-undef
          gtag('config', this.uaId, {'page_path': this.$route.path });

          var audio = this.$el.querySelectorAll('audio')[0];
          if(!audio.paused){
            audio.pause();
          }
        }

        var _this = this;
        this.isDetailVisible =  !this.isDetailVisible
        var _scale = 1;
        var workHTo =  window.innerHeight - this.$el.querySelector('.nav-bottom').clientHeight
        var workMarginTop = 0;
        if(this.isDetailVisible) {
          document.body.classList.add('detail-opened');
          _scale = 0.7;
          this.resetWorkZoom();
          workHTo = this.rangeWidthLive * 0.7;
          if(window.innerWidth > window.innerHeight) {
            workMarginTop = '10%'
          } else {
            workMarginTop = this.$el.querySelector('.page__top').clientHeight *  0.9;
          }
        } else {
          document.body.classList.remove('detail-opened');
        }

        anime({
          targets: [this.$el.querySelectorAll('.scale-item')],
          scale: _scale,
          duration: 500,
          easing:'easeOutQuart',
          complete: function() {
            _this.getRangeWidth();
          }
        });
        anime({
          targets: [this.$el.querySelector('.work-content')],
          height: workHTo,
          marginTop: workMarginTop,
          duration: 500,
          easing:'easeOutQuart',
        });
      },
      resizeWorkDetail: function() {
        var work = this.$el.querySelector('.work-content');
        if(this.isDetailVisible) {
          work.style.height =  this.rangeWidthLive * 0.7 + 'px';
          if(window.innerWidth > window.innerHeight) {
            work.style.marginTop = '10%'
          } else {
            work.style.marginTop = this.$el.querySelector('.page__top').clientHeight *  0.9 + 'px';
          }
        }
      },
      getRangeWidth: function() {
        const legend = this.$el.querySelector(".sun__legend-span");
        let legendH = legend.clientHeight;
        var winW = window.innerWidth;
        var winH = window.innerHeight;
        var workH = winH - this.$el.querySelector('.nav-bottom').clientHeight;
        if(!this.isDetailVisible) {
          this.$el.querySelector('.work-content').style.height = workH + 'px';
        }
        var availH = workH - this.$el.querySelector('.page__top').clientHeight - legendH + this.$el.querySelector('.page-top__text').clientHeight;
        var _bottomNav = parseInt(window.getComputedStyle(this.$el.querySelector('.nav-bottom')).getPropertyValue('bottom'))
        if(_bottomNav  > 0) {
          availH = availH - _bottomNav
        }
        if(availH < 200) {
          availH = 200
        }

        if(winW < 1025){
          availH = workH - this.$el.querySelector('.page__top').clientHeight - legendH;
        }

        var maxAailH = Math.min(winW, availH)
        this.rangeWidthLive = maxAailH
        this.slideWidthLive = this.rangeWidthLive * 0.85
        //this.workPosLive = this.rangeWidthLive * 0.15 * -1;
        if(winW < 1025){
          this.legendBottom = (this.rangeWidthLive * 0.15 + legendH ) * -1 ;
        } else {
          this.legendBottom = (this.rangeWidthLive * 0.135 + legendH ) * -1 ;
        }

      },
      resetWorkZoom : function() {
        var transform = "";
        this.zoomEl.style.transform = transform;
        this.zoomEl.style.webkitTransform = transform;
      },
      pageLoad : function() {
        this.isPageLoaded = true
        var _this = this
        this.pageLoadTimer = setTimeout(function () {
          _this.$el.querySelector(".work-intro-ani-in").classList.remove('work-intro-ani-in')
        }, 1600)
      },
    },
    beforeRouteLeave (to, from, next) {
      this.clearIntervalInstAni()
      if(this.pageLoadTimer) {
        clearTimeout(this.pageLoadTimer);
      }
      document.body.classList.remove('detail-opened');
      localStorage.setItem('sunIndex', '')
      this.aniMenuPageLeave(next());
    },
    mounted() {
      this.saveCurrentPlanetToLocalStorage();
      this.zoomEl = this.$el.querySelector(".work__scaleable");
      const rangeInteract = this.$el.querySelector("#range-interact");
      const mc = new Hammer.Manager(rangeInteract);
      this.windowWidth = window.innerWidth

      mc.domEvents = true
      mc.add( new Hammer.Pan({ direction: Hammer.DIRECTION_ALL}) );
      mc.add( new Hammer.Tap() );
      mc.add( new Hammer.Pinch({ threshold: 0 }) );
      mc.add( new Hammer.Press({
          event: 'press',
          pointer: 1,
          threshold: 500,
          time: 1,
        })
      );

      mc.on('pan', (e) => {
        var old = this.pinchTimmer
        var now = new Date().getTime(),
          delta = now - old;

        this.clearIntervalInstAni()

        //avoid pinch end -> pan
        if(delta > 100) {
          this.pinchTimmer = 0
          this.rangeHandleOnPan(e)
        }
      });

      //slide by tap
      mc.on('tap', (e) => {
        this.clearIntervalInstAni()

        var half = rangeInteract.offsetWidth / 2
        var posX = e.srcEvent.layerX

        if(posX < half) {
          this.stepUp()
        } else {
          this.stepDown()
        }
      });
      mc.on('press', (e) => {
        this.handleOnPress(e)
      });
      mc.on('pressup', () => {
        // this.handleOnPressup(e)
      });
      this.mc = mc;
      this.getRangeWidth();
      this.pageLoad();
      this.setWorkBgPos();

      if(localStorage.getItem('sunIndex')) {
        this.activeSlide = localStorage.getItem('sunIndex')
        this.getHandlePos()

        if(this.activeSlide == 0 || this.activeSlide == this.sunslides.length - 1 ){
          this.isSliderActive = false;
        }
      } else {
        this.instAniLoadTimer = setTimeout(this.instractionAni,2000)
      }

      window.onresize = () => {
        if(this.isMobile && this.windowWidth == window.innerWidth) {
          return false
        }
        this.getRangeWidth()
        this.resizeWorkDetail()
        this.windowWidth = window.innerWidth
      }
    }
  }
</script>
