<script>
  export default {
    name: 'saturnium',
    data: function (){
      return {
        slides : [
          {
            stepId: "1",
            legendDate: "25.",
            legendMonth: "März",
            legendYear: "1655",
            image: "saturnium-01.jpg",
            zoomImage: "saturnium-01-zoom.jpg",
            link: "https://www.e-rara.ch/zut/content/zoom/965555"
          },
          {
            stepId: "2",
            legendDate: "26.",
            legendMonth: "März",
            legendYear: "1655",
            image: "saturnium-02.jpg",
            zoomImage: "saturnium-02-zoom.jpg",
            link: "https://www.e-rara.ch/zut/content/zoom/965557"
          },
          {
            stepId: "3",
            legendDate: "27.",
            legendMonth: "März",
            legendYear: "1655",
            image: "saturnium-03.jpg",
            zoomImage: "saturnium-03-zoom.jpg",
            link: "https://www.e-rara.ch/zut/content/zoom/965558"
          },
          {
            stepId: "4",
            legendDate: "03.",
            legendMonth: "April",
            legendYear: "1655",
            image: "saturnium-04.jpg",
            zoomImage: "saturnium-04-zoom.jpg",
            link: "https://www.e-rara.ch/zut/content/zoom/965558"
          },
          {
            stepId: "5",
            legendDate: "09.",
            legendMonth: "April",
            legendYear: "1655",
            image: "saturnium-05.jpg",
            zoomImage: "saturnium-05-zoom.jpg",
            link: "https://www.e-rara.ch/zut/content/zoom/965558"
          },
          {
            stepId: "6",
            legendDate: "10.",
            legendMonth: "April",
            legendYear: "1655",
            image: "saturnium-06.jpg",
            zoomImage: "saturnium-06-zoom.jpg",
            link: "https://www.e-rara.ch/zut/content/zoom/965558"
          },
          {
            stepId: "7",
            legendDate: "11.",
            legendMonth: "April",
            legendYear: "1655",
            image: "saturnium-07.jpg",
            zoomImage: "saturnium-07-zoom.jpg",
            link: "https://www.e-rara.ch/zut/content/zoom/965559"
          },
          {
            stepId: "8",
            legendDate: "12.",
            legendMonth: "April",
            legendYear: "1655",
            image: "saturnium-08.jpg",
            zoomImage: "saturnium-08-zoom.jpg",
            link: "https://www.e-rara.ch/zut/content/zoom/965559"
          },
          {
            stepId: "9",
            legendDate: "13.",
            legendMonth: "April",
            legendYear: "1655",
            image: "saturnium-09.jpg",
            zoomImage: "saturnium-09-zoom.jpg",
            link: "https://www.e-rara.ch/zut/content/zoom/965559"
          },
          {
            stepId: "10",
            legendDate: "17.",
            legendMonth: "April",
            legendYear: "1655",
            image: "saturnium-10.jpg",
            zoomImage: "saturnium-10-zoom.jpg",
            link: "https://www.e-rara.ch/zut/content/zoom/965559"
          },
          {
            stepId: "11",
            legendDate: "19.",
            legendMonth: "April",
            legendYear: "1655",
            image: "saturnium-11.jpg",
            zoomImage: "saturnium-11-zoom.jpg",
            link: "https://www.e-rara.ch/zut/content/zoom/965559"
          },
          {
            stepId: "12",
            legendDate: "20.",
            legendMonth: "April",
            legendYear: "1655",
            image: "saturnium-12.jpg",
            zoomImage: "saturnium-12-zoom.jpg",
            link: "https://www.e-rara.ch/zut/content/zoom/965560"
          },
          {
            stepId: "13",
            legendDate: "21.",
            legendMonth: "April",
            legendYear: "1655",
            image: "saturnium-13.jpg",
            zoomImage: "saturnium-13-zoom.jpg",
            link: "https://www.e-rara.ch/zut/content/zoom/965560"
          },
          {
            stepId: "14",
            legendDate: "29.",
            legendMonth: "April",
            legendYear: "1655",
            image: "saturnium-14.jpg",
            zoomImage: "saturnium-14-zoom.jpg",
            link: "https://www.e-rara.ch/zut/content/zoom/965560"
          },
          {
            stepId: "15",
            legendDate: "03.",
            legendMonth: "Mai",
            legendYear: "1655",
            image: "saturnium-15.jpg",
            zoomImage: "saturnium-15-zoom.jpg",
            link: "https://www.e-rara.ch/zut/content/zoom/965560"
          },
          {
            stepId: "16",
            legendDate: "06.",
            legendMonth: "Mai",
            legendYear: "1655",
            image: "saturnium-16.jpg",
            zoomImage: "saturnium-16-zoom.jpg",
            link: "https://www.e-rara.ch/zut/content/zoom/965560"
          },
          {
            stepId: "17",
            legendDate: "07.",
            legendMonth: "Mai",
            legendYear: "1655",
            image: "saturnium-17.jpg",
            zoomImage: "saturnium-17-zoom.jpg",
            link: "https://www.e-rara.ch/zut/content/zoom/965560"
          },
          {
            stepId: "18",
            legendDate: "10.",
            legendMonth: "Mai",
            legendYear: "1655",
            image: "saturnium-18.jpg",
            zoomImage: "saturnium-18-zoom.jpg",
            link: "https://www.e-rara.ch/zut/content/zoom/965560"
          },
          {
            stepId: "19",
            legendDate: "11.",
            legendMonth: "Mai",
            legendYear: "1655",
            image: "saturnium-19.jpg",
            zoomImage: "saturnium-19-zoom.jpg",
            link: "https://www.e-rara.ch/zut/content/zoom/965560"
          },
          {
            stepId: "20",
            legendDate: "12.",
            legendMonth: "Mai",
            legendYear: "1655",
            image: "saturnium-20.jpg",
            zoomImage: "saturnium-20-zoom.jpg",
            link: "https://www.e-rara.ch/zut/content/zoom/965560"
          },
          {
            stepId: "21",
            legendDate: "14.",
            legendMonth: "Mai",
            legendYear: "1655",
            image: "saturnium-21.jpg",
            zoomImage: "saturnium-21-zoom.jpg",
            link: "https://www.e-rara.ch/zut/content/zoom/965560"
          },
          {
            stepId: "22",
            legendDate: "15.",
            legendMonth: "Mai",
            legendYear: "1655",
            image: "saturnium-22.jpg",
            zoomImage: "saturnium-22-zoom.jpg",
            link: "https://www.e-rara.ch/zut/content/zoom/965560"
          },
          {
            stepId: "23",
            legendDate: "18.",
            legendMonth: "Mai",
            legendYear: "1655",
            image: "saturnium-23.jpg",
            zoomImage: "saturnium-23-zoom.jpg",
            link: "https://www.e-rara.ch/zut/content/zoom/965561"
          },
          {
            stepId: "24",
            legendDate: "19.",
            legendMonth: "Mai",
            legendYear: "1655",
            image: "saturnium-24.jpg",
            zoomImage: "saturnium-24-zoom.jpg",
            link: "https://www.e-rara.ch/zut/content/zoom/965561"
          },
          {
            stepId: "25",
            legendDate: "20.",
            legendMonth: "Mai",
            legendYear: "1655",
            image: "saturnium-25.jpg",
            zoomImage: "saturnium-25-zoom.jpg",
            link: "https://www.e-rara.ch/zut/content/zoom/965561"
          }
        ]
      }
    }
  }
</script>
