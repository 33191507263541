<script>
  export default {
    name: 'Slides',
    data: function (){
      return {
        Slides: [
          {
            headline: 'Apian-Volvelle',
            subheadline: 'Cosmographia<br>(Apian Petrus, 1546)',
            img: '/assets/img/lunar-clock/thumb.jpg',
            thumb: '/assets/img/lunar-clock/thumb--small.jpg',
            style: '',
            url: 'lunar-clock',
          },
          {
            headline: 'Saturn-Ringe',
            subheadline: 'Systema Saturnium<br>(Christian Huygens, 1659)',
            img: '/assets/img/saturn-rings/thumb.jpg',
            thumb: '/assets/img/saturn-rings/thumb--small.jpg',
            style: '',
            url: 'saturn-rings',
          },
          {
            headline: 'Volvelle',
            subheadline: 'Organum Uranicum <br>(Sebastian Münster, 1536)',
            img: '/assets/img/volvelle/thumb.jpg',
            thumb: '/assets/img/volvelle/thumb--small.jpg',
            style: '',
            url: 'volvelle',
          },
          {
            headline: 'Saturnmond Titan',
            subheadline: 'Systema Saturnium <br>(Christian Huygens, 1659)',
            img: '/assets/img/saturnium/thumb.jpg',
            thumb: '/assets/img/saturnium/thumb--small.jpg',
            style: '',
            url: 'systema-saturnium',
          },

          {
            headline: 'Christliche Sternbilder',
            subheadline: 'Coelum stellatum christianum <br>(Schiller, 1627)',
            img: '/assets/img/coelum-stellatum/thumb.jpg',
            thumb: '/assets/img/coelum-stellatum/thumb--small.jpg',
            style: '',
            url: 'coelum-stellatum',
          },
          {
            headline: 'Moon Passing Jupiter',
            subheadline: 'Selenographia  <br>(Hevelius, 1647)',
            img: '/assets/img/moon-passing/thumb.jpg',
            thumb: '/assets/img/moon-passing/thumb--small.png',
            style: '',
            url: 'moon-passing',
          },
          {
            headline: 'Inkunabel',
            subheadline: 'Theoricae novae planetarum <br>(Peuerbach, 1485)',
            img: '/assets/img/inkunabel/thumb.jpg',
            thumb: '/assets/img/inkunabel/thumb--small.jpg',
            style: '',
            url: 'inkunabel',
          },
          {
            headline: 'Schleifenbewegung',
            subheadline: 'Atlas Novus Coelestis <br>(Doppelmayr, 1742)',
            img: '/assets/img/atlas-novus/thumb.jpg',
            thumb: '/assets/img/atlas-novus/thumb--small.jpg',
            style: '',
            url: 'atlas-novus',
          },
          {
            headline: 'Halleyscher Komet',
            subheadline: 'Astronomicum Caesareum <br>(Apian, 1540)',
            img: '/assets/img/halley-comet/thumb.jpg',
            thumb: '/assets/img/halley-comet/thumb--small.jpg',
            style: '',
            url: 'halley-comet',
          },
          {
            headline: 'Kosmologien',
            subheadline: 'Harmonia Macrocosmica <br>(Cellarius, 1708)',
            img: '/assets/img/harmonia/thumb.jpg',
            thumb: '/assets/img/harmonia/thumb--small.jpg',
            style: '',
            url: 'harmonia',
          },
          {
            headline: 'Tierkreiszeichen',
            subheadline: 'Uranometria <br />(Bayer, 1603)',
            img: '/assets/img/uranometria/thumb.jpg',
            thumb: '/assets/img/uranometria/thumb--small.jpg',
            style: '',
            url: 'uranometria',
          },
          {
            headline: 'Planisphaerium',
            subheadline: 'Opera mathematica <br>(Schöner, 1551)',
            img: '/assets/img/astrolab/thumb.jpg',
            thumb: '/assets/img/astrolab/thumb--small.jpg',
            style: '',
            url: 'astrolab',
          },
          {
            headline: 'Jupitermonde',
            subheadline: 'Sidereus Nuncius <br />(Galilei, 1610)',
            img: '/assets/img/jupiters-moons/thumb.jpg',
            thumb: '/assets/img/jupiters-moons/thumb--small.jpg',
            style: '',
            url: 'jupiters-moons',
          },
          {
            headline: 'Sonnenflecken',
            subheadline: 'Rosa Ursina <br />(Scheiner, 1630)',
            img: '/assets/img/sun-spots/thumb.jpg',
            thumb: '/assets/img/sun-spots/thumb--small.jpg',
            style: '',
            url: 'sun-spots',
          },
          {
            headline: 'Sternenkarte',
            subheadline: 'Astronomicum Caesareum <br>(Apian, 1540)',
            img: '/assets/img/stars-map/thumb.jpg',
            thumb: '/assets/img/stars-map/thumb--small.jpg',
            style: '',
            url: 'star-map',
          },
          {
            headline: 'Mondphasen',
            subheadline: 'Selenographia <br />(Hevelius, 1647)',
            img: '/assets/img/lunar-phases/thumb.jpg',
            thumb: '/assets/img/lunar-phases/thumb--small.jpg',
            style: '',
            url: 'lunar-phases',
          }
        ]
      }
    }
  }
</script>

