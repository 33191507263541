<template>
  <div class="zoom-coelum-stellatum native-pinch zoom-page">
    <div class="top-tool-nav-wrap">
      <a @click="$router.go(-1)" class="btn-close-circle" aria-hidden="true"><span class="visuallyhidden">close Zoom View</span></a>
    </div>
    <div class="zoomed-img-wrap">
      <img
        :src="'/assets/img/coelum-stellatum/'+ activeId +'-'+ type +'.jpg'"
        :alt="$t('coelum-stellatum.'+ activeId +'.title.'+ type)"
        class="zoomed-img__img"
        :style="'height:' + imageHeightLive"
        style="opacity: 0"
      >
    </div>
  </div>
</template>

<script>
  export default {
    name: 'zoomCoelumStellatumComponent',
    data() {
      return {
        imageHeightLive: 'auto'
      }
    },
    created() {
      this.activeId = this.$route.params.id;
      this.type = this.$route.params.type;
      localStorage.setItem('coelumStellatumIndexZoom', this.activeId);
    },
    methods: {
      setHeight: function (){
        var winH = window.innerHeight;
        document.body.style.height = winH + 'px';
        document.querySelector('.zoomed-img-wrap').style.height = winH + 'px';
        var _this = this;
        var newHeight = winH - this.$el.querySelector('.btn-close-circle').clientHeight - 10;

        if(window.innerWidth >= 768){
          _this.imageHeightLive = newHeight + 'px';
          document.body.classList.remove('zoomed-overflow');
        } else {
          _this.imageHeightLive = 'auto';
          setTimeout(function (){
            if(document.querySelector('.zoomed-img__img').clientHeight < window.innerHeight){
              document.body.classList.add('zoomed-overflow');
            }
          },5);
        }

        setTimeout(function (){
          if(window.innerWidth >= 768){
            var gap = 80;
          } else {
            gap = 40;
          }

          if(document.querySelector('.zoomed-img__img').clientWidth > window.innerWidth - gap){
            document.querySelector('.zoomed-img__img').style.width = window.outerWidth - gap + 'px';
            _this.imageHeightLive = 'auto';
            _this.$el.querySelector('.zoomed-img-wrap').classList.add('centered');
          } else {
            document.querySelector('.zoomed-img__img').style.width = 'auto';
            _this.imageHeightLive = newHeight + 'px';
            _this.$el.querySelector('.zoomed-img-wrap').classList.remove('centered');
          }
        },10);
      }
    },
    beforeRouteLeave (to, from, next) {
      document.body.classList.remove('zoomed-opened');
      localStorage.setItem('coelumStellatumIndex', this.activeId)
      localStorage.setItem('zoomCoelumStellatumType', this.type)
      next();
    },
    mounted() {
      document.body.classList.add('zoomed-opened');
      var _this = this;

      this.setHeight();

      setTimeout(function (){
        _this.$el.querySelector('.zoomed-img__img').style.opacity = '1';
      },100)

      window.onresize = () => {
        setTimeout(function (){
          _this.setHeight();
        },50)
      }

    }
  }
</script>
