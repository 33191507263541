<template>
  <div id="about-us" class="default-page about-us">
    <div class="about-us__bg">
      <img src="/assets/img/layout/bg-overview.jpg" class="about-us__bg-img"  alt="overview background image">
    </div>
    <div class="container">
      <main class="main">
        <section class="section">
          <h1 class="page-top__title">{{ $t('aboutus.title') }}</h1>
          <div class="interactive-content rte" ref="inter-cont-1">
            <div v-show="excerpt[1]">
              <span  v-html="$t('aboutus.excerpt1')"></span>
              <span v-touch="(event) => { showAll(1) }" class="link show-more">{{ $t('readmore') }}</span>
            </div>
            <div class="more-content" :class="{'more-content--visible':readMore[1]}" v-html="$t('aboutus.readmoretext1')"></div>
            <router-link to="overview" class="btn btn--arrow about-us__btn--arrow">
              {{ $t('enter Overview') }} <span class="sicon sicon__internal-link" aria-hidden="true"></span>
            </router-link>
          </div>
        </section>
        <section class="section section--accordion">
          <h2 class="section__title">FAQ</h2>
          <ul class="accordion">
            <li class="accordion-item" v-for="(item, index) in accordionItems" :key="index">
              <a class="accordion__title" href="#"  @click.prevent="toggleItem(index)" :class="{'open': item.isOpen}">
                <i class="sicon sicon__arrow-down"></i>
                <span>{{item.title}}</span>
              </a>
              <transition name="slide">
                <div v-if="item.isOpen" class="accordion__text" v-html="item.text"></div>
              </transition>
            </li>
          </ul>
        </section>
        <section class="section">
          <h2 class="section__title">{{ $t('aboutus.aboutlibrary_title') }}</h2>
          <div class="rte" v-html="$t('aboutus.aboutlibrary_text')"></div>
        </section>
        <section class="section">
          <div class="award">
            <div class="rte" v-html="$t('aboutus.bosa2020')"></div>
            <img src="/assets/img/layout/logo_bosa.png" alt="Best of Swiss Apps Award 2020">
          </div>
        </section>
      </main>
    </div>
    <menuprimary current-nav="about-us"></menuprimary>
    <div class="top-tool-nav-wrap">
      <router-link to="overview" class="btn-close-circle" aria-hidden="true">
        <span class="visuallyhidden">go to overview page</span>
      </router-link>
    </div>
  </div>
</template>

<script>
  import MenuPrimary from '../../components/menu-primary';

  export default {
    name: 'aboutusComponent',
    components: {
      'menuprimary': MenuPrimary
    },
    data() {
      return {
        excerpt: [true, true, true],
        readMore: [false, false, false],
        accordionItems: [
          { title: this.$t('aboutus.accordion.1.title'), text: this.$t('aboutus.accordion.1.text'), isOpen: false },
          { title: this.$t('aboutus.accordion.2.title'), text: this.$t('aboutus.accordion.2.text'), isOpen: false },
          { title: this.$t('aboutus.accordion.3.title'), text: this.$t('aboutus.accordion.3.text'), isOpen: false }
        ],
        isActive: null
      }
    },
    beforeRouteLeave (to, from, next) {
      document.body.classList.remove('scrollable');
      this.aniMenuPageLeave(next());
    },

    methods: {
      showAll: function(index) {
        this.$set(this.excerpt, index, false)
        this.$set(this.readMore, index, true)
      },
      toggleItem(index) {
        this.accordionItems[index].isOpen = !this.accordionItems[index].isOpen;
      },
    },
    mounted() {
      localStorage.AstroActivePlanet = '';
      document.body.classList.add('scrollable');
    },
  };
</script>
