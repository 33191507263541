<script>
  export default {
    name: 'uranometria',
    data: function () {
      return {
        books : [
          {
            id: 0,
            name: "Brahe",
            img: "image_brahe.png",
            info: 3,
            idText: 'brahe'
          },
          {
            id: 1,
            name: "Ptolemäus",
            img: "image_ptolemaus.png",
            info: 3,
            idText: 'ptolemaeus'
          },
          {
            id: 2,
            name: "Copernicus",
            img: "image_copernicus.png",
            info: 3,
            idText: 'copernicus'
          },
        ],
      }
    }
  };
</script>
