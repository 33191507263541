<template>
  <div id="inkunabel" class="work-page" :class="{ 'detail-loaded' : isDetailVisible, 'work-page-loaded': isPageLoaded, 'work-library-modal': showModal }" @click="closeWorkShare">

    <div class="work__bg-wrap">
      <div class="work__bg">
        <img src="/assets/img/layout/bg-overview.jpg" class="work__bg-img"  alt="work background image">
      </div>
      <div class="inkunabel__sun" :style="'height:'+ slideWidthLive +'px; top:' + this.sunTop + ';'">
      <img src="/assets/img/inkunabel/sun.png" alt="sun" :style="'height:'+ sunHeightLive +'px'">
      </div>
    </div>
    <div class="detail__bg" style="background-image: url('/assets/img/inkunabel/bg-info.jpg')"></div>
    <div class="container" >
      <div class="page__top">
        <h1 class="page-top__title">{{ $t('Inkunabel') }}</h1>
        <p class="page-top__text">Theoricae Novae Planetarum <br />(Peuerbach, 1485)</p>
      </div>

      <main class="main main--inkunabel">
        <div class="work-content work-intro-ani-in">
          <div class="work__scaleable " :style="'height:'+ slideWidthLive +'px; width:'+ slideWidthLive +'px; margin-top:'+ workPosLive +'px'">
            <div class="inkunabel-wrap scale-item">
                <div class="inkunabel" :style="'height:'+ slideWidthLive +'px; width:'+ slideWidthLive +'px'">
                  <div class="inkunabel-img-wrap">
                    <div v-for="(slide, index) in moonslides" :key="'inkunabel_moonphases_big'+index" class="inkunabel__moonphase" :class="[`inkunabel__moonphase__${index}`,  activeSlide === index ? 'active' : '']">
                      <img :src="'/assets/img/inkunabel/moonphases/' + slide.orbitimg" :alt="$t('inkunabel.moonphases')">
                      <img :src="'/assets/img/inkunabel/moonphases/moonphase-10-01.png'"  :alt="$t('inkunabel.moonphases')" v-if="index == 9" class="solaris-01">
                      <img :src="'/assets/img/inkunabel/moonphases/moonphase-10-02.png'"  :alt="$t('inkunabel.moonphases')" v-if="index == 9" class="solaris-02">
                      <a v-if="index == 3 || index == 9" href="#" class="icon-circle" aria-hidden="true" @click="openEclipse(index)">
                        <span class="visuallyhidden">additional info</span>
                      </a>
                    </div>
                    <div class="inkunabel__bracket-wrap" :style="'transform: ' + rotateBracket +''">
                      <img :src="'/assets/img/inkunabel/brackets.svg'" alt="" class="inkunabel__bracket">
                    </div>
                    <svg width="230" height="230" viewBox="0 0 230 230" fill="none" xmlns="http://www.w3.org/2000/svg" class="inkunabel__circle">
                      <circle opacity="0.3" cx="115" cy="115" r="114.5" stroke="white"/>
                    </svg>
                    <div class="inkunabel__earth">
                      <img :src="'/assets/img/inkunabel/earth.png'" alt="earth">
                    </div>
                  </div>
                </div>
            </div>
            <div class="inkunabel-description-wrap" :style="'max-width:' + rangeWidthLive + 'px'">
              <p class="inkunabel-description" v-if="activeSlide != 3 && activeSlide != 9">{{ $t('inkunabel.moonphases') }}</p>
              <p class="inkunabel-description inkunabel-description--left" v-if="activeSlide == 3">{{ $t('inkunabel.lunaris') }}</p>
              <p class="inkunabel-description inkunabel-description--right" v-if="activeSlide == 9">{{ $t('inkunabel.solaris') }}</p>
            </div>
            <ul class="inkunabel-moon-phases" :style="'width:' + rangeWidthLive + 'px'">
              <li v-for="(slide, index) in moonslides" :key="'inkunabel_moonphases_'+index" class="moonphase" :class="[`moonphase__${index}`,  activeSlide === index ? 'active' : '']">
                <img :src="'/assets/img/inkunabel/moonphases/' + slide.moonphaseimg" :alt="$t('inkunabel.moonphases')" @click="activateMoon(index)">
                <img :src="'/assets/img/inkunabel/moonphases/moonphase-10-01.png'"  :alt="$t('inkunabel.moonphases')" v-if="index == 9" class="solaris-01">
                <img :src="'/assets/img/inkunabel/moonphases/moonphase-10-02.png'"  :alt="$t('inkunabel.moonphases')" v-if="index == 9" class="solaris-02">
              </li>
            </ul>
            <div class="range-slider range-slider--horizontal scale-item" :style="'width:'+ rangeWidthLive +'px'">
              <div class="range-slider__handle-track--horizontal" :style="'transform: translateX(' + currentPos + 'px)'" ref="handle-track">
                <div class="range-slider__handle--horizontal">
                  <span class="sicon sicon__slide-handle" aria-hidden="true"></span>
                  <span class="visuallyhidden">Slide Handle</span>
                </div>
              </div>
              <div class="range-slider__track--horizontal">
                <svg width="339px" height="12px" viewBox="0 0 339 12" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g>
                      <rect fill-opacity="0.3" fill="#FFFFFF" fill-rule="nonzero" x="0" y="6" width="81" height="1"></rect>
                      <rect fill-opacity="0.3" fill="#FFFFFF" fill-rule="nonzero" x="97" y="6" width="144" height="1"></rect>
                      <rect fill-opacity="0.3" fill="#FFFFFF" fill-rule="nonzero" x="257" y="6" width="82" height="1"></rect>
                      <g opacity="0.35" transform="translate(242.000000, 0.000000)">
                        <path d="M0.558,10.712 C0.314,10.956 0.314,11.352 0.558,11.596 C0.802,11.84 1.198,11.84 1.442,11.596 L0.558,10.712 Z M6.558,11.596 C6.802,11.84 7.198,11.84 7.442,11.596 C7.686,11.352 7.686,10.956 7.442,10.712 L6.558,11.596 Z M1.442,11.596 C1.769,11.269 2.652,10.844 3.716,10.737 C4.757,10.633 5.812,10.85 6.558,11.596 L7.442,10.712 C6.342,9.612 4.858,9.367 3.592,9.493 C2.348,9.618 1.154,10.116 0.558,10.712 L1.442,11.596 Z" fill="#FFFFFF" fill-rule="nonzero"></path>
                        <path d="M13.442,10.716 C13.686,10.96 13.686,11.356 13.442,11.6 C13.198,11.844 12.802,11.844 12.558,11.6 L13.442,10.716 Z M7.442,11.6 C7.198,11.844 6.802,11.844 6.558,11.6 C6.314,11.356 6.314,10.96 6.558,10.716 L7.442,11.6 Z M12.558,11.6 C12.231,11.273 11.348,10.848 10.284,10.742 C9.243,10.638 8.188,10.854 7.442,11.6 L6.558,10.716 C7.658,9.616 9.142,9.371 10.408,9.498 C11.652,9.622 12.846,10.12 13.442,10.716 L12.558,11.6 Z" fill="#FFFFFF" fill-rule="nonzero"></path>
                        <path d="M0.558,1.481 C0.314,1.725 0.314,2.121 0.558,2.365 C0.802,2.609 1.198,2.609 1.442,2.365 L0.558,1.481 Z M6.558,2.365 C6.802,2.609 7.198,2.609 7.442,2.365 C7.686,2.121 7.686,1.725 7.442,1.481 L6.558,2.365 Z M1.442,2.365 C1.565,2.242 1.852,2.077 2.314,1.937 C2.758,1.802 3.297,1.708 3.857,1.682 C4.417,1.655 4.974,1.696 5.458,1.816 C5.947,1.936 6.32,2.126 6.558,2.365 L7.442,1.481 C6.988,1.027 6.38,0.756 5.758,0.602 C5.131,0.448 4.449,0.402 3.797,0.433 C3.145,0.464 2.502,0.573 1.95,0.741 C1.417,0.903 0.897,1.142 0.558,1.481 L1.442,2.365 Z" fill="#FFFFFF" fill-rule="nonzero"></path>
                        <path d="M13.442,1.432 C13.686,1.676 13.686,2.072 13.442,2.316 C13.198,2.56 12.802,2.56 12.558,2.316 L13.442,1.432 Z M7.442,2.316 C7.198,2.56 6.802,2.56 6.558,2.316 C6.314,2.072 6.314,1.676 6.558,1.432 L7.442,2.316 Z M12.558,2.316 C12.435,2.193 12.148,2.028 11.686,1.888 C11.242,1.753 10.703,1.659 10.143,1.633 C9.583,1.606 9.026,1.647 8.542,1.767 C8.053,1.887 7.68,2.077 7.442,2.316 L6.558,1.432 C7.012,0.978 7.62,0.707 8.242,0.553 C8.869,0.399 9.551,0.353 10.203,0.384 C10.855,0.415 11.498,0.524 12.05,0.692 C12.583,0.854 13.103,1.093 13.442,1.432 L12.558,2.316 Z"  fill="#FFFFFF" fill-rule="nonzero"></path>
                        <line x1="1" y1="1.923" x2="1" y2="11.154" stroke="#FFFFFF" stroke-width="1.25"></line>
                        <line x1="13" y1="1.923" x2="13" y2="11.154" stroke="#FFFFFF" stroke-width="1.25"></line>
                        <line x1="7" y1="1.923" x2="7" y2="11.154" stroke="#FFFFFF" stroke-width="1.25"></line>
                      </g>
                      <g opacity="0.35" transform="translate(82.000000, 0.000000)">
                        <path d="M0.558,10.712 C0.314,10.956 0.314,11.352 0.5581,11.596 C0.8022,11.84 1.1979,11.84 1.442,11.596 L0.558,10.712 Z M6.558,11.596 C6.802,11.84 7.198,11.84 7.442,11.596 C7.686,11.352 7.686,10.956 7.442,10.712 L6.558,11.596 Z M1.442,11.596 C1.7689,11.269 2.652,10.844 3.716,10.737 C4.757,10.633 5.812,10.85 6.558,11.596 L7.442,10.712 C6.342,9.612 4.858,9.367 3.592,9.493 C2.348,9.618 1.1542,10.116 0.558,10.712 L1.442,11.596 Z" fill="#FFFFFF" fill-rule="nonzero"></path>
                        <path d="M13.442,10.716 C13.686,10.96 13.686,11.356 13.442,11.6 C13.198,11.844 12.802,11.844 12.558,11.6 L13.442,10.716 Z M7.442,11.6 C7.198,11.844 6.802,11.844 6.558,11.6 C6.314,11.356 6.314,10.96 6.558,10.716 L7.442,11.6 Z M12.558,11.6 C12.231,11.273 11.348,10.848 10.284,10.742 C9.243,10.638 8.188,10.854 7.442,11.6 L6.558,10.716 C7.658,9.616 9.142,9.371 10.408,9.498 C11.652,9.622 12.846,10.12 13.442,10.716 L12.558,11.6 Z" fill="#FFFFFF" fill-rule="nonzero"></path>
                        <path d="M0.558,1.481 C0.314,1.725 0.314,2.121 0.5581,2.365 C0.8022,2.609 1.1979,2.609 1.442,2.365 L0.558,1.481 Z M6.558,2.365 C6.802,2.609 7.198,2.609 7.442,2.365 C7.686,2.121 7.686,1.725 7.442,1.481 L6.558,2.365 Z M1.442,2.365 C1.5648,2.242 1.8519,2.077 2.314,1.937 C2.758,1.802 3.297,1.708 3.857,1.682 C4.417,1.655 4.974,1.696 5.458,1.816 C5.947,1.936 6.32,2.126 6.558,2.365 L7.442,1.481 C6.988,1.027 6.38,0.756 5.758,0.602 C5.131,0.448 4.449,0.402 3.797,0.433 C3.145,0.464 2.502,0.573 1.9502,0.741 C1.4173,0.903 0.8967,1.142 0.558,1.481 L1.442,2.365 Z" fill="#FFFFFF" fill-rule="nonzero"></path>
                        <path d="M13.442,1.432 C13.686,1.676 13.686,2.072 13.442,2.316 C13.198,2.56 12.802,2.56 12.558,2.316 L13.442,1.432 Z M7.442,2.316 C7.198,2.56 6.802,2.56 6.558,2.316 C6.314,2.072 6.314,1.676 6.558,1.432 L7.442,2.316 Z M12.558,2.316 C12.435,2.193 12.148,2.028 11.686,1.888 C11.242,1.753 10.703,1.659 10.143,1.633 C9.583,1.606 9.026,1.647 8.542,1.767 C8.053,1.887 7.68,2.077 7.442,2.316 L6.558,1.432 C7.012,0.978 7.62,0.707 8.242,0.553 C8.869,0.399 9.551,0.353 10.203,0.384 C10.855,0.415 11.498,0.524 12.05,0.692 C12.583,0.854 13.103,1.093 13.442,1.432 L12.558,2.316 Z" fill="#FFFFFF" fill-rule="nonzero"></path>
                        <line x1="1" y1="1.923" x2="1" y2="11.154" stroke="#FFFFFF" stroke-width="1.25"></line>
                        <line x1="13" y1="1.923" x2="13" y2="11.154" stroke="#FFFFFF" stroke-width="1.25"></line>
                        <line x1="7" y1="1.923" x2="7" y2="11.154" stroke="#FFFFFF" stroke-width="1.25"></line>
                      </g>
                    </g>
                  </g>
                </svg>
              </div>
            </div>
            <div id="range-interact" :style="'width:'+ rangeInteractWidthLive +'px'"></div>
          </div>
        </div>
        <detail v-on:show-modal="showModalMsg"></detail>
      </main>
    </div>
    <menuprimary current-nav="inkunabel" :needNavprojects=true  v-on:toggle-detail="toggleDetail"></menuprimary>
    <div class="top-tool-nav-wrap">
      <div @click="toggleDetail" v-if="isDetailVisible" class="btn-close-circle" aria-hidden="true">
        <span class="visuallyhidden">close detail content</span>
      </div>
      <share-section v-if="isDetailVisible"></share-section>
      <router-link to="overview" class="btn-close-circle" aria-hidden="true" v-else>
        <span class="visuallyhidden">go to overview page</span>
      </router-link>
    </div>
    <modal v-if="showModal" url="https://www.e-rara.ch/zut/content/pageview/25615623" @close="showModal=false"></modal>
    <transition v-if="showEclipse" name="slideInkunabel">
      <div class="modal-mask modal__eclipse">
        <div class="modal-wrapper">
          <a class="btn-close-circle btn-close__bg" aria-hidden="true"><span class="visuallyhidden">close modal</span></a>
          <router-link :to="'zoom-inkunabel/'+this.activeSlide" class="zoom-btn">
            <span class="sicon sicon__zoom-more" aria-hidden="true"></span>
            <span class="visuallyhidden">Link zu zoomed img</span>
          </router-link>
          <div class="modal-container modal-container-slide">
            <div class="modal-body" :style="'height:'+ heightEclipse +'px'">
              <slot name="body">
                <img :src="'/assets/img/inkunabel/details/lunar-eclipse.png'" alt="Lunar eclipse" :style="'opacity:'+ opacityLunar +''">
                <img :src="'/assets/img/inkunabel/details/solar-eclipse.png'" alt="Solar eclipse" :style="'opacity:'+ opacitySolar +''">
                <p :style="'opacity:'+ opacityLunar +''">{{ $t('inkunabel.lunaris') }}</p>
                <p :style="'opacity:'+ opacitySolar +''">{{ $t('inkunabel.solaris') }}</p>
                <div class="controls">
                  <a class="icon-circle__prev"  @click="navEclipse()" href="#">
                    <span class="sicon sicon__arrow-left" aria-hidden="true"></span><span class="visuallyhidden">Prev</span>
                  </a>
                  <a class="icon-circle__next"  @click="navEclipse()" href="#">
                    <span class="sicon sicon__arrow-right" aria-hidden="true"></span>
                    <span class="visuallyhidden">Next</span>
                  </a>
                </div>
              </slot>
            </div>
            <div class="modal-footer">
              <slot name="footer">
                <div class="modal-caption">
                  <div class="caption__lunar" v-html="$t('inkunabel.lunar.caption')" :style="'opacity:'+ opacityLunar +''"></div>
                  <div class="caption__solar" v-html="$t('inkunabel.solar.caption')" :style="'opacity:'+ opacitySolar +''"></div>
                  </div>
              </slot>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
  import iInkunabelPhases from '../info/i-inkunabel';
  import Modal from '../../components/modal';
  import MenuPrimary from '../../components/menu-primary';
  import moonStates from '../../data/moon';
  import Slides from '../../data/planet-slides';
  import ShareSection from '../../components/share';

  import anime from 'animejs';
  import Hammer from '../../assets/js/hammer.min';

  export default {
    name: 'inkunabel',
    components: {
      'detail': iInkunabelPhases,
      'modal': Modal,
      'menuprimary': MenuPrimary,
      'share-section': ShareSection
    },
    data() {
      return {
        activeSlide: 0,
        bg: false,
        moonslides: moonStates.data()['slides'],
        currentPos : '',
        showModal: false,
        isDetailVisible: false,
        windowWidth: window.innerWidth,
        rangeWidthLive:0,
        rangeInteractWidthLive: 0,
        slideWidthLive:0,
        sunHeightLive: 0,
        sunTop: 0,
        rotateBracket: 'translate3d(-50%,0,0) rotate(0deg);',
        slides: Slides.data()['Slides'],
        workPosLive:'',
        zoomEl:'',
        mc:'',
        pinchTimmer: 0,
        isPageLoaded: false,
        instAniInterval: null,
        instAniLoadTimer: null,
        pageLoadTimer: null,
        showEclipse: false,
        opacityLunar: 0,
        opacitySolar: 0,
        heightEclipse: 0
      }
    },
    created() {
      this.currentPos = 0;
      this.viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0)
      this.isMobile = this.mobileAndTabletcheck();
    },
    methods: {
      instractionAni: function () {
        var step = 0;
        var _this = this;
        this.instAniInterval = setInterval(frame, 160);

        function frame() {
          if (step == 12 || _this.isDetailVisible) {
            _this.clearIntervalInstAni()
          } else {
            //if((step >= 5 && step < 10)|| (step >= 15 && step < 20)) {
            if((step >= 3 && step < 6)|| (step >= 9 && step < 12)) {
              _this.stepDown()
            } else {
              _this.stepUp()
            }
          }
          step++;
        }
      },
      clearIntervalInstAni : function() {
        if(this.instAniInterval) {
          clearInterval(this.instAniInterval);
        }
        if(this.instAniLoadTimer) {
          clearTimeout(this.instAniLoadTimer);
        }
      },
      stepUp: function () {
        this.activeSlide++
         if(this.activeSlide > this.moonslides.length -1) {
           this.activeSlide = this.moonslides.length -1
         }
        this.getHandlePos()
        this.getBracketRotation();
      },
      stepDown: function () {
        this.activeSlide--
        if(this.activeSlide < 0) {
          this.activeSlide = 0
        }
        this.getHandlePos();
        this.getBracketRotation();
      },
      eclipseDetailHeight: function (){
        var ratioEclipse = 1257/1006;
        var _this = this;
        setTimeout(function (){
          _this.heightEclipse = _this.$el.querySelector('.modal-body').clientWidth / ratioEclipse + 62;
        },50);
      },
      eclipseDetailWidthMobile: function (){
        var _this = this;
        var ratioEclipse = 1257/1006;

        if(window.innerWidth < 811 || window.innerHeight < 800 || window.innerWidth < 1025 && window.innerHeight <= 1366 && window.innerHeight > 1024){
          setTimeout(function(){
            if(window.innerWidth < 1025 && window.innerHeight <= 1366 && window.innerHeight > 1024){
              var gap = 100;
            } else {
              gap = 78;

              if(window.innerHeight < 767 && window.innerWidth > 1024){
                gap = 38;
              }
            }

            var captionHeight = _this.$el.querySelector('.modal-caption p').clientHeight;
            if(window.innerHeight < 740){
                gap = 60;
            }

            if(window.innerWidth > 700 && window.innerHeight < 800){
              gap = 20;

              if(window.innerHeight < 740){
                gap = 60;
              }
            }

            _this.$el.querySelector('.modal-footer').style.height = captionHeight + gap + 'px';
          },60)
        }
        if(window.innerWidth < 768 || window.innerHeight < 800){
          setTimeout(function (){
            var captionHeight = _this.$el.querySelector('.modal-caption p').clientHeight;
            var closeBtnHeight = _this.$el.querySelector('.btn-close__bg').clientHeight;
            var newEclipseHeight = window.innerHeight - closeBtnHeight - captionHeight - 50;


            if(window.innerHeight < 740){
              newEclipseHeight = newEclipseHeight - 92;
            }
            if(ratioEclipse * newEclipseHeight >= window.innerWidth - 40){
              newEclipseHeight = (window.innerWidth - 40) / ratioEclipse;
            }

            _this.$el.querySelectorAll('.modal-body img').forEach(function (image){
              image.style.height = newEclipseHeight + 'px';
              image.style.width = 'auto';
              _this.$el.querySelector('.controls').style.maxWidth = image.clientWidth + 'px';
              _this.$el.querySelector('.modal-caption').style.width = image.clientWidth + 'px'
            })

            _this.heightEclipse = newEclipseHeight + 62;
          },60)
        } else {
          if(window.innerWidth < 1025 && window.innerHeight <= 1366 && window.innerHeight > 1024){
            var gap = 100;
          } else {
            gap = 78;

            if(window.innerHeight < 860){
              gap = 40;

              if(window.innerHeight < 820){
                gap = 20;
              }
            }
          }

          _this.$el.querySelectorAll('.modal-body img').forEach(function (image){
            image.style.height = '';
            image.style.width = '';
            _this.$el.querySelector('.controls').style.maxWidth = '';
            _this.$el.querySelector('.modal-caption').style.width = '';
          })

          setTimeout(function (){
            var captionHeight = _this.$el.querySelector('.modal-caption p').clientHeight;
            if(window.innerHeight < 740){
              gap = 60;
            }

            _this.$el.querySelector('.modal-footer').style.height = captionHeight + gap + 'px';

          },60);

          _this.eclipseDetailHeight();
        }
      },
      navEclipse(){
        var _this = this;
        if(_this.activeSlide == 3){
          _this.activeSlide = 9;
          _this.opacityLunar = 0;
          setTimeout(function (){
            _this.opacitySolar = 1;
          },250);
        } else {
          _this.activeSlide = 3;
          _this.opacitySolar = 0;
          setTimeout(function (){
            _this.opacityLunar = 1;
          },250);
        }
        _this.getHandlePos()
        _this.getBracketRotation();
      },
      getBracketRotation: function (){
        this.rotateBracket = 'translate3d(-50%,0,0) rotate(' + this.moonslides[this.activeSlide].rotateBracket + 'deg);';
      },
      getHandlePos: function () {
        var rangeWidth = this.$el.querySelector('.range-slider__handle--horizontal').clientWidth;
        var slidesLength = this.moonslides.length -1;
        var totalSlideWidth =  this.rangeWidthLive - rangeWidth;

        if(window.innerWidth < 768){
          totalSlideWidth = totalSlideWidth + 11;
        }

        var stepPos = totalSlideWidth / slidesLength;

        this.currentPos = this.activeSlide * stepPos;

        if(this.activeSlide == 0){
          this.currentPos = 0;
        }

        if(this.activeSlide == 12){
          this.currentPos = totalSlideWidth;
        }
      },
      rangeHandleOnPan: function (e) {
       // eslint-disable-next-line no-undef
        gtag('event', 'rangeSlider', {
           'event_category' : 'inkunabel',
           'event_label' : 'inkunabel',
        });
        var rangeHandle = this.$el.querySelector('.range-slider__handle--horizontal');
        var slidesLength = this.moonslides.length -1;
        var rangeWidth = this.$el.querySelector('.range-slider__handle--horizontal').clientWidth;
        var totalSlideWidth =  this.rangeWidthLive - rangeWidth;
        var stepPos = totalSlideWidth / slidesLength;
        var rangeInteract = this.$el.querySelector('.range-slider').getBoundingClientRect();
        var pos = e.center.x + (rangeInteract.left * -1) - rangeHandle.clientWidth / 2;

        if(pos >= totalSlideWidth){
          pos = totalSlideWidth;

          if(window.innerWidth < 768){
            pos = totalSlideWidth + 11;
          }
        }

        if(pos <= 0){
          pos = 0;
        }

        this.currentPos = pos;

        this.getBracketRotation();

        var _curSlideIndex = Math.round(pos  / stepPos);
        if(this.activeSlide != _curSlideIndex) {
             this.activeSlide = _curSlideIndex
           } else {
             return false
           }
      },
      toggleDetail:function () {
        if(!this.isDetailVisible) {
          // eslint-disable-next-line no-undef
          gtag('config', this.uaId, {'page_path': this.$route.path + '-i-detail'});
        } else {
          // eslint-disable-next-line no-undef
          gtag('config', this.uaId, {'page_path': this.$route.path });

          var audio = this.$el.querySelectorAll('audio')[0];
          if(!audio.paused){
            audio.pause();
          }
        }

        this.isDetailVisible =  !this.isDetailVisible
        var _scale = 1;
        var workHTo =  window.innerHeight - this.$el.querySelector('.nav-bottom').clientHeight;
        var translateYInkunabel = 0;
        var _this = this;

        if(this.isDetailVisible) {
          document.body.classList.add('detail-opened');
          _scale = 0.8;
          this.resetWorkZoom();
          workHTo = this.slideWidthLive;
          translateYInkunabel = (document.querySelector('.inkunabel-description').clientHeight ) / 80 * 100;

          if(window.innerWidth < 769){
            var pageTopHeight = this.$el.querySelector('.page__top').clientHeight;
            var pageTopPaddingTop = parseFloat(getComputedStyle(this.$el.querySelector('.page__top')).paddingTop);
            var pageTopSpace = (pageTopHeight  + pageTopPaddingTop);
            var topSpaceWork = (this.slideWidthLive - this.slideWidthLive * 0.613) / 2;
            if(pageTopSpace > topSpaceWork){
              _scale = 0.7;
              translateYInkunabel = (pageTopSpace + 20)  / 70 * 100;
            }

            if(window.innerWidth > window.innerHeight && window.innerWidth <= 926){
              var newMarginDetailContent = (this.rangeWidthLive - this.slideWidthLive) / 2;
              translateYInkunabel = '10%';
              this.$el.querySelector('.detail-content').style.marginTop = newMarginDetailContent * -1 + 'px';
            } else {
              this.$el.querySelector('.detail-content').style.marginTop = '';
            }
          }
        } else {
          document.body.classList.remove('detail-opened');
        }

        anime({
          targets: '.inkunabel-wrap',
          scale: _scale,
          translateY: translateYInkunabel,
          duration: 500,
          easing:'easeOutQuart',
          complete: function() {
            _this.resizeWorkDetail();
          }
        });


        anime({
          targets: '.work-content',
          height: workHTo,
          duration: 500,
          easing:'easeOutQuart',
        });

      },
      resizeWorkDetail: function() {
        var work = this.$el.querySelector('.work-content');
        if(this.isDetailVisible) {
          work.style.height =  this.rangeWidthLive * 0.8 + 'px';
        }
      },
      activateMoon: function (index){
        this.activeSlide = index;
        this.getHandlePos();
        this.getBracketRotation();
      },
      openEclipse: function (index){
        this.showEclipse= true;

        if(this.rangeWidthLive == 0){
            this.$el.querySelector('.inkunabel-description-wrap').style.maxWidth = this.$el.querySelector('.range-slider').clientWidth + 'px';
        }

        this.eclipseDetailHeight();

        if(index == 3){
          this.opacityLunar = 1;
          this.opacitySolar = 0;

          this.eclipseDetailWidthMobile();
        } else {
          this.opacityLunar = 0;
          this.opacitySolar = 1;
          this.eclipseDetailWidthMobile();
        }

        var _this = this;

       setTimeout(function (){
         anime({
           targets: document.querySelector('.modal__eclipse'),
           opacity: 1,
           easing: 'easeOutQuad',
           duration: 200,
         });
       },100)

        setTimeout(function (){
          document.querySelector('.modal__eclipse').addEventListener('click',function(event) {
            if(event.target.classList[0] == 'btn-close-circle' || event.target.classList[0] == 'modal-body' || event.target.classList[0] == 'modal-wrapper' || event.target.classList[0] == 'modal-container'){
              _this.closeEclipse();
            }
          });
        }, 10);
      },
      closeEclipse: function (){
      var _this = this;

        anime({
          targets: document.querySelector('.modal__eclipse'),
          opacity: 0,
          easing: 'easeOutQuad',
          duration: 200,
          complete: function (){
            _this.showEclipse = false;
          }
        });
      },
      showModalMsg : function() {
        this.showModal = true
      },
      getRangeWidth: function() {
        var winW = window.innerWidth;
        var winH = window.innerHeight;
        var workH = winH - this.$el.querySelector('.nav-bottom').clientHeight;
        var pageTopHeight = this.$el.querySelector('.page__top').clientHeight;
        var legendEl = this.$el.querySelector('.inkunabel-description');
        var legendHeight = legendEl.clientHeight - parseInt(getComputedStyle(legendEl).paddingBottom);
        var topSpaceWork = (this.slideWidthLive - this.slideWidthLive * 0.613) / 2;
        var marginBottomPageTopText = parseFloat(getComputedStyle(this.$el.querySelector('.page-top__text')).marginBottom);

        if(!this.isDetailVisible) {
          this.$el.querySelector('.work-content').style.height = workH + 'px';
        }

        var availH = workH;

        var _bottomNav = parseInt(window.getComputedStyle(this.$el.querySelector('.nav-bottom')).getPropertyValue('bottom'))
        if (_bottomNav > 0) {
          availH = availH - _bottomNav
        }

        if(availH < 200) {
          availH = 200
        }

        if(winW <= 767 && winW > 570){
          if((pageTopHeight - marginBottomPageTopText) > topSpaceWork){
            availH = availH * 0.8;
          }
        }

        if(winW > availH && winW <= 926 && winH < 500 && this.isMobile) {
          var availHSlide = availH - legendHeight - this.$el.querySelector('.range-slider--horizontal').clientHeight - this.$el.querySelector('.inkunabel-moon-phases').clientHeight - 60;

        } else {
          if(winW == 1024 && winH == 1366){
            availHSlide = availH - legendHeight - this.$el.querySelector('.range-slider--horizontal').clientHeight - this.$el.querySelector('.inkunabel-moon-phases').clientHeight - 240;

          } else {
            availHSlide = availH - legendHeight - this.$el.querySelector('.range-slider--horizontal').clientHeight - this.$el.querySelector('.inkunabel-moon-phases').clientHeight - 140;
          }
        }

        if(winW <= 767) {
          this.rangeWidthLive = Math.min(winW - 52, availH)
        } else {
          this.rangeWidthLive = '508';

          if(winW == 1024 && winH == 1366){
            this.rangeWidthLive = winW * 0.66;
          }
        }

        var maxAailHSlide = Math.min(winW, availHSlide)
        this.slideWidthLive = maxAailHSlide;
        this.sunHeightLive = this.slideWidthLive * 2.5;

        if(winW > availH && winW <= 926 && winH < 500 && this.isMobile) {
          this.rangeWidthLive =  winW / 2;
          document.querySelector('.inkunabel').classList.add('landscape');
          var diffSun = (this.slideWidthLive * 0.187 / 2) + (this.slideWidthLive / 2) - (this.slideWidthLive / 2);
          legendEl.style.paddingBottom = document.querySelector('.inkunabel-moon-phases').clientHeight + 2 * document.querySelector('.range-slider--horizontal').clientHeight + 5 + 'px';
        } else {
          document.querySelector('.inkunabel').classList.remove('landscape');
          diffSun = (this.slideWidthLive * 0.12 / 2) + (this.slideWidthLive / 2) - (this.slideWidthLive / 2);
          if(winH > 1350){
            diffSun = (this.slideWidthLive * 0.08 / 2) + (this.slideWidthLive / 2) - (this.slideWidthLive / 2);
          }
        }

        this.sunTop = 'calc(50% - ' + diffSun +'px)';

        if(winW > 767){
          legendEl.style.paddingBottom = this.rangeWidthLive / 13 + (winW * 0.004) + 10 + 2 * document.querySelector('.range-slider--horizontal').clientHeight + 14 + 'px';
        } else {
          legendEl.style.paddingBottom = this.rangeWidthLive / 13 + 2 * document.querySelector('.range-slider--horizontal').clientHeight + 6 + 'px';
          var diffHeight = document.querySelector('.work-content').clientHeight - document.querySelector('.inkunabel-description-wrap').clientHeight - pageTopHeight + marginBottomPageTopText;
          document.querySelector('.inkunabel-img-wrap').style.marginTop = this.slideWidthLive - diffHeight + 4 + 'px';
        }

        if(winW > 1025){
          var moonHeight = (this.slideWidthLive * 0.67) * 0.113;

          if(winH > 800){
            this.$el.querySelector('.inkunabel').style.marginBottom = (moonHeight / 2) + 40 + 'px';
          } else {
            this.$el.querySelector('.inkunabel').style.marginBottom = (moonHeight / 2) + 20 + 'px';
          }
        }

        this.rangeInteractWidthLive = parseInt(this.rangeWidthLive) + parseInt((this.$el.querySelector('.range-slider__handle--horizontal').clientWidth / 2));

      },
      resetWorkZoom : function() {
        var transform = "";
        this.zoomEl.style.transform = transform;
        this.zoomEl.style.webkitTransform = transform;
      },
      pageLoad : function() {
        this.isPageLoaded = true;
        var _this = this
        this.pageLoadTimer = setTimeout(function () {
          _this.$el.querySelector(".work-intro-ani-in").classList.remove('work-intro-ani-in')
        }, 1600)
      },
    },
    beforeRouteLeave (to, from, next) {
      this.clearIntervalInstAni()
      if(this.pageLoadTimer) {
        clearTimeout(this.pageLoadTimer);
      }
      document.body.classList.remove('detail-opened');
      this.aniMenuPageLeave(next());
    },
    mounted() {
      var _this = this;
      document.body.style.zoom = 1.0;
      this.windowWidth = window.innerWidth
      this.saveCurrentPlanetToLocalStorage();
      this.zoomEl = this.$el.querySelector(".work__scaleable");
      const rangeInteract = this.$el.querySelector("#range-interact");
      const mc = new Hammer.Manager(rangeInteract);
      //rangeHm.domEvents = true
      mc.add( new Hammer.Pan({ direction: Hammer.DIRECTION_ALL, threshold:10}) );
      mc.add( new Hammer.Pinch({ threshold: 0 }) );
      mc.add( new Hammer.Tap() );
      mc.on('pan', (e) => {
        var old = this.pinchTimmer
        var now = new Date().getTime(),
          delta = now - old;

        this.clearIntervalInstAni()

        //avoid pinch end -> pan
        if(delta > 100) {
          this.pinchTimmer = 0
          this.rangeHandleOnPan(e)
        }
      });

      var lastX = 0;
      //slide by tap
      mc.on('tap', (e) => {
        this.clearIntervalInstAni()
        var posX = e.srcEvent.layerX

        if(posX > lastX){
          this.stepUp()
          lastX = posX - 1;
        } else {
          this.stepDown()
          lastX = posX + 1;
        }
      });

      this.mc = mc;
      this.getRangeWidth();
      this.pageLoad();
      this.setWorkBgPos();

      if(localStorage.getItem('inkunabelIndex')) {
        this.activeSlide = parseInt(localStorage.getItem('inkunabelIndex'));
        setTimeout(function (){
          _this.openEclipse(this.activeSlide);
          _this.getHandlePos();
          _this.getBracketRotation();
        },10);

        setTimeout(function (){
          localStorage.setItem('inkunabelIndex', '');
        },300);
      } else {
        this.instAniLoadTimer = setTimeout(this.instractionAni,2000);
      }

      window.onorientationchange = () => {
        if(this.showEclipse) {
          this.eclipseDetailHeight();
          this.eclipseDetailWidthMobile();
        }
      }

      window.onresize = () => {
        //avoild resize on scroll -> addressbar collapse
        if(this.isMobile && this.windowWidth == window.innerWidth) {
          return false
        }

        this.getRangeWidth();
        this.getHandlePos();
        this.resizeWorkDetail();

        if(this.showEclipse) {
          this.eclipseDetailHeight();
          this.eclipseDetailWidthMobile();
        }

        this.windowWidth = window.innerWidth
      }
    }
  }
</script>

