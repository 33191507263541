<template>
  <div class="zoom-harmonia native-pinch zoom-page">
    <div class="top-tool-nav-wrap">
      <a @click="$router.go(-1)" class="btn-close-circle" aria-hidden="true"><span class="visuallyhidden">close Zoom View</span></a>
    </div>
    <div class="zoomed-img-wrap">
      <img class="zoomed-img__img" :src="'/assets/img/harmonia/' + this.harmonia[this.activeId]['img']" alt="harmonia full image" :style="'height:'+ imageHeightLive">
    </div>
  </div>
</template>

<script>
  import harmoniaData from '../../data/harmonia';

  export default {
  name: 'zoomHarmoniaComponent',
    data() {
      return {
        harmonia:harmoniaData.data()['books'],
        imageHeightLive: 'auto'
      }
    },
    created() {
      this.activeId = this.$route.params.id;
      localStorage.setItem('harmoniaIndex', this.activeId)
    },
    beforeRouteLeave (to, from, next) {
      document.body.classList.remove('zoomed-opened');
      localStorage.setItem('harmonia', 'zoom');
      next();
    },
    methods:{
      setHeight: function (){
        var winH = window.innerHeight;
        document.body.style.height = winH + 'px';
        document.querySelector('.zoom-harmonia').style.height = winH + 'px';
        document.querySelector('.zoomed-img-wrap').style.height = winH + 'px';
        var _this = this;

        if(window.innerWidth >= 768){
          var newHeight = winH - this.$el.querySelector('.btn-close-circle').clientHeight - 50;
          _this.imageHeightLive = newHeight + 'px';
          setTimeout(function (){
            if(document.querySelector('.zoomed-img__img').clientWidth > screen.availWidth - 80){
              document.querySelector('.zoomed-img__img').style.width = screen.availWidth - 80 + 'px';
              _this.imageHeightLive = 'auto';
              _this.$el.querySelector('.zoomed-img-wrap').classList.add('centered');
            } else {
              document.querySelector('.zoomed-img__img').style.width = 'auto';
              _this.imageHeightLive = newHeight + 'px';
              _this.$el.querySelector('.zoomed-img-wrap').classList.remove('centered');
            }
          },5);
          document.body.classList.remove('zoomed-overflow');
        } else {
          _this.imageHeightLive = 'auto';
          setTimeout(function (){
            if(document.querySelector('.zoomed-img__img').clientHeight < window.innerHeight){
              document.body.classList.add('zoomed-overflow');
            }
          },5);
        }
      }
    },
    mounted() {
      var _this = this;
      document.body.classList.add('zoomed-opened');

      this.setHeight();

      window.onresize = () => {
        setTimeout(function (){
          _this.setHeight();
        },10)
      }
    }
  }
</script>
