<template>
  <div id="impressum" class="default-page">
    <div class="container">
      <main class="main">
        <div v-html="$t('impressumcontent')"></div>
        <section class="section">
          <dl class="list">
            <dt class="list__title">Version</dt>
            <dd>v5.5</dd>
          </dl>
          <dl class="list" v-show="!caching_progress">
            <dt class="list__title">Caching</dt>
            <dd v-touch="cacheFiles" class="link-text">Cache all files</dd>
          </dl>
          <dl class="list" v-show="caching_progress">
            <dt class="list__title">Caching</dt>
            <dd><div id="caching_done"><span id="caching_update">0</span> / <span id="total_files"></span></div></dd>
          </dl>
        </section>
        <div v-html="$t('impressumcontent2')"></div>
        <section class="section">
          <div class="award">
            <div class="rte" v-html="$t('aboutus.bosa2020')"></div>
            <img src="/assets/img/layout/logo_bosa.png" alt="Best of Swiss Apps Award 2020">
          </div>
        </section>
      </main>
    </div>
    <menuprimary current-nav="impressum"></menuprimary>
    <div class="top-tool-nav-wrap">
      <router-link to="overview" class="btn-close-circle" aria-hidden="true">
        <span class="visuallyhidden">go to overview page</span>
      </router-link>
    </div>
  </div>
</template>

<script>
  import MenuPrimary from '../../components/menu-primary';

  export default {
    name: 'impressum',
    components: {
      'menuprimary': MenuPrimary
    },
    data() {
      return {
        caching_progress: false
      }
    },
    beforeRouteLeave (to, from, next) {
      document.body.classList.remove('scrollable');
      this.aniMenuPageLeave(next());
    },
    mounted() {
      localStorage.AstroActivePlanet = '';
      document.body.classList.add('scrollable');
    },
    methods: {
      cacheFiles: function() {
        this.caching_progress = true
        var doneFiles = [];
        var filesToCache = [
          './index.html',
          './app.js.map',
          './chunk-vendors.js.map',
          './manifest.json',
          './chunk-vendors.js',
          './service-worker.js',
          './robots.txt',
          './assets/css/styles.css',
          './assets/css/splide-core.min.css',
          './assets/js/three.min.js',
          './assets/js/splide.min.js',
          './assets/js/three.interaction.js',
          './assets/js/propeller.min.js',
          './assets/favicon/favicon-16x16.png',
          './assets/favicon/safari-pinned-tab.svg',
          './assets/favicon/favicon.ico',
          './assets/favicon/apple-icon.png',
          './assets/favicon/apple-icon-144x144.png',
          './assets/favicon/android-icon-192x192.png',
          './assets/favicon/apple-icon-precomposed.png',
          './assets/favicon/apple-icon-114x114.png',
          './assets/favicon/ms-icon-310x310.png',
          './assets/favicon/ms-icon-144x144.png',
          './assets/favicon/apple-icon-57x57.png',
          './assets/favicon/apple-icon-152x152.png',
          './assets/favicon/android-icon-512x512.png',
          './assets/favicon/ms-icon-150x150.png',
          './assets/favicon/android-icon-72x72.png',
          './assets/favicon/android-icon-96x96.png',
          './assets/favicon/android-icon-36x36.png',
          './assets/favicon/apple-icon-180x180.png',
          './assets/favicon/favicon-96x96.png',
          './assets/favicon/android-icon-48x48.png',
          './assets/favicon/apple-icon-76x76.png',
          './assets/favicon/apple-icon-60x60.png',
          './assets/favicon/browserconfig.xml',
          './assets/favicon/android-icon-144x144.png',
          './assets/favicon/apple-icon-72x72.png',
          './assets/favicon/apple-icon-120x120.png',
          './assets/favicon/favicon-32x32.png',
          './assets/favicon/ms-icon-70x70.png',
          './assets/img/intro/silhouette.svg',
          './assets/img/intro/circle-behind.png',
          './assets/img/intro/circle-behind-mobile.png',
          './assets/img/intro/bg-landing-2022.jpg',
          './assets/img/intro/circle-front.png',
          './assets/img/intro/circle-front-mobile.png',
          './assets/img/halley-comet/bg-info.jpg',
          './assets/img/halley-comet/apian_comet.png',
          './assets/img/halley-comet/thumb--small.jpg',
          './assets/img/halley-comet/thumb.jpg',
          './assets/img/home2screen/ios_de_s1.png',
          './assets/img/home2screen/ios_de_s2.png',
          './assets/img/home2screen/ios_de_s3.png',
          './assets/img/home2screen/android_de_s2@2x.png',
          './assets/img/home2screen/android_de_s2.png',
          './assets/img/home2screen/android_de_s3.png',
          './assets/img/home2screen/ios_de_s3@2x.png',
          './assets/img/home2screen/android_de_s1.png',
          './assets/img/home2screen/ios_de_s1@2x.png',
          './assets/img/home2screen/android_de_s1@2x.png',
          './assets/img/home2screen/android_de_s3@2x.png',
          './assets/img/home2screen/ios_de_s2@2x.png',
          './assets/img/svg_icons/arrow-down.svg',
          './assets/img/svg_icons/audio-sound.svg',
          './assets/img/svg_icons/audio-play.svg',
          './assets/img/svg_icons/arrow-up-sml.svg',
          './assets/img/svg_icons/arrow-right.svg',
          './assets/img/svg_icons/slide-handle.svg',
          './assets/img/svg_icons/mail.svg',
          './assets/img/svg_icons/book.svg',
          './assets/img/svg_icons/zoom-reset.svg',
          './assets/img/svg_icons/info.svg',
          './assets/img/svg_icons/info-circle.svg',
          './assets/img/svg_icons/arrow-down-sml.svg',
          './assets/img/svg_icons/facebook.svg',
          './assets/img/svg_icons/zoom-less.svg',
          './assets/img/svg_icons/external-link.svg',
          './assets/img/svg_icons/share.svg',
          './assets/img/svg_icons/zoom-more.svg',
          './assets/img/svg_icons/twitter.svg',
          './assets/img/svg_icons/arrow-left.svg',
          './assets/img/svg_icons/inline-external-link.svg',
          './assets/img/svg_icons/string-handle.svg',
          './assets/img/svg_icons/audio-stop.svg',
          './assets/img/svg_icons/icon_information.svg',
          './assets/img/svg_icons/internal-link.svg',
          './assets/img/layout/splash/apple-splash-1136-640.jpg',
          './assets/img/layout/splash/apple-splash-1242-2208.jpg',
          './assets/img/layout/splash/apple-splash-750-1334.jpg',
          './assets/img/layout/splash/apple-splash-2208-1242.jpg',
          './assets/img/layout/splash/apple-splash-1668-2224.jpg',
          './assets/img/layout/splash/apple-splash-2688-1242.jpg',
          './assets/img/layout/splash/apple-splash-1536-2048.jpg',
          './assets/img/layout/splash/apple-splash-2388-1668.jpg',
          './assets/img/layout/splash/apple-splash-2048-2732.jpg',
          './assets/img/layout/splash/apple-splash-1334-750.jpg',
          './assets/img/layout/splash/apple-splash-1792-828.jpg',
          './assets/img/layout/splash/apple-splash-1125-2436.jpg',
          './assets/img/layout/splash/apple-splash-2732-2048.jpg',
          './assets/img/layout/splash/apple-splash-1242-2688.jpg',
          './assets/img/layout/splash/apple-splash-2436-1125.jpg',
          './assets/img/layout/splash/apple-splash-828-1792.jpg',
          './assets/img/layout/splash/apple-splash-1668-2388.jpg',
          './assets/img/layout/splash/apple-splash-2048-1536.jpg',
          './assets/img/layout/splash/apple-splash-2224-1668.jpg',
          './assets/img/layout/splash/apple-splash-640-1136.jpg',
          './assets/img/layout/logo_astrorara.svg',
          './assets/img/layout/bg-overview.jpg',
          './assets/img/layout/splash-bg.png',
          './assets/img/layout/ethz-logo-white.svg',
          './assets/img/layout/logo_bosa.png',
          './assets/img/layout/logo_erara.svg',
          './assets/img/inkunabel/bg-info.jpg',
          './assets/img/inkunabel/brackets.svg',
          './assets/img/inkunabel/details/lunar-eclipse.png',
          './assets/img/inkunabel/details/solar-eclipse.png',
          './assets/img/inkunabel/earth.png',
          './assets/img/inkunabel/moonphases/moonphase-07.png',
          './assets/img/inkunabel/moonphases/moonphase-10-01.png',
          './assets/img/inkunabel/moonphases/moonphase-13.png',
          './assets/img/inkunabel/moonphases/moonphase-12.png',
          './assets/img/inkunabel/moonphases/moonphase-06.png',
          './assets/img/inkunabel/moonphases/moonphase-10.png',
          './assets/img/inkunabel/moonphases/moonphase-04.png',
          './assets/img/inkunabel/moonphases/moonphase-10-02.png',
          './assets/img/inkunabel/moonphases/moonphase-05.png',
          './assets/img/inkunabel/moonphases/moonphase-11.png',
          './assets/img/inkunabel/moonphases/moonphase-01.png',
          './assets/img/inkunabel/moonphases/moonphase-02.png',
          './assets/img/inkunabel/moonphases/moonphase-03.png',
          './assets/img/inkunabel/moonphases/moonphase-08.png',
          './assets/img/inkunabel/moonphases/moonphase-09.png',
          './assets/img/inkunabel/sun.png',
          './assets/img/inkunabel/thumb--small.jpg',
          './assets/img/inkunabel/georg-von-peuerbach.png',
          './assets/img/inkunabel/thumb.jpg',
          './assets/img/atlas-novus/earth.svg',
          './assets/img/atlas-novus/bg-info.jpg',
          './assets/img/atlas-novus/beam.svg',
          './assets/img/atlas-novus/johann-gabriel-doppelmayr.png',
          './assets/img/atlas-novus/trace-map.png',
          './assets/img/atlas-novus/venus.svg',
          './assets/img/atlas-novus/thumb--small.jpg',
          './assets/img/atlas-novus/thumb.jpg',
          './assets/img/sun-spots/bg-info.jpg',
          './assets/img/sun-spots/christoph-scheiner.jpg',
          './assets/img/sun-spots/sun.jpg',
          './assets/img/sun-spots/thumb--small.jpg',
          './assets/img/sun-spots/thumb.jpg',
          './assets/img/coelum-stellatum/julius-schiller.jpg',
          './assets/img/coelum-stellatum/bg-info.jpg',
          './assets/img/coelum-stellatum/lyr-antiquity.jpg',
          './assets/img/coelum-stellatum/cyg-antiquity.jpg',
          './assets/img/coelum-stellatum/gem-antiquity.jpg',
          './assets/img/coelum-stellatum/her-antiquity.jpg',
          './assets/img/coelum-stellatum/linear/constellation_leo.png',
          './assets/img/coelum-stellatum/linear/constellation_tau.png',
          './assets/img/coelum-stellatum/linear/constellation_lyr.png',
          './assets/img/coelum-stellatum/linear/constellation_gem.png',
          './assets/img/coelum-stellatum/linear/constellation_per.png',
          './assets/img/coelum-stellatum/linear/constellation_boo.png',
          './assets/img/coelum-stellatum/linear/constellation_cyg.png',
          './assets/img/coelum-stellatum/linear/constellation_cas.png',
          './assets/img/coelum-stellatum/linear/constellation_her.png',
          './assets/img/coelum-stellatum/per-christian.jpg',
          './assets/img/coelum-stellatum/tau-antiquity.jpg',
          './assets/img/coelum-stellatum/boo-antiquity.jpg',
          './assets/img/coelum-stellatum/leo-antiquity.jpg',
          './assets/img/coelum-stellatum/cas-antiquity.jpg',
          './assets/img/coelum-stellatum/her-christian.jpg',
          './assets/img/coelum-stellatum/stellatum-bg.png',
          './assets/img/coelum-stellatum/per-antiquity.jpg',
          './assets/img/coelum-stellatum/leo-christian.jpg',
          './assets/img/coelum-stellatum/boo-christian.jpg',
          './assets/img/coelum-stellatum/tau-christian.jpg',
          './assets/img/coelum-stellatum/stellatum-glow.png',
          './assets/img/coelum-stellatum/cas-christian.jpg',
          './assets/img/coelum-stellatum/cyg-christian.jpg',
          './assets/img/coelum-stellatum/lyr-christian.jpg',
          './assets/img/coelum-stellatum/thumb--small.jpg',
          './assets/img/coelum-stellatum/thumb.jpg',
          './assets/img/coelum-stellatum/gem-christian.jpg',
          './assets/img/astrolab/bg-info.jpg',
          './assets/img/astrolab/handle.jpg',
          './assets/img/astrolab/bg-plate.jpg',
          './assets/img/astrolab/plate-zoom.jpg',
          './assets/img/astrolab/schoener.jpg',
          './assets/img/astrolab/plate.jpg',
          './assets/img/astrolab/bg-plate-zoom.jpg',
          './assets/img/astrolab/handle-zoom.jpg',
          './assets/img/astrolab/thumb--small.jpg',
          './assets/img/astrolab/thumb.jpg',
          './assets/img/jupiters-moons/03_7h.jpg',
          './assets/img/jupiters-moons/17_1h.jpg',
          './assets/img/jupiters-moons/11_5.5h.jpg',
          './assets/img/jupiters-moons/15_1h.jpg',
          './assets/img/jupiters-moons/09_0.5h.jpg',
          './assets/img/jupiters-moons/07_1h.jpg',
          './assets/img/jupiters-moons/10_1.5h.jpg',
          './assets/img/jupiters-moons/galileo-galilei.jpg',
          './assets/img/jupiters-moons/12_0.67h.jpg',
          './assets/img/jupiters-moons/15_6h.jpg',
          './assets/img/jupiters-moons/11_1h.jpg',
          './assets/img/jupiters-moons/27_1h.jpg',
          './assets/img/jupiters-moons/25_1.5h.jpg',
          './assets/img/jupiters-moons/26_5h.jpg',
          './assets/img/jupiters-moons/04_2h.jpg',
          './assets/img/jupiters-moons/13_0.5h.jpg',
          './assets/img/jupiters-moons/28_5h.jpg',
          './assets/img/jupiters-moons/19_0.67h.jpg',
          './assets/img/jupiters-moons/26_0.5h.jpg',
          './assets/img/jupiters-moons/21_1.5h.jpg',
          './assets/img/jupiters-moons/01_2h.jpg',
          './assets/img/jupiters-moons/08_1h.jpg',
          './assets/img/jupiters-moons/02_7h.jpg',
          './assets/img/jupiters-moons/11_3h.jpg',
          './assets/img/jupiters-moons/06_1h.jpg',
          './assets/img/jupiters-moons/15_5h.jpg',
          './assets/img/jupiters-moons/18_1h.jpg',
          './assets/img/jupiters-moons/04_7h.jpg',
          './assets/img/jupiters-moons/02_1h.jpg',
          './assets/img/jupiters-moons/18_6h.jpg',
          './assets/img/jupiters-moons/thumb--small.jpg',
          './assets/img/jupiters-moons/28_1h.jpg',
          './assets/img/jupiters-moons/16_6h.jpg',
          './assets/img/jupiters-moons/thumb.jpg',
          './assets/img/jupiters-moons/jupiter-icon-nav.png',
          './assets/img/harmonia/bg-info.jpg',
          './assets/img/harmonia/image_ptolemaus.png',
          './assets/img/harmonia/harmonia.jpg',
          './assets/img/harmonia/image_brahe.png',
          './assets/img/harmonia/image_copernicus.png',
          './assets/img/harmonia/cellarius.jpg',
          './assets/img/harmonia/thumb--small.jpg',
          './assets/img/harmonia/thumb.jpg',
          './assets/img/moon-passing/jupiter-08.svg',
          './assets/img/moon-passing/jupiter-09.svg',
          './assets/img/moon-passing/bg-info.jpg',
          './assets/img/moon-passing/jupiter-18.svg',
          './assets/img/moon-passing/mask-bg.jpg',
          './assets/img/moon-passing/jupiter-10_11_12_15_19.svg',
          './assets/img/moon-passing/jupiter-04_05_06.svg',
          './assets/img/moon-passing/moon.png',
          './assets/img/moon-passing/line.svg',
          './assets/img/moon-passing/jupiter-14.svg',
          './assets/img/moon-passing/jupiter-02.svg',
          './assets/img/moon-passing/johannes-hevelius.jpg',
          './assets/img/moon-passing/jupiter-07.svg',
          './assets/img/moon-passing/thumb--small.png',
          './assets/img/moon-passing/thumb.jpg',
          './assets/img/moon-passing/jupiter-16_17.svg',
          './assets/img/lunar-phases/mond-40.jpg',
          './assets/img/lunar-phases/bg-info.jpg',
          './assets/img/lunar-phases/mond-25.jpg',
          './assets/img/lunar-phases/mond-31.jpg',
          './assets/img/lunar-phases/mond-19.jpg',
          './assets/img/lunar-phases/mond-18.jpg',
          './assets/img/lunar-phases/mond-30.jpg',
          './assets/img/lunar-phases/mond-24.jpg',
          './assets/img/lunar-phases/mond-32.jpg',
          './assets/img/lunar-phases/mond-26.jpg',
          './assets/img/lunar-phases/bg-detail.jpg',
          './assets/img/lunar-phases/mond-27.jpg',
          './assets/img/lunar-phases/mond-33.jpg',
          './assets/img/lunar-phases/mond-37.jpg',
          './assets/img/lunar-phases/mond-23.jpg',
          './assets/img/lunar-phases/mond-22.jpg',
          './assets/img/lunar-phases/mond-36.jpg',
          './assets/img/lunar-phases/mond-08.jpg',
          './assets/img/lunar-phases/mond-20.jpg',
          './assets/img/lunar-phases/mond-34.jpg',
          './assets/img/lunar-phases/mond-35.jpg',
          './assets/img/lunar-phases/mond-21.jpg',
          './assets/img/lunar-phases/mond-09.jpg',
          './assets/img/lunar-phases/mond-04.jpg',
          './assets/img/lunar-phases/mond-10.jpg',
          './assets/img/lunar-phases/mond-38.jpg',
          './assets/img/lunar-phases/mond-39.jpg',
          './assets/img/lunar-phases/mond-11.jpg',
          './assets/img/lunar-phases/mond-05.jpg',
          './assets/img/lunar-phases/mond-13.jpg',
          './assets/img/lunar-phases/mond-07.jpg',
          './assets/img/lunar-phases/mond-06.jpg',
          './assets/img/lunar-phases/mond-12.jpg',
          './assets/img/lunar-phases/mond-16.jpg',
          './assets/img/lunar-phases/mond-02.jpg',
          './assets/img/lunar-phases/mond-03.jpg',
          './assets/img/lunar-phases/mond-17.jpg',
          './assets/img/lunar-phases/mond-29.jpg',
          './assets/img/lunar-phases/mond-01.jpg',
          './assets/img/lunar-phases/mond-15.jpg',
          './assets/img/lunar-phases/mond-14.jpg',
          './assets/img/lunar-phases/mond-28.jpg',
          './assets/img/lunar-phases/johannes-hevelius.jpg',
          './assets/img/lunar-phases/thumb--small.jpg',
          './assets/img/lunar-phases/thumb.jpg',
          './assets/img/stars-map/bg-info.jpg',
          './assets/img/stars-map/bg-plate.jpg',
          './assets/img/stars-map/plate-zoom.jpg',
          './assets/img/stars-map/plate.jpg',
          './assets/img/stars-map/bg-plate-zoom.jpg',
          './assets/img/stars-map/thumb--small.jpg',
          './assets/img/stars-map/thumb.jpg',
          './assets/img/stars-map/peter-apian.jpg',
          './assets/img/uranometria/bayer.jpg',
          './assets/img/uranometria/thumbs/s_krebst.jpg',
          './assets/img/uranometria/thumbs/s_widder.jpg',
          './assets/img/uranometria/thumbs/s_steinbock.jpg',
          './assets/img/uranometria/thumbs/s_zwillinge.jpg',
          './assets/img/uranometria/thumbs/s_jungfrau.jpg',
          './assets/img/uranometria/thumbs/s_schuetze.jpg',
          './assets/img/uranometria/thumbs/s_stier.jpg',
          './assets/img/uranometria/thumbs/s_wassermann.jpg',
          './assets/img/uranometria/thumbs/s_fisch.jpg',
          './assets/img/uranometria/thumbs/s_loewe.jpg',
          './assets/img/uranometria/thumbs/s_skorpion.jpg',
          './assets/img/uranometria/thumbs/s_waage.jpg',
          './assets/img/uranometria/bg-info.jpg',
          './assets/img/uranometria/details/s_krebst.jpg',
          './assets/img/uranometria/details/s_widder.jpg',
          './assets/img/uranometria/details/s_steinbock.jpg',
          './assets/img/uranometria/details/s_zwillinge.jpg',
          './assets/img/uranometria/details/s_jungfrau.jpg',
          './assets/img/uranometria/details/s_schuetze.jpg',
          './assets/img/uranometria/details/s_stier.jpg',
          './assets/img/uranometria/details/s_wassermann.jpg',
          './assets/img/uranometria/details/s_fisch.jpg',
          './assets/img/uranometria/details/s_loewe.jpg',
          './assets/img/uranometria/details/s_skorpion.jpg',
          './assets/img/uranometria/details/s_waage.jpg',
          './assets/img/uranometria/galaxy_starfield.png',
          './assets/img/uranometria/earth.jpg',
          './assets/img/uranometria/earth_hd.jpg',
          './assets/img/uranometria/zoom_icon_alpha.jpg',
          './assets/img/uranometria/thumb--small.jpg',
          './assets/img/uranometria/zoom_icon.jpg',
          './assets/img/uranometria/thumb.jpg',
          './assets/img/volvelle/thumb.jpg',
          './assets/img/volvelle/thumb--small.jpg',
          './assets/img/volvelle/bg-info.jpg',
          './assets/img/volvelle/bg-plate.jpg',
          './assets/img/volvelle/plate.jpg',
          './assets/img/saturnium/thumb.jpg',
          './assets/img/saturnium/thumb--small.jpg',
          './assets/img/saturnium/bg-info.jpg',
          './assets/img/saturnium/saturnium-bg.jpg',
          './assets/img/saturnium/saturnium-blank.jpg',
          './assets/img/saturnium/saturnium-01.jpg',
          './assets/img/saturnium/saturnium-01-zoom.jpg',
          './assets/img/saturnium/saturnium-02.jpg',
          './assets/img/saturnium/saturnium-02-zoom.jpg',
          './assets/img/saturnium/saturnium-03.jpg',
          './assets/img/saturnium/saturnium-03-zoom.jpg',
          './assets/img/saturnium/saturnium-04.jpg',
          './assets/img/saturnium/saturnium-04-zoom.jpg',
          './assets/img/saturnium/saturnium-05.jpg',
          './assets/img/saturnium/saturnium-05-zoom.jpg',
          './assets/img/saturnium/saturnium-06.jpg',
          './assets/img/saturnium/saturnium-06-zoom.jpg',
          './assets/img/saturnium/saturnium-07.jpg',
          './assets/img/saturnium/saturnium-07-zoom.jpg',
          './assets/img/saturnium/saturnium-08.jpg',
          './assets/img/saturnium/saturnium-08-zoom.jpg',
          './assets/img/saturnium/saturnium-09.jpg',
          './assets/img/saturnium/saturnium-09-zoom.jpg',
          './assets/img/saturnium/saturnium-10.jpg',
          './assets/img/saturnium/saturnium-10-zoom.jpg',
          './assets/img/saturnium/saturnium-11.jpg',
          './assets/img/saturnium/saturnium-11-zoom.jpg',
          './assets/img/saturnium/saturnium-12.jpg',
          './assets/img/saturnium/saturnium-12-zoom.jpg',
          './assets/img/saturnium/saturnium-13.jpg',
          './assets/img/saturnium/saturnium-13-zoom.jpg',
          './assets/img/saturnium/saturnium-14.jpg',
          './assets/img/saturnium/saturnium-14-zoom.jpg',
          './assets/img/saturnium/saturnium-15.jpg',
          './assets/img/saturnium/saturnium-15-zoom.jpg',
          './assets/img/saturnium/saturnium-16.jpg',
          './assets/img/saturnium/saturnium-16-zoom.jpg',
          './assets/img/saturnium/saturnium-17.jpg',
          './assets/img/saturnium/saturnium-17-zoom.jpg',
          './assets/img/saturnium/saturnium-18.jpg',
          './assets/img/saturnium/saturnium-18-zoom.jpg',
          './assets/img/saturnium/saturnium-19.jpg',
          './assets/img/saturnium/saturnium-19-zoom.jpg',
          './assets/img/saturnium/saturnium-20.jpg',
          './assets/img/saturnium/saturnium-20-zoom.jpg',
          './assets/img/saturnium/saturnium-21.jpg',
          './assets/img/saturnium/saturnium-21-zoom.jpg',
          './assets/img/saturnium/saturnium-22.jpg',
          './assets/img/saturnium/saturnium-22-zoom.jpg',
          './assets/img/saturnium/saturnium-23.jpg',
          './assets/img/saturnium/saturnium-23-zoom.jpg',
          './assets/img/saturnium/saturnium-24.jpg',
          './assets/img/saturnium/saturnium-24-zoom.jpg',
          './assets/img/saturnium/saturnium-25.jpg',
          './assets/img/saturnium/saturnium-25-zoom.jpg',
          './assets/audio/AstroRara_Sternbilder_EN.mp3',
          './assets/audio/AstroRara_Sternbilder_DE.mp3',
          './assets/audio/AstroRara_Jupiter_DE.mp3',
          './assets/audio/AstroRara_Jupiter_EN.mp3',
          './assets/audio/AstroRara_Mondphasen.mp3',
          './assets/audio/AstroRara_Doppelmayr_DE.mp3',
          './assets/audio/AstroRara_Schoener_Planisphaerium_DE.mp3',
          './assets/audio/AstroRara_Bayer_Uranometria_DE.mp3',
          './assets/audio/AstroRara_Peuerbach_EN.mp3',
          './assets/audio/starmap.mp3',
          './assets/audio/AstroRara_Cellarius_EN.mp3',
          './assets/audio/AstroRara_Apian_EN.mp3',
          './assets/audio/AstroRara_Galilei_Die_ Galileischen_Monde_EN.mp3',
          './assets/audio/AstroRara_Apian_DE.mp3',
          './assets/audio/AstroRara_Drehbare_Sternkarte.mp3',
          './assets/audio/AstroRara_Galilei_Die_ Galileischen_Monde_DE.mp3',
          './assets/audio/AstroRara_Cellarius_DE.mp3',
          './assets/audio/AstroRara_Doppelmayr_EN.mp3',
          './assets/audio/AstroRara_Schoener_Planisphaerium_EN.mp3',
          './assets/audio/AstroRara_Sonnenflecken-Beobachtungen.mp3',
          './assets/audio/AstroRara_Bayer_Uranometria_EN.mp3',
          './assets/audio/AstroRara_Peuerbach_DE.mp3',
          './assets/audio/AstroRara_Mars_Volvelle_DE.mp3',
          './assets/audio/AstroRara_Mars_Volvelle_EN.mp3',
          './assets/audio/AstroRara_Titan_DE.mp3',
          './assets/audio/AstroRara_Titan_EN.mp3',
          './assets/audio/lunar-phases.mp3',
          './assets/audio/sunspots.mp3',
          './assets/fonts/svg-icons.html',
          './assets/fonts/svg-icons.eot',
          './assets/fonts/DINNextLTPro-Bold.otf',
          './assets/fonts/DINNextLTPro-Light.otf',
          './assets/fonts/svg-icons.ttf',
          './assets/fonts/svg-icons.afm',
          './assets/fonts/svg-icons.svg',
          './assets/fonts/svg-icons.woff2',
          './assets/fonts/svg-icons.woff',
          './app.js'
        ]
        const _lang =  this.$i18n.locale
        var localFilesToCache = []
        if(_lang == 'en') {
          localFilesToCache = [
            '/assets/audio/starmap.mp3',
            '/assets/audio/lunar-phases.mp3',
            '/assets/audio/sunspots.mp3',
            '/assets/audio/AstroRara_Bayer_Uranometria_EN.mp3',
            '/assets/audio/AstroRara_Galilei_Die_ Galileischen_Monde_EN.mp3',
            '/assets/audio/AstroRara_Schoener_Planisphaerium_EN.mp3',
            '/assets/audio/AstroRara_Mars_Volvelle_EN.mp3',
            '/assets/audio/AstroRara_Titan_EN.mp3',
          ]
        } else {
          localFilesToCache = [
            '/assets/audio/AstroRara_Mondphasen.mp3',
            '/assets/audio/AstroRara_Drehbare_Sternkarte.mp3',
            '/assets/audio/AstroRara_Sonnenflecken-Beobachtungen.mp3',
            '/assets/audio/AstroRara_Bayer_Uranometria_DE.mp3',
            '/assets/audio/AstroRara_Galilei_Die_ Galileischen_Monde_DE.mp3',
            '/assets/audio/AstroRara_Schoener_Planisphaerium_DE.mp3',
            '/assets/audio/AstroRara_Mars_Volvelle_DE.mp3',
            '/assets/audio/AstroRara_Titan_DE.mp3',
          ]
        }
        filesToCache = filesToCache.concat(localFilesToCache);

        document.getElementById("total_files").innerHTML = filesToCache.length;
        filesToCache.forEach((f, i) => {
          const Http = new XMLHttpRequest();
          const url = f;
          Http.open("GET", url);
          Http.send();
          Http.onreadystatechange = () => {
            if( Http.readyState === 4 ) {
              if(document.getElementById("caching_update")) {
                document.getElementById("caching_update").innerHTML = i;
                doneFiles.push(i);
                if(doneFiles.length === filesToCache.length) {
                  document.getElementById("caching_done").innerHTML = 'All files cached';
                }
              }
            }
          }
        })
      }
    }
  }
</script>
