import { render, staticRenderFns } from "./jupiters-moons.vue?vue&type=template&id=33ababc8&scoped=true&"
import script from "./jupiters-moons.vue?vue&type=script&lang=js&"
export * from "./jupiters-moons.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33ababc8",
  null
  
)

export default component.exports