<template>
  <div class="zoom-sun-spots native-pinch zoom-page">
    <div class="top-tool-nav-wrap">
      <a @click="$router.go(-1)" class="btn-close-circle" aria-hidden="true"><span class="visuallyhidden">close Zoom View</span></a>
    </div>

    <div class="zoomed-img-wrap">
      <div class="zoomed__sun-wrap">
        <svg preserveAspectRatio="xMaxYMin meet" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 2168 2168" class="zoomed__sun-spots" :style="'height:'+ imageHeightLive">
          <defs>
            <clipPath id="clip_1" v-html="this.sunslides[this.activeId].clipath"></clipPath>
          </defs>
          <image clip-path="url(#clip_1)" xlink:href="/assets/img/sun-spots/sun.jpg" width="2168" height="2168" />
        </svg>
        <svg preserveAspectRatio="xMaxYMin meet" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 2168 2168" class="zoomed__sun-bg" :style="'height:'+ imageHeightLive">
          <defs>
            <clipPath id="clip_bg">
              <path class="st2" d="M1059,101.2c0,0-912.2-0.3-940,982.8c0,0-58.1,890.6,940,985.7c0,0,911.6,17.2,986.9-984.2C2045.9,1085.5,2057.8,172.6,1059,101.2z"/>
            </clipPath>
          </defs>
          <image clip-path="url(#clip_bg)" xlink:href="/assets/img/sun-spots/sun.jpg" width="2168" height="2168" />
        </svg>
        <svg preserveAspectRatio="xMaxYMin meet" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 2168 2168" class="zoomed__sun-blur" :style="'height:'+ imageHeightLive">
          <path fill="rgba(255,255,255,0.8)" d="M1059,101.2c0,0-912.2-0.3-940,982.8c0,0-58.1,890.6,940,985.7c0,0,911.6,17.2,986.9-984.2C2045.9,1085.5,2057.8,172.6,1059,101.2z"/>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
  import suns from '../../data/sun';

  export default {
  name: 'zoomSunSpotsComponent',
    data() {
      return {
        sunslides:suns.data()['slides'],
        imageHeightLive: 'auto'
      }
    },
    created() {
      this.activeId = this.$route.params.id;
      localStorage.setItem('sunIndex', this.activeId)

    },
    beforeRouteLeave (to, from, next) {
      document.body.classList.remove('zoomed-opened');
      next();
    },
    methods:{
      setHeight: function (){
        var winH = window.innerHeight;
        document.body.style.height = winH + 'px';
        document.querySelector('.zoom-sun-spots').style.height = winH + 'px';
        document.querySelector('.zoomed-img-wrap').style.height = winH + 'px';
        var _this = this;
        var newHeight = winH - this.$el.querySelector('.btn-close-circle').clientHeight - 12;

        if(window.innerWidth >= 768){
          _this.imageHeightLive = newHeight + 'px';
          document.body.classList.remove('zoomed-overflow');
        } else {
          _this.imageHeightLive = 'auto';
          setTimeout(function (){
            if(document.querySelector('.zoomed__sun-wrap').clientHeight < window.innerHeight){
              document.body.classList.add('zoomed-overflow');
            }
          },5);
        }

        setTimeout(function (){
          if(window.innerWidth >= 768){
            var gap = 80;
          } else {
            gap = 40;
          }

          if(document.querySelector('.zoomed__sun-spots').clientWidth > screen.availWidth - gap){
            document.querySelector('.zoomed__sun-spots').style.width = screen.availWidth - gap + 'px';
            document.querySelector('.zoomed__sun-bg').style.width = screen.availWidth - gap + 'px';
            document.querySelector('.zoomed__sun-blur').style.width = screen.availWidth - gap + 'px';
            _this.imageHeightLive = 'auto';
            _this.$el.querySelector('.zoomed__sun-wrap').classList.add('centered');
          } else {
            document.querySelector('.zoomed__sun-spots').style.width = 'auto';
            document.querySelector('.zoomed__sun-bg').style.width = 'auto';
            document.querySelector('.zoomed__sun-blur').style.width = 'auto';
            _this.imageHeightLive = newHeight + 'px';
            _this.$el.querySelector('.zoomed__sun-wrap').classList.remove('centered');
          }
        },5);
      }
    },
    mounted() {
      var _this = this;
      document.body.classList.add('zoomed-opened');
      if(this.activeId == 0 || this.activeId == this.sunslides.length - 1){
        this.$el.querySelector('.zoomed__sun-blur').style.opacity = '0';
      }

      this.setHeight();

      window.onresize = () => {
        setTimeout(function (){
          _this.setHeight();
        },10)
      }
    }
  }
</script>
