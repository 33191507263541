<template>
  <div class="detail-content">
    <section class="section lead">
      <p>{{ $t('sun-spots.text1') }}</p>
    </section>
    <audio-section :file="$t('sun-spots.audio')" format="audio/mp3" :audiotitle="$t('sun-spots.audiotitle')"></audio-section>
    <section class="section">
      <h2 class="section__title">{{ $t('howDoesItWork') }}</h2>
      <div class="interactive-content rte" ref="inter-cont-0">
        <p v-show="excerpt">{{ $t('sun-spots.excerpt1') }} <span v-touch="(event) => { showAll(0) }" class="link show-more">{{ $t('readmore') }}</span></p>
        <p class="more-content" :class="{'more-content--visible':readMore}" v-html="$t('sun-spots.readmoretext1')"></p>
      </div>
    </section>
    <section class="section author">
      <h2 class="section__title">{{ $t('authorHeadline') }}</h2>
      <div class="author__content">
        <div class="author-img-wrap"><img class="author__img" src="/assets/img/sun-spots/christoph-scheiner.jpg" :alt="$t('sun-spots.authorAlt')"></div>
        <div class="author-text-wrap">
          <h3 class="author__name">{{ $t('sun-spots.authorName') }}</h3>
          <p class="lifetime">{{ $t('sun-spots.authorLifetime') }}</p>
          <div class="interactive-content rte" ref="inter-cont-1">
            <p v-show="excerpt">{{ $t('sun-spots.excerpt2') }} <span v-touch="(event) => { showAll(1) }" class="link show-more">{{ $t('readmore') }}</span></p>
            <div class="more-content" :class="{'more-content--visible':readMore}" v-html="$t('sun-spots.readmoretext2')"></div>
          </div>
        </div>
      </div>
    </section>
    <section class="section">
      <h2 class="section__title">{{ $t('aboutHeadline') }}</h2>
      <div class="interactive-content rte" ref="inter-cont-2">
        <p v-show="excerpt">{{ $t('sun-spots.excerpt3') }} <span v-touch="(event) => { showAll(2) }" class="link show-more">{{ $t('readmore') }}</span></p>
        <p class="more-content" :class="{'more-content--visible':readMore}" v-html="$t('sun-spots.readmoretext3')"></p>
      </div>
    </section>
    <section class="section links">
      <h2 class="section__title">{{ $t('Links') }}</h2>
      <ul class="link-list">
        <li class="link-list__item" @click="$emit('show-modal')">
          <div class="link-list__icon"><img src="/assets/img/layout/logo_erara.svg" alt="e-rara"></div>
          <div class="link-list__cont">
            <h4 class="link-title">{{ $t('View Book on e-rara.ch') }}</h4>
            <p>Rosa Ursina (Scheiner, 1630)</p>
          </div>
        </li>
      </ul>
    </section>
  </div>
</template>

<script>
  import AudioSection from '../../components/audio';

  export default {
    name: "iSunSpots",
    components: {
      'audio-section': AudioSection
    },
    data() {
      return {
        excerpt: true,
        readMore: false,
      }
    },
    methods: {
      showAll: function(index) {
        this.excerpt = false
        this.readMore = true

        setTimeout(function (){
          var body = document.body;
          var docEl = document.documentElement;
          var parentEl = document.querySelectorAll('.more-content')[index].closest('.interactive-content');
          var getActiveTitle = parentEl.previousSibling;
          var gap = 0;

          if(index == 1){
            getActiveTitle = parentEl.closest('.author-text-wrap');
            gap = 24;
          }

          var scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
          var top  = getActiveTitle.getBoundingClientRect().top - gap +  scrollTop;

          window.scrollTo({
            top: top,
            left: 0,
            behavior: "smooth",
          });
        },10)

      },
    }
  }
</script>
