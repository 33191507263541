<script>
  export default {
    name: 'halley',
    data: function (){
      return {
        slides : [
          {
            legend: "06.08.1531",
            clipath : `<path fill-rule="evenodd" clip-rule="evenodd" d="M1631.2 451.4c39.8 22.9 53.6 73.8 30.7 113.6l-443.6 771.5c-22.9 39.8-73.8 53.6-113.6 30.7-39.8-22.9-53.6-73.8-30.7-113.6l443.6-771.5c22.9-39.8 73.8-53.6 113.6-30.7z"/>`
          },
          {
            legend: "06.08.1531",
            clipath : `<path fill-rule="evenodd" clip-rule="evenodd" d="M1631.2 451.4c39.8 22.9 53.6 73.8 30.7 113.6l-443.6 771.5c-22.9 39.8-73.8 53.6-113.6 30.7-39.8-22.9-53.6-73.8-30.7-113.6l443.6-771.5c22.9-39.8 73.8-53.6 113.6-30.7z"/>`
          },
          {
            legend: "13.08.1531",
            clipath : `<path fill-rule="evenodd" clip-rule="evenodd" d="m1564.7 264 .2.1c38.8 19.9 54.1 67.6 34.1 106.4l-481.7 937.1c-19.9 38.8-67.6 54.1-106.4 34.1l-.2-.1c-38.8-19.9-54.1-67.6-34.1-106.4l481.7-937.1c20-38.8 67.6-54.1 106.4-34.1z"/>`
          },
          {
            legend: "14.08.1531",
            clipath : `<path fill-rule="evenodd" clip-rule="evenodd" d="m1423.7 230.1 1.8.8c35.2 14.5 52 54.9 37.5 90.1l-401.9 974.9c-14.5 35.2-54.9 52-90.1 37.5l-1.8-.8c-35.2-14.5-52-54.9-37.5-90.1l401.9-974.9c14.5-35.2 54.8-52 90.1-37.5z"/>`
          },
          {
            legend: "15.08.1531",
            clipath : `<path fill-rule="evenodd" clip-rule="evenodd" d="M1263.7 207.3c37.3 10.3 59.2 48.8 48.9 86.1l-272.2 989.5c-10.3 37.3-48.8 59.2-86.1 48.9-37.3-10.3-59.2-48.8-48.9-86.1l272.2-989.5c10.3-37.2 48.8-59.1 86.1-48.9z"/>`
          },
          {
            legend: "16.08.1531",
            clipath : `<path fill-rule="evenodd" clip-rule="evenodd" d="M1131.6 171.6c38.2 6.2 64.1 42.1 57.9 80.3L1024 1274.3c-6.2 38.2-42.1 64.1-80.3 57.9-38.2-6.2-64.1-42.1-57.9-80.3l165.5-1022.4c6.2-38.2 42.1-64.1 80.3-57.9z"/>`
          },
          {
            legend: "17.08.1531",
            clipath : `<path fill-rule="evenodd" clip-rule="evenodd" d="M978.6 191.5c38.9 1.6 69.2 34.4 67.6 73.3l-40.5 993.3c-1.6 38.9-34.4 69.2-73.3 67.6-38.9-1.6-69.2-34.4-67.6-73.3l40.5-993.3c1.5-38.9 34.4-69.2 73.3-67.6z"/><`
          },
          {
            legend: "18.08.1531",
            clipath : `<path fill-rule="evenodd" clip-rule="evenodd" d="M791.6 176.8c38.6-4.7 73.8 22.8 78.6 61.4l122.3 997.6c4.7 38.6-22.8 73.8-61.4 78.6s-73.8-22.8-78.6-61.4L730.2 255.3c-4.7-38.6 22.7-73.8 61.4-78.5z"/>`
          },
          {
            legend: "22.08.1531",
            clipath : `<path fill-rule="evenodd" clip-rule="evenodd" d="M306.3 187.1c34.1-18.2 76.5-5.4 94.7 28.7l530.7 992.4c18.2 34.1 5.4 76.5-28.7 94.7-34.1 18.2-76.5 5.4-94.7-28.7L277.6 281.9c-18.2-34.1-5.4-76.5 28.7-94.8z"/>`
          },
          {
            legend: "23.08.1531",
            clipath : `<path fill-rule="evenodd" clip-rule="evenodd" d="M224.1 241.6c32.9-20.4 76.1-10.3 96.5 22.5l578.9 932c20.4 32.8 10.3 76-22.5 96.4-32.8 20.4-76 10.3-96.4-22.6l-578.9-932c-20.6-32.8-10.5-76 22.4-96.3z"/><`
          }
        ]
      }
    }
  }
</script>
