import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

import messages from './import';

const i18n = new VueI18n({
  locale: 'de',
  fallbackLocale: 'en',
  warnHtmlInMessage: 'off',
  messages,
});


export default i18n;
