<template>
  <div id="nav-wrap">
    <div class="nav-bottom">
      <nav class="nav-bottom__container" :class="{ 'active' : isMenuOpen }">
        <navprojects v-if="needNavprojects" :current-nav-id="currentNav"></navprojects>
        <a v-else :href="$t('intro')"><img class="nav-bottom__logo" src="/assets/img/layout/logo_astrorara.svg" alt="ETH ASTRORARA Logo" title="ETH ASTRORARA Logo"></a>
        <button class="nav-bottom__button hamburger-button hm-open-menu" @click="toggleMenu" title="Open the menu"
                :class="{ 'active' : isMenuOpen }"
        >
          <span class="menu__bar" aria-hidden="true"></span>
          <span class="menu__label visuallyhidden">Menu</span>
        </button>
      </nav>
    </div>
    <div class="menu-overlay-bg hm-close-menu" :class="{ 'active' : isMenuOpen }" @click="closeMenu"></div>
    <div class="menu-overlay menu-overlay--desktop" :class="{ 'active' : isMenuOpen }" @click="closeShare">
      <div class="menu-overlay__container">
        <div class="menu-overlay-container__row menu-overlay-container--scroll">
          <div class="menu-overlay__col-left">
            <div class="menu-overlay__title-stage">
              <div key="1" v-if="hoverMenuFlag && hoverMenuIndex >= 0">
                <transition name="menu-link-hover" appear>
                  <div class="menu-overlay-body__thumbnail-wrap">
                      <svg class="menu-overlay-body__thumbnail" width="1200px" height="1200px" viewBox="0 0 1200 1200" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <defs>
                          <clipPath :id="'clip-thumb_'+hoverMenuIndex">
                            <circle fill="#E4D5C2" cx="600" cy="600" r="600"></circle>
                          </clipPath>
                        </defs>
                        <image :clip-path="'url(#clip-thumb_'+hoverMenuIndex+')'"  :xlink:href="projectNavs[hoverMenuIndex].img" width="1200" height="1200" />
                      </svg>
                      <p  class="menu-overlay-body__thumbnail-info">{{$t(`${projectNavs[hoverMenuIndex].url}.text1`)}}</p>
                  </div>
                </transition>
                </div>
                <div v-else>
                  <transition name="menu-link-hover" appear>
                    <div  class="menu-overlay-body__title-wrap" >
                      <div class="menu-overlay-body__title">{{$t("The works")}}</div>
                      <p class="menu-overlay-body__subtitle">{{ $t('aboutus.menu-overlay-lead') }}</p>
                      <router-link to="about-us" class="btn btn--arrow">
                        <span class="desktop-only">{{ $t('about AstroRara') }}</span>
                        <span class="desktop-l-only">{{ $t('more about AstroRara') }}</span>
                        <span class="sicon sicon__internal-link" aria-hidden="true"></span>
                      </router-link>
                    </div>
                  </transition>
                </div>
            </div>
          </div>
          <div class="menu-overlay__col-right">
              <ul class="menu-grid menu-project" :style="'--menurows:'+ (Math.floor((projectNavs.length / 3 )) + (projectNavs.length % 3 > 0 ? 1: 0))">
                <li v-for="(item, key) in projectNavs" :key=key>
                  <router-link :to="item.url" :class="{ 'active' : item.url === currentNav }" @click.native="closeMenu()" @mouseover.native="mouseOverMenuLink(key)" @mouseleave.native="mouseLeaveMenuLink()">
                    <span class="menu-link-title" v-t="item.headline"></span>
                    <span class="menu-link-subtitle" v-html="item.subheadline"></span>
                  </router-link>
                </li>
              </ul>
          </div>
        </div>
        <div class="menu-overlay-container__row">
          <div class="menu-overlay__col-left">
            <ul class="lang">
              <li><a href='#' @click.prevent="setLocale('en')" :class="{ 'active' : lang == 'en' }">en</a></li>
              <li><a href='#' @click.prevent="setLocale('de')" :class="{ 'active' : lang == 'de' }">de</a></li>
            </ul>
          </div>
          <div class="menu-overlay__col-right menu-grid">
            <div class="menu-grid__item">
              <ul class="menu-meta">
                <li v-for="(item, key) in serviceNavs" :key=key><router-link :to="item.id" v-t="item.title" class="menu-meta__link" :class="{ 'active' : item.id === currentNav }"></router-link>
                </li>
                <li><share-section is-textlink></share-section></li>
              </ul>
            </div>
            <div class="menu-grid__item"><div class="presentor__label">{{$t("Presented by")}}</div></div>
            <div class="menu-grid__item">
              <div class="presentor__logo"><img src="/assets/img/layout/ethz-logo-white.svg" alt="ETH Zürich Logo" title="ETH Zürich Logo"></div>
              <div class="presentor__title">{{$t("ETH-Bibliothek")}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="menu-overlay menu-overlay--mobile" :class="{ 'active' : isMenuOpen }" @click="closeShare">
      <div class="menu-overlay__container">
        <div class="menu-overlay-body__title">{{$t("The works")}}</div>
        <div class="menu-overlay__main">
          <section>
            <ul>
              <li v-for="(item, key) in projectNavs" :key=key>
                <router-link :to="item.url" v-t="item.headline" :class="{ 'active' : item.url === currentNav }" @click.native="closeMenu()"></router-link>
              </li>
            </ul>
          </section>
          <section>
            <ul>
              <li v-for="(item, key) in serviceNavs" :key=key><router-link :to="item.id" v-t="item.title" :class="{ 'active' : item.id === currentNav }"></router-link>
              </li>
            </ul>
          </section>
          <div class="menu-overlay-body__footer">
            <div class="presentor__label">{{$t("Presented by")}}</div>
            <div class="presentor__logo"><img src="/assets/img/layout/ethz-logo-white.svg" alt="ETH Zürich Logo" title="ETH Zürich Logo"></div>
            <div class="presentor__title">{{$t("ETH-Bibliothek")}}</div>
          </div>
        </div>
      </div>

      <footer class="menu-overlay__footer">
        <ul class="lang">
          <li><a href='#' @click.prevent="setLocale('en')" :class="{ 'active' : lang == 'en' }">en</a></li>
          <li><a href='#' @click.prevent="setLocale('de')" :class="{ 'active' : lang == 'de' }">de</a></li>
        </ul>
        <share-section></share-section>
        <button class="menu-overlay-footer__button hamburger-button hm-close-menu" @click="closeMenu" title="Close the menu"
                :class="{ 'active' : isMenuOpen }"
        >
          <span class="menu__bar menu--close" aria-hidden="true"></span>
          <span class="menu__label visuallyhidden">Menu</span>
        </button>
      </footer>
    </div>
  </div>
</template>

<script>
  import NavProjects from './nav-projects';
  import ShareSection from './share';
  import Slides from "../data/planet-slides";
  export default {
    name: 'MenuPrimary',
    components: {
      'navprojects': NavProjects,
      'share-section': ShareSection
    },
    props: {
      currentNav: String,
      needNavprojects: Boolean
    },
    data: function () {
      return {
        langs: ['de', 'en'],
        lang: this.$route.params.lang,
        isMenuOpen: false,
        curScrollTop: 0,
        projectNavs: Slides.data()['Slides'],
        hoverMenuIndex: null,
        hoverMenuFlag: false,
        serviceNavs: [
          {
            title: 'aboutUs',
            id: 'about-us'
          },
          {
            title: 'Impressum',
            id: 'impressum'
          }
        ]
      }
    },
    methods: {
      setLocale(locale) {
        if(this.$i18n.locale != locale) {
          this.$i18n.locale = locale
          this.lang = locale
          this.$router.push({
            params: { lang: locale }
          });
        }
      },
      mouseOverMenuLink: function (index) {
        this.hoverMenuIndex = index;
        this.hoverMenuFlag = true;
      },
      mouseLeaveMenuLink: function () {
        this.hoverMenuIndex = null;
        this.hoverMenuFlag = false;
      },
      toggleMenu: function () {
        if(this.isMenuOpen) {
          this.closeMenu();
        } else {
          this.openMenu()
        }
      },
      openMenu: function () {
        this.isMenuOpen = true
        this.menuStatus();
      },
      closeMenu: function () {
        this.isMenuOpen = false
        this.menuStatus();
        this.resetShareLinks();
      },
      closeShare: function (e){
        if(!e.target.classList.contains('share-button') && !e.target.classList.contains('share-butto__item')){
          this.resetShareLinks();
        }
      },
      resetShareLinks : function () {
        var sharelinks = document.querySelectorAll('.menu-overlay .share-button');
        sharelinks.forEach(function(shareLink) {
          shareLink.classList.remove('open');
        });
      },
      menuStatus: function () {
        //var scrollbarWidth = this.getScrollbarWidth();
        var el = document.querySelector("body");
        var html = document.querySelector("html");
        var scrollHeight = el.scrollHeight;
        var windowHeight = window.innerHeight;
        var hasVerticalScrollbar = scrollHeight > windowHeight;

        if(this.isMenuOpen) {
          this.curScrollTop = document.documentElement.scrollTop || window.pageYOffset;
          html.classList.add('menu-opened');
          if(hasVerticalScrollbar) {
            //el.style.paddingRight = scrollbarWidth + 'px';

            if(this.curScrollTop > 0) {
              el.style.top = -(this.curScrollTop) + 'px';
            }
          }
        } else {
          html.classList.remove('menu-opened');
          el.style.paddingRight = '';
          el.style.top = '';
          window.scrollTo(0, this.curScrollTop);
          window.setTimeout(function () {
            this.curScrollTop = 0;
          }, 0);
        }
      },
      resetStatus: function () {
        var el = document.querySelector("body");
        var html = document.querySelector("html");
        html.classList.remove('menu-opened');
        this.curScrollTop = 0;
        el.style.paddingRight = '';
        el.style.top = '';
      },

    },
    mounted() {
      this.resetStatus();

      /*
      const closeMenus = document.querySelectorAll(".hm-close-menu");
      var $this = this;
      [].forEach.call(closeMenus, function(el) {
        var hammer = new Hammer(el);
        hammer.on('tap', (e) => {
                  $this.closeMenu()
        })
      })
      const openMenu = document.querySelector(".hm-open-menu");
      var hammer = new Hammer(openMenu)
      hammer.on('tap', (e) => {
              this.openMenu()
      })*/
    },
  }
</script>


