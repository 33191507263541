<template>
  <div id="saturn-rings" class="work-page" :class="{ 'detail-loaded' : isDetailVisible, 'work-page-loaded': isPageLoaded, 'work-library-modal': showModal }" @click="closeWorkShare">
    <div class="work__bg-wrap">
      <div class="work__bg">
        <img src="/assets/img/layout/bg-overview.jpg" class="work__bg-img"  alt="work background image">
      </div>
    </div>
    <div class="detail__bg" style="background-image: url('/assets/img/saturn-rings/bg-info.jpg')"></div>
    <div class="container" ref="container">
      <div class="page__top">
        <h1 class="page-top__title">{{ $t('Saturn-Ringe') }}</h1>
        <p class="page-top__text">Systema Saturnium <br/>(Huygens, 1659)</p>
      </div>
      <main class="main">
        <div class="work-content work-intro-ani-in">
          <div id="webgl" class="work-3d"></div>
          <div class="range-slider range-slider--horizontal scale-item"
               :style="'width:'+ rangeWidthLive +'px'">
            <div class="range-slider__handle-track--horizontal">
              <div class="range-slider__handle--horizontal" :style="'transform: translateX(' + currentHandleLeft + 'px)'" ref="handle">
                <span class="sicon sicon__slide-handle" aria-hidden="true"></span>
                <span class="visuallyhidden">Slide Handle</span>
              </div>
            </div>
            <div class="range-slider__track--horizontal">
                <svg width="526" height="19" viewBox="0 0 526 19"
                     xmlns="http://www.w3.org/2000/svg">
                  <g fill="none" fill-rule="evenodd">
                    <g opacity=".35" stroke="#FFF"
                       stroke-width="1.964">
                      <path
                        d="M1.761 17.599c1.45-1.45 6.527-2.901 9.429 0M20.618 17.606c-1.45-1.45-6.527-2.902-9.428 0M1.761 3.093c1.45-1.45 7.253-2.176 9.429 0M20.618 3.016c-1.45-1.45-7.253-2.176-9.428 0"
                        stroke-linecap="round"/>
                      <path d="M1.761 3.093v14.505M20.618 3.093v14.505M11.19 3.093v14.505"/>
                    </g>
                    <path fill-opacity=".3" fill="#FFF" fill-rule="nonzero"
                          d="M25.189 8.75h98v1.5h-98z"/>
                    <g opacity=".35" stroke="#FFF"
                       stroke-width="1.964">
                      <path
                        d="M127.761 17.599c1.451-1.45 6.527-2.901 9.429 0M146.618 17.606c-1.45-1.45-6.527-2.902-9.428 0M127.761 3.093c1.451-1.45 7.253-2.176 9.429 0M146.618 3.016c-1.45-1.45-7.253-2.176-9.428 0"
                        stroke-linecap="round"/>
                      <path d="M127.761 3.093v14.505M146.618 3.093v14.505M137.19 3.093v14.505"/>
                    </g>
                    <path fill-opacity=".3" fill="#FFF" fill-rule="nonzero"
                          d="M151.189 8.75h98v1.5h-98z"/>
                    <g opacity=".35" stroke="#FFF"
                       stroke-width="1.964">
                      <path
                        d="M253.761 17.599c1.451-1.45 6.527-2.901 9.429 0M272.618 17.606c-1.45-1.45-6.527-2.902-9.428 0M253.761 3.093c1.451-1.45 7.253-2.176 9.429 0M272.618 3.016c-1.45-1.45-7.253-2.176-9.428 0"
                        stroke-linecap="round"/>
                      <path d="M253.761 3.093v14.505M272.618 3.093v14.505M263.19 3.093v14.505"/>
                    </g>
                    <path fill-opacity=".3" fill="#FFF" fill-rule="nonzero"
                          d="M277.189 8.75h98v1.5h-98z"/>
                    <g opacity=".35" stroke="#FFF"
                       stroke-width="1.964">
                      <path
                        d="M379.761 17.599c1.451-1.45 6.527-2.901 9.429 0M398.618 17.606c-1.45-1.45-6.527-2.902-9.428 0M379.761 3.093c1.451-1.45 7.253-2.176 9.429 0M398.618 3.016c-1.45-1.45-7.253-2.176-9.428 0"
                        stroke-linecap="round"/>
                      <path d="M379.761 3.093v14.505M398.618 3.093v14.505M389.19 3.093v14.505"/>
                    </g>
                    <path fill-opacity=".3" fill="#FFF" fill-rule="nonzero"
                          d="M403.189 8.75h98v1.5h-98z"/>
                    <g opacity=".35" stroke="#FFF"
                       stroke-width="1.964">
                      <path
                        d="M505.761 17.599c1.451-1.45 6.527-2.901 9.429 0M524.618 17.606c-1.45-1.45-6.527-2.902-9.428 0M505.761 3.093c1.451-1.45 7.253-2.176 9.429 0M524.618 3.016c-1.45-1.45-7.253-2.176-9.428 0"
                        stroke-linecap="round"/>
                      <path d="M505.761 3.093v14.505M524.618 3.093v14.505M515.19 3.093v14.505"/>
                    </g>
                  </g>
                </svg>
            </div>
            <div id="range-interact">
              <span v-for="(bookmark, index) in bookMarkers" :class="'bookmarks__step bookmarks__step--'+ index"
                    :key="index" ></span>
            </div>
            <div class="work__legend">
              <span v-for="(bookmark, index) in bookMarkers" class="saturn-rings__legend-span"
                    :key="index" v-if="index == activeSlide">{{ $t(bookmark.legend) }}</span>
            </div>
          </div>

        </div>
        <detail v-on:show-modal="showModalMsg"></detail>
      </main>
    </div>
    <menuprimary current-nav="saturn-rings" :needNavprojects=true  v-on:toggle-detail="toggleDetail"></menuprimary>
    <div class="top-tool-nav-wrap">
      <div @click="toggleDetail" v-if="isDetailVisible" class="btn-close-circle" aria-hidden="true">
        <span class="visuallyhidden">close detail content</span>
      </div>
      <share-section v-if="isDetailVisible"></share-section>
      <router-link to="overview" class="btn-close-circle" aria-hidden="true" v-else>
        <span class="visuallyhidden">go to overview page</span>
      </router-link>
    </div>
    <modal v-if="showModal" url="https://www.e-rara.ch/zut/doi/10.3931/e-rara-3178" @close="showModal=false"></modal>
    <transition name="book" v-if="showBook">
      <div class="modal-mask modal-saturn-rings">
        <div class="modal-wrapper">
          <a class="btn-close-circle" @click="closeBook" aria-hidden="true"><span class="visuallyhidden">close book</span></a>
          <div class="modal-container" >
              <slot name="body" >
                <div class="saturn-rings-book" :style="'width:'+ bookWidth +'px'" v-for="(bookmark, index) in bookMarkers" :key="index" v-if="index == activeSlide">
                  <div class="modal__top-img"><img :src="'/assets/img/saturn-rings/' + bookmark.imgSaturn" :alt="bookmark.imgSaturn" class="modal__img-saturn"></div>
                  <div class="modal__legend">{{ bookmark.legend }}</div>
                  <div class="modal__bottom-img"><img :src="'/assets/img/saturn-rings/' + bookmark.imgMap" :alt="bookmark.imgMap" class="modal__img-map"></div>
                </div>
              </slot>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>

import iSaturnRings from '../info/i-saturn-rings';
import Modal from '../../components/modal';
import MenuPrimary from '../../components/menu-primary';
import ShareSection from '../../components/share';
import saturnRings from '../../data/saturn-rings';
import anime from "animejs";
import Hammer from "../../assets/js/hammer.min";

export default {
  name: 'saturnRingsComponent',
  components: {
    'detail': iSaturnRings,
    'modal': Modal,
    'menuprimary': MenuPrimary,
    'share-section': ShareSection
  },
  data() {
    return {
      activeSlide: 0,
      showModal: false,
      showBook: false,
      isDetailVisible: false,
      windowWidth: window.innerWidth,
      rangeWidthLive:0,
      bookWidth: '',
      zoomEl:'',
      mc:'',
      mcGlobe: '',
      pinchTimmer: 0,
      isPageLoaded: false,
      pageLoadTimer: null,
      pageTop:'',
      generalScale: 1,
      currentRingDeg: 1,
      currentHandleLeft: 0,
      currentClickPos: 10,
      slideWidthLive: 0,
      instAniInterval: null,
      instAniLoadTimer: null,
      bookMarkers: saturnRings.data()['bookMarkers'],
      imagesBaseUrl: '/assets/img/saturn-rings/',
      sphere: null,
      canvasSize: null,
      camera: null,
      scene: null,
      webglEl: null,
      ring: null,
      RAF: null,
      renderer: null,
      sphereSetting:{
        radius: 10, // {number} radius of the sphere
        segments: 52, // {number} number of segments
        rotationX: 0, // {number} rotation of the sphere
      },
      ringSetting:{
        innerRadius: 15, // {number} radius of the sphere
        outerRadius: 23, // {number} number of segments
        segments: 100, // {number} number of segments
        rotationX: 91, // {number} rotation of the ring
      },
      iconSetting:{
        size:2.2, // {number} size of the icon
        x: 11, // {number} x
        y: 1.8, // {number} y
        z: 21, // {number} z
        scale:0.7,
        rotationX: 0,
      },
      lastGlobePanY: 0,
      zoomIconAnimation: false,
    }
  },
  created() {
    this.isMobile = this.mobileAndTabletcheck()
  },
  beforeRouteLeave (to, from, next) {
    this.clearIntervalInstAni()
    if(this.pageLoadTimer) {
      clearTimeout(this.pageLoadTimer);
    }
    document.body.classList.remove('detail-opened');
    this.aniMenuPageLeave(next());
  },
  methods: {
    instractionAni: function () {
      var step = 0;
      var deltaStep = 0;
      const targetBookmarker = this.$el.querySelector('.bookmarks__step--1');
      const maxDis = targetBookmarker.offsetLeft + targetBookmarker.getBoundingClientRect().width - 1;
      var deltaUnit = maxDis / 5;
      var _this = this;
      this.instAniInterval = setInterval(frame, 150);
      function frame() {
        if (step == 8 || _this.isDetailVisible) {
          _this.clearIntervalInstAni();
          if(step == 8) {
            _this.displayZoomIcon();
          }
        } else {
          if ((step >= 4 && step < 8)) {
            //4,5,6,7
            deltaStep--;
          } else {
            //0,1,2,3
            deltaStep++;
          }
          _this.updateSliderPos(deltaStep*deltaUnit);
        }
        step++;
      }
    },
    clearIntervalInstAni: function () {
      if (this.instAniInterval) {
        clearInterval(this.instAniInterval);
      }
      if (this.instAniLoadTimer) {
        clearTimeout(this.instAniLoadTimer);
      }
    },
    pageLoad : function() {
      this.isPageLoaded = true;
      var _this = this
      this.pageLoadTimer = setTimeout(function () {
        _this.$el.querySelector(".work-intro-ani-in").classList.remove('work-intro-ani-in')
      }, 1600)
    },
    showModalMsg : function() {
      this.showModal = true
    },
    toggleDetail:function () {
      this.isDetailVisible = !this.isDetailVisible;
      if (!this.isDetailVisible) {
        // eslint-disable-next-line no-undef
        gtag('config', this.uaId, {'page_path': this.$route.path + '-i-detail'});
      } else {
        // eslint-disable-next-line no-undef
        gtag('config', this.uaId, {'page_path': this.$route.path});

        var audio = this.$el.querySelectorAll('audio')[0];
        if (!audio.paused) {
          audio.pause();
        }
      }
    //  const _this = this;
      var _scaleCanvas = 1;
      var _opacity = 1;
      var workMarginTop = 0;
      var workContentH = this.canvasSize.height;
      const workContent = this.$el.querySelector('.work-content');
      const windowR = window.innerWidth / window.innerHeight;
      const windowH = window.innerHeight;

      if(this.isDetailVisible) {
        document.body.classList.add('detail-opened');
        _opacity = 0;
        if (windowR < 0.75) {
          _scaleCanvas = 1;
          workMarginTop = windowH *  0.14;
          if(this.windowR < 0.6){
            workMarginTop = windowH *  0.09;
          }

        } else {
          _scaleCanvas = 0.8;
          workMarginTop = windowH *  0.25;
        }
        workContentH = windowH * 0.59
        this.hideZoomIcon();
      } else {
        document.body.classList.remove('detail-opened');
        if(this.activeSlide != -1) {
          this.displayZoomIcon();
        }
      }

      anime({
        targets: workContent,
        height: workContentH,
        duration: 500,
        easing:'easeOutQuart',
      });

      if(this.isDetailVisible) {
        anime({
          targets: '.work-3d canvas',
          scale: _scaleCanvas,
          marginTop:workMarginTop,
          duration: 500,
          easing:'easeOutQuart',
          complete: function (){
            anime({
              targets: '.range-interact-wrap',
              opacity: _opacity,
              easing: 'easeOutQuad',
              duration: 450,
            });
          }
        });
      } else {
        this.$el.querySelector('.work-3d canvas').style.removeProperty('margin-top');
        anime({
          targets: '.work-3d canvas',
          scale: _scaleCanvas,
          duration: 500,
          easing:'easeOutQuart',
        });
      }
    },
    getRangeWidth: function () {
      const rangeMaxWidth = 526;
      const range = this.$el.querySelector(".range-slider");
      const workLegend = this.$el.querySelector(".work__legend");
      let legendH = workLegend.clientHeight;
      //var pageTopPaddingTop = parseInt(getComputedStyle(this.$el.querySelector(".page__top")).paddingTop);
      this.canvasSize = this.getCanvasSize();
      var winW = window.innerWidth;
      var winH = window.innerHeight;
      var workH = this.canvasSize.height;

      var _bottomNav = this.$el.querySelector(".nav-bottom").getBoundingClientRect().height;
      workLegend.style.marginTop =  range.clientHeight + 16 + "px";
      //var availH = workH - this.$el.querySelector(".page__top").clientHeight - legendH - 60;
      var availH = workH - legendH - 60;
      if (winW > 1200 & winH < 750) {
        availH = availH + 40;
        //availH = workH - this.$el.querySelector(".page-top__title").clientHeight - pageTopPaddingTop * 2 - legendH - winH * 0.045 - 20;
      }
      if (availH < 200) {
        availH = 200
      }

      var maxAvailH = Math.min(winW, availH);
      var rangeWidth = Math.min(rangeMaxWidth, maxAvailH);
      this.slideWidthLive = maxAvailH;
      this.rangeWidthLive = rangeWidth;

      if (winW < 900 || this.slideWidthLive > this.$el.querySelector('.work-content').clientWidth) {
        var maxAvailW = Math.min((winW - 40), availH - 12)
        this.slideWidthLive = maxAvailW;
      }

      if (winW > winH && winW <= 926 && this.isMobile) {
        this.slideWidthLive = this.slideWidthLive * 0.9;
      }

     //this.rangeWidthLive = this.slideWidthLive * 0.8256;

      if (winW < 768) {
    //    this.rangeWidthLive = this.slideWidthLive * 0.96538461538461538;
      }

      if (!this.isDetailVisible) {
        this.$el.querySelector('.work-content').style.height = workH + 'px';
      }

      if (winW > 1200 && winH < 750) {
        range.style.bottom = this.legendBottom + legendH + 16 + "px";
      } else {
        range.style.bottom = this.legendBottom + legendH + range.clientHeight - 12 + "px";
      }

      if (winW < 1025) {
        this.marginTop = parseInt(getComputedStyle(this.$el.querySelector(".page-top__text")).marginBottom);
        range.style.bottom = parseInt(getComputedStyle(range).bottom) + "px";

         this.iconSetting.scale = 0.8;

        if (winW < 900) {
          this.marginTop = parseInt(getComputedStyle(this.$el.querySelector(".page-top__text")).marginBottom);
          this.iconSetting.scale = 0.85;
          if(winH > winW) {
            this.iconSetting.scale = 1;
          }
        }
        if (winW < 768) {
          this.marginTop = parseInt(getComputedStyle(this.$el.querySelector(".page-top__text")).marginBottom) + 10;
          range.style.bottom = this.legendBottom + legendH + range.clientHeight - 2 + "px"
          this.iconSetting.scale = 0.8;
          if(winH > winW) {
            this.iconSetting.scale = 1.2;
            if((winH/winW) > 1.5) {
              this.iconSetting.scale = 1.8;
            }
          }
        }

        if (winW > winH && winW <= 926 && this.isMobile) {
          this.marginTop = 0;
        }
        if(winH <= 500) {
          range.style.bottom = _bottomNav - 15 + "px";
          workLegend.style.marginTop =  40 + "px";
          if( winW <= 500) {
            range.style.bottom =  _bottomNav - 25 + "px";
          }
        }

      } else {
        this.marginTop = 0;
      }
    },
    getCanvasSize: function(){
      const sizes = { width: 0, height: 0};
      sizes.width = window.innerWidth;
      sizes.height = window.innerHeight - 80;
      return sizes;
    },
    createSphere: function(){
      /* eslint-disable no-undef */
      const geometry = new THREE.SphereGeometry(this.sphereSetting.radius, this.sphereSetting.segments, this.sphereSetting.segments);
      const material = new THREE.MeshLambertMaterial({
        map: new THREE.TextureLoader().load(this.imagesBaseUrl + 'saturn.jpg'),
      });
      const sphere = new THREE.Mesh( geometry, material);
      return sphere;
    },
    createRing: function(){
      const container = new THREE.Object3D();
      let ringGeometry = new THREE.RingGeometry(this.ringSetting.innerRadius, this.ringSetting.outerRadius, this.ringSetting.segments );
      const ringTexture = new THREE.TextureLoader().load( this.imagesBaseUrl + 'rings.jpg');
      const ringMaterial = new THREE.MeshLambertMaterial({ side:THREE.DoubleSide,map: ringTexture, transparent: true});
      const ring = new THREE.Mesh( ringGeometry, ringMaterial );
      ring.rotateOnWorldAxis( new THREE.Vector3( 1, 0, 0 ), Math.PI/2 - 0.383972 );
      this.currentRingDeg = this.bookMarkers[this.activeSlide].deg;
      this.ringSetting.rotationX = Math.PI / 180 * this.currentRingDeg;
      ring.rotation.x = this.ringSetting.rotationX;
      container.add(ring);

      //icon on ring
      const iconGeometry = new THREE.PlaneGeometry( this.iconSetting.size,this.iconSetting.size );
      const iconTexture = new THREE.TextureLoader().load( this.imagesBaseUrl + 'zoom_icon.jpg');
      const iconAlphaMap = new THREE.TextureLoader().load( this.imagesBaseUrl + 'zoom_icon_alpha.jpg');
      const iconMaterial = new THREE.MeshLambertMaterial({ side:THREE.DoubleSide,map: iconTexture, transparent: true,alphaMap: iconAlphaMap});
      const zoomIcon = new THREE.Mesh( iconGeometry, iconMaterial );
      zoomIcon.position.set(this.iconSetting.x, this.iconSetting.y, this.iconSetting.z);
      zoomIcon.scale.set(this.iconSetting.scale, this.iconSetting.scale, this.iconSetting.scale);
      //this.iconSetting.rotationX = Math.PI / 180 * (this.currentRingDeg + 80);
     // zoomIcon.rotation.x = this.iconSetting.rotationX;
      zoomIcon.name = 'zoomIcon';
      zoomIcon.cursor = 'pointer';
      zoomIcon.on('pointerdown', this.iconClickListener);
      container.add( zoomIcon );
      container.zoomIcon = zoomIcon;

      return container;
    },
    openBook: function() {
      const _this = this;
      this.showBook = true;
      anime({
        targets: document.querySelector('.modal-mask'),
        opacity: 1,
        easing: 'easeOutQuad',
        duration: 200,
        complete: function() {
          document.querySelector('.modal-container').addEventListener('click',function(e) {
            e.target === this && _this.closeBook();
          });
        }
      });
      anime({
        targets: '.work-content',
        opacity: 0,
        easing: 'easeOutQuad',
        duration: 200,
      });
      setTimeout(function (){
        _this.resizeBookSize();
        anime({
          targets: document.querySelector('.modal-container'),
          opacity: 1,
          easing: 'easeOutQuad',
          duration: 500,
        });
      }, 200);
    },
    closeBook: function (){
      const _this = this;

      anime({
        targets: document.querySelector('.modal-container'),
        opacity: 0,
        easing: 'easeOutQuad',
        duration: 500,
      });
      anime({
        targets: document.querySelector('.modal-mask'),
        opacity: 0,
        easing: 'easeOutQuad',
        duration: 200,
        complete: function(){
          _this.showBook = false;
          anime({
            targets: '.work-content',
            opacity: 1,
            easing: 'easeOutQuad',
            duration: 450
          });
        }
      });
    },
    iconClickListener: function(e) {
      e.stopPropagation();
      this.openBook();
    },
    displayZoomIcon: function(){
      const _this = this;
      if(_this.zoomIconAnimation){
        return;
      }
      _this.zoomIconAnimation = true;

      anime({
        targets: this.ring.zoomIcon.scale,
        x:  this.iconSetting.scale,
        y: this.iconSetting.scale,
        z: this.iconSetting.scale,
        easing: 'easeOutQuad',
        duration: 250,
        complete: function(){
          _this.zoomIconAnimation = false;
        }
      });
    },
    hideZoomIcon: function(){
      const _this = this;
      if(_this.zoomIconAnimation){
        return;
      }

      _this.zoomIconAnimation = true;
      anime({
        targets: this.ring.zoomIcon.scale,
        x: 0.001,
        y: 0.001,
        z: 0.001,
        easing: 'easeOutQuad',
        duration: 250,
        complete: function(){
          _this.zoomIconAnimation = false;
        }
      });
    },
    renderUpdateScale: function(){
      this.canvasSize = this.getCanvasSize();
      let _scale = 1;
      const windowRatio = this.canvasSize.width / this.canvasSize.height;

      if (windowRatio < 1) {
        _scale = windowRatio;
        var pagePaddingLeft = parseInt(getComputedStyle(this.$el.querySelector(".page__top")).paddingLeft);
        this.rangeWidthLive = this.canvasSize.width - pagePaddingLeft * 2;
      } else {
        //this.rangeWidthLive = this.canvasSize.height * 0.8;
        if(window.innerWidth >= 1024){
          //_scale = 1.2;
          _scale = 1;
        }
      }
     // console.log('_scale',_scale);
      this.sphere.scale.set(_scale, _scale, _scale);
      this.ring.scale.set(_scale, _scale, _scale);
      //this.ring.zoomIcon.scale.set(_scale, _scale, _scale);
    },
    renderScene: function(){
      this.RAF = requestAnimationFrame(this.renderScene);
      this.renderer.render(this.scene, this.camera);
    },
    resizeScene: function(){
      this.renderUpdateScale();
      this.canvasSize = this.getCanvasSize();
      this.camera.aspect = this.canvasSize.width / this.canvasSize.height;
      this.camera.updateProjectionMatrix();
      this.renderer.setSize(this.canvasSize.width, this.canvasSize.height);
    },
    resizeBookSize: function(){
      const bookSize = this.$el.querySelector('.saturn-rings-book').getBoundingClientRect();
      const bookRatio = bookSize.width / bookSize.height;
      const windowRatio = window.innerWidth / window.innerHeight;
      if(windowRatio > bookRatio){
        this.bookWidth = bookRatio * (window.innerHeight - 100);
      } else {
        this.bookWidth =  window.innerWidth - 40;
      }
    },
    setScene: function(){
      this.canvasSize = this.getCanvasSize();
      this.scene = new THREE.Scene();
      //saturn sphere
      this.sphere = this.createSphere();
      this.scene.add( this.sphere );

      //Ring
      this.ring = this.createRing();
      this.scene.add( this.ring );

      const light1 = new THREE.AmbientLight(0x333333);
      this.scene.add(light1);

      const light2 = new THREE.DirectionalLight(0xffffff, 1);
      light2.position.set(5, 3, 5);
      this.scene.add(light2);

      const light3 = new THREE.DirectionalLight(0xffffff, 1);
      light3.position.set(-5, -3, -2);
      this.scene.add(light3);

      //Camera
      this.camera = new THREE.PerspectiveCamera( 75, this.canvasSize.width / this.canvasSize.height,1, 1000);
      this.camera.position.z = 40;
      this.scene.add( this.camera );

      //Renderer
      this.renderer = new THREE.WebGLRenderer({
        antialias:true,
        alpha:true,
      });
      this.renderer.setSize( this.canvasSize.width,this.canvasSize.height );
      this.renderer.setClearColor( 0x000000, 0 )
      this.renderer.setPixelRatio(window.devicePixelRatio);

      new THREE.Interaction(this.renderer, this.scene, this.camera);

      this.renderUpdateScale();

      this.webglEl = document.getElementById('webgl');
      this.webglEl.appendChild(this.renderer.domElement);
    },
    getTotalSlideWidth: function(){
      return this.$el.querySelector('.range-slider__track--horizontal').clientWidth - this.$el.querySelector('.range-slider__handle--horizontal').clientWidth / 2;
    },
    updateSliderPos: function(pos, e){
      const bookmarksSteps = this.$el.querySelectorAll('.bookmarks__step');
      const totalSlideWidth = this.getTotalSlideWidth();
      const leftPercent = (pos / totalSlideWidth) * 100;
      const stepDist = this.bookMarkers[this.bookMarkers.length - 1].deg -  this.bookMarkers[0].deg;
      this.currentRingDeg = stepDist * leftPercent / 100 + this.bookMarkers[0].deg;

      if (pos >= totalSlideWidth) {
        pos = totalSlideWidth;
        this.activeSlide = this.bookMarkers.length - 1;
        this.currentRingDeg = this.bookMarkers[this.bookMarkers.length - 1].deg;
      }
      else if (pos <= 10) {
        pos = 10;
        this.currentRingDeg = this.bookMarkers[0].deg;
        this.activeSlide = 0;
      } else {
        const handleWidth = this.$el.querySelector('.range-slider__handle--horizontal').clientWidth;
        this.activeSlide = -1;
        bookmarksSteps.forEach((item, index) => {
          const stepLeft = item.offsetLeft - (handleWidth / 2);
          const stepRight = item.offsetWidth + stepLeft + handleWidth;
          if(pos <= stepRight && pos >= stepLeft){
            this.activeSlide = index;
            this.currentRingDeg = this.bookMarkers[index].deg;
          }
        });
      }
      if(e && e.type == 'tap') {
        this.updateRotation(this.currentRingDeg, true);
      } else {
        this.updateRotation(this.currentRingDeg, false);
      }
      //update handle position
      this.currentClickPos = pos;
      this.updateHandlePos();
    },
    updateHandlePos() {
      this.currentHandleLeft = this.currentClickPos - this.$el.querySelector('.range-slider__handle--horizontal').clientWidth / 2;
    },
    rangeHandleOnPan: function (e) {
      const rangeInteract = this.$el.querySelector('#range-interact').getBoundingClientRect();
      const posOnPan = e.center.x + (rangeInteract.left * -1);
      this.updateSliderPos(posOnPan, e);
    },
    globeOnPan: function (e) {
        let moveToPos = this.currentClickPos + e.deltaY;
        this.updateSliderPos(moveToPos, e);
    },
    updateRotation: function(deg, animation){
      this.ringSetting.rotationX = Math.PI / 180 * (deg - 85);
      this.sphereSetting.rotationX = Math.PI / 180 * (deg - 85);
      this.ring.zoomIcon.rotation.x = Math.PI / 180 * (265 - deg); //265 = 180 + 85

      if(animation){
        anime({
          targets: this.ring.rotation,
          x: this.ringSetting.rotationX,
          easing: 'easeOutSine',
          duration: 500
        });
        anime({
          targets: this.sphere.rotation,
          x: this.sphereSetting.rotationX,
          easing: 'easeOutSine',
          duration: 500
        });
      } else {
        this.ring.rotation.x = this.ringSetting.rotationX
        this.sphere.rotation.x = this.sphereSetting.rotationX;
      }

      if(this.activeSlide == -1) {
        this.hideZoomIcon();
      } else {
        this.displayZoomIcon();
      }
    },
  },
  mounted() {
    this.pageLoad();
    this.setWorkBgPos();
    this.getRangeWidth();
    this.canvasSize = this.getCanvasSize();
    this.setScene();
    this.renderScene();
    this.lastGlobePanY = this.bookMarkers[0].deg;
    this.updateHandlePos();

    this.instAniLoadTimer = setTimeout(this.instractionAni,2000)
    /*
    swipe event on globe
    */
    const globe = this.$el.querySelector("#webgl");
    const mcGlobe = new Hammer.Manager(globe);
    mcGlobe.domEvents = true
    mcGlobe.add( new Hammer.Pan({ direction: Hammer.DIRECTION_ALL}) );
    mcGlobe.add( new Hammer.Tap() );
    mcGlobe.add( new Hammer.Pinch({ threshold: 0 }) );
    mcGlobe.add( new Hammer.Press({
        event: 'press',
        pointer: 1,
        threshold: 500,
        time: 1,
      })
    );

    mcGlobe.on('pan panend', (e) => {
      var old = this.pinchTimmer
      var now = new Date().getTime(),
        delta = now - old;

      if(!this.isDetailVisible){
        this.clearIntervalInstAni();
        //avoid pinch end -> pan
        if(delta > 100) {
          this.pinchTimmer = 0
          document.getElementById("webgl").style.setProperty("pointer-events","none");
          this.globeOnPan(e);
        }
        if(e.type == 'panend'){
          document.getElementById("webgl").style.removeProperty("pointer-events");
        }
      }
    });

    /*
    swipe event on range Handle
    */
    const rangeInteract = this.$el.querySelector("#range-interact");
    const mc = new Hammer.Manager(rangeInteract);
    mc.domEvents = true
    mc.add(new Hammer.Pan({direction: Hammer.DIRECTION_ALL, threshold: 0}));
    mc.add(new Hammer.Tap());
    mc.on('panmove', (e) => {
      var old = this.pinchTimmer
      var now = new Date().getTime(),
        delta = now - old;

      this.clearIntervalInstAni();

      //avoid pinch end -> pan
      if (delta > 100) {
        this.pinchTimmer = 0
        this.rangeHandleOnPan(e)
      }
    });
    mc.on('tap', (e) => {
      this.clearIntervalInstAni();
      this.rangeHandleOnPan(e)
    });
    window.addEventListener('resize', () => {
      if (this.isMobile && this.windowWidth == window.innerWidth) {
        return false
      }
      this.getRangeWidth();
      this.resizeScene();
      this.windowWidth = window.innerWidth;
      if(this.showBook) {
        this.resizeBookSize();
      }
    });
  },
}
</script>

