<template>
  <div id="mond" class="work-page" :class="{ 'detail-loaded' : isDetailVisible, 'work-page-loaded': isPageLoaded, 'work-library-modal': showModal }" @click="closeWorkShare">

    <div class="work__bg-wrap">
      <div class="work__bg">
        <img src="/assets/img/layout/bg-overview.jpg" class="work__bg-img"  alt="work background image">
      </div>
    </div>
    <div class="detail__bg" style="background-image: url('/assets/img/lunar-phases/bg-info.jpg')"></div>
    <div class="container" >
      <div class="page__top">
        <h1 class="page-top__title">{{ $t('Mondphasen') }}</h1>
        <p class="page-top__text">Selenographia <br />(Hevelius, 1647)</p>
      </div>

      <main class="main main--mond">
        <div class="work-content work-intro-ani-in">
          <div class="work__scaleable " :style="'height:'+ rangeWidthLive +'px; width:'+ rangeWidthLive +'px; margin-top:'+ workPosLive +'px'">
            <div class="mond__slider-wrap scale-item">
              <transition mode="out-in" tag="div"  name="mond__slide-img-wrap">
                <div class="mond__slider" :style="'height:'+ slideWidthLive +'px; width:'+ slideWidthLive +'px'">
                  <template v-for="(slide, index) in lunarslides" >
                    <div v-show="index == activeSlide" :key="index" class="mond__slide-img-wrap">
                      <svg preserveAspectRatio="xMaxYMin meet" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 2168 2168" class="mond__slide-img">
                        <defs>
                          <clipPath :id="'clip_'+index" v-html="slide.svgpath"></clipPath>
                        </defs>
                        <image :clip-path="'url(#clip_'+index+')'" :xlink:href="'/assets/img/lunar-phases/'+slide.img" width="2168" height="2168" />
                      </svg>
                      <svg preserveAspectRatio="xMaxYMin meet" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 2168 2168" class="mond__slide-img img--blur">
                        <defs>
                          <clipPath :id="'clipbg_'+index" v-html="slide.svgpath"></clipPath>
                        </defs>
                        <image :clip-path="'url(#clipbg_'+index+')'" :xlink:href="'/assets/img/lunar-phases/'+slide.img" width="2168" height="2168" />
                      </svg>
                    </div>
                    <div v-if="index == activeSlide" :key="'legend_'+index" class="mond__legend work__legend"><span class="mond__legend-span" :style="'bottom:'+ legendBottom +'px'">{{ slide.legend }}</span> </div>
                  </template>
                  <svg preserveAspectRatio="xMaxYMin meet" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 2168 2168" class="mond__shadow">
                    <defs>
                      <circle id="mond-full-path" cx="966" cy="966" r="966"></circle>
                      <filter x="-7.9%" y="-7.9%" width="115.8%" height="115.8%" filterUnits="objectBoundingBox" id="mond-full-filter">
                        <feOffset dx="0" dy="0" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
                        <feGaussianBlur stdDeviation="25" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
                        <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1"></feComposite>
                        <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.901934003 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
                      </filter>
                    </defs>
                    <g id="Group" transform="translate(118.000000, 118.000000)">
                      <g id="Oval">
                        <use fill="black" fill-opacity="1" filter="url(#mond-full-filter)" xlink:href="#mond-full-path"></use>
                        <use fill-opacity="0.9" fill="#000000" fill-rule="evenodd" xlink:href="#mond-full-path"></use>
                      </g>
                    </g>
                  </svg>

                </div>
              </transition>

            </div>
            <div class="range-slider scale-item" :style="'height:'+ rangeWidthLive +'px; width:'+ rangeWidthLive +'px'">
              <div class="range-slider__handle-track" :style="'transform: rotate(' + currentDeg + 'deg)'" ref="handle-track">
                <div class="range-slider__handle">
                  <span class="sicon sicon__slide-handle" aria-hidden="true"></span>
                  <span class="visuallyhidden">Slide Handle</span>
                </div>
              </div>
              <div class="range-slider__track">
                <svg width="376px" height="376px" viewBox="0 0 376 376" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <defs>
                    <rect id="path-1" x="-3.81469727e-06" y="-3.81469727e-06" width="271" height="92"></rect>
                  </defs>
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" opacity="0.300000012">
                    <g transform="translate(52.000000, 289.000000)">
                      <mask id="mask-2" fill="white">
                        <use xlink:href="#path-1"></use>
                      </mask>
                      <g id="Mask"></g>
                      <circle stroke="#FFFFFF" mask="url(#mask-2)" cx="136" cy="-102" r="188"></circle>
                    </g>
                  </g>
                </svg>
              </div>
            </div>
            <div id="range-interact"></div>
          </div>
        </div>
        <detail v-on:show-modal="showModalMsg"></detail>
      </main>
    </div>
    <menuprimary current-nav="lunar-phases" :needNavprojects=true  v-on:toggle-detail="toggleDetail"></menuprimary>
    <div class="top-tool-nav-wrap">
      <div @click="toggleDetail" v-if="isDetailVisible" class="btn-close-circle" aria-hidden="true">
        <span class="visuallyhidden">close detail content</span>
      </div>
      <share-section v-if="isDetailVisible"></share-section>
      <router-link to="overview" class="btn-close-circle" aria-hidden="true" v-else>
        <span class="visuallyhidden">go to overview page</span>
      </router-link>
      <router-link :to="'zoom-lunar-phases/'+this.activeSlide" class="zoom-btn">
        <span class="sicon sicon__zoom-more" aria-hidden="true"></span>
        <span class="visuallyhidden">Link zu zoomed img</span>
      </router-link>
    </div>
    <modal v-if="showModal" url="https://www.e-rara.ch/zut/doi/10.3931/e-rara-238" @close="showModal=false"></modal>
  </div>
</template>

<script>
  import lunas  from '../../data/luna';
  import iLunarPhases from '../info/i-lunar-phases';
  import Modal from '../../components/modal';
  import MenuPrimary from '../../components/menu-primary';
  import Slides from '../../data/planet-slides';
  import ShareSection from '../../components/share';

  import anime from 'animejs';
  import Hammer from '../../assets/js/hammer.min';

  export default {
    name: 'lunar-phases',
    components: {
      'detail': iLunarPhases,
      'modal': Modal,
      'menuprimary': MenuPrimary,
      'share-section': ShareSection
    },
    data() {
      return {
        activeSlide: 0,
        bg: false,
        lunarslides:lunas.data()['slides'],
        degRange : [45,135],
        currentDeg : '',
        showModal: false,
        isDetailVisible: false,
        windowWidth: window.innerWidth,
        rangeWidthLive:'',
        slideWidthLive:'',
        slides: Slides.data()['Slides'],
        legendBottom:'',
        workPosLive:'',
        zoomEl:'',
        mc:'',
        pinchTimmer: 0,
        isPageLoaded: false,
        instAniInterval: null,
        instAniLoadTimer: null,
        pageLoadTimer: null,
        pageTop:'',
      }
    },
    created() {
      this.currentDeg = this.degRange[0]
      this.viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0)
      this.isMobile = this.mobileAndTabletcheck()
    },
    methods: {
      instractionAni: function () {
        var step = 0;
        var _this = this;
        this.instAniInterval = setInterval(frame, 75);

        function frame() {
          if (step == 20 || _this.isDetailVisible) {
            _this.clearIntervalInstAni()
          } else {
            if((step >= 5 && step < 10)|| (step >= 15 && step < 20)) {
              _this.stepDown()
            } else {
              _this.stepUp()
            }
          }
          step++;
        }
      },
      clearIntervalInstAni : function() {
        if(this.instAniInterval) {
          clearInterval(this.instAniInterval);
        }
        if(this.instAniLoadTimer) {
          clearTimeout(this.instAniLoadTimer);
        }
      },
      stepUp: function () {
        this.activeSlide++
        if(this.activeSlide > this.lunarslides.length -1) {
          this.activeSlide = this.lunarslides.length -1
        }
        this.getHandlePos()
      },
      stepDown: function () {
        this.activeSlide--
        if(this.activeSlide < 0) {
          this.activeSlide = 0
        }
        this.getHandlePos()
      },
      getHandlePos: function () {
        var slidesLength = this.lunarslides.length -1
        var totalSlideDeg = this.degRange[1] - this.degRange[0]
        var stepDeg = totalSlideDeg / slidesLength
        this.currentDeg = this.activeSlide * stepDeg + this.degRange[0]
      },
      rangeHandleOnPan: function (e) {
       // eslint-disable-next-line no-undef
       gtag('event', 'rangeSlider', {
          'event_category' : 'lunar-phases',
          'event_label' : 'lunar-phases',
        });
        var slidesLength = this.lunarslides.length -1
        var totalSlideDeg = this.degRange[1] - this.degRange[0]
        var stepDeg = totalSlideDeg / slidesLength
        var rangeInteract = this.$el.querySelector('#range-interact').getBoundingClientRect()
        var radius = (rangeInteract.height) / 2
        var _y = e.center.y + (rangeInteract.top * -1)
        var _x = e.center.x + (rangeInteract.left * -1)
        var atan = Math.atan2(_y - radius, _x - radius)
        var deg = (180 * Math.abs(atan)) / Math.PI

        if( deg >= this.degRange[1] ) {
          deg = this.degRange[1]
        }
        if( deg <= this.degRange[0] ) {
          deg = this.degRange[0]
        }

        this.currentDeg = deg
        var _curSlideIndex = Math.round((deg - this.degRange[0]) / stepDeg)
        if(this.activeSlide != _curSlideIndex) {
          this.activeSlide = _curSlideIndex
        } else {
          return false
        }
      },
      toggleDetail:function () {
        if(!this.isDetailVisible) {
          // eslint-disable-next-line no-undef
          gtag('config', this.uaId, {'page_path': this.$route.path + '-i-detail'});
        } else {
          // eslint-disable-next-line no-undef
          gtag('config', this.uaId, {'page_path': this.$route.path });

          var audio = this.$el.querySelectorAll('audio')[0];
          if(!audio.paused){
            audio.pause();
          }
        }


        this.isDetailVisible =  !this.isDetailVisible
        var _scale = 1;
        var workHTo =  window.innerHeight - this.$el.querySelector('.nav-bottom').clientHeight
        var workMarginTop = 0;

        if(this.isDetailVisible) {
          document.body.classList.add('detail-opened');
          _scale = 0.7;
          this.resetWorkZoom();
          workHTo = this.rangeWidthLive * 0.7;
          if(window.innerWidth > window.innerHeight) {
            workMarginTop = '10%'
          } else {
            workMarginTop = this.pageTop.clientHeight *  0.9;
          }
        } else {
          document.body.classList.remove('detail-opened');
        }

        anime({
          targets: '.scale-item',
          scale: _scale,
          duration: 500,
          easing:'easeOutQuart',
          complete: function() {
            window.onresize();
          }
        });
        anime({
          targets: '.work-content',
          height: workHTo,
          marginTop: workMarginTop,
          duration: 500,
          easing:'easeOutQuart',
        });

      },
      resizeWorkDetail: function() {
        var work = this.$el.querySelector('.work-content');
        if(this.isDetailVisible) {
          work.style.height =  this.rangeWidthLive * 0.7 + 'px';
          if(window.innerWidth > window.innerHeight) {
            work.style.marginTop = '10%'
          } else {
            work.style.marginTop = this.pageTop.clientHeight *  0.9 + 'px';
          }
        }
      },
      showModalMsg : function() {
        this.showModal = true
      },
      getRangeWidth: function() {
        //const legend = this.$el.querySelector(".mond__legend-span");
        //console.log('legend',legend)
        //  let legendH = legend.clientHeight;
        let legendH = 30;
        var winW = window.innerWidth;
        var winH = window.innerHeight;
        var workH = winH - this.$el.querySelector('.nav-bottom').clientHeight;
        if(!this.isDetailVisible) {
          this.$el.querySelector('.work-content').style.height = workH + 'px';
        }

        var availH = workH - this.pageTop.clientHeight - legendH + this.$el.querySelector('.page-top__text').clientHeight;
        //var availH = winH - document.querySelector('.nav-bottom').clientHeight - legendH * 2;

        if(window.innerWidth < 1025){
          availH = workH - this.pageTop.clientHeight - legendH;
        }

        var _bottomNav = parseInt(window.getComputedStyle(this.$el.querySelector('.nav-bottom')).getPropertyValue('bottom'))
        if (_bottomNav > 0) {
          availH = availH - _bottomNav
        }

        if(availH < 200) {
          availH = 200
        }

        var maxAailH = Math.min(winW, availH)
        this.rangeWidthLive = maxAailH
        this.slideWidthLive = this.rangeWidthLive * 0.85
        if(window.innerWidth < 1025){
          this.legendBottom = (this.rangeWidthLive * 0.15 + legendH ) * -1 ;
        } else {
          this.legendBottom = (this.rangeWidthLive * 0.115 + legendH ) * -1 ;
        }

        if(winW > availH) {
          //this.workPosLive = this.rangeWidthLive * 0.15 * -1;
        }
        // this.workPosLive = this.rangeWidthLive * 0.15 * -1;
      },
      resetWorkZoom : function() {
        var transform = "";
        this.zoomEl.style.transform = transform;
        this.zoomEl.style.webkitTransform = transform;
      },
      pageLoad : function() {
        this.isPageLoaded = true;
        var _this = this
        this.pageLoadTimer = setTimeout(function () {
          _this.$el.querySelector(".work-intro-ani-in").classList.remove('work-intro-ani-in')
        }, 1600)
      },
    },
    beforeRouteLeave (to, from, next) {
      this.clearIntervalInstAni()
      if(this.pageLoadTimer) {
        clearTimeout(this.pageLoadTimer);
      }
      document.body.classList.remove('detail-opened');
      localStorage.setItem('lunaIndex', '');
      this.aniMenuPageLeave(next());
    },
    mounted() {
      document.body.style.zoom = 1.0;
      this.pageTop = this.$el.querySelector('.page__top');
      this.windowWidth = window.innerWidth
      this.saveCurrentPlanetToLocalStorage();
      this.zoomEl = this.$el.querySelector(".work__scaleable");
      const rangeInteract = this.$el.querySelector("#range-interact");
      const mc = new Hammer.Manager(rangeInteract);
      //rangeHm.domEvents = true
      mc.add( new Hammer.Pan({ direction: Hammer.DIRECTION_ALL, threshold:10}) );
      mc.add( new Hammer.Tap() );
      mc.add( new Hammer.Pinch({ threshold: 0 }) );
      mc.on('pan', (e) => {
        var old = this.pinchTimmer
        var now = new Date().getTime(),
          delta = now - old;

        this.clearIntervalInstAni()

        //avoid pinch end -> pan
        if(delta > 100) {
          this.pinchTimmer = 0
          this.rangeHandleOnPan(e)
        }
      });

      //slide by tap
      mc.on('tap', (e) => {
        this.clearIntervalInstAni()

        var half = rangeInteract.offsetWidth / 2
        var posX = e.srcEvent.layerX
        if(posX < half) {
          this.stepUp()
        } else {
          this.stepDown()
        }
      });

      this.mc = mc;
      this.getRangeWidth();
      this.pageLoad();
      this.setWorkBgPos();

      if(localStorage.getItem('lunaIndex')) {
        this.activeSlide = localStorage.getItem('lunaIndex')
        this.getHandlePos()
      } else {
        this.instAniLoadTimer = setTimeout(this.instractionAni,2000)
      }

      window.onresize = () => {
        //avoild resize on scroll -> addressbar collapse
        if(this.isMobile && this.windowWidth == window.innerWidth) {
          return false
        }
        this.getRangeWidth()
        this.resizeWorkDetail()
        this.windowWidth = window.innerWidth
      }
    }
  }
</script>

