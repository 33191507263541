<template>
  <div class="zoom-saturnium native-pinch zoom-page">
    <div class="top-tool-nav-wrap">
      <a @click="$router.go(-1)" class="btn-close-circle" aria-hidden="true"><span class="visuallyhidden">close Zoom View</span></a>
    </div>
    <div class="zoomed-img-wrap">
      <div class="mask-layer-wrap">
        <img :src="'/assets/img/saturnium/' + this.thisSlides[this.activeId].zoomImage" />
      </div>
      <a :href="this.thisSlides[this.activeId].link" target="_blank" class="btn--open">
        {{ $t('View Page on e-rara.ch') }}
        <span aria-hidden="true" class="sicon sicon__external-link"></span>
      </a>
    </div>
  </div>
</template>

<script>
  import saturniumData from "../../data/saturnium";

  export default {
  name: 'zoomSaturniumComponent',
    data() {
      return {
        thisSlides: saturniumData.data()['slides'],
        imageHeightLive: 'auto'
      }
    },
    created() {
      this.activeId = this.$route.params.id;
      localStorage.setItem('saturniumIndex', this.activeId);
    },
    beforeRouteLeave (to, from, next) {
      document.body.classList.remove('zoomed-opened');
      next();
    },
    methods:{
      setHeight: function (){
        var winH = window.innerHeight;
        var btnHeight = document.querySelector('.btn--open').clientHeight + 40;
        document.body.style.height = winH + 'px';
        document.querySelector('.zoom-saturnium').style.height = winH + 'px';
        document.querySelector('.mask-layer-wrap').style.height = winH - (btnHeight * 2) + 'px';
        document.querySelector('.mask-layer-wrap').style.paddingRight = btnHeight + 'px';
        document.querySelector('.mask-layer-wrap').style.paddingLeft = btnHeight + 'px';
        var _this = this;

        if(window.innerWidth < 1024){
          document.querySelector('.mask-layer-wrap').style.paddingRight = '20px';
          document.querySelector('.mask-layer-wrap').style.paddingLeft = '20px';
        }

        if(window.innerWidth >= 768){
          var newHeight = winH - this.$el.querySelector('.btn-close-circle').clientHeight - 60;
          _this.imageHeightLive = newHeight + 'px';
          document.body.classList.remove('zoomed-overflow');
        } else {
          _this.imageHeightLive = 'auto';
          setTimeout(function (){
            if(document.querySelector('.zoomed-img-wrap').clientHeight < window.innerHeight){
              document.body.classList.add('zoomed-overflow');
            }
          },5);
        }
      }
    },
    mounted() {
      var _this = this;
      document.body.classList.add('zoomed-opened');
      this.setHeight();

      window.onresize = () => {
        setTimeout(function (){
          _this.setHeight();
        },20)
      }
    }
  }
</script>
