<script>
  export default {
    name: 'jmoons',
    data: function (){
      return {
        slides : [
          {
            legend: "01. Februar 1610, 19h15",
            img: "01_2h.jpg",
            imgx: '-22',
            imgy: '-9',
            pos: "0.03",
            svgpath : `M330.98,0 C333.200185,-4.07841312e-16 335,1.79981531 335,4.02 L335,37.855 C335,40.0751847 333.200185,41.875 330.98,41.875 L261.732,41.875 L259.64458,44.1973793 C258.654867,45.2979398 256.960366,45.3877997 255.859806,44.3980871 C255.789405,44.3347768 255.722408,44.2677803 255.659098,44.1973793 L253.571,41.875 L176.630004,41.8763781 C176.601022,41.9121254 176.570985,41.947307 176.539892,41.9818818 L169.919105,49.3441975 C168.929392,50.444758 167.234891,50.5346179 166.13433,49.5449053 C166.063929,49.481595 165.996933,49.4145985 165.933623,49.3441975 L159.312835,41.9818818 L159.223,41.875 L159.097,41.875 L157.009773,44.1973793 C156.02006,45.2979398 154.325559,45.3877997 153.224999,44.3980871 C153.154598,44.3347768 153.087601,44.2677803 153.024291,44.1973793 L150.936,41.875 L101.022,41.875 L98.9340545,44.1973793 C97.9443418,45.2979398 96.2498406,45.3877997 95.1492802,44.3980871 C95.0788792,44.3347768 95.0118827,44.2677803 94.9485724,44.1973793 L92.86,41.875 L4.02,41.875 C1.79981531,41.875 1.48738755e-14,40.0751847 0,37.855 L0,4.02 C-2.71894208e-16,1.79981531 1.79981531,4.07841312e-16 4.02,0 L330.98,0 Z`
          },
          {
            legend: "02. Februar 1610, 18h15",
            img: "02_1h.jpg",
            imgx: '-22',
            imgy: '-10',
            pos: "0.0628571429",
            svgpath : `M330.98,0 C333.200185,0 335,1.79981531 335,4.02 L335,37.855 C335,40.0751847 333.200185,41.875 330.98,41.875 L299.756,41.875 L297.668331,44.1973793 C296.678618,45.2979398 294.984117,45.3877997 293.883556,44.3980871 C293.813155,44.3347768 293.746159,44.2677803 293.682848,44.1973793 L291.594,41.875 L223.732,41.875 L221.64458,44.1973793 C220.654867,45.2979398 218.960366,45.3877997 217.859806,44.3980871 C217.789405,44.3347768 217.722408,44.2677803 217.659098,44.1973793 L215.571,41.875 L176.630004,41.8763781 L176.539892,41.9818818 L169.919105,49.3441975 C168.929392,50.444758 167.234891,50.5346179 166.13433,49.5449053 C166.063929,49.481595 165.996933,49.4145985 165.933623,49.3441975 L159.312835,41.9818818 L159.223,41.875 L103.022,41.875 L100.934054,44.1973793 C99.9443418,45.2979398 98.2498406,45.3877997 97.1492802,44.3980871 C97.0788792,44.3347768 97.0118827,44.2677803 96.9485724,44.1973793 L94.86,41.875 L4.02,41.875 C1.79981531,41.875 0,40.0751847 0,37.855 L0,4.02 C0,1.79981531 1.79981531,0 4.02,0 L330.98,0 Z
             `
          },
          {
            legend: "03. Februar 1610, 01h15",
            img: "02_7h.jpg",
            imgx: '-22.34',
            imgy: '-9',
            pos: "0.0732142857",
            svgpath : `M330.98,0 C333.200185,0 335,1.79981531 335,4.02 L335,37.855 C335,40.0751847 333.200185,41.875 330.98,41.875 L318.756,41.875 L316.668331,44.1973793 C315.678618,45.2979398 313.984117,45.3877997 312.883556,44.3980871 C312.813155,44.3347768 312.746159,44.2677803 312.682848,44.1973793 L310.594,41.875 L241.732,41.875 L239.64458,44.1973793 C238.654867,45.2979398 236.960366,45.3877997 235.859806,44.3980871 C235.789405,44.3347768 235.722408,44.2677803 235.659098,44.1973793 L233.571,41.875 L176.630004,41.8763781 L176.539892,41.9818818 L176.539892,41.9818818 L169.919105,49.3441975 C168.929392,50.444758 167.234891,50.5346179 166.13433,49.5449053 C166.063929,49.481595 165.996933,49.4145985 165.933623,49.3441975 L159.312835,41.9818818 L159.223,41.875 L145.097,41.875 L143.009773,44.1973793 C142.02006,45.2979398 140.325559,45.3877997 139.224999,44.3980871 C139.154598,44.3347768 139.087601,44.2677803 139.024291,44.1973793 L136.936,41.875 L103.022,41.875 L100.934054,44.1973793 C99.9443418,45.2979398 98.2498406,45.3877997 97.1492802,44.3980871 C97.0788792,44.3347768 97.0118827,44.2677803 96.9485724,44.1973793 L94.86,41.875 L4.02,41.875 C1.79981531,41.875 0,40.0751847 0,37.855 L0,4.02 C0,1.79981531 1.79981531,0 4.02,0 L330.98,0 Z
            `
          },
          {
            legend: "04. Februar 1610, 01h15",
            img: "03_7h.jpg",
            imgx: '-22',
            imgy: '-9',
            pos: "0.108928571428571",
            svgpath : `M330.98,0 C333.200185,0 335,1.79981531 335,4.02 L335,37.855 C335,40.0751847 333.200185,41.875 330.98,41.875 L302.756,41.875 L300.668331,44.1973793 C299.678618,45.2979398 297.984117,45.3877997 296.883556,44.3980871 C296.813155,44.3347768 296.746159,44.2677803 296.682848,44.1973793 L294.594,41.875 L202.732,41.875 L200.64458,44.1973793 C199.654867,45.2979398 197.960366,45.3877997 196.859806,44.3980871 C196.789405,44.3347768 196.722408,44.2677803 196.659098,44.1973793 L194.571,41.875 L176.630004,41.8763781 L176.539892,41.9818818 L176.539892,41.9818818 L169.919105,49.3441975 C168.929392,50.444758 167.234891,50.5346179 166.13433,49.5449053 C166.063929,49.481595 165.996933,49.4145985 165.933623,49.3441975 L159.312835,41.9818818 L159.223,41.875 L150.097,41.875 L148.009773,44.1973793 C147.02006,45.2979398 145.325559,45.3877997 144.224999,44.3980871 C144.154598,44.3347768 144.087601,44.2677803 144.024291,44.1973793 L141.936,41.875 L4.02,41.875 C1.79981531,41.875 0,40.0751847 0,37.855 L0,4.02 C0,1.79981531 1.79981531,0 4.02,0 L330.98,0 Z
            `
          },
          {
            legend: "05. Februar 1610, 19h15",
            img: "04_2h.jpg",
            imgx: '-22',
            imgy: '-9',
            pos: "0.135714285714285",
            svgpath : `M330.98,0 C333.200185,0 335,1.79981531 335,4.02 L335,37.855 C335,40.0751847 333.200185,41.875 330.98,41.875 L269.756,41.875 L267.668331,44.1973793 C266.678618,45.2979398 264.984117,45.3877997 263.883556,44.3980871 C263.813155,44.3347768 263.746159,44.2677803 263.682848,44.1973793 L261.594,41.875 L217.732,41.875 L215.64458,44.1973793 C214.654867,45.2979398 212.960366,45.3877997 211.859806,44.3980871 C211.789405,44.3347768 211.722408,44.2677803 211.659098,44.1973793 L209.571,41.875 L176.630004,41.8763781 L176.539892,41.9818818 L176.539892,41.9818818 L169.919105,49.3441975 C168.929392,50.444758 167.234891,50.5346179 166.13433,49.5449053 C166.063929,49.481595 165.996933,49.4145985 165.933623,49.3441975 L159.312835,41.9818818 L159.223,41.875 L154.097,41.875 L152.009773,44.1973793 C151.02006,45.2979398 149.325559,45.3877997 148.224999,44.3980871 C148.154598,44.3347768 148.087601,44.2677803 148.024291,44.1973793 L145.936,41.875 L125.022,41.875 L122.934054,44.1973793 C121.944342,45.2979398 120.249841,45.3877997 119.14928,44.3980871 C119.078879,44.3347768 119.011883,44.2677803 118.948572,44.1973793 L116.86,41.875 L4.02,41.875 C1.79981531,41.875 0,40.0751847 0,37.855 L0,4.02 C0,1.79981531 1.79981531,0 4.02,0 L330.98,0 Z
            `
          },
          {
            legend: "06. Februar 1610, 01h15",
            img: "04_7h.jpg",
            imgx: '-22',
            imgy: '-9',
            pos: "0.144642857142857",
            svgpath : `M330.98,0 C333.200185,0 335,1.79981531 335,4.02 L335,37.855 C335,40.0751847 333.200185,41.875 330.98,41.875 L244.732,41.875 L242.64458,44.1973793 C241.654867,45.2979398 239.960366,45.3877997 238.859806,44.3980871 C238.789405,44.3347768 238.722408,44.2677803 238.659098,44.1973793 L236.571,41.875 L219.756,41.875 L217.668331,44.1973793 C216.678618,45.2979398 214.984117,45.3877997 213.883556,44.3980871 C213.813155,44.3347768 213.746159,44.2677803 213.682848,44.1973793 L211.594,41.875 L176.630004,41.8763781 L176.539892,41.9818818 L176.539892,41.9818818 L169.919105,49.3441975 C168.929392,50.444758 167.234891,50.5346179 166.13433,49.5449053 C166.063929,49.481595 165.996933,49.4145985 165.933623,49.3441975 L159.312835,41.9818818 L159.223,41.875 L143.097,41.875 L141.009773,44.1973793 C140.02006,45.2979398 138.325559,45.3877997 137.224999,44.3980871 C137.154598,44.3347768 137.087601,44.2677803 137.024291,44.1973793 L134.936,41.875 L133.022,41.875 L130.934054,44.1973793 C129.944342,45.2979398 128.249841,45.3877997 127.14928,44.3980871 C127.078879,44.3347768 127.011883,44.2677803 126.948572,44.1973793 L124.86,41.875 L4.02,41.875 C1.79981531,41.875 0,40.0751847 0,37.855 L0,4.02 C0,1.79981531 1.79981531,0 4.02,0 L330.98,0 Z
            `
          },
          {
            legend: "06. Februar 1610, 18h20",
            img: "06_1h.jpg",
            imgx: '-22',
            imgy: '-9',
            pos: "0.205714285714285",
            svgpath : `M330.98,0 C333.200185,0 335,1.79981531 335,4.02 L335,37.855 C335,40.0751847 333.200185,41.875 330.98,41.875 L212.732,41.875 L210.64458,44.1973793 C209.654867,45.2979398 207.960366,45.3877997 206.859806,44.3980871 C206.789405,44.3347768 206.722408,44.2677803 206.659098,44.1973793 L204.571,41.875 L176.630004,41.8763781 L176.539892,41.9818818 L176.539892,41.9818818 L169.919105,49.3441975 C168.929392,50.444758 167.234891,50.5346179 166.13433,49.5449053 C166.063929,49.481595 165.996933,49.4145985 165.933623,49.3441975 L159.312835,41.9818818 L159.223,41.875 L139.097,41.875 L137.009773,44.1973793 C136.02006,45.2979398 134.325559,45.3877997 133.224999,44.3980871 C133.154598,44.3347768 133.087601,44.2677803 133.024291,44.1973793 L130.936,41.875 L4.02,41.875 C1.79981531,41.875 0,40.0751847 0,37.855 L0,4.02 C0,1.79981531 1.79981531,0 4.02,0 L330.98,0 Z
            `
          },
          {
            legend: "07. Februar 1610, 18h20",
            img: "07_1h.jpg",
            imgx: '-22',
            imgy: '-9',
            pos: "0.241428571428571",
            svgpath : `M330.98,0 C333.200185,0 335,1.79981531 335,4.02 L335,37.855 C335,40.0751847 333.200185,41.875 330.98,41.875 L176.630004,41.8763781 L176.539892,41.9818818 L176.539892,41.9818818 L169.919105,49.3441975 C168.929392,50.444758 167.234891,50.5346179 166.13433,49.5449053 C166.063929,49.481595 165.996933,49.4145985 165.933623,49.3441975 L159.312835,41.9818818 L159.223,41.875 L154.097,41.875 L152.009773,44.1973793 C151.02006,45.2979398 149.325559,45.3877997 148.224999,44.3980871 C148.154598,44.3347768 148.087601,44.2677803 148.024291,44.1973793 L145.936,41.875 L141.022,41.875 L138.934054,44.1973793 C137.944342,45.2979398 136.249841,45.3877997 135.14928,44.3980871 C135.078879,44.3347768 135.011883,44.2677803 134.948572,44.1973793 L132.86,41.875 L4.02,41.875 C1.79981531,41.875 0,40.0751847 0,37.855 L0,4.02 C0,1.79981531 1.79981531,0 4.02,0 L330.98,0 Z
            `
          },
          {
            legend: "08. Februar 1610, 18h20",
            img: "08_1h.jpg",
            imgx: '-22',
            imgy: '-9',
            pos: "0.277142857142857",
            svgpath : `M330.98,0 C333.200185,0 335,1.79981531 335,4.02 L335,37.855 C335,40.0751847 333.200185,41.875 330.98,41.875 L176.630004,41.8763781 L176.539892,41.9818818 L176.539892,41.9818818 L169.919105,49.3441975 C168.929392,50.444758 167.234891,50.5346179 166.13433,49.5449053 C166.063929,49.481595 165.996933,49.4145985 165.933623,49.3441975 L159.312835,41.9818818 L159.223,41.875 L147.097,41.875 L145.009773,44.1973793 C144.02006,45.2979398 142.325559,45.3877997 141.224999,44.3980871 C141.154598,44.3347768 141.087601,44.2677803 141.024291,44.1973793 L138.936,41.875 L108.756,41.875 L106.668331,44.1973793 C105.678618,45.2979398 103.984117,45.3877997 102.883556,44.3980871 C102.813155,44.3347768 102.746159,44.2677803 102.682848,44.1973793 L100.594,41.875 L100.022,41.875 L97.9340545,44.1973793 C96.9443418,45.2979398 95.2498406,45.3877997 94.1492802,44.3980871 C94.0788792,44.3347768 94.0118827,44.2677803 93.9485724,44.1973793 L91.86,41.875 L4.02,41.875 C1.79981531,41.875 0,40.0751847 0,37.855 L0,4.02 C0,1.79981531 1.79981531,0 4.02,0 L330.98,0 Z
            `
          },
          {
            legend: "09. Februar 1610, 17h50",
            img: "09_0.5h.jpg",
            imgx: '-22',
            imgy: '-9',
            pos: "0.312142857142857",
            svgpath : `M330.98,0 C333.200185,0 335,1.79981531 335,4.02 L335,37.855 C335,40.0751847 333.200185,41.875 330.98,41.875 L218.732,41.875 L216.64458,44.1973793 C215.654867,45.2979398 213.960366,45.3877997 212.859806,44.3980871 C212.789405,44.3347768 212.722408,44.2677803 212.659098,44.1973793 L210.571,41.875 L176.630004,41.8763781 L176.539892,41.9818818 L176.539892,41.9818818 L169.919105,49.3441975 C168.929392,50.444758 167.234891,50.5346179 166.13433,49.5449053 C166.063929,49.481595 165.996933,49.4145985 165.933623,49.3441975 L159.312835,41.9818818 L159.223,41.875 L95.022,41.875 L92.9340545,44.1973793 C91.9443418,45.2979398 90.2498406,45.3877997 89.1492802,44.3980871 C89.0788792,44.3347768 89.0118827,44.2677803 88.9485724,44.1973793 L86.86,41.875 L56.097,41.875 L54.0097731,44.1973793 C53.0200604,45.2979398 51.3255592,45.3877997 50.2249988,44.3980871 C50.1545978,44.3347768 50.0876013,44.2677803 50.024291,44.1973793 L47.936,41.875 L4.02,41.875 C1.79981531,41.875 0,40.0751847 0,37.855 L0,4.02 C0,1.79981531 1.79981531,0 4.02,0 L330.98,0 Z
            `
          },
          {
            legend: "10. Februar 1610, 18h50",
            img: "10_1.5h.jpg",
            imgx: '-22',
            imgy: '-9',
            pos: "0.349285714285714",
            svgpath : `M330.98,0 C333.200185,0 335,1.79981531 335,4.02 L335,37.855 C335,40.0751847 333.200185,41.875 330.98,41.875 L176.630004,41.8763781 L176.539892,41.9818818 L176.539892,41.9818818 L169.919105,49.3441975 C168.929392,50.444758 167.234891,50.5346179 166.13433,49.5449053 C166.063929,49.481595 165.996933,49.4145985 165.933623,49.3441975 L159.312835,41.9818818 L159.223,41.875 L157.097,41.875 L155.009773,44.1973793 C154.02006,45.2979398 152.325559,45.3877997 151.224999,44.3980871 C151.154598,44.3347768 151.087601,44.2677803 151.024291,44.1973793 L148.936,41.875 L66.022,41.875 L63.9340545,44.1973793 C62.9443418,45.2979398 61.2498406,45.3877997 60.1492802,44.3980871 C60.0788792,44.3347768 60.0118827,44.2677803 59.9485724,44.1973793 L57.86,41.875 L4.02,41.875 C1.79981531,41.875 0,40.0751847 0,37.855 L0,4.02 C0,1.79981531 1.79981531,0 4.02,0 L330.98,0 Z
            `
          },

          {
            legend: "11. Februar 1610, 18h20",
            img: "11_1h.jpg",
            imgx: '-22',
            imgy: '-9',
            pos: "0.384285714285714",
            svgpath : `M330.98,0 C333.200185,0 335,1.79981531 335,4.02 L335,37.855 C335,40.0751847 333.200185,41.875 330.98,41.875 L223.732,41.875 L221.64458,44.1973793 C220.654867,45.2979398 218.960366,45.3877997 217.859806,44.3980871 C217.789405,44.3347768 217.722408,44.2677803 217.659098,44.1973793 L215.571,41.875 L176.630004,41.8763781 L176.539892,41.9818818 L176.539892,41.9818818 L169.919105,49.3441975 C168.929392,50.444758 167.234891,50.5346179 166.13433,49.5449053 C166.063929,49.481595 165.996933,49.4145985 165.933623,49.3441975 L159.312835,41.9818818 L159.223,41.875 L129.097,41.875 L127.009773,44.1973793 C126.02006,45.2979398 124.325559,45.3877997 123.224999,44.3980871 C123.154598,44.3347768 123.087601,44.2677803 123.024291,44.1973793 L120.936,41.875 L51.022,41.875 L48.9340545,44.1973793 C47.9443418,45.2979398 46.2498406,45.3877997 45.1492802,44.3980871 C45.0788792,44.3347768 45.0118827,44.2677803 44.9485724,44.1973793 L42.86,41.875 L4.02,41.875 C1.79981531,41.875 0,40.0751847 0,37.855 L0,4.02 C0,1.79981531 1.79981531,0 4.02,0 L330.98,0 Z
            `
          },
          {
            legend: "11. Februar 1610, 20h20",
            img: "11_3h.jpg",
            imgx: '-22',
            imgy: '-9',
            pos: "0.387499999999999",
            svgpath : `M330.98,0 C333.200185,0 335,1.79981531 335,4.02 L335,37.855 C335,40.0751847 333.200185,41.875 330.98,41.875 L219.732,41.875 L217.64458,44.1973793 C216.654867,45.2979398 214.960366,45.3877997 213.859806,44.3980871 C213.789405,44.3347768 213.722408,44.2677803 213.659098,44.1973793 L211.571,41.875 L176.630004,41.8763781 L176.539892,41.9818818 L176.539892,41.9818818 L169.919105,49.3441975 C168.929392,50.444758 167.234891,50.5346179 166.13433,49.5449053 C166.063929,49.481595 165.996933,49.4145985 165.933623,49.3441975 L159.312835,41.9818818 L159.223,41.875 L156.097,41.875 L154.009773,44.1973793 C153.02006,45.2979398 151.325559,45.3877997 150.224999,44.3980871 C150.154598,44.3347768 150.087601,44.2677803 150.024291,44.1973793 L147.936,41.875 L123.022,41.875 L120.934054,44.1973793 C119.944342,45.2979398 118.249841,45.3877997 117.14928,44.3980871 C117.078879,44.3347768 117.011883,44.2677803 116.948572,44.1973793 L114.86,41.875 L50.756,41.875 L48.6683305,44.1973793 C47.6786179,45.2979398 45.9841167,45.3877997 44.8835562,44.3980871 C44.8131552,44.3347768 44.7461587,44.2677803 44.6828485,44.1973793 L42.594,41.875 L4.02,41.875 C1.79981531,41.875 0,40.0751847 0,37.855 L0,4.02 C0,1.79981531 1.79981531,0 4.02,0 L330.98,0 Z
            `
          },
          {
            legend: "11. Februar 1610, 22h50",
            img: "11_5.5h.jpg",
            imgx: '-22',
            imgy: '-9',
            pos: "0.391071428571428",
            svgpath : `M330.98,0 C333.200185,0 335,1.79981531 335,4.02 L335,37.855 C335,40.0751847 333.200185,41.875 330.98,41.875 L223.732,41.875 L221.64458,44.1973793 C220.654867,45.2979398 218.960366,45.3877997 217.859806,44.3980871 C217.789405,44.3347768 217.722408,44.2677803 217.659098,44.1973793 L215.571,41.875 L176.630004,41.8763781 L176.539892,41.9818818 L176.539892,41.9818818 L169.919105,49.3441975 C168.929392,50.444758 167.234891,50.5346179 166.13433,49.5449053 C166.063929,49.481595 165.996933,49.4145985 165.933623,49.3441975 L159.312835,41.9818818 L159.223,41.875 L146.097,41.875 L144.009773,44.1973793 C143.02006,45.2979398 141.325559,45.3877997 140.224999,44.3980871 C140.154598,44.3347768 140.087601,44.2677803 140.024291,44.1973793 L137.936,41.875 L127.022,41.875 L124.934054,44.1973793 C123.944342,45.2979398 122.249841,45.3877997 121.14928,44.3980871 C121.078879,44.3347768 121.011883,44.2677803 120.948572,44.1973793 L118.86,41.875 L41.756,41.875 L39.6683305,44.1973793 C38.6786179,45.2979398 36.9841167,45.3877997 35.8835562,44.3980871 C35.8131552,44.3347768 35.7461587,44.2677803 35.6828485,44.1973793 L33.594,41.875 L4.02,41.875 C1.79981531,41.875 0,40.0751847 0,37.855 L0,4.02 C0,1.79981531 1.79981531,0 4.02,0 L330.98,0 Z
            `
          },
          {
            legend: "12. Februar 1610, 17h40",
            img: "12_0.67h.jpg",
            imgx: '-22',
            imgy: '-9',
            pos: "0.419642857142857",
            svgpath : `M330.98,0 C333.200185,0 335,1.79981531 335,4.02 L335,37.855 C335,40.0751847 333.200185,41.875 330.98,41.875 L261.732,41.875 L259.64458,44.1973793 C258.654867,45.2979398 256.960366,45.3877997 255.859806,44.3980871 C255.789405,44.3347768 255.722408,44.2677803 255.659098,44.1973793 L253.571,41.875 L189.756,41.875 L187.668331,44.1973793 C186.678618,45.2979398 184.984117,45.3877997 183.883556,44.3980871 C183.813155,44.3347768 183.746159,44.2677803 183.682848,44.1973793 L181.594,41.875 L176.630004,41.8763781 L176.539892,41.9818818 L176.539892,41.9818818 L169.919105,49.3441975 C168.929392,50.444758 167.234891,50.5346179 166.13433,49.5449053 C166.063929,49.481595 165.996933,49.4145985 165.933623,49.3441975 L159.312835,41.9818818 L159.223,41.875 L159.097,41.875 L157.009773,44.1973793 C156.02006,45.2979398 154.325559,45.3877997 153.224999,44.3980871 C153.154598,44.3347768 153.087601,44.2677803 153.024291,44.1973793 L150.936,41.875 L62.022,41.875 L59.9340545,44.1973793 C58.9443418,45.2979398 57.2498406,45.3877997 56.1492802,44.3980871 C56.0788792,44.3347768 56.0118827,44.2677803 55.9485724,44.1973793 L53.86,41.875 L4.02,41.875 C1.79981531,41.875 0,40.0751847 0,37.855 L0,4.02 C0,1.79981531 1.79981531,0 4.02,0 L330.98,0 Z
            `
          },
          {
            legend: "13. Februar 1610, 18h00",
            img: "13_0.5h.jpg",
            imgx: '-21',
            imgy: '-11',
            pos: "0.454999999999999",
            svgpath : `M330.98,0 C333.200185,0 335,1.79981531 335,4.02 L335,37.855 C335,40.0751847 333.200185,41.875 330.98,41.875 L229.732,41.875 L227.64458,44.1973793 C226.654867,45.2979398 224.960366,45.3877997 223.859806,44.3980871 C223.789405,44.3347768 223.722408,44.2677803 223.659098,44.1973793 L221.571,41.875 L218.756,41.875 L216.668331,44.1973793 C215.678618,45.2979398 213.984117,45.3877997 212.883556,44.3980871 C212.813155,44.3347768 212.746159,44.2677803 212.682848,44.1973793 L210.594,41.875 L176.630004,41.8763781 L176.539892,41.9818818 L176.539892,41.9818818 L169.919105,49.3441975 C168.929392,50.444758 167.234891,50.5346179 166.13433,49.5449053 C166.063929,49.481595 165.996933,49.4145985 165.933623,49.3441975 L159.312835,41.9818818 L159.223,41.875 L136.097,41.875 L134.009773,44.1973793 C133.02006,45.2979398 131.325559,45.3877997 130.224999,44.3980871 C130.154598,44.3347768 130.087601,44.2677803 130.024291,44.1973793 L127.936,41.875 L86.022,41.875 L83.9340545,44.1973793 C82.9443418,45.2979398 81.2498406,45.3877997 80.1492802,44.3980871 C80.0788792,44.3347768 80.0118827,44.2677803 79.9485724,44.1973793 L77.86,41.875 L4.02,41.875 C1.79981531,41.875 0,40.0751847 0,37.855 L0,4.02 C0,1.79981531 1.79981531,0 4.02,0 L330.98,0 Z
            `
          },
          {
            legend: "15. Februar 1610, 18h30",
            img: "15_1h.jpg",
            imgx: '-22',
            imgy: '-9',
            pos: "0.527142857142857",
            svgpath : `M330.98,0 C333.200185,0 335,1.79981531 335,4.02 L335,37.855 C335,40.0751847 333.200185,41.875 330.98,41.875 L176.630004,41.8763781 L176.539892,41.9818818 L176.539892,41.9818818 L169.919105,49.3441975 C168.929392,50.444758 167.234891,50.5346179 166.13433,49.5449053 C166.063929,49.481595 165.996933,49.4145985 165.933623,49.3441975 L159.312835,41.9818818 L159.223,41.875 L156.732,41.875 L154.64458,44.1973793 C153.654867,45.2979398 151.960366,45.3877997 150.859806,44.3980871 C150.789405,44.3347768 150.722408,44.2677803 150.659098,44.1973793 L149.834,43.28 L149.009773,44.1973793 C148.02006,45.2979398 146.325559,45.3877997 145.224999,44.3980871 C145.154598,44.3347768 145.087601,44.2677803 145.024291,44.1973793 L142.936,41.875 L130.022,41.875 L127.934054,44.1973793 C126.944342,45.2979398 125.249841,45.3877997 124.14928,44.3980871 C124.078879,44.3347768 124.011883,44.2677803 123.948572,44.1973793 L121.86,41.875 L4.02,41.875 C1.79981531,41.875 0,40.0751847 0,37.855 L0,4.02 C0,1.79981531 1.79981531,0 4.02,0 L330.98,0 Z
            `
          },
          {
            legend: "15. Februar 1610, 22h30",
            img: "15_5h.jpg",
            imgx: '-22',
            imgy: '-9',
            pos: "0.534642857142857",
            svgpath : `M330.98,0 C333.200185,0 335,1.79981531 335,4.02 L335,37.855 C335,40.0751847 333.200185,41.875 330.98,41.875 L176.630004,41.8763781 L176.539892,41.9818818 L176.539892,41.9818818 L169.919105,49.3441975 C168.929392,50.444758 167.234891,50.5346179 166.13433,49.5449053 C166.063929,49.481595 165.996933,49.4145985 165.933623,49.3441975 L159.312835,41.9818818 L159.223,41.875 L158.097,41.875 L156.009773,44.1973793 C155.02006,45.2979398 153.325559,45.3877997 152.224999,44.3980871 C152.154598,44.3347768 152.087601,44.2677803 152.024291,44.1973793 L149.936,41.875 L123.022,41.875 L120.934054,44.1973793 C119.944342,45.2979398 118.249841,45.3877997 117.14928,44.3980871 C117.078879,44.3347768 117.011883,44.2677803 116.948572,44.1973793 L114.86,41.875 L4.02,41.875 C1.79981531,41.875 0,40.0751847 0,37.855 L0,4.02 C0,1.79981531 1.79981531,0 4.02,0 L330.98,0 Z
            `
          },
          {
            legend: "15. Februar 1610, 23h30",
            img: "15_6h.jpg",
            imgx: '-22',
            imgy: '-9',
            pos: "0.535357142857142",
            svgpath : `M330.98,0 C333.200185,0 335,1.79981531 335,4.02 L335,37.855 C335,40.0751847 333.200185,41.875 330.98,41.875 L197.732,41.875 L195.64458,44.1973793 C194.654867,45.2979398 192.960366,45.3877997 191.859806,44.3980871 C191.789405,44.3347768 191.722408,44.2677803 191.659098,44.1973793 L189.571,41.875 L176.630004,41.8763781 L176.539892,41.9818818 L176.539892,41.9818818 L169.919105,49.3441975 C168.929392,50.444758 167.234891,50.5346179 166.13433,49.5449053 C166.063929,49.481595 165.996933,49.4145985 165.933623,49.3441975 L159.312835,41.9818818 L159.223,41.875 L159.097,41.875 L157.009773,44.1973793 C156.02006,45.2979398 154.325559,45.3877997 153.224999,44.3980871 C153.154598,44.3347768 153.087601,44.2677803 153.024291,44.1973793 L150.936,41.875 L120.022,41.875 L117.934054,44.1973793 C116.944342,45.2979398 115.249841,45.3877997 114.14928,44.3980871 C114.078879,44.3347768 114.011883,44.2677803 113.948572,44.1973793 L111.86,41.875 L4.02,41.875 C1.79981531,41.875 0,40.0751847 0,37.855 L0,4.02 C0,1.79981531 1.79981531,0 4.02,0 L330.98,0 Z
            `
          },
          {
            legend: "16. Februar 1610, 23h30",
            img: "16_6h.jpg",
            imgx: '-22',
            imgy: '-10',
            pos: "0.571071428571428",
            svgpath : `M330.98,0 C333.200185,0 335,1.79981531 335,4.02 L335,37.855 C335,40.0751847 333.200185,41.875 330.98,41.875 L263.756,41.875 L261.668331,44.1973793 C260.678618,45.2979398 258.984117,45.3877997 257.883556,44.3980871 C257.813155,44.3347768 257.746159,44.2677803 257.682848,44.1973793 L255.594,41.875 L235.732,41.875 L233.64458,44.1973793 C232.654867,45.2979398 230.960366,45.3877997 229.859806,44.3980871 C229.789405,44.3347768 229.722408,44.2677803 229.659098,44.1973793 L227.571,41.875 L176.630004,41.8763781 L176.539892,41.9818818 L176.539892,41.9818818 L169.919105,49.3441975 C168.929392,50.444758 167.234891,50.5346179 166.13433,49.5449053 C166.063929,49.481595 165.996933,49.4145985 165.933623,49.3441975 L159.312835,41.9818818 L159.223,41.875 L88.097,41.875 L86.0097731,44.1973793 C85.0200604,45.2979398 83.3255592,45.3877997 82.2249988,44.3980871 C82.1545978,44.3347768 82.0876013,44.2677803 82.024291,44.1973793 L79.936,41.875 L4.02,41.875 C1.79981531,41.875 0,40.0751847 0,37.855 L0,4.02 C0,1.79981531 1.79981531,0 4.02,0 L330.98,0 Z
            `
          },
          {
            legend: "17. Februar 1610, 18h30",
            img: "17_1h.jpg",
            imgx: '-21',
            imgy: '-10',
            pos: "0.599285714285714",
            svgpath : `M330.98,0 C333.200185,0 335,1.79981531 335,4.02 L335,37.855 C335,40.0751847 333.200185,41.875 330.98,41.875 L287.732,41.875 L285.64458,44.1973793 C284.654867,45.2979398 282.960366,45.3877997 281.859806,44.3980871 C281.789405,44.3347768 281.722408,44.2677803 281.659098,44.1973793 L279.571,41.875 L176.630004,41.8763781 L176.539892,41.9818818 L176.539892,41.9818818 L169.919105,49.3441975 C168.929392,50.444758 167.234891,50.5346179 166.13433,49.5449053 C166.063929,49.481595 165.996933,49.4145985 165.933623,49.3441975 L159.312835,41.9818818 L159.223,41.875 L129.097,41.875 L127.009773,44.1973793 C126.02006,45.2979398 124.325559,45.3877997 123.224999,44.3980871 C123.154598,44.3347768 123.087601,44.2677803 123.024291,44.1973793 L120.936,41.875 L4.02,41.875 C1.79981531,41.875 0,40.0751847 0,37.855 L0,4.02 C0,1.79981531 1.79981531,0 4.02,0 L330.98,0 Z
            `
          },
          {
            legend: "18. Februar 1610, 18h40",
            img: "18_1h.jpg",
            imgx: '-21',
            imgy: '-10',
            pos: "0.635",
            svgpath : `M330.98,0 C333.200185,0 335,1.79981531 335,4.02 L335,37.855 C335,40.0751847 333.200185,41.875 330.98,41.875 L282.756,41.875 L280.668331,44.1973793 C279.678618,45.2979398 277.984117,45.3877997 276.883556,44.3980871 C276.813155,44.3347768 276.746159,44.2677803 276.682848,44.1973793 L274.594,41.875 L204.732,41.875 L202.64458,44.1973793 C201.654867,45.2979398 199.960366,45.3877997 198.859806,44.3980871 C198.789405,44.3347768 198.722408,44.2677803 198.659098,44.1973793 L196.571,41.875 L176.630004,41.8763781 L176.539892,41.9818818 L176.539892,41.9818818 L169.919105,49.3441975 C168.929392,50.444758 167.234891,50.5346179 166.13433,49.5449053 C166.063929,49.481595 165.996933,49.4145985 165.933623,49.3441975 L159.312835,41.9818818 L159.223,41.875 L131.097,41.875 L129.009773,44.1973793 C128.02006,45.2979398 126.325559,45.3877997 125.224999,44.3980871 C125.154598,44.3347768 125.087601,44.2677803 125.024291,44.1973793 L122.936,41.875 L4.02,41.875 C1.79981531,41.875 0,40.0751847 0,37.855 L0,4.02 C0,1.79981531 1.79981531,0 4.02,0 L330.98,0 Z
            `
          },
          {
            legend: "18. Februar 1610, 23h40",
            img: "18_6h.jpg",
            imgx: '-21',
            imgy: '-11',
            pos: "0.6425",
            svgpath : `M330.98,0 C333.200185,0 335,1.79981531 335,4.02 L335,37.855 C335,40.0751847 333.200185,41.875 330.98,41.875 L299.732,41.875 L297.64458,44.1973793 C296.654867,45.2979398 294.960366,45.3877997 293.859806,44.3980871 C293.789405,44.3347768 293.722408,44.2677803 293.659098,44.1973793 L291.571,41.875 L218.756,41.875 L216.668331,44.1973793 C215.678618,45.2979398 213.984117,45.3877997 212.883556,44.3980871 C212.813155,44.3347768 212.746159,44.2677803 212.682848,44.1973793 L210.594,41.875 L176.630004,41.8763781 L176.539892,41.9818818 L176.539892,41.9818818 L169.919105,49.3441975 C168.929392,50.444758 167.234891,50.5346179 166.13433,49.5449053 C166.063929,49.481595 165.996933,49.4145985 165.933623,49.3441975 L159.312835,41.9818818 L159.223,41.875 L152.022,41.875 L149.934054,44.1973793 C148.944342,45.2979398 147.249841,45.3877997 146.14928,44.3980871 C146.078879,44.3347768 146.011883,44.2677803 145.948572,44.1973793 L143.86,41.875 L123.097,41.875 L121.009773,44.1973793 C120.02006,45.2979398 118.325559,45.3877997 117.224999,44.3980871 C117.154598,44.3347768 117.087601,44.2677803 117.024291,44.1973793 L114.936,41.875 L4.02,41.875 C1.79981531,41.875 0,40.0751847 0,37.855 L0,4.02 C0,1.79981531 1.79981531,0 4.02,0 L330.98,0 Z
            `
          },
          {
            legend: "19. Februar 1610, 18h00",
            img: "19_0.67h.jpg",
            imgx: '-22',
            imgy: '-10',
            pos: "0.67",
            svgpath : `M176.539892,41.9818818 L169.919105,49.3441975 C168.929392,50.444758 167.234891,50.5346179 166.13433,49.5449053 C166.063929,49.481595 165.996933,49.4145985 165.933623,49.3441975 L159.312835,41.9818818 L159.223,41.875 L4.02,41.875 C1.79981531,41.875 0,40.0751847 0,37.855 L0,4.02 C0,1.79981531 1.79981531,0 4.02,0 L330.98,0 C333.200185,0 335,1.79981531 335,4.02 L335,37.855 C335,40.0751847 333.200185,41.875 330.98,41.875 L296.732,41.875 L294.64458,44.1973793 C293.654867,45.2979398 291.960366,45.3877997 290.859806,44.3980871 C290.789405,44.3347768 290.722408,44.2677803 290.659098,44.1973793 L288.571,41.875 L242.756,41.875 L240.668331,44.1973793 C239.678618,45.2979398 237.984117,45.3877997 236.883556,44.3980871 C236.813155,44.3347768 236.746159,44.2677803 236.682848,44.1973793 L234.594,41.875 L176.630004,41.8763781 L176.539892,41.9818818 L176.539892,41.9818818 Z
            `
          },
          {
            legend: "21. Februar 1610, 19h10",
            img: "21_1.5h.jpg",
            imgx: '-22',
            imgy: '-10',
            pos: "0.741428571428571",
            svgpath : `M330.98,0 C333.200185,0 335,1.79981531 335,4.02 L335,37.855 C335,40.0751847 333.200185,41.875 330.98,41.875 L276.732,41.875 L274.64458,44.1973793 C273.654867,45.2979398 271.960366,45.3877997 270.859806,44.3980871 C270.789405,44.3347768 270.722408,44.2677803 270.659098,44.1973793 L268.571,41.875 L208.756,41.875 L206.668331,44.1973793 C205.678618,45.2979398 203.984117,45.3877997 202.883556,44.3980871 C202.813155,44.3347768 202.746159,44.2677803 202.682848,44.1973793 L200.594,41.875 L176.630004,41.8763781 L176.539892,41.9818818 L176.539892,41.9818818 L169.919105,49.3441975 C168.929392,50.444758 167.234891,50.5346179 166.13433,49.5449053 C166.063929,49.481595 165.996933,49.4145985 165.933623,49.3441975 L159.312835,41.9818818 L159.223,41.875 L139.097,41.875 L137.009773,44.1973793 C136.02006,45.2979398 134.325559,45.3877997 133.224999,44.3980871 C133.154598,44.3347768 133.087601,44.2677803 133.024291,44.1973793 L130.936,41.875 L4.02,41.875 C1.79981531,41.875 0,40.0751847 0,37.855 L0,4.02 C0,1.79981531 1.79981531,0 4.02,0 L330.98,0 Z
            `
          },

          {
            legend: "25. Februar 1610, 19h20",
            img: "25_1.5h.jpg",
            imgx: '-22',
            imgy: '-10',
            pos: "0.884285714285714",
            svgpath : `M330.98,0 C333.200185,0 335,1.79981531 335,4.02 L335,37.855 C335,40.0751847 333.200185,41.875 330.98,41.875 L205.732,41.875 L203.64458,44.1973793 C202.654867,45.2979398 200.960366,45.3877997 199.859806,44.3980871 C199.789405,44.3347768 199.722408,44.2677803 199.659098,44.1973793 L197.571,41.875 L176.630004,41.8763781 L176.539892,41.9818818 L176.539892,41.9818818 L169.919105,49.3441975 C168.929392,50.444758 167.234891,50.5346179 166.13433,49.5449053 C166.063929,49.481595 165.996933,49.4145985 165.933623,49.3441975 L159.312835,41.9818818 L159.223,41.875 L126.097,41.875 L124.009773,44.1973793 C123.02006,45.2979398 121.325559,45.3877997 120.224999,44.3980871 C120.154598,44.3347768 120.087601,44.2677803 120.024291,44.1973793 L117.936,41.875 L89.022,41.875 L86.9340545,44.1973793 C85.9443418,45.2979398 84.2498406,45.3877997 83.1492802,44.3980871 C83.0788792,44.3347768 83.0118827,44.2677803 82.9485724,44.1973793 L80.86,41.875 L4.02,41.875 C1.79981531,41.875 0,40.0751847 0,37.855 L0,4.02 C0,1.79981531 1.79981531,0 4.02,0 L330.98,0 Z
            `
          },
          {
            legend: "26. Februar 1610, 18h20",
            img: "26_0.5h.jpg",
            imgx: '-22',
            imgy: '-10',
            pos: "0.92",
            svgpath : `M330.98,0 C333.200185,0 335,1.79981531 335,4.02 L335,37.855 C335,40.0751847 333.200185,41.875 330.98,41.875 L247.732,41.875 L245.64458,44.1973793 C244.654867,45.2979398 242.960366,45.3877997 241.859806,44.3980871 C241.789405,44.3347768 241.722408,44.2677803 241.659098,44.1973793 L239.571,41.875 L176.630004,41.8763781 L176.539892,41.9818818 L176.539892,41.9818818 L169.919105,49.3441975 C168.929392,50.444758 167.234891,50.5346179 166.13433,49.5449053 C166.063929,49.481595 165.996933,49.4145985 165.933623,49.3441975 L159.312835,41.9818818 L159.223,41.875 L66.022,41.875 L63.9340545,44.1973793 C62.9443418,45.2979398 61.2498406,45.3877997 60.1492802,44.3980871 C60.0788792,44.3347768 60.0118827,44.2677803 59.9485724,44.1973793 L57.86,41.875 L4.02,41.875 C1.79981531,41.875 0,40.0751847 0,37.855 L0,4.02 C0,1.79981531 1.79981531,0 4.02,0 L330.98,0 Z
            `
          },
          {
            legend: "26. Februar 1610, 22h50",
            img: "26_5h.jpg",
            imgx: '-22',
            imgy: '-10',
            pos: "0.926428571428571",
            svgpath : `M330.98,0 C333.200185,0 335,1.79981531 335,4.02 L335,37.855 C335,40.0751847 333.200185,41.875 330.98,41.875 L245.732,41.875 L243.64458,44.1973793 C242.654867,45.2979398 240.960366,45.3877997 239.859806,44.3980871 C239.789405,44.3347768 239.722408,44.2677803 239.659098,44.1973793 L237.571,41.875 L188.732,41.875 L186.64458,44.1973793 C185.654867,45.2979398 183.960366,45.3877997 182.859806,44.3980871 C182.789405,44.3347768 182.722408,44.2677803 182.659098,44.1973793 L180.571,41.875 L176.630004,41.8763781 L176.539892,41.9818818 L176.539892,41.9818818 L169.919105,49.3441975 C168.929392,50.444758 167.234891,50.5346179 166.13433,49.5449053 C166.063929,49.481595 165.996933,49.4145985 165.933623,49.3441975 L159.312835,41.9818818 L159.223,41.875 L57.097,41.875 L55.0097731,44.1973793 C54.0200604,45.2979398 52.3255592,45.3877997 51.2249988,44.3980871 C51.1545978,44.3347768 51.0876013,44.2677803 51.024291,44.1973793 L48.936,41.875 L4.02,41.875 C1.79981531,41.875 0,40.0751847 0,37.855 L0,4.02 C0,1.79981531 1.79981531,0 4.02,0 L330.98,0 Z
            `
          },
          {
            legend: "27. Februar 1610, 18h50",
            img: "27_1h.jpg",
            imgx: '-22',
            imgy: '-10',
            pos: "0.956428571428571",
            svgpath : `M330.98,-4.22772928e-13 C333.200185,-4.22772928e-13 335,1.79981531 335,4.02 L335,37.855 C335,40.0751847 333.200185,41.875 330.98,41.875 L223.732,41.875 L221.64458,44.1973793 C220.654867,45.2979398 218.960366,45.3877997 217.859806,44.3980871 C217.789405,44.3347768 217.722408,44.2677803 217.659098,44.1973793 L215.571,41.875 L208.732,41.875 L206.64458,44.1973793 C205.654867,45.2979398 203.960366,45.3877997 202.859806,44.3980871 C202.789405,44.3347768 202.722408,44.2677803 202.659098,44.1973793 L200.571,41.875 L176.630004,41.8763781 L176.539892,41.9818818 L176.539892,41.9818818 L169.919105,49.3441975 C168.929392,50.444758 167.234891,50.5346179 166.13433,49.5449053 C166.063929,49.481595 165.996933,49.4145985 165.933623,49.3441975 L159.312835,41.9818818 L159.223,41.875 L157.097,41.875 L155.009773,44.1973793 C154.02006,45.2979398 152.325559,45.3877997 151.224999,44.3980871 C151.154598,44.3347768 151.087601,44.2677803 151.024291,44.1973793 L148.936,41.875 L63.022,41.875 L60.9340545,44.1973793 C59.9443418,45.2979398 58.2498406,45.3877997 57.1492802,44.3980871 C57.0788792,44.3347768 57.0118827,44.2677803 56.9485724,44.1973793 L54.86,41.875 L4.02,41.875 C1.79981531,41.875 0,40.0751847 0,37.855 L0,4.02 C0,1.79981531 1.79981531,-4.22772928e-13 4.02,-4.22772928e-13 L330.98,-4.22772928e-13 Z
            `
          },
          {
            legend: "28. Februar 1610, 18h50",
            img: "28_1h.jpg",
            imgx: '-22',
            imgy: '-11',
            pos: "0.992142857142857",
            svgpath : `M330.98,0 C333.200185,0 335,1.79981531 335,4.02 L335,37.855 C335,40.0751847 333.200185,41.875 330.98,41.875 L199.732,41.875 L197.64458,44.1973793 C196.654867,45.2979398 194.960366,45.3877997 193.859806,44.3980871 C193.789405,44.3347768 193.722408,44.2677803 193.659098,44.1973793 L191.571,41.875 L176.630004,41.8763781 L176.539892,41.9818818 L169.919105,49.3441975 C168.929392,50.444758 167.234891,50.5346179 166.13433,49.5449053 C166.063929,49.481595 165.996933,49.4145985 165.933623,49.3441975 L159.312835,41.9818818 L159.223,41.875 L72.022,41.875 L69.9340545,44.1973793 C68.9443418,45.2979398 67.2498406,45.3877997 66.1492802,44.3980871 C66.0788792,44.3347768 66.0118827,44.2677803 65.9485724,44.1973793 L63.86,41.875 L4.02,41.875 C1.79981531,41.875 0,40.0751847 0,37.855 L0,4.02 C0,1.79981531 1.79981531,0 4.02,0 L330.98,0 Z
            `
          },
          {
            legend: "28. Februar 1610, 22h50",
            img: "28_5h.jpg",
            imgx: '-22',
            imgy: '-10',
            pos: "0.998571428571428",
            svgpath : `M330.98,0 C333.200185,0 335,1.79981531 335,4.02 L335,37.855 C335,40.0751847 333.200185,41.875 330.98,41.875 L202.732,41.875 L200.64458,44.1973793 C199.654867,45.2979398 197.960366,45.3877997 196.859806,44.3980871 C196.789405,44.3347768 196.722408,44.2677803 196.659098,44.1973793 L194.571,41.875 L176.630004,41.8763781 L176.539892,41.9818818 L169.919105,49.3441975 C168.929392,50.444758 167.234891,50.5346179 166.13433,49.5449053 C166.063929,49.481595 165.996933,49.4145985 165.933623,49.3441975 L159.312835,41.9818818 L159.223,41.875 L149.097,41.875 L147.009773,44.1973793 C146.02006,45.2979398 144.325559,45.3877997 143.224999,44.3980871 C143.154598,44.3347768 143.087601,44.2677803 143.024291,44.1973793 L140.936,41.875 L68.022,41.875 L65.9340545,44.1973793 C64.9443418,45.2979398 63.2498406,45.3877997 62.1492802,44.3980871 C62.0788792,44.3347768 62.0118827,44.2677803 61.9485724,44.1973793 L59.86,41.875 L4.02,41.875 C1.79981531,41.875 0,40.0751847 0,37.855 L0,4.02 C0,1.79981531 1.79981531,0 4.02,0 L330.98,0 Z
            `
          }
        ]
      }
    }
  };
</script>

