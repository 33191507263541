<template>
  <div id="atlas-novus" class="work-page" :class="{ 'detail-loaded' : isDetailVisible, 'work-page-loaded': isPageLoaded, 'work-library-modal': showModal }" @click="closeWorkShare">

    <div class="work__bg-wrap">
      <div class="work__bg">
        <img src="/assets/img/layout/bg-overview.jpg" class="work__bg-img"  alt="work background image">
      </div>
    </div>
    <div class="detail__bg" style="background-image: url('/assets/img/atlas-novus/bg-info.jpg')"></div>
    <div class="container" >
      <div class="page__top">
        <h1 class="page-top__title">{{ $t('Schleifenbewegung') }}</h1>
        <p class="page-top__text">{{ $t('atlas-novus.workname') }} <br />(Doppelmayr, 1742)</p>
      </div>
      <main class="main main--atlasnovus">
        <div class="work-content work-content--atlasnovus work-intro-ani-in" :style="'height:' + workWrapperHeightLive + 'px; padding-bottom:' + workPaddingBottomLive +'px;'">
          <div class="atlasnovus-work-container" :style="'width:'+ workWidthLive +'px; height:'+ workHeightLive +'px;'">
            <div class="work-scaleable scale-item">
              <router-link :to="'zoom-atlas-novus/'+this.activeSlide+'/loop'" class="icon-circle icon-circle--loop" v-if="activeSlide > 0">
                <span class="visuallyhidden">Link zu zoomed img loop</span>
              </router-link>
              <router-link :to="'zoom-atlas-novus/'+this.activeSlide+'/orbit'" class="icon-circle icon-circle--orbit" v-if="activeSlide > 0">
                <span class="visuallyhidden">Link zu zoomed img orbit</span>
              </router-link>
              <div class="atlasnovus-container" :class="[activeSlide > 0 ? 'active' : '']">
                <img src="/assets/img/atlas-novus/venus.svg" alt="Venus Loop" class="atlasnovus__venus--loop">
                <img src="/assets/img/atlas-novus/earth.svg" alt="Earth Orbit" class="atlasnovus__earth--orbit">
                <img src="/assets/img/atlas-novus/venus.svg" alt="Venus Orbit" class="atlasnovus__venus--orbit">
                <img src="/assets/img/atlas-novus/beam.svg" alt="Venus Earth Beam" class="atlasnovus__beam">
                <svg width="108px" height="1118px" viewBox="0 0 108 1118" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="atlasnovus__path"  :style="'top:' + topPath + 'px; left:' + leftPath +'px;'">
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round">
                    <g transform="translate(-131.000000, -152.000000)" stroke="#FEEEC5" stroke-width="10">
                      <g transform="translate(136.000000, 157.000000)">
                        <path d="M77.6014443,1107.1542 C81.9346735,1068.15335 79.7680119,1016.15535 77.6014443,974.983613 C74.9260801,905.020791 65.0765333,838.534212 58.8332964,775.656387 C55.5078867,742.166405 54.462476,714.625228 51.6017873,693.324187 M51.6017873,693.324187 C49.9146012,680.761215 41.0889442,649.867844 39.333906,643.49144 C22.0041846,580.536553 19.1022395,561.15924 8.26902561,563.326372 C-2.56422589,565.492563 -0.397554911,584.992047 1.76912546,600.159148 C3.93580584,615.32531 25.6021303,697.65751 51.6017873,693.324187 Z M51.6017873,693.324187 C59.9875335,685.181937 77.6031359,658.760037 86.2679966,606.658663 C90.6511281,580.305367 95.7372146,507.935769 97.1008346,418.161455 C99.1082036,286.07827 90.6013198,116.998785 82.6661978,0"></path>
                      </g>
                    </g>
                  </g>
                </svg>
                <img src="/assets/img/atlas-novus/trace-map.png" alt="Atlas Novus Coelestis" class="atlasnovus__image">
              </div>
            </div>
            <div class="range-slider range-slider--horizontal scale-item" :style="'width:'+ rangeWidthLive +'px'">
              <div class="range-slider__handle-track--horizontal" :style="'transform: translateX(' + currentPos + 'px)'" ref="handle-track">
                <div class="range-slider__handle--horizontal">
                  <span class="sicon sicon__slide-handle" aria-hidden="true"></span>
                  <span class="visuallyhidden">Slide Handle</span>
                </div>
              </div>
              <div class="range-slider__track--horizontal">
                <div class="range-slider__track-line"></div>
              </div>
              <div id="range-interact"></div>
            </div>
            <div class="atlasnovus__legend" :style="'bottom:' + legendBottom + 'px'">
              <span v-for="(slide, index) in venusSlides" :key="'atlas-novus-date__'+index" v-if="index == activeSlide && activeSlide > 0">{{ slide.legend }}</span>
              <span v-if="activeSlide == 0">&nbsp;</span>
            </div>
          </div>
        </div>
        <detail v-on:show-modal="showModalMsg"></detail>
      </main>
    </div>
    <menuprimary current-nav="atlas-novus" :needNavprojects=true v-on:toggle-detail="toggleDetail"></menuprimary>
    <div class="top-tool-nav-wrap">
      <div @click="toggleDetail" v-if="isDetailVisible" class="btn-close-circle" aria-hidden="true">
        <span class="visuallyhidden">close detail content</span>
      </div>
      <share-section v-if="isDetailVisible"></share-section>
      <router-link to="overview" class="btn-close-circle" aria-hidden="true" v-else>
        <span class="visuallyhidden">go to overview page</span>
      </router-link>

    </div>
    <modal v-if="showModal" url="https://www.e-rara.ch/zut/content/pageview/4594061" @close="showModal=false"></modal>
  </div>
</template>

<script>
  import venusStates from '../../data/venus';
  import iAtlasNovus from '../info/i-atlas-novus'
  import Modal from '../../components/modal';
  import MenuPrimary from '../../components/menu-primary';
  import Slides from '../../data/planet-slides';
  import ShareSection from '../../components/share';

  import anime from 'animejs';
  import Hammer from '../../assets/js/hammer.min';

  export default {
    name: 'atlasNovusComponent',
    components: {
      'detail': iAtlasNovus,
      'modal': Modal,
      'menuprimary': MenuPrimary,
      'share-section': ShareSection
    },
    data() {
      return {
        activeSlide: 0,
        currentPos: 0,
        venusSlides:venusStates.data()['slides'],
        layoutWidth: 1444,
        layoutLeftValue: 75,
        layoutTopValue: 56,
        showModal: false,
        isDetailVisible: false,
        windowWidth: window.innerWidth,
        lastWindowW: window.innerWidth,
        lastWindowH: window.innerHeight,
        rangeWidthLive: 0,
        workWidthLive: 0,
        workHeightLive: 0,
        workWrapperHeightLive: 0,
        workPaddingBottomLive: 0,
        legendBottom: 0,
        topPath: 0,
        leftPath: 0,
        slides: Slides.data()['Slides'],
        zoomEl:'',
        mc:'',
        pinchTimmer: 0,
        isPageLoaded: false,
        instAniInterval: null,
        instStepTimer: null,
        instAniLoadTimer: null,
        pageLoadTimer: null,
        pageTop:'',
        getPathScale: 1,
        ratioImageLayoutLive: 1
      }
    },
    created() {
      this.viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0)
      this.isMobile = this.mobileAndTabletcheck()
    },
    methods: {
      instractionAni: function () {
        var step = 0;
        var _this = this;
        this.instAniInterval = setInterval(frame, 110);

        function frame() {
          if (step == 20 || _this.isDetailVisible) {
            _this.clearIntervalInstAni();
            _this.resetInstAni();
          } else {
            if((step >= 5 && step < 10)|| (step >= 15 && step < 20)) {
              _this.stepDown()
            } else {
              _this.stepUp()
            }
          }
          step++;
        }
      },
      clearIntervalInstAni : function() {
        if(this.instAniInterval) {
          clearInterval(this.instAniInterval);
          this.instAniInterval = null
        }
        if(this.instAniLoadTimer != null) {
          clearTimeout(this.instAniLoadTimer);
          this.instAniLoadTimer = null
        }
      },
      stepUp: function () {
        this.activeSlide++
        if(this.activeSlide > this.venusSlides.length -1) {
          this.activeSlide = this.venusSlides.length -1;
        }

        this.moveVenusOnLoop(this.activeSlide, this.getPathScale, this.ratioImageLayoutLive);
        this.moveEarthOnOrbit(this.activeSlide, this.getPathScale, this.ratioImageLayoutLive);
        this.moveVenusOnOrbit(this.activeSlide, this.getPathScale, this.ratioImageLayoutLive);
        this.getHandlePos();
      },
      stepDown: function () {
        this.activeSlide--
        if(this.activeSlide < 1) {
          this.activeSlide = 1
        }

        this.moveVenusOnLoop(this.activeSlide, this.getPathScale, this.ratioImageLayoutLive);
        this.moveEarthOnOrbit(this.activeSlide, this.getPathScale, this.ratioImageLayoutLive);
        this.moveVenusOnOrbit(this.activeSlide, this.getPathScale, this.ratioImageLayoutLive);
        this.getHandlePos();
      },
      resetInstAni: function () {
        this.activeSlide = 0;

        this.moveVenusOnLoop(this.activeSlide, this.getPathScale, this.ratioImageLayoutLive);
        this.moveEarthOnOrbit(this.activeSlide, this.getPathScale, this.ratioImageLayoutLive);
        this.moveVenusOnOrbit(this.activeSlide, this.getPathScale, this.ratioImageLayoutLive);
        this.getHandlePos();
      },
      getHandlePos: function (){
        var rangeHandle = this.$el.querySelector('.range-slider__handle--horizontal');
        var slidesLength = this.venusSlides.length -1
        var totalSlideWidth = this.$el.querySelector('#range-interact').clientWidth - rangeHandle.clientWidth;
        var stepPos = totalSlideWidth / slidesLength;
        this.currentPos = this.activeSlide * stepPos;
      },
      rangeHandleOnPan: function (e) {
        // eslint-disable-next-line no-undef
       gtag('event', 'rangeSlider', {
          'event_category' : 'atlas-novus',
          'event_label' : 'atlas-novus',
        });

        var rangeHandle = this.$el.querySelector('.range-slider__handle--horizontal');
        var slidesLength = this.venusSlides.length - 1;
        var totalSlideWidth = this.$el.querySelector('#range-interact').clientWidth - rangeHandle.clientWidth;
        var stepPos = totalSlideWidth / slidesLength;
        var rangeInteract = this.$el.querySelector('#range-interact').getBoundingClientRect();
        var posOnPan = e.center.x + (rangeInteract.left * -1) - rangeHandle.clientWidth / 2;

        if(posOnPan >= totalSlideWidth){
          posOnPan = totalSlideWidth;
        }

        if(posOnPan <= 0){
          posOnPan = 0;
        }

        this.currentPos = posOnPan;

        var _curSlideIndex = Math.round(posOnPan  / stepPos);
        if(this.activeSlide != _curSlideIndex) {
          this.activeSlide = _curSlideIndex
        } else {
          return false
        }

        this.moveVenusOnLoop(this.activeSlide, this.getPathScale, this.ratioImageLayoutLive);
        this.moveEarthOnOrbit(this.activeSlide, this.getPathScale, this.ratioImageLayoutLive);
        this.moveVenusOnOrbit(this.activeSlide, this.getPathScale, this.ratioImageLayoutLive);
      },
      toggleDetail:function () {
        if(!this.isDetailVisible) {
          // eslint-disable-next-line no-undef
          gtag('config', this.uaId, {'page_path': this.$route.path + '-i-detail'});
        } else {
          // eslint-disable-next-line no-undef
          gtag('config', this.uaId, {'page_path': this.$route.path });

          var audio = this.$el.querySelectorAll('audio')[0];
          if(!audio.paused){
            audio.pause();
          }
        }

        this.isDetailVisible = !this.isDetailVisible
        var _scale = 1;
        var workPaddingBottom = this.workPaddingBottomLive;
        var _marginTop = 0;
        var _translateY = 0;
        var workHTo =  window.innerHeight - this.$el.querySelector('.nav-bottom').clientHeight;


        if(this.isDetailVisible) {
          document.body.classList.add('detail-opened');
          _scale = 0.7;
          workHTo = this.workWidthLive * 0.9;
          workPaddingBottom = 0;

          if(window.innerWidth < window.innerHeight && window.innerWidth < 1025 && window.innerWidth/window.innerHeight > 0.73 ){
            _marginTop = (this.workHeightLive - (this.workHeightLive * 0.3)) / 2;
          }

          if(window.innerWidth < window.innerHeight && window.innerWidth < 768 && window.innerWidth/window.innerHeight < 0.59){
            _marginTop = document.querySelector('.page__top').clientHeight;
          }

          if(window.innerWidth < 1025){
            workHTo = this.workWidthLive;

            if(window.innerWidth < 768){
              workHTo = this.workWidthLive + 90;

              if(window.innerWidth > 374 && window.innerHeight < 658){
                workHTo = this.workWidthLive + 20;
              }
            }
          }
        } else {
          document.body.classList.remove('detail-opened');
        }
        anime({
          targets: '.atlasnovus-container',
          scale: _scale,
          duration: 500,
          easing:'easeOutQuart',
          complete: function() {
            //window.onresize();
          }
        });

        if(window.innerWidth < window.innerHeight && window.innerWidth < 768 && window.innerWidth/window.innerHeight > 0.59){
          _translateY = 40;

          anime({
            targets: '.atlasnovus-work-container',
            marginTop: _marginTop,
            translateY: _translateY,
            duration: 500,
            easing:'easeOutQuart'
          });
        } else {
          anime({
            targets: '.atlasnovus-work-container',
            marginTop: _marginTop,
            duration: 500,
            easing:'easeOutQuart'
          });
        }




        anime({
          targets: '.work-content',
          height: workHTo,
          paddingBottom: workPaddingBottom,
          duration: 500,
          easing:'easeOutQuart',
        });

      },
      showModalMsg : function() {
        this.showModal = true
      },
      pageLoad : function() {
        this.isPageLoaded = true;
        let _this = this
        this.pageLoadTimer = setTimeout(function () {
          _this.$el.querySelector(".work-intro-ani-in").classList.remove('work-intro-ani-in')
        }, 1600)
      },
      moveVenusOnLoop: function (activeSlide, workScale ,imageScale) {
        if(this.isDetailVisible) {
          return
        }
        var venusEl = document.querySelector('.atlasnovus__venus--loop');
        var venusHalfWidth = (venusEl.clientWidth / 2) * workScale;
        var venusLoopPosLeft = this.setLeftPositionPlanet(this.venusSlides[this.activeSlide].posX,this.layoutLeftValue,imageScale);
        var venusLoopPosTop = this.setTopPositionPlanet(this.venusSlides[this.activeSlide].posY,this.layoutTopValue,imageScale);

        anime({
          targets: venusEl,
          top: venusLoopPosTop - venusHalfWidth,
          left: venusLoopPosLeft - venusHalfWidth,
          duration: 0,
        });
      },
      moveEarthOnOrbit: function (activeSlide, workScale ,imageScale){
        if(this.isDetailVisible) {
          return
        }

        var earthEl = document.querySelector('.atlasnovus__earth--orbit');
        var beamEl = document.querySelector('.atlasnovus__beam');

        var earthHalfWidth = (earthEl.clientWidth / 2) * imageScale;
        var earthHalfQuarter = (document.querySelector('.atlasnovus__earth--orbit').clientWidth / 4) * workScale;
        var earthPosLeft = this.setLeftPositionPlanet(this.venusSlides[this.activeSlide].earthPosX,this.layoutLeftValue,imageScale);
        var earthPosTop = this.setTopPositionPlanet(this.venusSlides[this.activeSlide].earthPosY,this.layoutTopValue,imageScale);
        var rotationBeam = this.venusSlides[this.activeSlide].beamDeg;

        if(window.innerWidth < 768){
          anime({
            targets: beamEl,
            top: earthPosTop - earthHalfWidth,
            left: earthPosLeft,
            rotate: rotationBeam,
            duration: 0,
          });
        } else {
          anime({
            targets: beamEl,
            top: earthPosTop - earthHalfQuarter,
            left: earthPosLeft,
            rotate: rotationBeam,
            duration: 0,
          });
        }


        anime({
          targets: earthEl,
          top: earthPosTop - earthHalfWidth,
          left: earthPosLeft - earthHalfWidth,
          duration: 0,
        });
      },
      moveVenusOnOrbit: function (activeSlide, workScale ,imageScale){
        if(this.isDetailVisible) {
          return
        }

        var venusEl = document.querySelector('.atlasnovus__venus--orbit');
        var venusHalfWidth = (venusEl.clientWidth / 2) * imageScale;
        var venusPosLeft = this.setLeftPositionPlanet(this.venusSlides[this.activeSlide].venusPosX,this.layoutLeftValue,imageScale);
        var venusPosTop = this.setTopPositionPlanet(this.venusSlides[this.activeSlide].venusPosY,this.layoutTopValue,imageScale);
        anime({
          targets: venusEl,
          top: venusPosTop - venusHalfWidth,
          left: venusPosLeft - venusHalfWidth,
          duration: 0,
        });
      },
      setLeftPositionPlanet: function (positionX,layoutLeftValue,imageScale){
        return (positionX - layoutLeftValue) * imageScale
      },
      setTopPositionPlanet: function (positionY,layoutTopValue,imageScale){
        return (positionY - layoutTopValue) * imageScale
      },
      setPositionPlanet: function (planetEl, positionX, positionY, workScale, imageScale){
        var planetHalfWidth = (planetEl.clientWidth / 2) * workScale;
        var planetPosLeft = this.setLeftPositionPlanet(positionX,this.layoutLeftValue,imageScale);
        var planetPosTop = this.setTopPositionPlanet(positionY,this.layoutTopValue,imageScale);

        planetEl.style.transform = 'scale(' + imageScale +')';
        planetEl.style.left =  planetPosLeft - planetHalfWidth  +'px';
        planetEl.style.top =  planetPosTop - planetHalfWidth +'px';
      },
      setPositionBeam: function (beamEl, positionX, positionY, workScale, imageScale){
        var earthHalfQuarter = (document.querySelector('.atlasnovus__earth--orbit').clientWidth / 4) * workScale
        var beamPosLeft = this.setLeftPositionPlanet(positionX,this.layoutLeftValue,imageScale);
        var beamPosTop = this.setTopPositionPlanet(positionY,this.layoutTopValue,imageScale);

        beamEl.style.left =  beamPosLeft  +'px';
        beamEl.style.top =  beamPosTop - earthHalfQuarter +'px';
      },
      getWorkWidth: function (){
        var winW = window.innerWidth;
        var winH = window.innerHeight;
        var workH = winH - this.$el.querySelector('.nav-bottom').clientHeight;
        var pageTopEl = this.pageTop;
        var pageTopPaddingTop = parseInt(getComputedStyle(pageTopEl).paddingTop);
        var pageTopPaddingLeft = parseFloat(getComputedStyle(pageTopEl).paddingLeft) * 2;
        var pageTopTitleH = document.querySelector('.page-top__title').clientHeight;
        var workW = this.$el.querySelector('.work-content').clientWidth - pageTopPaddingLeft;
        var ratio = 1.174740484429066;  //ratio image 679/578
        var availH = workH - (pageTopPaddingTop + pageTopTitleH) - 40;

        if(winW > availH && winW <= 926 && this.isMobile) {
          availH = workH - pageTopTitleH - pageTopPaddingTop - 40;
        }

        var maxAailW = Math.min(workW, availH);

        this.workWidthLive = maxAailW * 0.95;

        if(winW < 1200){
          this.workWidthLive = maxAailW * 0.9;

          if(winW < 1025){
            this.workWidthLive = maxAailW * 0.85;

            if(winW < 800){
              this.workWidthLive = maxAailW;
            }
          }
        }

        this.workHeightLive = this.workWidthLive / ratio;


        if(winW < 1025){
          this.workWrapperHeightLive = window.innerHeight - 80;
        } else {
          this.workWrapperHeightLive = window.innerHeight - 64;
        }

        this.rangeWidthLive = this.workWidthLive * 0.86;
        this.legendBottom = this.workWidthLive * 0.028;

        if(winW < 768){
          this.legendBottom = this.workWidthLive * 0.065;
        }

        this.workPaddingBottomLive = this.legendBottom + this.$el.querySelector('.range-slider').clientHeight + 64;

        if(winW < 768){
          this.workPaddingBottomLive = this.workPaddingBottomLive - 8;
        }

        if(winW > winH && winW <= 926 && this.isMobile) {
          this.legendBottom = this.workWidthLive * 0.032;
          this.rangeWidthLive = this.workWidthLive;
          this.workPaddingBottomLive = this.legendBottom + this.$el.querySelector('.range-slider').clientHeight + 50;
        }


        if(this.workHeightLive + 30 > this.workPaddingBottomLive + pageTopEl.clientHeight){
          if(winW > 768){
            this.workWidthLive = this.workWidthLive * 0.9;
            this.workHeightLive = this.workHeightLive * 0.9;
            this.rangeWidthLive =  this.rangeWidthLive * 0.9;
          } else {
            this.workWidthLive = this.workWidthLive * 0.82;
            this.workHeightLive = this.workHeightLive * 0.82;
            this.rangeWidthLive =  this.rangeWidthLive * 0.82;
          }
        }

        if(winW < winH && winW < 768 && winW/winH < 0.59 || winW < winH && winW < 1025 && winW/winH > 0.73 ){
          this.$el.querySelector('.main--atlasnovus').classList.add('portrait');
          this.workPaddingBottomLive = 0;
          var legendHeight = this.$el.querySelector('.atlasnovus__legend').clientHeight;
          var notUsedHeight = this.workWrapperHeightLive - this.workHeightLive;
          var notUsedHeightBottom = notUsedHeight / 2 - this.$el.querySelector('.range-slider').clientHeight - 20 - legendHeight - 20;
          this.legendBottom = notUsedHeightBottom;
        } else {
          this.$el.querySelector('.main--atlasnovus').classList.remove('portrait');
        }

      },
      setPathPosition: function (){
        var pathEl = this.$el.querySelector('.atlasnovus__path');
        var getPathHeight = pathEl.clientHeight;
        var newPathHeight = this.workHeightLive * 0.884471117779445;
        this.getPathScale = newPathHeight / getPathHeight;

        pathEl.style.transform = 'scale(' + this.getPathScale +')';
        this.topPath = this.workHeightLive * 0.08996539792387543;
        this.leftPath = this.workHeightLive * 0.045;
      },
      setPlanetPositions: function (){
        var venusLoopEl = this.$el.querySelector('.atlasnovus__venus--loop');
        var venusOrbitEl = this.$el.querySelector('.atlasnovus__venus--orbit');
        var earthOrbitEl = this.$el.querySelector('.atlasnovus__earth--orbit');
        var beamOrbitEl = this.$el.querySelector('.atlasnovus__beam');
        //image width with space for 1600px, image width without space 1444px, space-left 75px
        //image height with space 1344px, image height without space 1232px, space-top 56px
       this.ratioImageLayoutLive = this.workWidthLive / this.layoutWidth;

        this.setPositionPlanet(venusLoopEl, this.venusSlides[this.activeSlide].posX, this.venusSlides[this.activeSlide].posY, this.getPathScale, this.ratioImageLayoutLive);
        this.setPositionPlanet(venusOrbitEl, this.venusSlides[this.activeSlide].venusPosX, this.venusSlides[this.activeSlide].venusPosY, this.getPathScale, this.ratioImageLayoutLive);
        this.setPositionPlanet(earthOrbitEl, this.venusSlides[this.activeSlide].earthPosX, this.venusSlides[this.activeSlide].earthPosY, this.getPathScale, this.ratioImageLayoutLive);
        this.setPositionBeam(beamOrbitEl, this.venusSlides[this.activeSlide].earthPosX, this.venusSlides[this.activeSlide].earthPosY, this.getPathScale, this.ratioImageLayoutLive);

        beamOrbitEl.style.transform = 'scale(' + this.getPathScale +')  rotate(' + this.venusSlides[this.activeSlide].beamDeg +'deg)';
      }
    },
    beforeRouteLeave (to, from, next) {
      if(this.instStepTimer) {
        clearTimeout(this.instStepTimer)
      }
      if(this.pageLoadTimer) {
        clearTimeout(this.pageLoadTimer);
      }
      this.clearIntervalInstAni()
      document.body.classList.remove('detail-opened');
      localStorage.setItem('atlasNovusIndex', '');
      this.aniMenuPageLeave(next());
    },
    mounted() {
      document.body.style.zoom = 1.0;
      this.pageTop = this.$el.querySelector('.page__top');
      this.windowWidth = window.innerWidth
      this.saveCurrentPlanetToLocalStorage();
      this.zoomEl = this.$el.querySelector(".work__scaleable");
      const rangeInteract = this.$el.querySelector("#range-interact");
      const mc = new Hammer.Manager(rangeInteract);
      mc.add( new Hammer.Pan({ direction: Hammer.DIRECTION_ALL, threshold:0}) );
      mc.add( new Hammer.Tap() );
      mc.on('panmove', (e) => {
        var old = this.pinchTimmer
        var now = new Date().getTime(),
          delta = now - old;

        this.clearIntervalInstAni();

        //avoid pinch end -> pan
        if(delta > 100) {
          this.pinchTimmer = 0
          this.rangeHandleOnPan(e)
        }
      });

      var lastX = 0;
      mc.on('tap', (e) => {
        this.clearIntervalInstAni()
        var posX = e.srcEvent.layerX

        if(posX > lastX){
          this.stepUp()
          lastX = posX - 1;
        } else {
          this.stepDown()
          lastX = posX + 1;
        }
      });

      this.mc = mc;
      this.pageLoad();
      this.getWorkWidth();
      var _this = this;
      setTimeout(function (){
        _this.setPathPosition();
        _this.setPlanetPositions();
      },100);
      this.setWorkBgPos();

      if(localStorage.getItem('atlasNovusIndex')) {
        this.activeSlide = localStorage.getItem('atlasNovusIndex');
       setTimeout(function (){
         _this.getHandlePos();
       },100);

       setTimeout(function (){
         localStorage.setItem('atlasNovusIndex', '');
       },300);
      } else {
        this.instAniLoadTimer = setTimeout(this.instractionAni,1500)
      }
      window.onresize = () => {
        //avoild resize on scroll -> addressbar collapse
        if(this.isMobile && this.windowWidth == window.innerWidth) {
          return false
        }
        this.windowWidth = window.innerWidth;

        if(this.lastWindowW != window.innerWidth || this.lastWindowH != window.innerHeight && !this.isDetailVisible){
          this.getWorkWidth();
          this.setPathPosition();
          this.setPlanetPositions();
          this.getHandlePos();
        }

        this.lastWindowW = window.innerWidth
        this.lastWindowH = window.innerHeight
      }
    }
  };
</script>
