<script>
  export default {
    name: 'suns',
    data: function (){
      return {
        slides : [
          {
            legend: "",
            clipath : ``
          },
          {
            legend: "01.06.1625",
            clipath : `
              <ellipse id="flecken_01_2" class="st1" cx="178.8" cy="1190.8" rx="31.4" ry="71.5"/>
              <ellipse id="flecken_01_3" class="st1" cx="207.1" cy="1383.2" rx="21.6" ry="49.8"/>
              <ellipse id="flecken_01_4" transform="matrix(7.563597e-02 -0.9971 0.9971 7.563597e-02 -1116.9225 1608.7432)" class="st1" cx="309.2" cy="1406.8" rx="57.1" ry="24.2"/>
              <ellipse id="flecken_01_5" transform="matrix(0.9869 -0.1614 0.1614 0.9869 -226.0717 93.8761)" class="st1" cx="464.7" cy="1438.3" rx="24.9" ry="44.5"/>
              <circle id="flecken_01_6" class="st1" cx="657.8" cy="1442.5" r="19"/>
              <ellipse id="flecken_01_7" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -595.6479 955.1836)" class="st1" cx="855.2" cy="1196.6" rx="59.3" ry="37.7"/>
              <ellipse id="flecken_01_8" class="st1" cx="890.3" cy="1200.5" rx="21.5" ry="47.5"/>
              <circle id="flecken_01_9" class="st1" cx="1349.7" cy="1345.8" r="43.8"/>
              <circle id="flecken_01_10" class="st1" cx="1573.3" cy="1317" r="35.6"/>
          <circle id="flecken_01_11" class="st1" cx="623.5" cy="659.5" r="48.8"/>
              `
          },
          {
            legend: "02.06.1625",
            clipath : `
              <circle id="flecken_02_2" class="st1" cx="311.6" cy="1200.2" r="46.2"/>
              <circle id="flecken_02_3" class="st1" cx="815" cy="691.2" r="45.2"/>
              <circle id="flecken_02_4" class="st1" cx="1091.8" cy="1227.5" r="50.6"/>
              <circle id="flecken_02_5" class="st1" cx="1578.9" cy="1376.9" r="30.3"/>
              <ellipse id="flecken_02_6" class="st1" cx="1745.8" cy="1346.6" rx="36.9" ry="29.6"/>
              <circle id="flecken_02_7" class="st1" cx="1746.5" cy="387.9" r="16.3"/>
              `
          },
          {
            legend: "03.06.1625",
            clipath : `
              <circle id="flecken_03_2" class="st1" cx="453.6" cy="1210.3" r="49.3"/>
              <ellipse id="flecken_03_3" transform="matrix(0.9321 -0.3622 0.3622 0.9321 -378.789 552.095)" class="st1" cx="1283.1" cy="1286.3" rx="60.2" ry="24"/>
              <circle id="flecken_03_4" class="st1" cx="1001.2" cy="716.9" r="42.4"/>
              <ellipse id="flecken_03_5" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -485.1994 1633.1371)" class="st1" cx="1728.8" cy="1402.3" rx="37.7" ry="31.2"/>
              <ellipse id="flecken_03_6" transform="matrix(0.5578 -0.83 0.83 0.5578 -311.8214 2177.7722)" class="st1" cx="1887.9" cy="1381.5" rx="37" ry="18.4"/>
              `
          },
          {
            legend: "04.06.1625",
            clipath : `
              <ellipse id="flecken_04_2" transform="matrix(0.5 -0.866 0.866 0.5 -758.0363 1187.991)" class="st1" cx="649.8" cy="1250.5" rx="43.7" ry="30.6"/>
              <ellipse id="flecken_04_3" transform="matrix(4.917530e-02 -0.9988 0.9988 4.917530e-02 135.6683 2756.9783)" class="st1" cx="1515.9" cy="1307.2" rx="34.9" ry="28.6"/>
              <ellipse id="flecken_04_4" class="st1" cx="1875.9" cy="1447.3" rx="43.6" ry="41.2"/>
              <circle id="flecken_04_5" class="st1" cx="1208.8" cy="753.4" r="46.9"/>
              `
          },
          {
            legend: "05.06.1625",
            clipath : `
              <ellipse id="flecken_05_2" class="st1" cx="852.2" cy="1273.2" rx="34" ry="47.8"/>
              <ellipse id="flecken_05_3" class="st1" cx="1683.2" cy="1340.8" rx="30.1" ry="28.2"/>
              <circle id="flecken_05_4" class="st1" cx="1408.1" cy="782.1" r="38.8"/>
              <circle id="flecken_05_5" class="st1" cx="1758.2" cy="407.2" r="14.4"/>
              `
          },
          {
            legend: "06.06.1625",
            clipath : `
              <circle id="flecken_06_2" class="st1" cx="1604.8" cy="803.9" r="45.1"/>
              <ellipse id="flecken_06_3" class="st1" cx="133.4" cy="1053.2" rx="14.4" ry="60.9"/>
              <circle id="flecken_06_4" class="st1" cx="1077.1" cy="1311.4" r="34.4"/>
              <ellipse id="flecken_06_5" class="st1" cx="1850.6" cy="1351.9" rx="37.7" ry="44.1"/>

              `
          },
          {
            legend: "07.06.1625",
            clipath : `
              <ellipse id="flecken_07_2" transform="matrix(0.2486 -0.9686 0.9686 0.2486 -1098.2258 1202.8475)" class="st1" cx="226.2" cy="1309.3" rx="56.1" ry="18"/>
              <ellipse id="flecken_07_3" class="st1" cx="175.3" cy="1048.2" rx="37.9" ry="61.9"/>
              <ellipse id="flecken_07_4" class="st1" cx="1283.1" cy="1342.5" rx="30.2" ry="33.5"/>
              <ellipse id="flecken_07_5" transform="matrix(0.5933 -0.805 0.805 0.5933 -303.2867 2114.5767)" class="st1" cx="1941.2" cy="1357.5" rx="32.8" ry="20.1"/>
              <circle id="flecken_07_6" class="st1" cx="1741.8" cy="822.8" r="37.2"/>
              <circle id="flecken_07_7" class="st1" cx="1771" cy="421.2" r="14.5"/>
              `
          },
          {
            legend: "08.06.1625",
            clipath : `
              <circle id="flecken_08_2" class="st1" cx="1486.5" cy="1367.3" r="33.7"/>
              <circle id="flecken_08_3" class="st1" cx="1829" cy="835.3" r="63.1"/>
              <ellipse id="flecken_08_4" transform="matrix(0.3727 -0.9279 0.9279 0.3727 -1014.4675 1165.6665)" class="st1" cx="355" cy="1333.2" rx="58.8" ry="20.1"/>
              <ellipse id="flecken_08_5" transform="matrix(0.4061 -0.9138 0.9138 0.4061 -947.8994 942.6672)" class="st1" cx="251.3" cy="1200.6" rx="47.6" ry="27"/>
              <circle id="flecken_08_6" class="st1" cx="286.7" cy="1082.2" r="73.3"/>
              `
          },
          {
            legend: "09.06.1625",
            clipath : `
              <ellipse id="flecken_09_2" transform="matrix(0.2905 -0.9569 0.9569 0.2905 -760.6743 1152.5469)" class="st1" cx="396.8" cy="1089.2" rx="69.6" ry="43.3"/>
              <circle id="flecken_09_3" class="st1" cx="501.6" cy="1401.9" r="29.4"/>
              <ellipse id="flecken_09_4" transform="matrix(0.6831 -0.7303 0.7303 0.6831 -488.8967 1659.6382)" class="st1" cx="1668" cy="1393.2" rx="35.1" ry="26.2"/>
              <ellipse id="flecken_09_5" class="st1" cx="1971.6" cy="822.9" rx="45.6" ry="47.9"/>
              <circle id="flecken_09_6" class="st1" cx="1788.8" cy="445.5" r="19.3"/>
              `
          },
          {
            legend: "10.06.1625",
            clipath : `
              <circle id="flecken_10_2" class="st1" cx="599" cy="1107.4" r="63.3"/>
              <ellipse id="flecken_10_3" class="st1" cx="475.3" cy="1126.8" rx="50.3" ry="42.8"/>
              <circle id="flecken_10_4" class="st1" cx="666.1" cy="1429.2" r="40.3"/>
              <circle id="flecken_10_5" class="st1" cx="1801.8" cy="1417.2" r="49.8"/>
              <circle id="flecken_10_6" class="st1" cx="1981.3" cy="826" r="52.1"/>
              <circle id="flecken_10_7" class="st1" cx="1788.9" cy="445.2" r="18.4"/>
              `
          },
          {
            legend: "11.06.1625",
            clipath : `
          <ellipse id="flecken_11_2" transform="matrix(0.4496 -0.8932 0.8932 0.4496 -571.4074 1357.4927)" class="st1" cx="815.8" cy="1142.4" rx="62.8" ry="43.7"/>
          <ellipse id="flecken_11_3" transform="matrix(0.2356 -0.9719 0.9719 0.2356 85.6084 2958.5278)" class="st1" cx="1923.5" cy="1424.8" rx="37" ry="21.1"/>
              `
          },
          {
            legend: "12.06.1625",
            clipath : `
          <ellipse id="flecken_12_2" transform="matrix(0.4159 -0.9094 0.9094 0.4159 -476.9048 589.2164)" class="st1" cx="220.3" cy="665.9" rx="88.5" ry="44.8"/>
          <ellipse id="flecken_12_3" transform="matrix(0.5754 -0.8179 0.8179 0.5754 -511.2665 1333.8175)" class="st1" cx="1028.9" cy="1159.3" rx="58.4" ry="44"/>
          <ellipse id="flecken_12_4" transform="matrix(0.5643 -0.8256 0.8256 0.5643 -329.8583 2241.9004)" class="st1" cx="1959.1" cy="1433.5" rx="40.2" ry="23"/>

              `
          },
          {
            legend: "13.06.1625",
            clipath : `
          <circle id="flecken_13_2" class="st1" cx="1255" cy="1189.8" r="65.5"/>
              `
          },
          {
            legend: "14.06.1625",
            clipath : `
          <circle id="flecken_14_2" class="st1" cx="1470.2" cy="1225.1" r="60.6"/>
              `
          },
          {
            legend: "15.06.1625",
            clipath : `
          <circle id="flecken_15_2" class="st1" cx="1661.3" cy="1234.5" r="59.8"/>
              `
          },
          {
            legend: "16.06.1625",
            clipath : `
          <circle id="flecken_16_2" class="st1" cx="1819.6" cy="1259.6" r="62.3"/>
              `
          },
          {
            legend: "17.06.1625",
            clipath : `
          <ellipse id="flecken_17_2" transform="matrix(0.1808 -0.9835 0.9835 0.1808 342.0588 2946.1682)" class="st1" cx="1939.6" cy="1267.7" rx="52" ry="42"/>
              `
          },
          {
            legend: "18.06.1625",
            clipath : `
          <ellipse id="flecken_18_2" transform="matrix(0.1867 -0.9824 0.9824 0.1867 378.2677 3007.8186)" class="st1" cx="2005.8" cy="1275.4" rx="50.4" ry="27.7"/>
              `
          },
          {
            legend: "",
            clipath : ``
          },
        ]
      }
    }
  }
</script>
