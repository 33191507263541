<script>
export default {
  name: 'moonStates',
  data: function () {
    return {
      slides: [
        {
          moonphaseimg: "moonphase-01.png",
          orbitimg: "moonphase-01.png",
          rotateBracket: "0"
        },
        {
          moonphaseimg: "moonphase-02.png",
          orbitimg: "moonphase-01.png",
          rotateBracket: "-30"
        },
        {
          moonphaseimg: "moonphase-03.png",
          orbitimg: "moonphase-01.png",
          rotateBracket: "-60"
        },
        {
          moonphaseimg: "moonphase-04.png",
          orbitimg: "moonphase-04.png",
          rotateBracket: "-90"
        },
        {
          moonphaseimg: "moonphase-05.png",
          orbitimg: "moonphase-01.png",
          rotateBracket: "-120"
        },
        {
          moonphaseimg: "moonphase-06.png",
          orbitimg: "moonphase-01.png",
          rotateBracket: "-150"
        },
        {
          moonphaseimg: "moonphase-07.png",
          orbitimg: "moonphase-01.png",
          rotateBracket: "-180"
        },
        {
          moonphaseimg: "moonphase-08.png",
          orbitimg: "moonphase-01.png",
          rotateBracket: "-210"
        },
        {
          moonphaseimg: "moonphase-09.png",
          orbitimg: "moonphase-01.png",
          rotateBracket: "-240"
        },
        {
          moonphaseimg: "moonphase-10.png",
          orbitimg: "moonphase-10.png",
          rotateBracket: "-270"
        },
        {
          moonphaseimg: "moonphase-11.png",
          orbitimg: "moonphase-01.png",
          rotateBracket: "-300"
        },
        {
          moonphaseimg: "moonphase-12.png",
          orbitimg: "moonphase-01.png",
          rotateBracket: "-330"
        },
        {
          moonphaseimg: "moonphase-13.png",
          orbitimg: "moonphase-01.png",
          rotateBracket: "-360"
        }
      ]
    }
  }
};
</script>
