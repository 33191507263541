<template>
  <ul class="project-nav">
    <li><router-link :to='prevNavUrl' class="project-nav__link" rel="prev"><span class="sicon sicon__arrow-left" aria-hidden="true"></span><span class="visuallyhidden">Project prev</span></router-link></li>
    <li><span class="link-i project-nav__link" @click="$parent.$emit('toggle-detail')"><span class="sicon sicon__icon_information" aria-hidden="true"></span><span class="visuallyhidden">open Detail page</span></span></li>
    <li><router-link :to="nextNavUrl" class="project-nav__link" rel="next"><span class="sicon sicon__arrow-right" aria-hidden="true"></span><span class="visuallyhidden">Project next</span></router-link></li>
  </ul>
</template>

<script>

  import Slides from "../data/planet-slides";
  export default {
    name: 'NavProjects',
    props: {
      currentNavId: {
        type: String,
        required: true
      },
    },
    data() {
      return {
        projectNavs: Slides.data()['Slides']
      }
    },
    methods: {
      setLocale(locale) {
        if(this.$i18n.locale != locale) {
          this.$i18n.locale = locale
          this.$router.push({
            params: { lang: locale }
          });
        }
      },
      findNav: function(val) {
        var calNav = this.curNavIndex + val

        if (calNav < 0) {
          calNav = this.projectNavs.length - 1
        }
        if(calNav > this.projectNavs.length - 1) {
          calNav = 0
        }
        return calNav
      },
      nextNav: function () {
        return this.projectNavs[this.findNav(1)].url
      },
      prevNav: function () {
        return this.projectNavs[this.findNav(-1)].url

      },
    },
    created() {
      this.curNavIndex = this.projectNavs.findIndex(item => item.url == this.currentNavId);
      this.prevNavUrl = this.prevNav()
      this.nextNavUrl = this.nextNav()
    }
  }
</script>
