<template>
  <div class="zoom-atlas-novus native-pinch zoom-page">
    <div class="top-tool-nav-wrap">
      <a @click="$router.go(-1)" class="btn-close-circle" aria-hidden="true"><span class="visuallyhidden">close Zoom View</span></a>
    </div>
    <div class="zoomed-img-wrap" :class="{ 'orbit' : kind == 'orbit'}" :style="'height:' + containerHeightLive + 'px'">
      <div class="atlasnovus-container" :style="'margin-left:' + imageMarginLeftLive + 'px; margin-top:' + imageMarginTopLive + 'px; height:' + imageHeightLive + 'px; width: ' + imageWidthLive + 'px'">
        <div class="loop" v-if="kind == 'loop'">
          <p class="venus--loop">{{ $t('atlas-novus.venus') }}</p>
          <img src="/assets/img/atlas-novus/venus.svg" alt="Venus Loop" class="atlasnovus__venus--loop">
          <svg width="108px" height="1118px" viewBox="0 0 108 1118" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="atlasnovus__path"  :style="'top:' + topPath + 'px; left:' + leftPath +'px;'">
            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round">
              <g transform="translate(-131.000000, -152.000000)" stroke="#FEEEC5" stroke-width="10">
                <g transform="translate(136.000000, 157.000000)">
                  <path d="M77.6014443,1107.1542 C81.9346735,1068.15335 79.7680119,1016.15535 77.6014443,974.983613 C74.9260801,905.020791 65.0765333,838.534212 58.8332964,775.656387 C55.5078867,742.166405 54.462476,714.625228 51.6017873,693.324187 M51.6017873,693.324187 C49.9146012,680.761215 41.0889442,649.867844 39.333906,643.49144 C22.0041846,580.536553 19.1022395,561.15924 8.26902561,563.326372 C-2.56422589,565.492563 -0.397554911,584.992047 1.76912546,600.159148 C3.93580584,615.32531 25.6021303,697.65751 51.6017873,693.324187 Z M51.6017873,693.324187 C59.9875335,685.181937 77.6031359,658.760037 86.2679966,606.658663 C90.6511281,580.305367 95.7372146,507.935769 97.1008346,418.161455 C99.1082036,286.07827 90.6013198,116.998785 82.6661978,0"></path>
                </g>
              </g>
            </g>
          </svg>
        </div>
        <div class="orbit" v-if="kind == 'orbit'">
          <p class="earth--orbit">{{ $t('atlas-novus.earth') }}</p>
          <img src="/assets/img/atlas-novus/earth.svg" alt="Earth Orbit" class="atlasnovus__earth--orbit">
          <p class="venus--orbit">{{ $t('atlas-novus.venus') }}</p>
          <img src="/assets/img/atlas-novus/venus.svg" alt="Venus Orbit" class="atlasnovus__venus--orbit">
          <img src="/assets/img/atlas-novus/beam.svg" alt="Venus Earth Beam" class="atlasnovus__beam">
        </div>
        <img src="/assets/img/atlas-novus/trace-map.png" alt="Atlas Novus Coelestis" class="atlasnovus__image">
      </div>
    </div>
  </div>
</template>

<script>
import venusStates from '../../data/venus';
  export default {
    name: 'zoomAtlasNovusComponent',
    data() {
      return {
        venusSlides:venusStates.data()['slides'],
        layoutWidth: 1444,
        layoutLeftValue: 75,
        layoutTopValue: 56,
        layoutMarginTopValue: 121,
        pathScaleZoom: 1,
        imageHeightLive: 0,
        imageWidthLive: 0,
        imageMarginTopLive: 0,
        imageMarginLeftLive: 0,
        containerHeightLive: 0,
        ratioImageLayoutLive: 1,
      }
    },
    created() {
      this.activeId = this.$route.params.id;
      this.kind = this.$route.params.kind;
    },
    methods: {
        setRatioImageLayoutLive: function (workSize){
          this.ratioImageLayoutLive = workSize / this.layoutWidth;
        },
        setHeightOrbit: function (){
          var imageRatio = 1.174740484429066;
          this.containerHeightLive = window.innerHeight;
          this.imageHeightLive = this.containerHeightLive - this.$el.querySelector('.btn-close-circle').clientHeight - 96;
          this.pathScaleZoom = this.imageHeightLive / 1189;
          this.imageWidthLive = this.imageHeightLive * imageRatio; //ratio image 679/578

          if(this.imageWidthLive > window.innerWidth){
            if(window.innerWidth > 767){
              var gap = 80;
            } else {
              gap = 40;
            }
            this.imageWidthLive = window.innerWidth - gap;
            this.imageHeightLive = this.imageWidthLive / imageRatio;
            this.pathScaleZoom = this.imageHeightLive / 1189;
            this.$el.querySelector('.orbit').classList.add('centered');
          } else {
            this.$el.querySelector('.orbit').classList.remove('centered')
          }

          this.imageMarginLeftLive = (window.innerWidth - this.imageWidthLive) / 2;
          this.setRatioImageLayoutLive(this.imageWidthLive);
        },
        setHeightLoop: function (){
          var setImageHeight = window.innerHeight / 0.884471117779445;
          var pathScaleEl = this.$el.querySelector('.zoomed-img-wrap .atlasnovus__path');
          var getPathHeight = pathScaleEl.clientHeight;
          this.pathScaleZoom = window.innerHeight / getPathHeight;
          this.containerHeightLive = window.innerHeight;
          this.imageHeightLive = this.containerHeightLive / 0.884471117779445;
          this.imageWidthLive = this.imageHeightLive * 1.174740484429066; //ratio image 679/578
          this.imageMarginTopLive =  this.layoutMarginTopValue * this.pathScaleZoom * -1;
          this.imageMarginLeftLive = (window.innerWidth / 2) - document.querySelector('.venus--loop').clientWidth;

          pathScaleEl.style.transform = 'scale(' + this.pathScaleZoom +')';
          pathScaleEl.style.top = setImageHeight * 0.08996539792387543;
          pathScaleEl.style.left = setImageHeight * 0.045;

          this.setRatioImageLayoutLive(this.imageWidthLive);
        },
        setVenusOnLoop: function (){
          var slidesLength = this.venusSlides.length -1;
          var venusEl = document.querySelector('.zoomed-img-wrap .atlasnovus__venus--loop');
          var venusText = document.querySelector('.venus--loop');
          var venusHalfWidth = (venusEl.clientWidth / 2) * this.pathScaleZoom;
          var venusLoopLeft = ((this.venusSlides[this.activeId]).posX - this.layoutLeftValue) * this.ratioImageLayoutLive;
          var venusLoopTop = ((this.venusSlides[this.activeId]).posY - this.layoutTopValue) * this.ratioImageLayoutLive;
          venusEl.style.transform = 'scale(' + this.ratioImageLayoutLive +')';
          venusEl.style.left = venusLoopLeft - venusHalfWidth + 'px';
          venusEl.style.top = venusLoopTop - venusHalfWidth + 'px';
          venusText.style.left = venusLoopLeft - venusHalfWidth - venusText.clientWidth  - 30 + 'px';

          if(slidesLength == this.activeId){
            venusText.style.top = venusLoopTop + venusText.clientHeight / 2 + 'px';
          } else {
            venusText.style.top = venusLoopTop - venusText.clientHeight + 'px';
          }
        },
        setEarthOnOrbit: function (){
          var earthEl = document.querySelector('.zoomed-img-wrap .atlasnovus__earth--orbit');
          var earthText = document.querySelector('.earth--orbit');
          var earthHalfWidth = (earthEl.clientWidth / 2) * this.pathScaleZoom;
          var earthLeft = ((this.venusSlides[this.activeId]).earthPosX - this.layoutLeftValue) * this.ratioImageLayoutLive;
          var earthTop = ((this.venusSlides[this.activeId]).earthPosY - this.layoutTopValue) * this.ratioImageLayoutLive;
          earthEl.style.transform = 'scale(' + this.ratioImageLayoutLive +')';
          earthEl.style.left = earthLeft - earthHalfWidth + 'px';
          earthEl.style.top = earthTop - earthHalfWidth + 'px';

          if(this.activeId < 5){
            earthText.style.top = earthTop - earthText.clientHeight - 20 + 'px';
            earthText.style.left = earthLeft - earthHalfWidth + 'px';
          } else {
            earthText.style.top = earthTop + earthEl.clientHeight - 12 + 'px';
            earthText.style.left = earthLeft - earthHalfWidth  + 'px';
          }
        },
        setVenusOnOrbit: function (){
          var venusEl = document.querySelector('.zoomed-img-wrap .atlasnovus__venus--orbit');
          var venusText = document.querySelector('.venus--orbit');
          var venusHalfWidth = (venusEl.clientWidth / 2) * this.pathScaleZoom;
          var venusLeft = ((this.venusSlides[this.activeId]).venusPosX - this.layoutLeftValue) * this.ratioImageLayoutLive;
          var venusTop = ((this.venusSlides[this.activeId]).venusPosY - this.layoutTopValue) * this.ratioImageLayoutLive;

          venusEl.style.transform = 'scale(' + this.ratioImageLayoutLive +')';
          venusEl.style.left = venusLeft - venusHalfWidth + 'px';
          venusEl.style.top = venusTop - venusHalfWidth + 'px';


          venusText.style.left = venusLeft + 20 + 'px';

          if(this.activeId < 5){
            venusText.style.top = venusTop  - venusText.clientHeight - 20 + 'px';
          } else {
            venusText.style.left = venusLeft + 10 + 'px';
            venusText.style.top = venusTop + venusText.clientHeight + 'px';

            if(this.activeId > 12){
              venusText.style.top = venusTop + venusText.clientHeight - 10 + 'px';
              venusText.style.left = venusLeft + 20 + 'px';
            }

            if(this.activeId > 21){
              venusText.style.left = venusLeft + 10 + 'px';
              venusText.style.top = venusTop + venusText.clientHeight + 'px';
            }
          }
        },
        setBeamPositionAndRotation: function (){
          var beamEl = document.querySelector('.zoomed-img-wrap .atlasnovus__beam');
          var beamLeft = ((this.venusSlides[this.activeId]).earthPosX - this.layoutLeftValue) * this.ratioImageLayoutLive;
          var beamTop = ((this.venusSlides[this.activeId]).earthPosY - this.layoutTopValue) * this.ratioImageLayoutLive;

          if(window.innerWidth < 768){
           var halfEarth = (document.querySelector('.zoomed-img-wrap .atlasnovus__earth--orbit').clientWidth * this.pathScaleZoom) / 2;
            beamTop = beamTop - halfEarth;
          }

          beamEl.style.transform = 'scale(' + this.pathScaleZoom +') rotate(' + (this.venusSlides[this.activeId].beamDeg) + 'deg)';
          beamEl.style.left = beamLeft + 'px';
          beamEl.style.top = beamTop + 'px';
        }
    },
    beforeRouteLeave (to, from, next) {
      document.body.classList.remove('zoomed-opened');
      localStorage.setItem('atlasNovusIndex', this.activeId)
      next();
    },
    mounted() {
      document.body.classList.add('zoomed-opened');
      var _this = this;

      if(this.kind == 'loop'){
        this.setHeightLoop();
        setTimeout(function (){
          _this.setVenusOnLoop();
        },100)
      } else {
        this.setHeightOrbit();
        setTimeout(function (){
          _this.setEarthOnOrbit();
          _this.setVenusOnOrbit();
          _this.setBeamPositionAndRotation();
        },100);
      }

      window.onresize = () => {
        //avoild resize on scroll -> addressbar collapse
        if(this.isMobile && this.windowWidth == window.innerWidth) {
          return false
        }

        if(this.kind == 'loop'){
          this.setHeightLoop();
          setTimeout(function (){
            _this.setVenusOnLoop();
          },100)
        } else {
          this.setHeightOrbit();
          setTimeout(function (){
            _this.setEarthOnOrbit();
            _this.setVenusOnOrbit();
            _this.setBeamPositionAndRotation();
          },100);
        }

      }

    }
  }
</script>
