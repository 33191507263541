<template>
  <div id="harmonia" class="work-page" :class="{'detail-loaded' : isDetailVisible, 'work-page-loaded': isPageLoaded, 'work-library-modal': showModal}" @click="closeWorkShare">
    <div class="work__bg-wrap">
      <div class="work__bg">
        <img src="/assets/img/layout/bg-overview.jpg" class="work__bg-img"  alt="work background image">
      </div>
    </div>
    <div class="detail__bg" style="background-image: url('/assets/img/harmonia/bg-info.jpg')"></div>
    <div class="container container--harmonia">
      <div class="page__top">
        <h1 class="page-top__title">{{ $t('harmonia.title') }}</h1>
        <p class="page-top__text">Harmonia macrocosmica <br />
          (Cellarius, 1708)</p>
      </div>
      <main class="main main--harmonia">
        <div class="work-content work-intro-ani-in">
          <div class="work__scaleable">
            <div class="work__image-wrapper">
              <img src="/assets/img/harmonia/harmonia.jpg" alt="Harmonia Macrocosmica" class="harmonia__image">
              <div v-for="(book, index) of books" :key="index" class="btn__wrapper"  @click="openBook(index)">
                <a href="#" class="icon-circle" aria-hidden="true">
                  <span class="visuallyhidden" v-t="book.name"></span>
                </a>
                <p v-t="book.name"></p>
              </div>
            </div>
          </div>
        </div>
        <detail v-on:show-modal="showModalMsg"></detail>
      </main>
    </div>
    <div class="work__gradient-bottom"></div>
    <menuprimary current-nav="harmonia" :needNavprojects=true  v-on:toggle-detail="toggleDetail"></menuprimary>
    <div class="top-tool-nav-wrap">
      <div @click="toggleDetail" v-if="isDetailVisible" class="btn-close-circle" aria-hidden="true">
        <span class="visuallyhidden">close detail content</span>
      </div>
      <share-section v-if="isDetailVisible"></share-section>
      <router-link to="overview" class="btn-close-circle" aria-hidden="true" v-else>
        <span class="visuallyhidden">go to overview page</span>
      </router-link>
    </div>
    <div class="top__darken"></div>
    <div class="work__gradient-top"></div>
    <modal v-if="showModal" url=" https://www.e-rara.ch/zut/content/zoom/18789599" @close="showModal=false"></modal>
    <transition name="book" v-if="showBook">
      <div class="modal-mask modal__book">
        <div class="modal-wrapper">
          <a class="btn-close-circle" aria-hidden="true"><span class="visuallyhidden">close book</span></a>
          <router-link :to="'zoom-harmonia/'+this.activeSlide" class="zoom-btn">
            <span class="sicon sicon__zoom-more" aria-hidden="true"></span>
            <span class="visuallyhidden">Link zu zoomed img</span>
          </router-link>
          <div class="modal-container">
            <div class="modal-body">
              <slot name="body">
                <div class="book">
                  <div v-for="(book, index) of books" :key="'books'+ index" class="book__page" :class="`book__page-`+index+``">
                    <h2 class="book__page-title" v-t="book.name"></h2>
                    <img :src="'/assets/img/harmonia/' + book.img" :alt="book.name" class="book__image">
                    <div v-for="n of book.info" :key="'info'+ n" class="book__info" :class="`book__info-`+n+``">
                      <a href="#" class="icon-info" aria-hidden="true" @click="openInfo(n)">
                        <span aria-hidden="true" class="sicon sicon__icon_information"></span>
                        <span class="visuallyhidden">additional info</span>
                      </a>
                    </div>
                    <div v-for="n of book.info" :key="n" class="book__info-text" :class="`book__info-text-`+n+``">
                      <div v-html="$t('harmonia.'+book.idText+'.text'+n+'')"></div>
                    </div>
                  </div>
                  <div class="book__controls flex">
                    <a class="icon-circle__prev"  @click="navBooks('prev')" href="#">
                      <span class="sicon sicon__arrow-left" aria-hidden="true"></span><span class="visuallyhidden">Prev</span>
                    </a>
                    <div class="book__legend"></div>
                    <a class="icon-circle__next"  @click="navBooks('next')" href="#">
                      <span class="sicon sicon__arrow-right" aria-hidden="true"></span>
                      <span class="visuallyhidden">Next</span>
                    </a>
                  </div>
                </div>
              </slot>
            </div>
            <div class="modal-footer">
              <slot name="footer">
                <div class="book__info-additional">
                  <div class="book__info-additional-0">
                    <div v-html="$t('harmonia.brahe.textMain')"></div>
                  </div>
                  <div class="book__info-additional-1">
                    <div v-html="$t('harmonia.ptolemaeus.textMain')"></div>
                  </div>
                  <div class="book__info-additional-2">
                    <div v-html="$t('harmonia.copernicus.textMain')"></div>
                  </div>
                </div>
              </slot>
            </div>

          </div>
        </div>

      </div>
    </transition>
  </div>
</template>
<script>
  import iHarmonia from '../info/i-harmonia'
  import harmoniaData from '../../data/harmonia';
  import Modal from '../../components/modal';
  import MenuPrimary from '../../components/menu-primary';
  import Slides from '../../data/planet-slides';
  import ShareSection from '../../components/share';

  import anime from 'animejs';

  export default {
    name: "harmoniaComponent",
    components: {
      'detail': iHarmonia,
      'modal': Modal,
      'menuprimary': MenuPrimary,
      'share-section': ShareSection
    },
    data() {
      return {
        activeSlide: 1,
        activeText:0,
        showModal: false,
        showBook: false,
        isDetailVisible: false,
        books: harmoniaData.data()['books'],
        windowWidth:'',
        rangeWidthLive:'',
        slideWidthLive:'',
        slides: Slides.data()['Slides'],
        workPosLive:'',
        zoomEl:'',
        isPageLoaded: false,
        instAniInterval: null,
        instAniLoadTimer: null,
        pageLoadTimer: null,
        refs: {},
        settings: {
          imagesHRatio: 1324/1600,
          currendPageID: undefined,
          pageAnimationDuration: 1,
          isPageTransitioning: false,
          pagesTitles: [],
          closeTimeout: undefined,
          pageTransitionTimeout: undefined
        }
      }
    },
    created() {
      this.isMobile = this.mobileAndTabletcheck()
    },
    methods: {
      instractionAni: function () {
        var step = 0;
        var _this = this;
        this.instAniInterval = setInterval(frame, 150);

        function frame() {
          if (step == 1 || _this.isDetailVisible) {
            _this.clearIntervalInstAni()
          } else {
            _this.animateWorkImage(1500);

          }
          step++;

        }
      },
      clearIntervalInstAni : function() {
        if(this.instAniInterval) {
          clearInterval(this.instAniInterval);
        }
        if(this.instAniLoadTimer) {
          clearTimeout(this.instAniLoadTimer);
        }
      },
      resizeWorkImage: function (width){
        let getGradientTopHeight = parseFloat(getComputedStyle(this.$el.querySelector('.top__darken')).height);
        let getNavHeight = parseFloat(getComputedStyle(this.$el.querySelector('.nav-bottom')).height);
        let getSpaceHeightImage = window.innerHeight - getGradientTopHeight - getNavHeight;
        let getRatioImageToHeight = getSpaceHeightImage / (width * 0.8025);
        let getNewWidth = width * getRatioImageToHeight;
        let getHeightImage = parseFloat(getComputedStyle(this.$el.querySelector('.work__image-wrapper')).height);
        let getRatioBtn = 1 - (1 - width / 920.561) / 2

        if(getSpaceHeightImage < width * 0.8025){
          this.$el.querySelector('.work__image-wrapper').style.setProperty("width", getNewWidth * 0.7+"px");
          width = getNewWidth;
          getRatioBtn = 1;

          if(window.innerHeight < 420){
            getRatioBtn = 0.6;
          }

        } else {
          this.$el.querySelector('.work__image-wrapper').style.removeProperty("width");
        }

        this.$el.querySelectorAll(".btn__wrapper").forEach(function (btn,index){
          switch (index){
            case 0:
              btn.style.setProperty("left", width * 0.09 +"px");
              btn.style.setProperty("bottom", getHeightImage * (0.28 * getRatioBtn) +"px");
              break;
            case 1:
              btn.style.setProperty("left", width * 0.33 +"px");
              btn.style.setProperty("bottom", getHeightImage * (0.379 * getRatioBtn) +"px");
              break;
            case 2:
              btn.style.setProperty("left", width * 0.67  +"px");
              btn.style.setProperty("bottom", getHeightImage * (0.368 * getRatioBtn) +"px");
              break;
            default:
              break;
          }
        });

        return width;
      },
      animateWorkImage: function (duration){
        let _this = this;

        let element = getComputedStyle(this.$el.querySelector('.work__scaleable'));
        let _width = parseFloat(element.width);
        _width -= parseFloat(element.paddingLeft) + parseFloat(element.paddingRight);

        let widthImage = this.resizeWorkImage(_width);

        anime({
          targets: [_this.$el.querySelector('.work__image-wrapper')],
          width: widthImage,
          duration: duration,
          easing:'easeOutQuart',
        });

        anime({
          targets: [_this.$el.querySelector('.harmonia__image')],
          bottom: 0,
          duration: duration,
          easing:'easeOutQuart',
          complete: function (){
            _this.$el.querySelectorAll('.btn__wrapper').forEach((item) => {
              item.classList.add("animate")
            });
          }
        });
      },
      showModalMsg : function() {
        this.showModal = true
      },
      setPages: function(pageID, animate, rotateL, rotateR, shadowL, shadowR) {
        const pL = this.refs.pages[pageID].querySelector('.book__page--left');
        const pR = this.refs.pages[pageID].querySelector('.book__page--right');

        if (animate == false){
          this.refs.pages[pageID].classList.add('no-transition');
        } else {
          this.refs.pages[pageID].classList.remove('no-transition');
        }

        if (shadowL) {
          pL.classList.add('shadowed');
        } else {
          pL.classList.remove('shadowed');
        }
        if (shadowR) {
          pR.classList.add('shadowed');
        } else {
          pR.classList.remove('shadowed');
        }

        this.refs.pages[pageID].style.setProperty('--rl', rotateL+'deg');
        this.refs.pages[pageID].style.setProperty('--rr', rotateR+'deg');

      },
      setBubblePosition: function (_this,width,height){
        _this.$el.querySelectorAll(".book__page-0 .book__info-text").forEach(function (info,index){
          let getInfoHeight = parseFloat(getComputedStyle(info).height) + 4;
          switch (index){
            case 0:
              info.style.setProperty('left', width * 0.38 - 99  + 'px')
              info.style.setProperty('top', height * 0.18 - getInfoHeight  + 'px')
              break;
            case 1:
              info.style.setProperty('left', width * 0.4967 - 99  + 'px')
              info.style.setProperty('top', height * 0.2879 - getInfoHeight  + 'px')
              break;
            case 2:
              info.style.setProperty('left', width * 0.4967 - 99  + 'px')
              info.style.setProperty('top', height * 0.44 - getInfoHeight  + 'px')
              break;
            default:
              break;
          }
        });

        _this.$el.querySelectorAll(".book__page-1 .book__info-text").forEach(function (info,index){
          let getInfoHeight = parseFloat(getComputedStyle(info).height) + 4;
          switch (index){
            case 0:
              info.style.setProperty('left', width * 0.4967 - 99  + 'px')
              info.style.setProperty('top', height * 0.1618 - getInfoHeight  + 'px')
              break;
            case 1:
              info.style.setProperty('left', width * 0.4967 - 99  + 'px')
              info.style.setProperty('top', height * 0.464 - getInfoHeight  + 'px')
              break;
            case 2:
              info.style.setProperty('left', width * 0.59 - 99  + 'px')
              info.style.setProperty('top', height * 0.88 - getInfoHeight  + 'px')
              break;
            default:
              break;
          }
        });

        _this.$el.querySelectorAll(".book__page-2 .book__info-text").forEach(function (info,index){
          let getInfoHeight = parseFloat(getComputedStyle(info).height) + 4;
          switch (index){
            case 0:
              info.style.setProperty('left', width * 0.5 - 99  + 'px')
              info.style.setProperty('top', height * 0.46 - getInfoHeight  + 'px')

              break;
            case 1:
              info.style.setProperty('left', width * 0.5 - 99  + 'px')
              info.style.setProperty('top', height * 0.23 - getInfoHeight  + 'px')
              break;
            case 2:
              info.style.setProperty('left', width * 0.66 - 99  + 'px')
              info.style.setProperty('top', height * 0.44 - getInfoHeight  + 'px')
              break;
            default:
              break;
          }
        });
      },
      resizeBooks:(_this) => {
        let transformYLegend = 1.4;
        let padding = 50;

        if(window.innerWidth < 321){
          transformYLegend = 1.1;
        }

        if(window.innerWidth < 767 && window.innerHeight > 800){
          padding = 100;
        }

        if(window.innerWidth < 767 && window.innerHeight < 800){
          padding = 25;
        }

        if(window.innerWidth > 1000 && window.innerHeight < 900){
          padding = 80;

          if(window.innerHeight < 785){
            padding = 90;
          }
        }

        let getButtonOffsetTop = parseFloat(getComputedStyle(document.getElementsByClassName("btn-close-circle")[1]).top);
        let getButtonHeight = parseFloat(getComputedStyle(document.getElementsByClassName("btn-close-circle")[1]).height);
        let getBookControlHeight = parseFloat(getComputedStyle(document.getElementsByClassName("book__controls")[0]).height);
        let getUsedHeight = _this.refs.book.offsetWidth*_this.settings.imagesHRatio + getButtonOffsetTop + getButtonHeight + (getBookControlHeight * transformYLegend);
        let getInfoTextParagraph = _this.$el.querySelector('.book__info-additional-'+_this.activeSlide+'').childNodes[0].childNodes[0];
        let modalFooterHeight = parseFloat(getComputedStyle(getInfoTextParagraph).height) + parseFloat(getComputedStyle(getInfoTextParagraph).marginBottom);
        let getNewBookHeight = window.innerHeight  - getButtonOffsetTop - getButtonHeight - (getBookControlHeight * transformYLegend) - modalFooterHeight - padding;
        let getNewBookWidth = getNewBookHeight / _this.settings.imagesHRatio;
        let getNewMarginLeft = (getNewBookWidth - window.innerWidth) / 2;

        if(window.innerHeight - getUsedHeight < modalFooterHeight + 40){
          //if the unused height is too small for the additional info, we overwrite the book height
          _this.refs.book.style.setProperty('--s-height', getNewBookHeight + 'px');
          _this.refs.book.style.setProperty('width', getNewBookWidth + 'px');
          _this.setBubblePosition(_this,getNewBookWidth,getNewBookHeight);
        } else {

          _this.refs.book.style.setProperty('--s-height', _this.refs.book.offsetWidth*_this.settings.imagesHRatio + 'px');

          //for mobile we want to have a bigger book image, so we count the unused Height get the percentage
          // of the whole page height and set a new height for the book, we also need to overwrite the width
          // and add a margin-left to align the book

          if(window.innerWidth < 767){
            _this.setBubblePosition(_this,getNewBookWidth,getNewBookHeight);

            _this.refs.book.style.setProperty('--s-height', getNewBookHeight + 'px')
            _this.refs.book.style.setProperty('width', getNewBookWidth + 'px')
            _this.refs.pages.forEach((item) => {
              item.style.setProperty('margin-left', '-'+ getNewMarginLeft +'px');
            });

            document.getElementsByClassName("book__controls")[0].style.setProperty('margin-left', '-'+ getNewMarginLeft +'px');
          }
        }
      },
      initBook: function (){
        this.settings.pageAnimationDuration = getComputedStyle(this.refs.book).getPropertyValue('--d-page').split('s')[0] * 1000;

        this.refs.pages.forEach((item) => {
          this.settings.pagesTitles.push(item.querySelector('.book__page-title').innerHTML);

          item.style.setProperty('--img', 'url(' + item.querySelector('img').src + ')');

          const pageL = document.createElement('div');
          pageL.classList.add('book__page--left');
          item.appendChild(pageL);
          pageL.style.setProperty('background-image','url(' + item.querySelector('img').src + ')')

          const pageR = document.createElement('div');
          pageR.classList.add('book__page--right');
          item.appendChild(pageR)
          pageR.style.setProperty('background-image','url(' + item.querySelector('img').src + ')')

        });
      },
      setHeightAdditionalInfo: function (self, selector){
        let getInfoTextParagraph = self.$el.querySelector(selector).childNodes[0].childNodes[0];
        let getHeightInfoTextParagraph = parseFloat(getComputedStyle(getInfoTextParagraph).height) + parseFloat(getComputedStyle(getInfoTextParagraph).marginBottom)

        self.$el.querySelector('.book__info-additional').style.height = getHeightInfoTextParagraph + 'px';
      },
      openBook: function (book){
        var _this = this;

        this.showBook = true;

        const pageID = book;
        this.activeSlide = pageID;

        localStorage.setItem('harmoniaIndex', '')

        setTimeout(function (){
          _this.refs = {  //replace with vuejs refs: $refs.xx -> this.$refs.xx
            book: _this.$el.querySelector('.book'),
            pages: _this.$el.querySelectorAll('.book__page'),
            navLinks: _this.$el.querySelectorAll('.bt-paginate'),
            legend: _this.$el.querySelector('.book__legend'),
            info: _this.$el.querySelectorAll('.book__info'),
          };

          document.querySelector('.modal__book').addEventListener('click',function(event) {
            _this.closeBook(event);
          });

          _this.initBook();
          _this.resizeBooks(_this);

          _this.refs.book.classList.add('displayed');

          _this.refs.legend.innerHTML = _this.settings.pagesTitles[pageID];
          _this.refs.legend.classList.add('active');

          if (_this.settings.closeTimeout) {
            clearTimeout(_this.settings.closeTimeout);
            _this.deactivePage(_this.settings.currendPageID);
          }

          _this.refs.pages[pageID].classList.add('displayed');

          _this.setPages(pageID, false, 90, -90, true, true);

          let selectorText = '.book__info-additional-'+book+'';

          _this.setHeightAdditionalInfo(_this,selectorText);

          anime({
            targets: '.modal__book',
            opacity: 1,
            easing: 'easeOutQuad',
            duration: 350,
            complete: function (){
              anime({
                targets: selectorText,
                opacity: 1,
                easing: 'easeOutQuad',
                duration: 350,
                delay: 1500,
              });

              setTimeout(() => {
                _this.setPages(pageID, true, 0, 0, false, false);
              }, 500);

              setTimeout(function (){
                _this.refs.info.forEach((info) => {
                  if(info.parentNode.classList[2] == 'displayed'){
                    info.classList.add("animate");
                  }
                });
              },1500)

              _this.refs.book.addEventListener('click',function(event) {
                if(event.target.classList[0] == "book__page--right" || event.target.classList[0] == "book__page--left"){
                  _this.$el.querySelectorAll(".book__page.displayed .book__info").forEach(function (info){
                    info.classList.remove("active");
                  });

                  _this.$el.querySelectorAll(".book__page.displayed .book__info-text").forEach(function (info){
                    info.classList.remove("active");
                  });
                }
              });
            }
          });

          _this.settings.currendPageID = pageID;

        },100)
      },
      closeBook: function (event){
        if(event.target.classList[0] == 'btn-close-circle' || event.target.classList[0] == 'modal-body' || event.target.classList[0] == 'modal-wrapper'){
          this.refs.book.classList.remove('displayed');
          this.refs.pages[this.settings.currendPageID].classList.remove('displayed');

          this.setPages(this.settings.currendPageID, true, 90, -90, true, true);

          anime({
            targets: '.book__info-additional',
            opacity: 0,
            easing: 'easeOutQuad',
            duration: 350,
          });

          this.settings.closeTimeout = setTimeout(() => {
            this.deactivePage(this.settings.currendPageID);
            this.settings.closeTimeout = undefined;
            this.$el.querySelectorAll(".book__page .book__info").forEach(function (info){
              info.classList.remove("active");
              info.classList.remove("animate");
            });

            [this.$el.querySelector('.main--harmonia')][0].classList.remove("no-transition");
          }, 50);
        }
      },
      deactivePage: function(pageID) {
        this.refs.pages[pageID].classList.remove('displayed');
        this.showBook = false;
      },
      navBooks: function (direction){
        let newID;
        let selectorText = '.book__info-additional-'+this.activeSlide+'';

        this.$el.querySelectorAll(".book__page .book__info").forEach(function (info){
          info.classList.remove("active");
          info.classList.remove("animate");
        });

        this.$el.querySelectorAll(".book__page .book__info-text").forEach(function (info){
          info.classList.remove("active");
        });

        anime({
          targets: selectorText,
          opacity: 0,
          easing: 'easeOutQuad',
          duration: 350,
        });

        if (this.settings.isPageTransitioning == false) {
          this.settings.isPageTransitioning = true;


          if (direction == 'next') {
            newID = (this.settings.currendPageID < this.refs.pages.length - 1 ) ? this.settings.currendPageID + 1 : 0 ;

          } else {
            newID = (this.settings.currendPageID != 0 ) ? this.settings.currendPageID - 1 : this.refs.pages.length - 1 ;
          }

          this.activeSlide = newID;


          let selectorTextNext = '.book__info-additional-'+newID+'';

          this.refs.legend.classList.remove('active');

          this.refs.legend.classList.add('hiddenLegend');

          this.refs.pages[this.settings.currendPageID].zIndex = 0;
          this.refs.pages[this.settings.currendPageID].classList.remove('displayed');

          this.setHeightAdditionalInfo(this,selectorTextNext);

          anime({
            targets: selectorTextNext,
            opacity: 1,
            easing: 'easeOutQuad',
            duration: 350,
            delay:1800,
          });


          setTimeout(() => {
            this.refs.pages[newID].classList.add('displayed');
            this.refs.pages[newID].zIndex = 1;

            this.setPages(newID, false, 90, -90, true, true);

            setTimeout(() => {
              this.setPages(newID, true, 0, 0, false, false);
            }, 150);

            var _this = this;

            setTimeout(function (){
              _this.refs.info.forEach((info) => {
                if(info.parentNode.classList[2] == 'displayed') {
                  info.classList.add("animate");
                }
              });
            },1500)

          }, 250);

          this.settings.currendPageID = newID;

          this.settings.pageTransitionTimeout = setTimeout(() => {
            this. refs.legend.innerHTML = this.settings.pagesTitles[newID];
            this.refs.legend.classList.remove('hiddenLegend');
            this.refs.legend.classList.add('active');

            this.settings.isPageTransitioning = false;
          }, this.settings.pageAnimationDuration);
        }
      },
      openInfo: function (index){
        //here we remove all active classes and set an active class on the info which should be displayed
        this.$el.querySelectorAll(".book__page.displayed .book__info").forEach(function (info){
          info.classList.remove("active");
        });

        this.$el.querySelectorAll(".book__page.displayed .book__info-text").forEach(function (info){
          info.classList.remove("active");
        });

        this.$el.querySelectorAll(".book__page.displayed .book__info")[index - 1].classList.add("active");
        this.$el.querySelectorAll(".book__page.displayed .book__info-text")[index - 1].classList.add("active");

      },
      ipadPortraitPositionImage: function (){
        //on ipad there is a problem with the viewport positioning so we overwrite it with javascript
        document.getElementsByClassName("work__scaleable")[0].style.removeProperty("bottom");
        document.getElementsByClassName("work-content")[0].style.removeProperty("height");

        if(window.innerWidth > 767 && window.innerWidth < 1023 && window.innerHeight < 1024){
          let getHeightForBottom = window.innerHeight * 0.05;
          let getWorkScaleableEl = document.getElementsByClassName("work__scaleable")[0];
          document.getElementsByClassName("work-content")[0].style.setProperty("height", window.innerHeight - 70 + "px");


          if(-1 * parseFloat(getComputedStyle(getWorkScaleableEl).bottom) > getHeightForBottom){
            document.getElementsByClassName("work__scaleable")[0].style.bottom = "-" + getHeightForBottom +"px";
          }
        }
      },
      toggleDetail:function () {
        if(!this.isDetailVisible) {
          // eslint-disable-next-line no-undef
          gtag('config', this.uaId, {'page_path': this.$route.path + '-i-detail'});
        } else {
          // eslint-disable-next-line no-undef
          gtag('config', this.uaId, {'page_path': this.$route.path });

          var audio = this.$el.querySelectorAll('audio')[0];
          if(!audio.paused){
            audio.pause();
          }
        }

        this.isDetailVisible =  !this.isDetailVisible
        var _scale = 1;
        var _marginBottom = 0;
        var _opacity = 1;
        var workHTo =  window.innerHeight - this.$el.querySelector('.nav-bottom').clientHeight;
        var delay = 0;



        if(parseFloat(getComputedStyle(this.$el.querySelector('.harmonia__image')).bottom) != 0){
          this.$el.querySelector('.harmonia__image').style.setProperty("bottom",0);
        }

        if(this.isDetailVisible) {
          document.body.classList.add('detail-opened');
          _opacity = 0;
          _marginBottom = "20px";
          this.resetWorkZoom();

          workHTo = workHTo * 0.6;
          _scale = 0.3;


          if(window.innerWidth < 421){
            _scale = 0.25;
          }

        } else {
          document.body.classList.remove('detail-opened');
          delay = 100;

          if(typeof this.$el.querySelector('.btn__wrapper').classList[1] === 'undefined'){
            this.animateWorkImage(0.001);
          }

          this.$el.querySelectorAll(".btn__wrapper").forEach(function (btn){
            btn.style.removeProperty("left");
            btn.style.removeProperty("bottom");
          });

          this.animateWorkImage(0.001);
          this.ipadPortraitPositionImage();
        }

        var getPageTopHeight = this.$el.querySelector('.page__top').clientHeight - (parseFloat(getComputedStyle(this.$el.querySelector('.page-top__text')).marginBottom) / 2);
        var getUnusedHeight = workHTo - getPageTopHeight;
        var getImageHeight = this.$el.querySelector('.harmonia__image').clientHeight * _scale;
        var ratioImageUnusedHeight = getUnusedHeight / getImageHeight;
        if(getUnusedHeight < getImageHeight) {
          _scale = _scale * ratioImageUnusedHeight;
        }


        anime({
          targets: [this.$el.querySelectorAll('.work__scaleable')],
          scale: _scale,
          duration: 500,
          easing:'easeOutQuart'
        });

        anime({
          targets: [this.$el.querySelector('.work-content')],
          height: workHTo,
          marginBottom: _marginBottom,
          duration: 500,
          easing:'easeOutQuart',
        });


        anime({
          targets: [this.$el.querySelector('.top__darken')],
          opacity: _opacity,
          duration: 500,
          easing:'easeOutQuart',
          delay: delay
        });

        anime({
          targets: [this.$el.querySelector('.work__gradient-top')],
          opacity: _opacity,
          duration: 500,
          easing:'easeOutQuart',
          delay: delay
        });

      },
      resetWorkZoom : function() {
        var transform = "";
        this.zoomEl.style.transform = transform;
        this.zoomEl.style.webkitTransform = transform;
      },
      pageLoad : function() {
        this.isPageLoaded = true
        var _this = this
        this.pageLoadTimer = setTimeout(function () {
          _this.$el.querySelector(".work-intro-ani-in").classList.remove('work-intro-ani-in')
        }, 1600)
      },
    },
    beforeRouteLeave (to, from, next) {
      this.clearIntervalInstAni()
      if(this.pageLoadTimer) {
        clearTimeout(this.pageLoadTimer);
      }
      document.body.classList.remove('detail-opened');
      localStorage.setItem('harmoniaIndex', '')
      this.aniMenuPageLeave(next());
    },
    mounted() {
      var preloadImages = [
        '/assets/img/harmonia/harmonia.jpg',
        '/assets/img/harmonia/image_brahe.png',
        '/assets/img/harmonia/image_copernicus.png',
        '/assets/img/harmonia/image_ptolemaus.png',
      ];
      preloadImages.forEach((f) => {
        const Http = new XMLHttpRequest();
        Http.open("GET", f);
        Http.send();
      })

      let element = getComputedStyle(this.$el.querySelector('.work__scaleable'));
      let _width = parseFloat(element.width);
      _width -= parseFloat(element.paddingLeft) + parseFloat(element.paddingRight);

      this.saveCurrentPlanetToLocalStorage();
      this.zoomEl = this.$el.querySelector(".work__scaleable");
      this.windowWidth = window.innerWidth

      this.pageLoad();
      this.setWorkBgPos();

      this.ipadPortraitPositionImage();

      if(localStorage.getItem('harmoniaIndex')) {
        this.activeSlide = localStorage.getItem('harmoniaIndex');
        this.openBook(parseInt(localStorage.getItem('harmoniaIndex')));
        this.resizeWorkImage(_width);

        anime({
          targets: '.harmonia__image',
          opacity: 1,
          duration: 5,
          easing:'easeOutQuart',
        });
        this.animateWorkImage(1);
      } else {
        if(!this.isDetailVisible){
          document.body.classList.remove('detail-opened');
        }

        this.resizeWorkImage(_width);

        setTimeout(function (){
          anime({
            targets: '.harmonia__image',
            opacity: 1,
            duration: 500,
            easing:'linear',
          });
        },500)
        this.instAniLoadTimer = setTimeout(this.instractionAni,2000)
      }

      window.onresize = () => {
        if(this.isMobile && this.windowWidth == window.innerWidth) {
          return false
        }

        if(!this.isDetailVisible){
          this.$el.querySelectorAll(".btn__wrapper").forEach(function (btn){
            btn.style.removeProperty("left");
            btn.style.removeProperty("bottom");
          });

          this.animateWorkImage(0.001);
          this.ipadPortraitPositionImage();
        } else {

          let workHTo =  (window.innerHeight - this.$el.querySelector('.nav-bottom').clientHeight) * 0.6;
          let element = getComputedStyle(this.$el.querySelector('.work__scaleable'));
          let _width = parseFloat(element.width);
          _width -= parseFloat(element.paddingLeft) + parseFloat(element.paddingRight);


          this.$el.querySelectorAll('.work__image-wrapper')[0].style.setProperty("width",_width+"px");
          this.$el.querySelectorAll('.work-content')[0].style.setProperty("height",workHTo +"px");


        }

        this.windowWidth = window.innerWidth;

        if(this.showBook){
          let _this = this;

          this.refs.book.style.removeProperty('--s-height');
          this.refs.book.style.removeProperty('width');
          this.refs.pages.forEach((item) => {
            item.style.removeProperty('margin-left');
          });

          _this.setHeightAdditionalInfo(_this,'.book__info-additional-'+this.activeSlide+'');

          document.getElementsByClassName("book__controls")[0].style.removeProperty('margin-left');

          setTimeout(function (){
            _this.resizeBooks(_this);
          },150)
        }
      }
    }
  }
</script>

