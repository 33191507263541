<script>
  export default {
    name: 'saturnium',
    data: function (){
      return {
        slides : [
          {
            stepId: "1",
            legend: "25. März 1655",
            image: "saturnium-01.jpg",
            zoomImage: "saturnium-01-zoom.jpg",
            link: "https://www.e-rara.ch/zut/content/zoom/965555"
          },
          {
            stepId: "2",
            legend: "26. März 1655",
            image: "saturnium-02.jpg",
            zoomImage: "saturnium-02-zoom.jpg",
            link: "https://www.e-rara.ch/zut/content/zoom/965557"
          },
          {
            stepId: "3",
            legend: "27. März 1655",
            image: "saturnium-03.jpg",
            zoomImage: "saturnium-03-zoom.jpg",
            link: "https://www.e-rara.ch/zut/content/zoom/965558"
          },
          {
            stepId: "4",
            legend: "03. April 1655",
            image: "saturnium-04.jpg",
            zoomImage: "saturnium-04-zoom.jpg",
            link: "https://www.e-rara.ch/zut/content/zoom/965558"
          },
          {
            stepId: "5",
            legend: "09. April 1655",
            image: "saturnium-05.jpg",
            zoomImage: "saturnium-05-zoom.jpg",
            link: "https://www.e-rara.ch/zut/content/zoom/965558"
          },
          {
            stepId: "6",
            legend: "10. April 1655",
            image: "saturnium-06.jpg",
            zoomImage: "saturnium-06-zoom.jpg",
            link: "https://www.e-rara.ch/zut/content/zoom/965558"
          },
          {
            stepId: "7",
            legend: "11. April 1655",
            image: "saturnium-07.jpg",
            zoomImage: "saturnium-07-zoom.jpg",
            link: "https://www.e-rara.ch/zut/content/zoom/965559"
          },
          {
            stepId: "8",
            legend: "12. April 1655",
            image: "saturnium-08.jpg",
            zoomImage: "saturnium-08-zoom.jpg",
            link: "https://www.e-rara.ch/zut/content/zoom/965559"
          },
          {
            stepId: "9",
            legend: "13. April 1655",
            image: "saturnium-09.jpg",
            zoomImage: "saturnium-09-zoom.jpg",
            link: "https://www.e-rara.ch/zut/content/zoom/965559"
          },
          {
            stepId: "10",
            legend: "17. April 1655",
            image: "saturnium-10.jpg",
            zoomImage: "saturnium-10-zoom.jpg",
            link: "https://www.e-rara.ch/zut/content/zoom/965559"
          },
          {
            stepId: "11",
            legend: "19. April 1655",
            image: "saturnium-11.jpg",
            zoomImage: "saturnium-11-zoom.jpg",
            link: "https://www.e-rara.ch/zut/content/zoom/965559"
          },
          {
            stepId: "12",
            legend: "20. April 1655",
            image: "saturnium-12.jpg",
            zoomImage: "saturnium-12-zoom.jpg",
            link: "https://www.e-rara.ch/zut/content/zoom/965560"
          },
          {
            stepId: "13",
            legend: "21. April 1655",
            image: "saturnium-13.jpg",
            zoomImage: "saturnium-13-zoom.jpg",
            link: "https://www.e-rara.ch/zut/content/zoom/965560"
          },
          {
            stepId: "14",
            legend: "29. April 1655",
            image: "saturnium-14.jpg",
            zoomImage: "saturnium-14-zoom.jpg",
            link: "https://www.e-rara.ch/zut/content/zoom/965560"
          },
          {
            stepId: "15",
            legend: "03. Mai 1655",
            image: "saturnium-15.jpg",
            zoomImage: "saturnium-15-zoom.jpg",
            link: "https://www.e-rara.ch/zut/content/zoom/965560"
          },
          {
            stepId: "16",
            legend: "06. Mai 1655",
            image: "saturnium-16.jpg",
            zoomImage: "saturnium-16-zoom.jpg",
            link: "https://www.e-rara.ch/zut/content/zoom/965560"
          },
          {
            stepId: "17",
            legend: "07. Mai 1655",
            image: "saturnium-17.jpg",
            zoomImage: "saturnium-17-zoom.jpg",
            link: "https://www.e-rara.ch/zut/content/zoom/965560"
          },
          {
            stepId: "18",
            legend: "10. Mai 1655",
            image: "saturnium-18.jpg",
            zoomImage: "saturnium-18-zoom.jpg",
            link: "https://www.e-rara.ch/zut/content/zoom/965560"
          },
          {
            stepId: "19",
            legend: "11. Mai 1655",
            image: "saturnium-19.jpg",
            zoomImage: "saturnium-19-zoom.jpg",
            link: "https://www.e-rara.ch/zut/content/zoom/965560"
          },
          {
            stepId: "20",
            legend: "12. Mai 1655",
            image: "saturnium-20.jpg",
            zoomImage: "saturnium-20-zoom.jpg",
            link: "https://www.e-rara.ch/zut/content/zoom/965560"
          },
          {
            stepId: "21",
            legend: "14. Mai 1655",
            image: "saturnium-21.jpg",
            zoomImage: "saturnium-21-zoom.jpg",
            link: "https://www.e-rara.ch/zut/content/zoom/965560"
          },
          {
            stepId: "22",
            legend: "15. Mai 1655",
            image: "saturnium-22.jpg",
            zoomImage: "saturnium-22-zoom.jpg",
            link: "https://www.e-rara.ch/zut/content/zoom/965560"
          },
          {
            stepId: "23",
            legend: "18. Mai 1655",
            image: "saturnium-23.jpg",
            zoomImage: "saturnium-23-zoom.jpg",
            link: "https://www.e-rara.ch/zut/content/zoom/965561"
          },
          {
            stepId: "24",
            legend: "19. Mai 1655",
            image: "saturnium-24.jpg",
            zoomImage: "saturnium-24-zoom.jpg",
            link: "https://www.e-rara.ch/zut/content/zoom/965561"
          },
          {
            stepId: "25",
            legend: "20. Mai 1655",
            image: "saturnium-25.jpg",
            zoomImage: "saturnium-25-zoom.jpg",
            link: "https://www.e-rara.ch/zut/content/zoom/965561"
          }
        ]
      }
    }
  }
</script>
