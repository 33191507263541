<template>
  <transition name="modal">
    <div class="modal-mask" @click="$emit('close')">
      <div class="modal-wrapper">
        <a class="btn-close btn-close-modal" @click="$emit('close')" aria-hidden="true"><span class="visuallyhidden">close modal</span></a>
        <div class="modal-container" @click="jump">

          <div class="modal-header">
            <slot name="header">
              {{ $t('View Book on e-rara?') }}
            </slot>
          </div>

          <div class="modal-body">
            <slot name="body">
              <p>{{ $t('Sie werden auf e-rara.ch weitergeleitet – die Plattform für digitalisierte seltene Drucke.') }}</p>
            </slot>
          </div>

          <div class="modal-footer">
            <slot name="footer">
              <a :href="url" target="_blank">{{ $t('Go to www.e-rara.ch') }}<span class="sicon sicon__external-link" aria-hidden="true"></span></a>
            </slot>
          </div>
        </div>
      </div>

    </div>
  </transition>
</template>

<script>
  export default {
   name: 'Modal',
    props: {
      url: String
    },
    methods: {
      jump: function () {
        window.open(this.url, '_blank');
      },
    },
  }

  // https://vuejs.org/v2/examples/modal.html
</script>

