<template>
  <div id="uranometria" class="work-page" :class="{ 'detail-loaded' : isDetailVisible, 'work-page-loaded': isPageLoaded, 'work-library-modal': showModal }" @click="closeWorkShare">
    <div class="detail__bg" style="background-image: url('/assets/img/uranometria/bg-info.jpg')"></div>
    <div class="container" ref="container">
      <div class="page__top">
        <h1 class="page-top__title">{{ $t('Tierkreiszeichen') }}</h1>
        <p class="page-top__text">Uranometria <br />(Bayer, 1603)</p>
      </div>
      <main class="main main--uranometria">
        <div class="work-content work-intro-ani-in">
          <div id="webgl" class="work-3d"></div>
            <div class="range-interact-wrap" :style="'height:'+ rangeWidthLive +'px; width:'+ rangeWidthLive +'px'">
              <div class="range-slider">
                <div class="range-slider__handle-track" :style="'transform: rotate(' + currentDeg + 'deg)'" ref="handle-track">
                  <div class="range-slider__handle handle--interactive">
                    <span class="sicon sicon__slide-handle" aria-hidden="true"></span>
                    <span class="visuallyhidden">Slide Handle</span>
                  </div>
                </div>
                <div class="range-slider__track">
                  <svg width="376px" height="376px" viewBox="0 0 376 376" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <defs>
                      <rect id="path-1" x="-3.81469727e-06" y="-3.81469727e-06" width="271" height="92"></rect>
                    </defs>
                    <g id="Artboard" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" opacity="0.300000012">
                      <g id="Oval" transform="translate(52.000000, 289.000000)">
                        <mask id="mask-2" fill="white">
                          <use xlink:href="#path-1"></use>
                        </mask>
                        <g id="Mask"></g>
                        <circle stroke="#FFFFFF" mask="url(#mask-2)" cx="136" cy="-102" r="188"></circle>
                      </g>
                    </g>
                  </svg>
                </div>
              </div>
            </div>
        </div>
        <detail v-on:show-modal="showModalMsg"></detail>
      </main>
    </div>
    <menuprimary current-nav="uranometria" :needNavprojects=true  v-on:toggle-detail="toggleDetail"></menuprimary>
    <div class="top-tool-nav-wrap" :class="{'hidden': showSlide === true }">
      <div @click="toggleDetail" v-if="isDetailVisible" class="btn-close-circle" aria-hidden="true">
        <span class="visuallyhidden">close detail content</span>
      </div>
      <share-section v-if="isDetailVisible"></share-section>
      <router-link to="overview" class="btn-close-circle" aria-hidden="true" v-else>
        <span class="visuallyhidden">go to overview page</span>
      </router-link>
    </div>
    <modal v-if="showModal" url="https://www.e-rara.ch/zut/content/titleinfo/77412" @close="showModal=false"></modal>
    <transition v-if="showSlide" name="slideUranometria">
      <div class="modal-mask modalSlide">
        <div class="modal-wrapper">
          <a class="btn-close-circle btn-close__bg" aria-hidden="true"><span class="visuallyhidden">close modal</span></a>
          <div class="modal-container modal-container-slide">
            <div class="modal-body">
              <slot name="body">
                <div class="slider-wrapper splide">
                  <div class="splide__track">
                    <div class="splide__list">
                      <div v-for="(slide, index) of uranometriaSlides" :key="index" class="slider-item splide__slide" :slide-id="slide.id">
                        <img :src="'/assets/img/uranometria/details/' + slide.image" :alt="slide.name">
                        <div class="flex">
                          <p v-t="slide.name"></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </slot>
            </div>

          </div>
        </div>

      </div>
    </transition>
  </div>
</template>
<script>
  import iUranometria from '../info/i-uranometria'
  import uranometria from '../../data/uranometria';
  import Modal from '../../components/modal';
  import MenuPrimary from '../../components/menu-primary';
  import Slides from '../../data/planet-slides';
  import ShareSection from '../../components/share';

  import Hammer from '../../assets/js/hammer.min';
  import anime from 'animejs';

  export default {
    name: "uranometriaComponent",
    components: {
      'detail': iUranometria,
      'modal': Modal,
      'menuprimary': MenuPrimary,
      'share-section': ShareSection
    },
    data() {
      return {
        uranometriaSlides: uranometria.data()['slides'],
        activeSlide: 0,
        bg: false,
        currentDeg : '',
        showModal: false,
        showSlide: false,
        isDetailVisible: false,
        windowWidth: window.innerWidth,
        rangeWidthLive:0,
        workHeightLive:0,
        slides: Slides.data()['Slides'],
        slider: null,
        zoomEl:'',
        mc:'',
        mcGlobe: '',
        pinchTimmer: 0,
        isPageLoaded: false,
        pageLoadTimer: null,
        pageTop:'',
        degRange : [45,135],
        imagesBaseUrl:'/assets/img/uranometria/',
        thumbsBaseURL: 'thumbs/',
        sphere: null,
        canvaSize: null,
        camera: null,
        scene: null,
        webglEl: null,
        thumbsContainer:null,
        stars: null,
        RAF: null,
        RAFRANG: null,
        container: null,
        renderer: null,
        windowW: 0,
        windowH: 0,
        windowR: 0,
        generalScale: 1,
        thumbsDir: [],
        thumbsUrls: uranometria.data()['thumbs'],
        earthSettings: {
          radius: 0.25,
          radiusStars: 2,
          segments: 32,
          segmentsStars: 32,
          rotation: {
            x: 0,
            y: 250 * Math.PI/180,
            z: 0
          },
          currrentRotation: {
            x:0,
            y:0,
            z:0
          },
          rotationStars: {
            x: 135 * Math.PI/180,
            y: 0,
            z: 0
          },
          continuousRotation: 0.001,
          renderedDimension: 0,
          currentRotationDeg: 0,
          currentRotationPercent: 0
        },
        thumbsSettings: {
          radius: 0.4,
          scale: 0.16,
          angleStep: (2 * Math.PI) / 12,
          rotation: {
            x: 0,
            y: 0,
            z: 23.5 * Math.PI/180,
          },
          iconScale: 0.3,
          iconRotationID: 0,
          iconCurrentID: undefined,
          currentIcon: undefined,
          areIconsDisplayed: true,
        }
      }
    },
    created() {
      this.currentDeg = this.degRange[1];
      this.viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0)
      this.isMobile = this.mobileAndTabletcheck()
    },
    methods: {
      toggleDetail:function () {
        if(!this.isDetailVisible) {
          // eslint-disable-next-line no-undef
          gtag('config', this.uaId, {'page_path': this.$route.path + '-i-detail'});
        } else {
          // eslint-disable-next-line no-undef
          gtag('config', this.uaId, {'page_path': this.$route.path });
          var audio = this.$el.querySelectorAll('audio')[0];
          if(!audio.paused){
            audio.pause();
          }
        }

        this.isDetailVisible =  !this.isDetailVisible
        var _this = this;
        var _scaleCanvas = 1;
        var workMarginTop = "0px";
        var work = this.$el.querySelector('.work-content');
        var _heightWork3d = this.windowH - 70;
        var _opacity = 1;

        this.toggleRendering(false);

        if(this.isDetailVisible) {
          _opacity = 0;

          if (this.windowR < 0.75) {
            _scaleCanvas = 1;

          } else {
            _scaleCanvas = 0.8;

          }

          _heightWork3d = this.windowH * 0.59;


          if (this.windowR < 0.75) {
            workMarginTop = this.windowH *  0.14;

            if(this.windowR < 0.6){
              workMarginTop = this.windowH *  0.09;
            }

          } else {
            workMarginTop = this.windowH *  0.25;
          }

          document.body.classList.add('detail-opened');
        }


        anime({
          targets: work,
          height: _heightWork3d,
          duration: 500,
          easing:'easeOutQuart',
        });

        anime({
          targets: _this.thumbsDir[_this.thumbsSettings.iconCurrentID].icon.material,
          opacity: _opacity,
          easing: 'easeOutQuad',
          duration: 500,
        });

        anime({
          targets: '.work-3d canvas',
          scale: _scaleCanvas,
          marginTop:workMarginTop,
          duration: 500,
          easing:'easeOutQuart',
          complete: function (){
            anime({
              targets: '.range-interact-wrap',
              opacity: _opacity,
              easing: 'easeOutQuad',
              duration: 450,
            });




            if(!_this.isDetailVisible){
              work.style.removeProperty('height');
              document.body.classList.remove('detail-opened');
              _this.resizeScene();

              anime({
                targets: _this.stars.material,
                opacity: 1,
                easing: 'easeOutQuad',
                duration: 450
              });
            }
          }
        });
      },

      showModalMsg : function() {
        this.showModal = true
      },
      setAvailableHeight: function() {
        var winW = window.innerWidth;
        var winH = window.innerHeight;
        var workH = winH - this.$el.querySelector('.nav-bottom').clientHeight;
        if(!this.isDetailVisible) {
          this.$el.querySelector('.work-content').style.height = '';
        }

        var availH = workH - this.pageTop.clientHeight;

        var _bottomNav = parseInt(window.getComputedStyle(this.$el.querySelector('.nav-bottom')).getPropertyValue('bottom'))
        if (_bottomNav > 0) {
          availH = availH - _bottomNav
        }

        if(availH < 200) {
          availH = 200
        }

        var maxAailH = Math.min(winW, availH)
        this.workHeightLive = maxAailH
      },
      resetWorkZoom : function() {
        var transform = "";
        this.zoomEl.style.transform = transform;
        this.zoomEl.style.webkitTransform = transform;
      },
      pageLoad : function() {
        this.isPageLoaded = true;
        var _this = this
        this.pageLoadTimer = setTimeout(function () {
          _this.$el.querySelector(".work-intro-ani-in").classList.remove('work-intro-ani-in')
        }, 1600)
      },
      getRangeWidth: function() {
        var winW = window.innerWidth;
        var winH = window.innerHeight;
        var workH = winH - this.$el.querySelector('.nav-bottom').clientHeight;

        var availH = workH - this.$el.querySelector('.page__top').clientHeight;
        var _bottomNav = parseInt(window.getComputedStyle(this.$el.querySelector('.nav-bottom')).getPropertyValue('bottom'))
        if(_bottomNav  > 0) {
          availH = availH - _bottomNav
        }
        if(availH < 200) {
          availH = 200
        }

        var maxAailH = Math.min(winW, availH)
        this.rangeWidthLive = maxAailH

        this.rangeWidthLive = this.rangeWidthLive * 0.8;

        if(window.innerWidth < 1025){
          var getBottomRangeInteract =  ((this.rangeWidthLive * 0.3) / 2);

          if(window.innerWidth < 630){
            getBottomRangeInteract = ((this.rangeWidthLive * 0.7) / 2) + 72
          }

          if(window.innerWidth > window.innerHeight && window.innerWidth < 800){
            getBottomRangeInteract = 75;
          }

          this.$el.querySelector('.range-interact-wrap').style.bottom = getBottomRangeInteract +'px';

        } else {
          this.$el.querySelector('.range-interact-wrap').style.bottom = '';
        }

        },
      rangeHandleOnPan: function (e,el) {
        if(!this.isDetailVisible){
          // eslint-disable-next-line no-undef
          gtag('event', 'rangeSlider', {
            'event_category' : 'uranometria',
            'event_label' : 'uranometria',
          });

          var totalSlideDeg = this.degRange[1] - this.degRange[0];
          var stepDeg = (360 * Math.PI/180) / totalSlideDeg

          if(el == 'globe'){

            //left
            if(e.direction == 2){
              this.earthSettings.currrentRotation.y = this.earthSettings.currrentRotation.y - stepDeg;
            }
            //right
            if(e.direction == 4){
              this.earthSettings.currrentRotation.y = this.earthSettings.currrentRotation.y + stepDeg;
            }

          } else {
            var rangeSize = this.$el.querySelector('.range-slider').getBoundingClientRect()
            var radius = rangeSize.height / 2;
            var _y = e.center.y + (rangeSize.top * -1)
            var _x = e.center.x + (rangeSize.left * -1);
            var atan = Math.atan2(_y - radius, _x - radius)
            var deg = (180 * Math.abs(atan)) / Math.PI;

            if( deg >= this.degRange[1] ) {
              //console.log('ganz links',deg)
              deg = this.degRange[1]
              this.updateRotation(0 * Math.PI/180)
            }
            else if( deg <= this.degRange[0] ) {
              //console.log('ganz rechts',deg)
              deg = this.degRange[0]
              this.updateRotation(360 * Math.PI/180)
            } else {
              var movedDeg = this.degRange[1] - deg
              var movedPercent = (movedDeg / 90 ) * 360
              this.updateRotation(movedPercent * Math.PI/180)
            }
            this.currentDeg = deg
          }
        }
      },
      initSlider: function (){
        let _this = this;
        _this.padding = "16.66vw"


        if(window.innerWidth < 1024){
          if(window.innerWidth < 767){
            if(window.innerWidth > window.innerHeight){
              _this.padding = "16.66vw"
              document.querySelector(".slider-wrapper").classList.add("landscape");
            } else {
              _this.padding = "6.6vw";
              document.querySelector(".slider-wrapper").classList.remove("landscape");
            }
          }
        } else {

          if(window.innerHeight < 750){
            _this.padding = "20.66vw";

            if(window.innerHeight < 670){
              _this.padding = "24.66vw";
            }
          }
        }



        if(window.innerWidth > 1700){
          _this.padding = "20.66vw"

          if(window.innerHeight < 840){
            _this.padding = "50.66vh";

            if(window.innerHeight < 770){
              _this.padding = "60.66vh";
            }
          }
        }

        setTimeout(function (){
          // eslint-disable-next-line no-undef
          var splide = new Splide( '.splide', {
            start: _this.activeSlide,
            type: 'loop',
            perPage: 1,
            padding: {
              right: _this.padding,
              left : _this.padding,
            },
            pagination: false,
            updateOnMove: true
          } );


          splide.mount();

          _this.slider = splide;

          var counter = _this.activeSlide;
          var items_in_slider = _this.uranometriaSlides.length - 1;

          document.querySelector('.splide__arrow--prev').addEventListener('click',function() {
            counter--;

            if(counter < 0) {
              counter = items_in_slider
            }

            document.querySelector(".splide").setAttribute("data-id",counter);

          });

          document.querySelector('.splide__arrow--next').addEventListener('click',function() {
            counter++;

            if(counter > items_in_slider) {
              counter = 0;
            }
            document.querySelector(".splide").setAttribute("data-id",counter);

          });

          splide.on( 'dragged', function () {
            // This won't be executed.
            setTimeout(function (){
              let getId = document.querySelector(".slider-item.is-active").getAttribute("slide-id");
              document.querySelector(".splide").setAttribute("data-id",getId);
            },10);
          } );


        },500);
      },
      closeSlide: function (event){
        if(event.target.classList[0] == 'btn-close-circle' || event.target.classList[0] == 'modal-wrapper'){
          var _this = this;

          anime({
            targets: document.querySelector('.modalSlide'),
            opacity: 0,
            easing: 'easeOutQuad',
            duration: 200,
            complete: function(){
              _this.showSlide = false;
              _this.getRotationDegAfterSlider();
              _this.resumeRendering();

              anime({
                targets: _this.stars.material,
                opacity: 1,
                easing: 'easeOutQuad',
                duration: 450
              });

              anime({
                targets: '.range-interact-wrap',
                opacity: 1,
                easing: 'easeOutQuad',
                duration: 450,
                delay: 200
              });

              anime({
                targets: '.work-3d',
                opacity: 1,
                easing: 'easeOutQuad',
                duration: 450,
                delay: 200,
              });

              anime({
                targets: '.page__top',
                opacity: 1,
                easing: 'easeOutQuad',
                duration: 450,
                delay: 200,
              });
            }
          });

        }
      },
      getCanvasSize: function(){
        const sizes = { width: 0, height: 0};

        sizes.width = window.innerWidth;
        sizes.height = window.innerHeight - 56;

        return sizes;
      },
      createSphere: function(){
        /* eslint-disable no-undef */
        return new THREE.Mesh(
          new THREE.SphereGeometry(this.earthSettings.radius, this.earthSettings.segments, this.earthSettings.segments),
          new THREE.MeshLambertMaterial({
            map: new THREE.TextureLoader().load(this.imagesBaseUrl + 'earth.jpg'),
          })
        );
      },
      createThumb: function(url){
        const container = new THREE.Object3D();

        const mesh = new THREE.Mesh(
          new THREE.PlaneGeometry(1, 0.744),
          new THREE.MeshLambertMaterial({
            map: new THREE.TextureLoader().load(url),
            side: THREE.DoubleSide
          })
        );
        container.add(mesh);

        const icon  = new THREE.Mesh(
          new THREE.PlaneGeometry(1, 1),
          new THREE.MeshBasicMaterial({
            map: new THREE.TextureLoader().load(this.imagesBaseUrl + 'zoom_icon.jpg'),
            alphaMap: new THREE.TextureLoader().load(this.imagesBaseUrl + 'zoom_icon_alpha.jpg'),
            transparent: true,
            side: THREE.DoubleSide
          })
        );
        icon.position.set(0.5, 0.35, 0.1);
        icon.scale.set(0.001, 0.001, 0.001);


        container.add(icon);
        container.icon = icon;

        return container;
      },
      createStars: function(){
        return new THREE.Mesh(
          new THREE.SphereGeometry(this.earthSettings.radiusStars, this.earthSettings.segmentsStars, this.earthSettings.segmentsStars),
          new THREE.MeshBasicMaterial({
            map:  new THREE.TextureLoader().load(this.imagesBaseUrl + 'galaxy_starfield.png'),
            side: THREE.BackSide,
            transparent: true
          })
        );
      },
      setScene: function(){

        this.canvaSize = this.getCanvasSize();

        this.windowW = (window.innerWidth || screen.width);
        this.windowH = (window.innerHeight || screen.height);
        this.windowR = this.windowW / this.windowH;

        if (this.windowR < 0.75) {
          this.generalScale = 0.75;
        } else {
          this.generalScale = 1;
        }

        this.webglEl = document.getElementById('webgl');

        this.scene = new THREE.Scene();

        this.camera = new THREE.PerspectiveCamera(45, this.canvaSize.width / this.canvaSize.height, 0.01, 1000);
        this.camera.position.z = 1.5;

        this.renderer = new THREE.WebGLRenderer({ alpha: true });
        this.renderer.setClearColor( 0x000000, 0 );
        this.renderer.setSize(this.canvaSize.width, this.canvaSize.height);
        this.renderer.setPixelRatio( window.devicePixelRatio );

        new THREE.Interaction(this.renderer, this.scene, this.camera);

        const light1 = new THREE.AmbientLight(0x333333);
        this.scene.add(light1);

        const light2 = new THREE.DirectionalLight(0xffffff, 1);
        light2.position.set(5, 3, 5);
        this.scene.add(light2);

        const light3 = new THREE.DirectionalLight(0xffffff, 1);
        light3.position.set(-5, -3, -2);
        this.scene.add(light3);

        this.container = new THREE.Object3D();
        this.scene.add(this.container);

        this.thumbsContainer = new THREE.Object3D();
        this.thumbsContainer.rotation.set(this.thumbsSettings.rotation.x, this.thumbsSettings.rotation.y, this.thumbsSettings.rotation.z);
        this.thumbsContainer.scale.set(this.generalScale, this.generalScale, this.generalScale);
        this.container.add(this.thumbsContainer);

        this.thumbsUrls.forEach((url, i) => {
          const thumb = this.createThumb(this.imagesBaseUrl + this.thumbsBaseURL + url.url);
          thumb.scale.set(this.thumbsSettings.scale, this.thumbsSettings.scale, this.thumbsSettings.scale);
          thumb.position.set(this.thumbsSettings.radius * Math.cos(i * -this.thumbsSettings.angleStep), 0, this.thumbsSettings.radius * Math.sin(i * -this.thumbsSettings.angleStep));
          thumb.rotation.set(0, (i * this.thumbsSettings.angleStep) + (90 * Math.PI/180) , 0);
          thumb.name = "thumb_"+i;
          thumb.cursor = 'pointer';
          thumb.on('pointerdown', this.thumbClickListener);

          this.thumbsContainer.add(thumb);
          this.thumbsDir.push(thumb);
        });

        this.sphere = this.createSphere(this.earthSettings.radius, this.earthSettings.segments);
        this.sphere.rotation.set(this.earthSettings.rotation.x, this.earthSettings.rotation.y, this.earthSettings.rotation.z);
        this.sphere.scale.set(this.generalScale, this.generalScale, this.generalScale);
        this.container.add(this.sphere);

        this.stars = this.createStars();
        this.stars.rotation.set(this.earthSettings.rotationStars.x, this.earthSettings.rotationStars.y, this.earthSettings.rotationStars.z);
        this.container.add(this.stars);

        this.webglEl.appendChild(this.renderer.domElement);
      },
      resizeScene: function(){
        this.windowW = (window.innerWidth || screen.width);
        this.windowH = (window.innerHeight || screen.height);
        this.windowR = this.windowW / this.windowH;

        if (this.windowR < 0.75) {
          this.generalScale = 0.75;
        } else {
          if(window.innerWidth < 1025){

            this.generalScale = 1
          } else {
            this.generalScale = 1.2;
          }
        }

        this.sphere.scale.set(this.generalScale, this.generalScale, this.generalScale);
        this.thumbsContainer.scale.set(this.generalScale, this.generalScale, this.generalScale);

        this.canvaSize = this.getCanvasSize();

        this.renderer.setSize(this.canvaSize.width, this.canvaSize.height);
        this.camera.aspect = this.canvaSize.width / this.canvaSize.height;
        this.camera.updateProjectionMatrix();

        var targetAngle = 2 * Math.asin((this.earthSettings.radius * this.generalScale) / this.camera.position.z);
        var vFOV = THREE.Math.degToRad(this.camera.fov);
        this.earthSettings.renderedDimension = targetAngle / vFOV * this.windowH * 0.96;
      },
      renderScene: function(){
        this.earthSettings.currrentRotation.y += this.earthSettings.continuousRotation;
        this.container.rotation.y = this.earthSettings.currrentRotation.y;
        this.RAF = requestAnimationFrame(this.renderScene);
        this.renderer.render(this.scene, this.camera);

        this.earthSettings.currentRotationDeg = (this.container.rotation.y * 180/Math.PI) % 360;
        this.earthSettings.currentRotationPercent = this.earthSettings.currentRotationDeg / 360;

        if (this.thumbsSettings.areIconsDisplayed == true) this.updateZoomIcons();

        this.updateSliderPos();
      },
      updateZoomIcons: function(){
        this.thumbsSettings.iconRotationID = 11 - Math.floor((this.earthSettings.currentRotationDeg + 80) / 30); // 11 - to get inverted order    // 30 = 120 / 12 thumbs

        //correction if rotation is negative
        if(Math.sign(this.earthSettings.currentRotationDeg) == -1){
          this.thumbsSettings.iconRotationID = this.thumbsSettings.iconRotationID - 12;
        }

        //correction for negative ids
        if (this.thumbsSettings.iconRotationID < 0) {
          this.thumbsSettings.iconRotationID = 12 + this.thumbsSettings.iconRotationID;
        }

        if (this.thumbsSettings.iconRotationID != this.thumbsSettings.iconCurrentID) {
          if (this.thumbsSettings.iconCurrentID != undefined) this.hideZoomIcon(this.thumbsDir[this.thumbsSettings.iconCurrentID].icon);

          this.thumbsSettings.iconCurrentID = this.thumbsSettings.iconRotationID;

          this.displayZoomIcon(this.thumbsDir[this.thumbsSettings.iconCurrentID].icon);
        }

        if (this.thumbsSettings.currentIcon) {
          this.thumbsSettings.currentIcon.lookAt( this.camera.position );
        }

        this.activeSlide = this.thumbsSettings.iconRotationID;

      },
      displayZoomIcon: function(icon){
        this.thumbsSettings.currentIcon = icon;

        if(window.innerWidth < 767){
          if(window.innerWidth > window.innerHeight){
            this.thumbsSettings.iconScale = 0.6;
          }
        }

        if(window.innerWidth < 630){
          this.thumbsSettings.iconScale = 0.5;
        }

        anime({
          targets: this.thumbsSettings.currentIcon.scale,
          x: this.thumbsSettings.iconScale,
          y: this.thumbsSettings.iconScale,
          z: this.thumbsSettings.iconScale,
          easing: 'easeOutQuad',
          duration: 250
        });

      },
      hideZoomIcon: function(icon){
        anime({
          targets: icon.scale,
          x: 0.001,
          y: 0.001,
          z: 0.001,
          easing: 'easeOutQuad',
          duration: 250
        });
      },
      updateSliderPos: function(){
        var totalSlideDeg = this.degRange[1] - this.degRange[0];
        this.currentDeg = this.degRange[1] - this.earthSettings.currentRotationPercent * totalSlideDeg;

        if(this.currentDeg > this.degRange[1]){
          this.currentDeg = (this.currentDeg - this.degRange[1]) + this.degRange[0];
        }
      },
      getRotationDegAfterSlider: function (){
        if(!isNaN(parseInt(document.querySelector(".splide").getAttribute("data-id")))){
          this.activeSlide = parseInt(document.querySelector(".splide").getAttribute("data-id"));
        }

        if(this.activeSlide == 9){
          this.earthSettings.currrentRotation.y = 0;
          this.updateRotation(0);
        } else {
          if(this.activeSlide > 9){
            var index = this.activeSlide - 9;

          } else {
            index = this.activeSlide + 3;
          }
          this.updateRotation(-1 * ((index * 30) * Math.PI/180));
        }
      },
      //to map with swip
      updateRotation: function(rot){
        this.earthSettings.currrentRotation.y = rot;
      },
      stopRendering: function (){
        if (this.RAF) {
          window.cancelAnimationFrame(this.RAF);
          this.RAF = undefined;
        }
      },
      destroy: function(){
        this.stopRendering();
        this.scene = null;
        this.projector = null;
        this.renderer = null;
        this.camera = null;
        window.removeEventListener('resize', this.resizeScene);
      },
      resumeRendering: function(){
        if (!this.RAF) this.RAF = requestAnimationFrame(this.renderScene);
      },
      toggleRendering: function(boolean){
        if (this.RAF) {
          anime({
            targets: this.stars.material,
            opacity: 0,
            easing: 'easeOutQuad',
            duration: 450,
            complete: this.stopRendering
          });
        } else {
          this.resumeRendering();

          if(boolean){
            anime({
              targets: this.stars.material,
              opacity: 1,
              easing: 'easeOutQuad',
              duration: 450
            });
          }
        }
      },
      thumbClickListener: function(e){
        e.stopPropagation();

        if(!this.isDetailVisible){
          const id = e.target.name.split("_")[1];
          this.activeSlide = id;

          this.showSlide = true;

          var _this = this;
          setTimeout(function (){
            document.querySelector('.modalSlide').addEventListener('click',function(event) {
              _this.closeSlide(event);
            });

            _this.initSlider();

          },100);

          setTimeout(function (){
            anime({
              targets: document.querySelector('.modalSlide'),
              opacity: 1,
              easing: 'easeOutQuad',
              duration: 200,
            });

            anime({
              targets: document.querySelector('.slider-wrapper'),
              opacity: 1,
              easing: 'easeOutQuad',
              duration: 350,
            });

            anime({
              targets: '.range-interact-wrap',
              opacity: 0,
              easing: 'easeOutQuad',
              duration: 200,
            });

            anime({
              targets: '.work-3d',
              opacity: 0,
              easing: 'easeOutQuad',
              duration: 200,
            });

            anime({
              targets: '.page__top',
              opacity: 0,
              easing: 'easeOutQuad',
              duration: 200,
            });
          },150)
        }
      },
    },
    beforeRouteLeave (to, from, next) {
      if(this.pageLoadTimer) {
        clearTimeout(this.pageLoadTimer);
      }

      localStorage.setItem('uranometriaIndex', '');
      this.aniMenuPageLeave(next());
    },
    mounted() {
      document.body.style.zoom = 1.0;
      this.pageTop = this.$el.querySelector('.page__top');
      this.windowWidth = window.innerWidth
      this.zoomEl = this.$el.querySelector(".work__scaleable");
      this.saveCurrentPlanetToLocalStorage();
      this.pageLoad();
      this.getRangeWidth();

      this.lastWindowW =  window.innerWidth
      this.lastWindowH =  window.innerWidth

      window.onresize = () => {
        //avoid resize on scroll -> addressbar collapse
        this.windowHeight = window.innerHeight
        this.windowWidth = window.innerWidth

        if(this.isMobile && this.lastWindowW == window.innerWidth) {
          return false
        }

        if(this.showSlide){
          let _this = this;
          _this.initSlider();
        }

        if(this.lastWindowW != window.innerWidth && !this.isDetailVisible){
          this.getRangeWidth();
          this.resizeScene();
        }

      }

      window.onorientationchange = () => {
        this.lastWindowW =  window.innerWidth
        this.lastWindowH =  window.innerWidth

        if(window.innerWidth < 767){
          this.getRangeWidth();
          this.resizeScene();
        }
      }

      this.setScene();
      this.renderScene();
      this.resizeScene();

      /*
      swipe event globe
      */
      const globe = this.$el.querySelector("#webgl");
      const mcGlobe = new Hammer.Manager(globe);
      mcGlobe.domEvents = true
      mcGlobe.add( new Hammer.Pan({ direction: Hammer.DIRECTION_ALL}) );
      mcGlobe.add( new Hammer.Tap() );
      mcGlobe.add( new Hammer.Pinch({ threshold: 0 }) );
      mcGlobe.add( new Hammer.Press({
          event: 'press',
          pointer: 1,
          threshold: 500,
          time: 1,
        })
      );

      mcGlobe.on('pan panend', (e) => {
        var old = this.pinchTimmer
        var now = new Date().getTime(),
          delta = now - old;

        if(!this.isDetailVisible){
          //avoid pinch end -> pan
          if(delta > 100) {
            this.pinchTimmer = 0
            document.getElementById("webgl").style.setProperty("pointer-events","none");
            this.rangeHandleOnPan(e, "globe");
            this.resumeRendering()
            this.earthSettings.continuousRotation = 0;
          }

          if(e.type == 'panend'){
            document.getElementById("webgl").style.removeProperty("pointer-events");
            this.earthSettings.continuousRotation = 0.001;
          }

        } else {
          this.stopRendering;
        }

      });

      this.mcGlobe = mcGlobe;

      const rangeHandle = this.$el.querySelector(".range-slider__handle");

      const mcHandle = new Hammer.Manager(rangeHandle);
      mcHandle.add( new Hammer.Pan({ direction: Hammer.DIRECTION_ALL}) );
      mcHandle.add( new Hammer.Tap() );
      mcHandle.add( new Hammer.Pinch({ threshold: 0 }) );
      mcHandle.add( new Hammer.Press({
          event: 'press',
          pointer: 1,
          threshold: 500,
          time: 1,
        })
      );
      mcHandle.domEvents = true
      mcHandle.on('press', () => {
        this.earthSettings.continuousRotation = 0;
        this.stopRendering();
      });
      mcHandle.on('pressup', () => {
        this.earthSettings.continuousRotation = 0.001;
        this.resumeRendering()
      });
      mcHandle.on('pan panend', (e) => {
        var old = this.pinchTimmer
        var now = new Date().getTime(),
          delta = now - old;

        if(delta > 100) {
          this.rangeHandleOnPan(e, "range");
          this.earthSettings.continuousRotation = 0;
          this.resumeRendering()
        }
        if(e.type == 'panend'){
          this.isRangeActive = false;
          this.earthSettings.continuousRotation = 0.001;
          this.resumeRendering()
        }
      });

    }
  }
</script>

