<script>
  export default {
    name: 'moon-passing',
    data: function (){
      return {
        slides : [
          {
            stepId: "2",
            legend: "21:19",
            left: "87.62%",
            jupiter: "jupiter-02.svg",
            clipath : `<path d="M2000,0H0v13.5c3-0.6,6.2-0.9,9.4-0.9c26,0,47,21.1,47,47s-21.1,47-47,47c-3.2,0-6.4-0.3-9.4-0.9v1649.8h2000V0 z" fill-rule="evenodd" clip-rule="evenodd" fill="black" fill-opacity="0.2" />`
          },
          {
            stepId: "4",
            legend: "21:38",
            left: "81.59%",
            jupiter: "jupiter-04_05_06.svg",
            clipath : `<path d="M2000,0H0v1755.5h2000V0z M134.8,163c26,0,47-21.1,47-47c0-26-21.1-47-47-47c-26,0-47,21.1-47,47 C87.8,142,108.8,163,134.8,163z" fill-rule="evenodd" clip-rule="evenodd" fill="black" fill-opacity="0.2" />`
          },
          {
            stepId: "5",
            legend: "21:59",
            left: "76.51%",
            jupiter: "jupiter-04_05_06.svg",
            clipath : `<path d="M2000,0H0v1755.5h2000V0z M285.3,225.7c26,0,47-21.1,47-47c0-26-21.1-47-47-47c-26,0-47,21.1-47,47 C238.2,204.7,259.3,225.7,285.3,225.7z" fill-rule="evenodd" clip-rule="evenodd" fill="black" fill-opacity="0.2" />`
          },
          {
            stepId: "6",
            legend: "22:04",
            jupiter: "jupiter-04_05_06.svg",
            left: "70.48%",
            clipath : `<path d="M2000,0H0v1755.5h2000V0z M373,275.9c26,0,47-21.1,47-47c0-26-21.1-47-47-47c-26,0-47,21.1-47,47 C326,254.8,347.1,275.9,373,275.9z" fill-rule="evenodd" clip-rule="evenodd" fill="black" fill-opacity="0.2" />`
          },
          {
            stepId: "7",
            legend: "22:22",
            jupiter: "jupiter-07.svg",
            left: "57.14%",
            clipath : `<path d="M2000,0H0v1755.5h2000V0z M655.2,420.1c26,0,47-21.1,47-47c0-26-21.1-47-47-47c-26,0-47,21.1-47,47 C608.2,399,629.2,420.1,655.2,420.1z" fill-rule="evenodd" clip-rule="evenodd" fill="black" fill-opacity="0.2" />`
          },
          {
            stepId: "8",
            legend: "22:37",
            jupiter: "jupiter-08.svg",
            left: "49.52%",
            clipath : `<path d="M2000,0H0v1755.5h2000V0z M818.2,501.6c26,0,47-21.1,47-47c0-26-21.1-47-47-47c-26,0-47,21.1-47,47 C771.2,480.5,792.2,501.6,818.2,501.6z" fill-rule="evenodd" clip-rule="evenodd" fill="black" fill-opacity="0.2" />`
          },
          {
            stepId: "9",
            legend: "22:50",
            jupiter: "jupiter-09.svg",
            left: "42.22%",
            clipath : `<path d="M2000,0H0v1755.5h2000V0z M974.9,570.5c26,0,47-21.1,47-47c0-26-21-47-47-47c-26,0-47,21.1-47,47 C927.9,549.5,949,570.5,974.9,570.5z" fill-rule="evenodd" clip-rule="evenodd" fill="black" fill-opacity="0.2" />`
          },
          {
            stepId: "10",
            legend: "23:01",
            jupiter: "jupiter-10_11_12_15_19.svg",
            left: "37.14%",
            clipath : `<path d="M2000,0H0v1755.5h2000V0z M1075.2,620.7c26,0,47-21.1,47-47c0-26-21.1-47-47-47c-26,0-47,21.1-47,47 C1028.2,599.6,1049.3,620.7,1075.2,620.7z" fill-rule="evenodd" clip-rule="evenodd" fill="black" fill-opacity="0.2" />`
          },
          {
            stepId: "11",
            legend: "23:04",
            jupiter: "jupiter-10_11_12_15_19.svg",
            left: "34.6%",
            clipath : `<path d="M2000,0H0v1755.5h2000V0z M1125.4,645.8c26,0,47-21.1,47-47c0-26-21.1-47-47-47s-47,21.1-47,47 C1078.4,624.7,1099.4,645.8,1125.4,645.8z" fill-rule="evenodd" clip-rule="evenodd" fill="black" fill-opacity="0.2" />`
          },
          {
            stepId: "12",
            legend: "23:09",
            jupiter: "jupiter-10_11_12_15_19.svg",
            left: "32.06%",
            clipath : `<path d="M2000,0H0v1755.5h2000V0z M1188.1,677.1c26,0,47-21.1,47-47c0-26-21-47-47-47c-26,0-47,21.1-47,47 C1141.1,656.1,1162.1,677.1,1188.1,677.1z" fill-rule="evenodd" clip-rule="evenodd" fill="black" fill-opacity="0.2" />`
          },
          {
            stepId: "14",
            legend: "23:14",
            jupiter: "jupiter-14.svg",
            left: "30.16%",
            clipath : `<path d="M2000,0H0v1755.5h2000V0z M1238.2,702.2c26,0,47-21.1,47-47c0-26-21.1-47-47-47c-26,0-47,21.1-47,47 C1191.2,681.1,1212.3,702.2,1238.2,702.2z" fill-rule="evenodd" clip-rule="evenodd" fill="black" fill-opacity="0.2" />`
          },
          {
            stepId: "15",
            legend: "23:21",
            jupiter: "jupiter-10_11_12_15_19.svg",
            left: "24.76%",
            clipath : `<path d="M2000,0H0v1755.5h2000V0z M1338.6,758.6c26,0,47-21.1,47-47c0-26-21-47-47-47c-26,0-47,21.1-47,47 C1291.5,737.6,1312.6,758.6,1338.6,758.6z" fill-rule="evenodd" clip-rule="evenodd" fill="black" fill-opacity="0.2" />`
          },
          {
            stepId: "16",
            legend: "23:23",
            jupiter: "jupiter-16_17.svg",
            left: "21.27%",
            clipath : `<path d="M2000,0H0v1755.5h2000V0z M1413.8,790c26,0,47-21.1,47-47c0-26-21.1-47-47-47c-26,0-47,21.1-47,47 C1366.8,768.9,1387.8,790,1413.8,790z" fill-rule="evenodd" clip-rule="evenodd" fill="black" fill-opacity="0.2" />`
          },
          {
            stepId: "17",
            legend: "23:30",
            jupiter: "jupiter-16_17.svg",
            left: "15.24%",
            clipath : `<path d="M2000,0H0v1755.5h2000V0z M1532.9,846.4c26,0,47-21.1,47-47c0-26-21.1-47-47-47c-26,0-47,21.1-47,47 C1485.9,825.3,1506.9,846.4,1532.9,846.4z" fill-rule="evenodd" clip-rule="evenodd" fill="black" fill-opacity="0.2" />`
          },
          {
            stepId: "18",
            legend: "23:41",
            jupiter: "jupiter-18.svg",
            left: "6.98%",
            clipath : `<path d="M2000,0H0v1755.5h2000V0z M1702.2,927.9c26,0,47-21.1,47-47c0-26-21.1-47-47-47c-26,0-47,21.1-47,47 C1655.2,906.8,1676.2,927.9,1702.2,927.9z" fill-rule="evenodd" clip-rule="evenodd" fill="black" fill-opacity="0.2" />`
          },
          {
            stepId: "19",
            legend: "23:59",
            jupiter: "jupiter-10_11_12_15_19.svg",
            left: "-4.76%",
            clipath : `<path d="M2000,0H0v1755.5h2000V0z M1934.2,1040.8c26,0,47-21,47-47c0-26-21-47-47-47c-26,0-47,21.1-47,47 C1887.2,1019.7,1908.2,1040.8,1934.2,1040.8z" fill-rule="evenodd" clip-rule="evenodd" fill="black" fill-opacity="0.2" />`
          }
        ]
      }
    }
  }
</script>
