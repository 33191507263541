<script>
  export default {
    name: 'uranometria',
    data: function () {
      return {
        slides : [
          {
            id: 0,
            image: "s_widder.jpg",
            name : "Widder"
          },
          {
            id: 1,
            image: "s_stier.jpg",
            name : "Stier"
          },
          {
            id: 2,
            image: "s_zwillinge.jpg",
            name : "Zwillinge"
          },
          {
            id: 3,
            image: "s_krebst.jpg",
            name : "Krebs"
          },
          {
            id: 4,
            image: "s_loewe.jpg",
            name : "Löwe"
          },
          {
            id: 5,
            image: "s_jungfrau.jpg",
            name : "Jungfrau"
          },
          {
            id: 6,
            image: "s_waage.jpg",
            name : "Waage"
          },
          {
            id: 7,
            image: "s_skorpion.jpg",
            name : "Skorpion"
          },
          {
            id: 8,
            image: "s_schuetze.jpg",
            name : "Schütze"
          },
          {
            id: 9,
            image: "s_steinbock.jpg",
            name : "Steinbock"
          },

          {
            id: 10,
            image: "s_wassermann.jpg",
            name : "Wassermann"
          },
          {
            id: 11,
            image: "s_fisch.jpg",
            name : "Fische"
          }
        ],
        thumbs:
          [
            {
              url: 's_widder.jpg'
            },
            {
              url: 's_stier.jpg'
            },
            {
              url: 's_zwillinge.jpg'
            },
            {
              url: 's_krebst.jpg'
            },
            {
              url: 's_loewe.jpg'
            },
            {
              url: 's_jungfrau.jpg'
            },
            {
              url: 's_waage.jpg'
            },
            {
              url: 's_skorpion.jpg'
            },
            {
              url: 's_schuetze.jpg'
            },
            {
              url: 's_steinbock.jpg'
            },
            {
              url: 's_wassermann.jpg'
            },
            {
              url: 's_fisch.jpg'
            }
          ],
      }
    }
  };
</script>
