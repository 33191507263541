<template>
  <section class="section section--share">
    <template v-if="hasShare">
        <button  class="share-button" v-touch="openNativeShare" :class="{ 'has-links share-button__text-only': isTextlink }">
          <span v-if="isTextlink" class="share-button__text">{{$t("share")}}</span>
          <span v-else class="sicon sicon__share" aria-hidden="true"></span>
        </button>
    </template>
    <template v-else>
      <div class="has-links share-button" @click="showShareList" :class="{ 'share-button__text-only': isTextlink }">
        <span v-if="isTextlink" class="share-button__text">{{$t("share")}}</span>
        <span v-else class="sicon sicon__share" aria-hidden="true"></span>
        <div class="share-button__items">
          <a href="#" @click.prevent="shareFacebook"  @mouseover="mouseOverShareList" @mouseleave="mouseLeaveShareList" class="share-butto__item"><span class="sicon sicon__facebook" aria-hidden="true"></span></a>
          <a href="#" @click.prevent="shareTwitter"  @mouseover="mouseOverShareList" @mouseleave="mouseLeaveShareList" class="share-butto__item"><span class="sicon sicon__twitter" aria-hidden="true"></span></a>
          <a href="#" @click.prevent="shareMail"  @mouseover="mouseOverShareList" @mouseleave="mouseLeaveShareList" class="share-butto__item"><span class="sicon sicon__mail" aria-hidden="true"></span></a>
        </div>
      </div>
    </template>
  </section>
</template>
<script>
export default {
  name: 'ShareSection',
  props:{
    isTextlink: Boolean
  },
  data() {
    return {
      hasShare: false
    }
  },
  methods: {
    openNativeShare: function() {
      navigator.share({
        title: 'Astrorara',
        text: 'Astrorara präsentiert von ETH Zürich Bibliothek.',
        url: window.location.href,
      })
        .then(() => console.log('Successful share'))
        .catch((error) => console.log('Error sharing', error));
    },
    shareFacebook: function() {
      var width  = 626,
        height = 436,
        left   = (window.innerWidth  - width)  / 2,
        top    = (window.innerHeight - height) / 2,
        url    = 'https://www.facebook.com/sharer.php?u=' + window.location.href,
        opts   = 'status=1' +
          ',width='  + width  +
          ',height=' + height +
          ',top='    + top    +
          ',left='   + left;

      window.open(url, 'Facebook', opts);
    },
    shareTwitter: function() {
      var width  = 575,
        height = 300,
        left   = (window.innerWidth  - width)  / 2,
        top    = (window.innerHeight - height) / 2,
        url    = 'https://twitter.com/intent/tweet?text='+window.location.href,
        opts   = 'status=1' +
          ',width='  + width  +
          ',height=' + height +
          ',top='    + top    +
          ',left='   + left;

      window.open(url, 'Twitter', opts);
    },
    shareMail: function() {
      window.location.href = "mailto:?subject=Astrorara präsentiert von ETH Zürich Bibliothek.&body="+window.location.href;
    },
    showShareList: function (e){
      if(e.target.classList.contains('open')){
        e.target.classList.remove('open');
        return true;
      } else {
        e.target.classList.add('open');
        return true;
      }
    },
    mouseOverShareList: function (e){
      if(e.target.parentNode.parentNode.parentNode.parentNode.classList.contains('menu-overlay__footer')){
        if(e.target.children[0].classList.contains('sicon__mail')){
          e.target.parentNode.classList.add('hovered');
        }
      } else {
        if(e.target.children[0].classList.contains('sicon__facebook')){
          e.target.parentNode.classList.add('hovered');
        }
      }
    },
    mouseLeaveShareList: function (e){
      e.target.parentNode.classList.remove('hovered');
    },
  },
  mounted() {
    if (navigator.share) {
      this.hasShare = true
    } else {
      this.hasShare = false
    }
  }
};
</script>

