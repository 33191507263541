<template>
  <div class="zoom-inkunabel native-pinch zoom-page">
    <div class="top-tool-nav-wrap">
      <a @click="$router.go(-1)" class="btn-close-circle" aria-hidden="true"><span class="visuallyhidden">close Zoom View</span></a>
    </div>
    <div class="zoomed-img-wrap">
      <img class="zoomed-img__img" :src="'/assets/img/inkunabel/details/lunar-eclipse.png'" alt="Lunar eclipse full image" v-if="lunar" :style="'height:'+ imageHeightLive">
      <img class="zoomed-img__img" :src="'/assets/img/inkunabel/details/solar-eclipse.png'" alt="Solar eclipse full image" v-if="solar" :style="'height:'+ imageHeightLive">
    </div>
  </div>
</template>

<script>
  export default {
  name: 'zoomInkunabelComponent',
    data() {
      return {
        lunar: false,
        solar: false,
        imageHeightLive: 'auto'
      }
    },
    created() {
      this.activeId = this.$route.params.id;
      localStorage.setItem('inkunabelIndex', this.activeId)
    },
    beforeRouteLeave (to, from, next) {
      document.body.classList.remove('zoomed-opened');
      next();
    },
    methods:{
      showEclipseImage: function (){
        if(this.activeId == 3){
          this.lunar = true;
        } else {
          this.solar = true;
        }
      },
      setHeight: function (height){
        document.body.style.height = height + 'px';
        document.querySelector('.zoom-inkunabel').style.height = height + 'px';
        document.querySelector('.zoomed-img-wrap').style.height = height + 'px';
        var _this = this;

        if(window.innerWidth >= 768){
          var newHeight = height - this.$el.querySelector('.btn-close-circle').clientHeight - 50;
          _this.imageHeightLive = newHeight + 'px';
          setTimeout(function (){
            if(document.querySelector('.zoomed-img__img').clientWidth > screen.availWidth - 80){
              document.querySelector('.zoomed-img__img').style.width = screen.availWidth - 80 + 'px';
              _this.imageHeightLive = 'auto';
              _this.$el.querySelector('.zoomed-img-wrap').classList.add('centered');
            } else {
              document.querySelector('.zoomed-img__img').style.width = 'auto';
              _this.imageHeightLive = newHeight + 'px';
              _this.$el.querySelector('.zoomed-img-wrap').classList.remove('centered');
            }
          },5);
          document.body.classList.remove('zoomed-overflow');
        } else {
          _this.imageHeightLive = 'auto';
          setTimeout(function (){
            if(document.querySelector('.zoomed-img__img').clientHeight < height){
              document.body.classList.add('zoomed-overflow');
            }
          },5);
        }
      }
    },
    mounted() {
      var _this = this;
      document.body.classList.add('zoomed-opened');

      this.showEclipseImage();
      setTimeout(function (){
        _this.setHeight(window.innerHeight);
      },50)

      window.onresize = () => {
        setTimeout(function (){
          _this.setHeight(window.innerHeight);
        },10)
      }
    }
  }
</script>
