<template>
  <section class="section audio-guide">
    <p>    {{ audiotitle }}</p>
    <div class="audio-guide-wrap">
      <template v-if="playing">
        <button class="btn audio-control" @click.prevent="stop"><span class="sicon sicon__audio-stop" aria-hidden="true"></span></button>
      </template>
      <template v-else>
        <button class="btn audio-control" @click.prevent="play"><span class="sicon sicon__audio-play" aria-hidden="true"></span></button>
      </template>
      <div class="audio-progress-wrap">
        <progress :max="durationSeconds" :value="playbackTime" class="progress-bar"></progress>
        <input
          v-model="playbackTime"
          type="range"
          min="0"
          :max="durationSeconds"
          class="audio-progress-bar-position"
          id="position"
          name="position"
        />
        <div class="audio-progress-time-wrap">
          <span class="audio-progress-time"> {{ playbackTimeSeconds }} </span> / <span class="audio-progress-time"  v-html="totalTime()"> 00:00 </span>
        </div>
      </div>
      <audio :src="file" :type="format" ref="player"></audio>
    </div>
  </section>
</template>
<script>
export default {
  name: 'AudioSection',
  props: {
    file: {
      type: String,
      default: null
    },
    audiotitle: {
      type: String,
      default: null
    },
    format: {
      type: String,
      default: 'audio/mpeg'
    },
  },
  data() {
    return {
      audio: undefined,
      loaded: false,
      playing: false,
      pause: false,
      playbackTime: 0,
      playbackTimeSeconds: 0,
      durationSeconds: 100,
      listenerActive: false
    }
  },
  methods: {
    play: function() {
      this.playing = true;
      this.audio.play();
      //console.log('play');
      //console.log('this.playing',this.playing);
    },
    stop: function() {
      this.playing = false;
      this.pause = true;
      this.listenerActive = false;
      this.cleanupListeners();
        this.audio.pause();
      //console.log('stop');
      //console.log('this.playing',this.playing);
    },
    //Remove listeners after audio play stops
    cleanupListeners() {
      var audio = this.$refs.player;
      audio.removeEventListener("freqtimeupdate", this.playbackListener);
      audio.removeEventListener("ended", this.reset);
      audio.removeEventListener("pause", this.stop);
      //console.log("All cleaned up!");
    },
    load() {
      if (this.audio.readyState >= 2) {
        this.loaded = true;
        this.durationSeconds = parseInt(this.audio.duration);
      }
    },
    reset: function() {
      this.audio.currentTime = 0;
      this.playing = false;
      this.pause = false;
      this.listenerActive = false;
      this.cleanupListeners();
    },
    //Convert audio current time from seconds to min:sec display
    convertTime(seconds){
      const format = val => `0${Math.floor(val)}`.slice(-2);
      var minutes = (seconds % 3600) / 60;
      return [minutes, seconds % 60].map(format).join(":");
    },
    //Show the total duration of audio file
    totalTime() {
      var audio = this.$refs.player;
      if (audio) {
        var seconds = audio.duration;
        return this.convertTime(seconds);
      } else {
        return '00:00';
      }
    },
    //Playback listener function runs every 100ms while audio is playing
    playbackListener() {
      var audio = this.$refs.player;
      //Sync local 'playbackTime' var to audio.currentTime and update global state
      this.playbackTime = audio.currentTime;
      this.playbackTimeSeconds = this.convertTime(audio.currentTime);

      //Add listeners for audio pause and audio end events
      audio.addEventListener("ended", this.reset);
      audio.addEventListener("pause", this.stop);
    },
  },
  mounted() {
    this.audio = this.$el.querySelectorAll('audio')[0];
    this.audio.addEventListener('loadeddata', this.load);
    this.audio.addEventListener('ended', this.reset);
    this.playbackTimeSeconds = this.convertTime(this.$refs.player.currentTime);

    this.$watch("playing",function() {
      if(this.playing) {
        var audio=this.$refs.player;
        //prevent starting multiple listeners at the same time
       if(!this.listenerActive) {
          this.listenerActive=true;
          audio.addEventListener("freqtimeupdate",this.playbackListener);
        }
      }
    });
    //Update current audio position when user drags progress slider
    this.$watch("playbackTime",function() {
      var diff=Math.abs(this.playbackTime-this.$refs.player.currentTime);

      //Throttle synchronization to prevent infinite loop between playback listener and this watcher
      if(diff>0.01) {
        this.$refs.player.currentTime=this.playbackTime;
      }
    });
  }
};
</script>

