<template>
  <div id="overview" :class="{ 'loading-ani-completed' : doneLoadAni}">
    <transition name="overview__bg" appear>
      <div class="overview__bg-wrap">
        <div class="overview__bg">
          <img src="/assets/img/layout/bg-overview.jpg" class="overview__bg-img"  alt="overview background image">
        </div>
      </div>
    </transition>
    <div class="container overview-container">
      <div class="page__top">
        <div style="display: none;">{{$t('hello')}}</div>
        <div class="overview__headline">
          <transition name="overview__headline-ani" mode="out-in">
            <template>
              <div v-for="(slide, index) of slides" :key="`key-${index}`" v-if="index === activeSlide" class="overview__headline-ani">
                <h1 class="page-top__title" v-t="slide.headline"></h1>
                <p class="page-top__text" v-html="slide.subheadline"></p>
              </div>
            </template>
          </transition>
        </div>
      </div>
      <main class="main main--overview" id="main">
        <transition tag="div" :name="loadAni" appear v-on:after-enter="afterLoadAni">
          <div :class="'overview__slide-wrap ' + loadAni">
            <transition-group appear tag="div" class="overview__slide" name="overview__slide-item" ref="overiew">
              <div v-for="(slide, index) of slides" :key="`key-${index}`" :index="index" class="overview__slide-item"
                   :class="{ 'prev' : index === activeSlide - 1,  'active' : index === activeSlide,  'next' : index === activeSlide + 1 }"
                   :style="slide.style"
              >
                <svg class="slide-item__img" width="1200px" height="1200px" viewBox="0 0 1200 1200" version="1.1" xmlns="http://www.w3.org/2000/svg">
                  <defs>
                    <clipPath :id="'clip_'+index">
                      <circle fill="#E4D5C2" cx="600" cy="600" r="600"></circle>
                    </clipPath>
                  </defs>
                  <image :clip-path="'url(#clip_'+index+')'" :xlink:href="slide.img" width="1200" height="1200" />
                </svg>
                <router-link :to="slide.url" class="slide-item__link fade" v-show="doneLoadAni && index === activeSlide" >
                  <span class="slide-item__icon-circle" aria-hidden="true"></span>
                  <span class="visuallyhidden">Link zu {{ slide.url }}</span>
                </router-link>
              </div>
            </transition-group>
            <div class="overview__headline">
              <transition name="overview__headline-ani" mode="out-in">
                <template>
                  <div v-for="(slide, index) of slides" :key="`key-${index}`" v-if="index === activeSlide" class="overview__headline-ani">
                    <h1 class="page-top__title" v-t="slide.headline"></h1>
                    <p class="page-top__text" v-html="slide.subheadline"></p>
                  </div>
                </template>
              </transition>
            </div>
            <div class="bottom-wrap">
              <ul class="overview__nav">
                <li v-for="(slide, indexNav) of slides" :key="`keyNav-${indexNav}`" :index="indexNav" class="overview__nav-item"
                    :class="{ 'active' : indexNav === activeSlide }"
                    ref="overviewNavItem"
                >
                  <svg class="nav-item__img" width="40" height="40" viewBox="0 0 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg">
                    <defs>
                      <clipPath :id="'clip_nav_'+indexNav">
                        <circle fill="#E4D5C2" cx="20" cy="20" r="20"></circle>
                      </clipPath>
                    </defs>
                    <image :clip-path="'url(#clip_nav_'+indexNav+')'" :xlink:href="slide.thumb" width="40" height="40" />
                  </svg>
                </li>
              </ul>
              <a class="overview__prev" aria-hidden="true">
                <span class="visuallyhidden">Previous</span>
                <svg width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="5" y="0.513672" width="1.5" height="6" transform="rotate(45 5 0.513672)" fill="white"/>
                  <rect x="6.05859" y="7.93945" width="1.5" height="6" transform="rotate(135 6.05859 7.93945)" fill="white"/>
                </svg>

              </a>
              <div class="pagination">{{activeSlide + 1}} / {{slidesLength}}</div>
              <a class="overview__next" aria-hidden="true">
                <svg width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="5" y="8.48633" width="1.5" height="6" transform="rotate(-135 5 8.48633)" fill="white"/>
                  <rect x="3.94141" y="1.06055" width="1.5" height="6" transform="rotate(-45 3.94141 1.06055)" fill="white"/>
                </svg>
                <span class="visuallyhidden">Next</span>
              </a>
            </div>
          </div>
        </transition>
      </main>
    </div>
    <menuprimary current-nav="overview"></menuprimary>
  </div><!-- end of #Overview-->
</template>

<script>
  import MenuPrimary from '../components/menu-primary';
  import Slides from '../data/planet-slides';
  import anime from 'animejs';
  import Hammer from '../assets/js/hammer.min';

  export default {
    name: "overviewComponent",
    components: {
      'menuprimary': MenuPrimary
    },
    data() {
      return {
        activeSlide: 0,
        nextSlide: '',
        prevSlide: '',
        doneLoadAni: false,
        isSlideAnimating: false,
        instanceAnime: '',
        slides: Slides.data()['Slides'],
        slidesLength: Slides.data()['Slides'].length,
        savedActiveIndex: false,
        slideIsMoving: false,
        loadAni:'loading-animation',
        mc:'',
        bgSize:'',
        bgStep:'',
        bgPosX:0,
        resizeDelay: 200,
        rtime:'',
        resizeTimeout: false,
        slideW: '',
        slideNewX:'',
        slideNewY: '',
        slideEl: '',
        slideInitX:'',
        slideInitY:'',
        bg:'',
        bgNewX:'',
        animationFrame: undefined,
        animation: null
      }
    },
    created() {

      if(localStorage.AstroActivePlanet) {
        this.savedActiveIndex = parseInt(localStorage.AstroActivePlanet);
        localStorage.AstroActivePlanet = '';
        this.activeSlide = this.savedActiveIndex;
        this.loadAni = 'loading-animation-back';
      }
      this.updateSlideItemStyle();
    },
    methods: {
      handleSlideChange: function(val) {
        //let direction;
        const calculatedNextSlide = this.activeSlide + val
        if (val > 0) {
          //direction = "next";
          if(calculatedNextSlide < this.slides.length) {
            this.activeSlide += val
            document.querySelector('.overview__prev').classList.remove('disabled');
            if(calculatedNextSlide == this.slides.length - 1){
              document.querySelector('.overview__next').classList.add('disabled');
            }
          } else {
            this.activeSlide = this.slides.length - 1
            this.nextSlide = ''
          }

        } else {
          //direction = "previous";
          if(calculatedNextSlide < 0) {
            this.activeSlide = 0

          } else {
            document.querySelector('.overview__next').classList.remove('disabled');
            this.activeSlide += val
            if(calculatedNextSlide == 0){
              document.querySelector('.overview__prev').classList.add('disabled');
            }
          }
        }

        //this.prevSlide = this.activeSlide - 1 < 0 ? '' : this.activeSlide - 1
        //this.nextSlide = this.activeSlide + 1 > this.slides.length - 1 ? '' : this.activeSlide + 1
      },
      onTap: function (e) {
        this.removeAni();
        var _classList = e.target.classList
        if(_classList.contains('overview__slide-item')) {
          if(_classList.contains('active')) {
            e.target.querySelector('a').click()
          } else if(_classList.contains('prev')) {
            this.activeSlide -= 1
            this.moveSlideOne(-1)
          } else if(_classList.contains('next')) {
            this.activeSlide += 1
            this.moveSlideOne(1)
          }
        }
      },
      moveSlideOne: function (dir) {
        var el = document.querySelector('.overview__slide')
        var elWidth = el.offsetWidth
        var posX = el.offsetLeft
        var posY = el.offsetTop
        //var _x,_y, _xBg;
        var _x,_y;

        if(dir > 0) {
          //next
          if(window.innerWidth > 767){
            _x = (elWidth * 1.9) * -1 + posX
            _y = posY
          } else {
            _x = elWidth * -1 + posX
            _y = elWidth + posY
          }
          //_xBg = this.bgStep * -1 + this.bg.offsetLeft
        } else {
          //prev
          if(window.innerWidth > 767){
            _x = posX - (elWidth * 1.9) * -1
            _y = posY
          } else {
            _x = posX - elWidth * -1
            _y = posY - elWidth
          }

          //_xBg = this.bg.offsetLeft - this.bgStep * -1
        }

        this.moveSlideAni(_x,_y);
      },
      moveSlideAni: function (_x,_y) {
        var _this = this;

        _this.animation = anime({
                            targets: '.overview__slide',
                            top: _y,
                            left: _x,
                            duration: 750,
                            easing: 'easeOutSine',
                            begin: function() {
                              //_this.slideIsMoving = true;
                              // _this.isSlideAnimating = true
                            },
                            complete: function() {
                              _this.slideIsMoving = false;
                              //  _this.isSlideAnimating = false
                            }
                          });
      },
      setSlideStyle: function (dist, style) {
        if(window.innerWidth > 767){
          var _x = 190 * dist
          var _y = 0
        } else {
          _x = 100 * dist
          _y = -100 * dist
        }

        if(style){
          return 'translate3d('+ _x +'%,'+_y +'%, 0)'
        } else {
          return { 'transform' : 'translate3d('+ _x +'%,'+_y +'%, 0)'}
        }

      },
      updateSlideItemStyle: function () {
        var dist = 0;
        for (var i = 0; i < this.slides.length; i++) {
          this.rePaint = i
          if (i == 0) {
            dist = this.activeSlide * -1
          } else {
            dist = i - this.activeSlide
          }

          if(window.innerWidth < 768){
            this.slides[i].style = this.setSlideStyle(dist, false)
          } else {
            this.slides[i].style = this.setSlideStyle(i, false)
          }

        }
      },
      updateSlideItemOnResize: function (){
        if(!this.savedActiveIndex){
          for (var i = 0; i < this.slides.length; i++) {
            document.querySelectorAll('.overview__slide-item')[i].style.transform = this.setSlideStyle(i, true)
          }
        }
      },
      afterLoadAni: function () {
        this.doneLoadAni = true
      },
      initPan: function() {
        let lastPosX = 0;
        let lastPosY = 0;
        //let lastBgPosX = 0;
        let isDragging = false;
        let el = this.slideEl
        //let bg = this.bg

        this.mc.on('pan', (e) => {
          this.removeAni();
          if ( ! isDragging ) {
            isDragging = true;
            lastPosX = el.offsetLeft;
            lastPosY = el.offsetTop;
            //lastBgPosX = bg.offsetLeft;
          }

          let posX = e.deltaX + lastPosX
          let posY = lastPosY + (e.deltaX * -1)

          //console.log('e.deltaX',e.deltaX);
          // console.log('posX',posX);
          //let posBgX = e.deltaX + lastBgPosX

          el.style.left = posX + 'px';

          if(window.innerWidth < 768){
            el.style.top = posY + 'px';
          }
          //   bg.style.left = posBgX + 'px';

          if(e.isFinal) {
            isDragging = false

            //move to back
            if(e.deltaX > 0 ) {
              this.handleSlideChange(-1)
            }
            //move to next
            if(e.deltaX < 0) {
              this.handleSlideChange(1)
            }
            this.updateSlidePos();
          }
        })
      },
      updateSlidePos: function() {
        this.calNewPos();
        if(this.savedActiveIndex && this.savedActiveIndex == this.activeSlide ||
          !this.savedActiveIndex && this.activeSlide == 0
        ) {
          if(window.innerWidth > 767){
            this.moveSlideAni(this.slideInitX)
          } else {
            this.moveSlideAni(this.slideInitX, this.slideInitY)
          }
        } else {
          if(window.innerWidth > 767){
            this.moveSlideAni(this.slideNewX);
          } else {
            this.moveSlideAni(this.slideNewX, this.slideNewY)
          }

        }
        this.calNewBgPos();
      },
      resizeSlidePos: function() {
        if(this.savedActiveIndex && this.savedActiveIndex == this.activeSlide ||
          !this.savedActiveIndex && this.activeSlide == 0) {
          this.slideEl.style.left = ''
          this.slideEl.style.top = ''
        } else {
          this.slideEl.style.left = this.slideNewX + 'px'

          if(window.innerWidth < 768){
            this.slideEl.style.top = this.slideNewY + 'px'
          } else {
            this.slideEl.style.top = ''
          }

        }
      },
      calNewPos: function() {
        const slideStage = document.querySelector(".overview__slide-wrap")
        var slideW = this.slideEl.offsetWidth
        var footerH = document.querySelector('.nav-bottom').offsetHeight
        this.slideInitX = (slideStage.offsetWidth - slideW) / 2
        this.slideInitY = (window.innerHeight - footerH - slideW) / 2

        this.slideNewY = slideW * (this.activeSlide - this.savedActiveIndex) + this.slideInitY

        if(window.innerWidth > 767){
          /* the gap between two slides is 90% of a slide, so a slide and his gap is 190% of a slide width */
          this.slideNewX = (slideW * 1.9) * (this.activeSlide - this.savedActiveIndex) * -1 + this.slideInitX
        } else {
          this.slideNewX = slideW * (this.activeSlide - this.savedActiveIndex) * -1 + this.slideInitX
        }

      },
      calNewBgPos: function() {
        this.bgNewX = this.bgStep * this.activeSlide  * -1
      },
      setBodyHeight: function() {
        //console.log('window.innerHeight',window.innerHeight)
        document.getElementById('overview').style.height = window.innerHeight + 'px'
      },
      removeAni: function() {
        anime.remove('.overview__slide');
        anime.remove('.overview__bg');
      },
      positionBackground: function () {
        let defaultX = '';
        let  x = this.slideEl.style.left;
        let distUnit = 10
        x = x.split('px')[0];
        if(x.length > 0) {
          this.bg.style.left = x / distUnit + defaultX + 'px';
        } else {
          let lastPosX = this.slideEl.offsetLeft;
          defaultX = lastPosX / distUnit
          this.bg.style.left = defaultX + 'px';
        }

        this.animationFrame = requestAnimationFrame(this.positionBackground);
      },
      goToNextSlide: function (_this){
        document.querySelector('.overview__next').addEventListener('click',function(e){
          e.preventDefault();
          if(!_this.slideIsMoving) {
            _this.slideIsMoving = true;
            _this.activeSlide += 1;
            _this.moveSlideOne(1);

            if(_this.slidesLength - 1 == _this.activeSlide){
              document.querySelector('.overview__next').classList.add('disabled');
            }

            if(_this.activeSlide == 1){
              document.querySelector('.overview__prev').classList.remove('disabled');
            }
          }
        })

      },
      goToPrevSlide: function (_this){
        document.querySelector('.overview__prev').addEventListener('click',function(e){
          e.preventDefault();

          if(!_this.slideIsMoving) {
            _this.slideIsMoving = true;

            setTimeout(function (){
              _this.slideIsMoving = false;
            },720);
            if(_this.activeSlide == 1){
              document.querySelector('.overview__prev').classList.add('disabled');
            }

            document.querySelector('.overview__next').classList.remove('disabled');

            _this.activeSlide -= 1;
            _this.moveSlideOne(-1);
          }
        })
      },
      moveToSlide: function (_this, index, clicked){
        const slideStage = document.querySelector(".overview__slide-wrap")
        var clickedIndex = index;
        var slideElWidth = _this.slideEl.offsetWidth;
        var slideWrapInitX = (slideStage.offsetWidth - slideElWidth) / 2;
        var _x,_y;

        if(clicked){
          var condition = clickedIndex != _this.activeSlide
        } else {
          condition = true;
        }


        if(condition){
          if(window.innerWidth > 767){
            _x = ((slideElWidth * 1.9) * clickedIndex * -1) + slideWrapInitX
            _y = _this.slideEl.offsetTop
          }

          _this.activeSlide = clickedIndex;
          _this.moveSlideAni(_x,_y);
          _this.calNewPos();
        }
      },
      navSlide: function (_this){
        document.querySelectorAll('.overview__nav-item').forEach(function (item){
          item.addEventListener('click', function(){
            var clickedIndex = parseFloat(item.getAttribute('index'));

            if(clickedIndex== 0){
              document.querySelector('.overview__prev').classList.add('disabled');
            } else {
              document.querySelector('.overview__prev').classList.remove('disabled');

              if(clickedIndex == _this.slidesLength - 1){
                document.querySelector('.overview__next').classList.add('disabled');
              } else {
                document.querySelector('.overview__next').classList.remove('disabled');
              }
            }


            _this.moveToSlide(_this, clickedIndex, true);

          });
        });

      },
      paginationPosition: function (){
        if(document.getElementsByClassName('isIOS').length == 1 || this.isIpadOS() || window.innerWidth < 767) {
          var heightWithoutFooter = window.innerHeight - document.querySelector('.nav-bottom').offsetHeight;
          document.querySelector('.overview__slide-wrap').style.height = heightWithoutFooter + 'px';
          if(window.innerWidth > window.innerHeight && !this.isIpadOS()){
            var windowHHalf = window.innerHeight / 2;

            document.getElementsByClassName('bottom-wrap')[0].style.bottom = ((heightWithoutFooter - windowHHalf) / 2) * 0.3  + "px";
          } else {
            document.getElementsByClassName('bottom-wrap')[0].style.bottom = "";
          }
        }
      }
    },
    beforeRouteLeave (to, from, next) {
      window.cancelAnimationFrame(this.animationFrame);
      localStorage.setItem('lunaIndex', '');
      localStorage.setItem('sunIndex', '');
      this.aniMenuPageLeave(next());
    },
    mounted() {
      const slideStage = document.querySelector(".overview__slide-wrap")
      const mc = new Hammer.Manager(slideStage);
      this.slideEl = this.$el.querySelector('.overview__slide')
      this.bg = this.$el.querySelector('.overview__bg')
      this.bgImg = this.$el.querySelector('.overview__bg-img')
      this.setBodyHeight();
      this.goToNextSlide(this);
      this.goToPrevSlide(this);
      this.navSlide(this);

      if(this.activeSlide == 0){
        document.querySelector('.overview__prev').classList.add('disabled');
      } else {
        document.querySelector('.overview__prev').classList.remove('disabled');

        if(this.activeSlide == this.slidesLength - 1){
          document.querySelector('.overview__next').classList.add('disabled');
        } else {
          document.querySelector('.overview__next').classList.remove('disabled');
        }

        this.moveToSlide(this,this.activeSlide,false);
      }

      mc.add( new Hammer.Pan({ direction: Hammer.DIRECTION_ALL}) );
      mc.add( new Hammer.Tap() );
      //mc.add( new Hammer.Pinch({ threshold: 0 }) );
      //mc.domEvents = true
      mc.on('tap', (e) => {
        if(!this.slideIsMoving){
          this.removeAni()
          // console.log(e)
          //if(!this.isSlideAnimating) {
          if(e.tapCount > 1) {
            //  console.log('multitap')
            e.preventDefault();
            return false
          } else {
            this.onTap(e)
          }
        }

        // }
      })
      this.mc = mc;
      this.initPan();
      this.positionBackground();

      this.paginationPosition();

      if(this.mobileAndTabletcheck() || this.isIpadOS()){
        document.body.classList.add('mobile');
      } else {
        document.body.classList.remove('mobile');
      }



      window.onresize = () => {
        this.updateSlideItemOnResize();
        this.resizeSlidePos();
        this.setBodyHeight();
        this.moveToSlide(this,this.activeSlide,false);
      }

      window.onorientationchange = () => {
        var _this = this;
        if(document.getElementsByClassName('isIOS').length == 1 || this.isIpadOS() || window.innerWidth < 767) {
          setTimeout(function (){
            _this.paginationPosition();
          },100)
        }

        setTimeout(function (){
          _this.resizeSlidePos();
        },100)


      };

      /**
       * Images Preload at first load only image using for overview
       */
      var preloadOvewview = [
        '/assets/img/sun-spots/thumb.jpg',
        '/assets/img/lunar-phases/thumb.jpg',
        '/assets/img/stars-map/thumb.jpg',
        '/assets/img/jupiters-moons/thumb.jpg',
        '/assets/img/astrolab/thumb.jpg',
        '/assets/img/uranometria/thumb.jpg',
        '/assets/img/harmonia/thumb.jpg',
        '/assets/img/inkunabel/thumb.jpg',
        '/assets/img/atlas-novus/thumb.jpg',
        '/assets/img/halley-comet/thumb.jpg',
        '/assets/img/sun-spots/thumb--small.jpg',
        '/assets/img/lunar-phases/thumb--small.jpg',
        '/assets/img/stars-map/thumb--small.jpg',
        '/assets/img/jupiters-moons/thumb--small.jpg',
        '/assets/img/astrolab/thumb--small.jpg',
        '/assets/img/uranometria/thumb--small.jpg',
        '/assets/img/harmonia/thumb--small.jpg',
        '/assets/img/inkunabel/thumb--small.jpg',
        '/assets/img/atlas-novus/thumb--small.jpg',
        '/assets/img/halley-comet/thumb--small.jpg'
      ];
      preloadOvewview.forEach((f) => {
        const Http = new XMLHttpRequest();
        Http.open("GET", f);
        Http.send();
      })

      /**
       * Images Preload after overview finished to load
       */
      var preload = [
        '/assets/img/layout/logo_bosa.png',
        '/assets/img/layout/logo_erara.svg',
        '/assets/img/sun-spots/bg-info.jpg',
        '/assets/img/sun-spots/christoph-scheiner.jpg',
        '/assets/img/sun-spots/sun.jpg',
        '/assets/img/lunar-phases/mond-40.jpg',
        '/assets/img/lunar-phases/bg-info.jpg',
        '/assets/img/lunar-phases/mond-25.jpg',
        '/assets/img/lunar-phases/mond-31.jpg',
        '/assets/img/lunar-phases/mond-19.jpg',
        '/assets/img/lunar-phases/mond-18.jpg',
        '/assets/img/lunar-phases/mond-30.jpg',
        '/assets/img/lunar-phases/mond-24.jpg',
        '/assets/img/lunar-phases/mond-32.jpg',
        '/assets/img/lunar-phases/mond-26.jpg',
        '/assets/img/lunar-phases/bg-detail.jpg',
        '/assets/img/lunar-phases/mond-27.jpg',
        '/assets/img/lunar-phases/mond-33.jpg',
        '/assets/img/lunar-phases/mond-37.jpg',
        '/assets/img/lunar-phases/mond-23.jpg',
        '/assets/img/lunar-phases/mond-22.jpg',
        '/assets/img/lunar-phases/mond-36.jpg',
        '/assets/img/lunar-phases/mond-08.jpg',
        '/assets/img/lunar-phases/mond-20.jpg',
        '/assets/img/lunar-phases/mond-34.jpg',
        '/assets/img/lunar-phases/mond-35.jpg',
        '/assets/img/lunar-phases/mond-21.jpg',
        '/assets/img/lunar-phases/mond-09.jpg',
        '/assets/img/lunar-phases/mond-04.jpg',
        '/assets/img/lunar-phases/mond-10.jpg',
        '/assets/img/lunar-phases/mond-38.jpg',
        '/assets/img/lunar-phases/mond-39.jpg',
        '/assets/img/lunar-phases/mond-11.jpg',
        '/assets/img/lunar-phases/mond-05.jpg',
        '/assets/img/lunar-phases/mond-13.jpg',
        '/assets/img/lunar-phases/mond-07.jpg',
        '/assets/img/lunar-phases/mond-06.jpg',
        '/assets/img/lunar-phases/mond-12.jpg',
        '/assets/img/lunar-phases/mond-16.jpg',
        '/assets/img/lunar-phases/mond-02.jpg',
        '/assets/img/lunar-phases/mond-03.jpg',
        '/assets/img/lunar-phases/mond-17.jpg',
        '/assets/img/lunar-phases/mond-29.jpg',
        '/assets/img/lunar-phases/mond-01.jpg',
        '/assets/img/lunar-phases/mond-15.jpg',
        '/assets/img/lunar-phases/mond-14.jpg',
        '/assets/img/lunar-phases/mond-28.jpg',
        '/assets/img/lunar-phases/johannes-hevelius.jpg',
        '/assets/img/stars-map/bg-info.jpg',
        '/assets/img/stars-map/bg-plate.jpg',
        '/assets/img/stars-map/bg-plate-zoom.jpg',
        '/assets/img/stars-map/plate.jpg',
        '/assets/img/stars-map/plate-zoom.jpg',
        '/assets/img/stars-map/bg-info.jpg',
        '/assets/img/stars-map/bg-plate.jpg',
        '/assets/img/stars-map/plate.jpg',
        '/assets/img/stars-map/peter-apian.jpg',
        '/assets/img/jupiters-moons/03_7h.jpg',
        '/assets/img/jupiters-moons/17_1h.jpg',
        '/assets/img/jupiters-moons/bg-info.jpg',
        '/assets/img/jupiters-moons/11_5.5h.jpg',
        '/assets/img/jupiters-moons/15_1h.jpg',
        '/assets/img/jupiters-moons/09_0.5h.jpg',
        '/assets/img/jupiters-moons/07_1h.jpg',
        '/assets/img/jupiters-moons/10_1.5h.jpg',
        '/assets/img/jupiters-moons/galileo-galilei.jpg',
        '/assets/img/jupiters-moons/12_0.67h.jpg',
        '/assets/img/jupiters-moons/15_6h.jpg',
        '/assets/img/jupiters-moons/11_1h.jpg',
        '/assets/img/jupiters-moons/27_1h.jpg',
        '/assets/img/jupiters-moons/25_1.5h.jpg',
        '/assets/img/jupiters-moons/26_5h.jpg',
        '/assets/img/jupiters-moons/04_2h.jpg',
        '/assets/img/jupiters-moons/13_0.5h.jpg',
        '/assets/img/jupiters-moons/28_5h.jpg',
        '/assets/img/jupiters-moons/19_0.67h.jpg',
        '/assets/img/jupiters-moons/26_0.5h.jpg',
        '/assets/img/jupiters-moons/21_1.5h.jpg',
        '/assets/img/jupiters-moons/01_2h.jpg',
        '/assets/img/jupiters-moons/08_1h.jpg',
        '/assets/img/jupiters-moons/02_7h.jpg',
        '/assets/img/jupiters-moons/11_3h.jpg',
        '/assets/img/jupiters-moons/06_1h.jpg',
        '/assets/img/jupiters-moons/15_5h.jpg',
        '/assets/img/jupiters-moons/18_1h.jpg',
        '/assets/img/jupiters-moons/04_7h.jpg',
        '/assets/img/jupiters-moons/02_1h.jpg',
        '/assets/img/jupiters-moons/18_6h.jpg',
        '/assets/img/jupiters-moons/28_1h.jpg',
        '/assets/img/jupiters-moons/16_6h.jpg',
        '/assets/img/jupiters-moons/jupiter-icon-nav.png',
        '/assets/img/astrolab/bg-info.jpg',
        '/assets/img/astrolab/bg-plate.jpg',
        '/assets/img/astrolab/handle.jpg',
        '/assets/img/astrolab/handle-zoom.jpg',
        '/assets/img/astrolab/plate-zoom.jpg',
        '/assets/img/astrolab/plate.jpg',
        '/assets/img/astrolab/schoener.jpg',
        '/assets/img/astrolab/bg-plate-zoom.jpg',
        '/assets/img/harmonia/bg-info.jpg',
        '/assets/img/harmonia/cellarius.jpg',
        '/assets/img/harmonia/harmonia.jpg',
        '/assets/img/harmonia/image_brahe.png',
        '/assets/img/harmonia/image_copernicus.png',
        '/assets/img/harmonia/image_ptolemaus.png',
        '/assets/img/uranometria/bayer.jpg',
        '/assets/img/uranometria/bg-info.jpg',
        '/assets/img/uranometria/earth.jpg',
        '/assets/img/uranometria/earth_hd.jpg',
        '/assets/img/uranometria/galaxy_starfield.png',
        '/assets/img/uranometria/zoom_icon.jpg',
        '/assets/img/uranometria/zoom_icon_alpha.jpg',
        '/assets/img/uranometria/details/s_fisch.jpg',
        '/assets/img/uranometria/details/s_jungfrau.jpg',
        '/assets/img/uranometria/details/s_krebst.jpg',
        '/assets/img/uranometria/details/s_loewe.jpg',
        '/assets/img/uranometria/details/s_schuetze.jpg',
        '/assets/img/uranometria/details/s_skorpion.jpg',
        '/assets/img/uranometria/details/s_steinbock.jpg',
        '/assets/img/uranometria/details/s_stier.jpg',
        '/assets/img/uranometria/details/s_waage.jpg',
        '/assets/img/uranometria/details/s_wassermann.jpg',
        '/assets/img/uranometria/details/s_widder.jpg',
        '/assets/img/uranometria/details/s_zwillinge.jpg',
        '/assets/img/uranometria/thumbs/s_fisch.jpg',
        '/assets/img/uranometria/thumbs/s_jungfrau.jpg',
        '/assets/img/uranometria/thumbs/s_krebst.jpg',
        '/assets/img/uranometria/thumbs/s_loewe.jpg',
        '/assets/img/uranometria/thumbs/s_schuetze.jpg',
        '/assets/img/uranometria/thumbs/s_skorpion.jpg',
        '/assets/img/uranometria/thumbs/s_steinbock.jpg',
        '/assets/img/uranometria/thumbs/s_stier.jpg',
        '/assets/img/uranometria/thumbs/s_waage.jpg',
        '/assets/img/uranometria/thumbs/s_wassermann.jpg',
        '/assets/img/uranometria/thumbs/s_widder.jpg',
        '/assets/img/uranometria/thumbs/s_zwillinge.jpg',
        '/assets/img/inkunabel/sun.png',
        '/assets/img/inkunabel/earth.png',
        '/assets/img/inkunabel/bg-info.jpg',
        '/assets/img/inkunabel/moonphases/moonphase-01.png',
        '/assets/img/inkunabel/moonphases/moonphase-02.png',
        '/assets/img/inkunabel/moonphases/moonphase-03.png',
        '/assets/img/inkunabel/moonphases/moonphase-04.png',
        '/assets/img/inkunabel/moonphases/moonphase-05.png',
        '/assets/img/inkunabel/moonphases/moonphase-06.png',
        '/assets/img/inkunabel/moonphases/moonphase-07.png',
        '/assets/img/inkunabel/moonphases/moonphase-08.png',
        '/assets/img/inkunabel/moonphases/moonphase-09.png',
        '/assets/img/inkunabel/moonphases/moonphase-10.png',
        '/assets/img/inkunabel/moonphases/moonphase-10-01.png',
        '/assets/img/inkunabel/moonphases/moonphase-10-02.png',
        '/assets/img/inkunabel/moonphases/moonphase-11.png',
        '/assets/img/inkunabel/moonphases/moonphase-12.png',
        '/assets/img/inkunabel/moonphases/moonphase-13.png',
        '/assets/img/inkunabel/details/lunar-eclipse.png',
        '/assets/img/inkunabel/details/solar-eclipse.png',
        '/assets/img/inkunabel/brackets.svg',
        '/assets/img/inkunabel/georg-von-peuerbach.png',
        '/assets/img/atlas-novus/bg-info.jpg',
        '/assets/img/atlas-novus/trace-map.png',
        '/assets/img/atlas-novus/johann-gabriel-doppelmayer.png',
        '/assets/img/atlas-novus/beam.svg',
        '/assets/img/atlas-novus/earth.svg',
        '/assets/img/atlas-novus/venus.svg',
        '/assets/img/halley-comet/apian_comet.png',
        '/assets/img/halley-comet/bg-info.jpg'
      ];

      window.addEventListener('load', () => {
        preload.forEach((f) => {
          const Http = new XMLHttpRequest();
          Http.open("GET", f);
          Http.send();
        })
      })

    }
  }
</script>

