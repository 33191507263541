<template>
  <div id="moon-passing" class="work-page"
       :class="{ 'slider-active' : isSliderActive, 'detail-loaded' : isDetailVisible, 'work-page-loaded': isPageLoaded, 'work-library-modal': showModal}"
       @click="closeWorkShare">
    <div class="work__bg-wrap">
      <div class="work__bg">
        <img src="/assets/img/layout/bg-overview.jpg" class="work__bg-img"
             alt="work background image">
      </div>
    </div>
    <div class="detail__bg"
         style="background-image: url('/assets/img/moon-passing/bg-info.jpg')"></div>
    <div class="container">
      <div class="page__top">
        <h1 class="page-top__title">{{ $t('moon-passing.title') }}</h1>
        <p class="page-top__text">Selenographia <br/>(Hevelius, 1647) </p>
      </div>
      <main class="main main--moon-passing">
        <div class="work-content work-intro-ani-in">
          <div class="work__scaleable work--main"
               :style="'height:'+ slideHeightLive +'px; width:'+ slideWidthLive +'px; margin-top:'+ marginTop +'px;'">
            <div v-for="(slide, index) in thisSlides" class="moon-passing__jupiter" :key="index"
                 v-if="index == activeSlide">
              <img :src="'/assets/img/moon-passing/' + slide.jupiter" alt="jupiter">
            </div>
            <div class="moon-passing-wrap">
              <div v-for="(slide, index) in thisSlides" class="moon-passing__moon-img"
                   :style="'left:' + slide.left + ';'" :key="index" v-if="index == activeSlide">
                <img src="/assets/img/moon-passing/moon.png" alt="moon">
              </div>
            </div>
            <div class="moon-passing__line"
                 :style="'transform: rotate(' + lineRotate + 'deg);'"></div>
          </div>
          <div class="range-slider range-slider--horizontal scale-item"
               :style="'width:'+ rangeWidthLive +'px'">
            <div class="range-slider__handle-track--horizontal"
                 :style="'transform: translateX(' + currentHandleLeft + 'px)'" ref="handle-track">
              <div class="range-slider__handle--horizontal">
                <span class="sicon sicon__slide-handle" aria-hidden="true"></span>
                <span class="visuallyhidden">Slide Handle</span>
              </div>
            </div>
            <div class="range-slider__track--horizontal">
              <div class="range-slider__track-line"></div>
            </div>
            <div id="range-interact"></div>
          </div>
          <div class="work__legend">
            <span v-for="(slide, index) in thisSlides" class="moon-passing__legend-span"
                  :key="index" v-if="index == activeSlide">{{ slide.legend }}</span>
            <span class="moon-passing__date">12. April 1647</span>
          </div>
          <div class="detail__work"
               :style="'height:'+ slideHeightLive +'px; width:'+ slideWidthLive +'px; margin-top:'+ marginTop +'px;'">
            <div class="mask-layer-wrap scale-item">
              <svg preserveAspectRatio="xMaxYMin meet" viewBox="0 0 2000 1756" xml:space="preserve"
                   class="mask-layer__top">
                <image xlink:href="/assets/img/moon-passing/mask-bg.jpg" width="2000" height="1756"/>
                <g v-html="this.thisSlides[this.activeSlide].clipath"></g>
              </svg>
            </div>
          </div>
        </div>
        <detail v-on:show-modal="showModalMsg"></detail>
      </main>
    </div>
    <menuprimary current-nav="moon-passing" :needNavprojects=true
                 v-on:toggle-detail="toggleDetail"></menuprimary>
    <div class="top-tool-nav-wrap">
      <div @click="toggleDetail" v-if="isDetailVisible" class="btn-close-circle" aria-hidden="true">
        <span class="visuallyhidden">close detail content</span>
      </div>
      <share-section v-if="isDetailVisible"></share-section>
      <router-link to="overview" class="btn-close-circle" aria-hidden="true" v-else>
        <span class="visuallyhidden">go to overview page</span>
      </router-link>
      <router-link :to="'zoom-moon-passing/'+this.activeSlide" class="zoom-btn">
        <span class="sicon sicon__zoom-more" aria-hidden="true"></span>
        <span class="visuallyhidden">Link zu zoomed img</span>
      </router-link>
    </div>
    <modal v-if="showModal" url="https://doi.org/10.3931/e-rara-238"
           @close="showModal=false"></modal>
  </div>
</template>

<script>
import moonPassingData from '../../data/moon-passing';
import iMoonPassing from '../info/i-moon-passing';
import Modal from '../../components/modal';
import MenuPrimary from '../../components/menu-primary';
import Slides from '../../data/planet-slides';
import ShareSection from '../../components/share';

import anime from 'animejs';
import Hammer from '../../assets/js/hammer.min';

export default {
  name: 'moonPassingComponent',
  components: {
    'detail': iMoonPassing,
    'modal': Modal,
    'menuprimary': MenuPrimary,
    'share-section': ShareSection
  },
  data() {
    return {
      lineRotate: -59,
      activeSlide: 0,
      printIndex: 1,
      bg: false,
      isSliderActive: false,
      thisSlides: moonPassingData.data()['slides'],
      currentHandleLeft: 0,
      showModal: false,
      isDetailVisible: false,
      windowWidth: 0,
      marginTop: 0,
      rangeWidthLive: 0,
      slideWidthLive: 0,
      slideHeightLive: 0,
      slides: Slides.data()['Slides'],
      legendBottom: '',
      workPosLive: '',
      zoomEl: '',
      mc: '',
      pinchTimmer: 0,
      isPageLoaded: false,
      instAniInterval: null,
      instAniLoadTimer: null,
      pageLoadTimer: null,
    }
  },
  created() {
    this.currentHandleLeft = 0;
    this.isMobile = this.mobileAndTabletcheck()
  },
  methods: {
    instractionAni: function () {
      var step = 0;
      var _this = this;
      this.instAniInterval = setInterval(frame, 150);

      function frame() {
        if (step == 6 || _this.isDetailVisible) {
          _this.clearIntervalInstAni()

          if (_this.isDetailVisible) {
            _this.isSliderActive = false;
          }
        } else {
          if ((step >= 3 && step < 6)) {
            //3,4,5
            _this.stepDown()
          } else {
            //0,1,2,
            _this.stepUp()
          }
        }
        step++;
      }
    },
    clearIntervalInstAni: function () {
      if (this.instAniInterval) {
        clearInterval(this.instAniInterval);
      }
      if (this.instAniLoadTimer) {
        clearTimeout(this.instAniLoadTimer);
      }
    },
    stepUp: function () {
      this.activeSlide++
      if (this.activeSlide > this.thisSlides.length - 1) {
        this.activeSlide = this.thisSlides.length - 1
      }
      this.getHandlePos();
    },
    stepDown: function () {
      this.activeSlide--
      if (this.activeSlide < 0) {
        this.activeSlide = 0
      }
      this.getHandlePos();
    },
    getHandlePos: function () {
      var thisSlidesLength = this.thisSlides.length - 1;
      var rangeHandle = this.$el.querySelector('.range-slider__handle--horizontal');
      var totalSlideWidth = this.$el.querySelector('.range-slider__track-line').clientWidth - rangeHandle.clientWidth;
      var stepPos = totalSlideWidth / thisSlidesLength;
      this.currentHandleLeft = this.activeSlide * stepPos
      this.isSliderActive = true;
      //wait until update finish (for offsetLeft)
      this.$nextTick(function () {
        this.setLineRotate();
      });
    },
    rangeHandleOnPan: function (e) {
      if (!this.isSliderActive) {
        this.isSliderActive = true
      }

      // eslint-disable-next-line no-undef
      gtag('event', 'rangeSlider', {
        'event_category': 'moon-passing',
        'event_label': 'moon-passing',
      });

      var rangeHandle = this.$el.querySelector('.range-slider__handle--horizontal');
      var slidesLength = this.thisSlides.length - 1;
      var totalSlideWidth = this.$el.querySelector('.range-slider__track-line').clientWidth - rangeHandle.clientWidth;
      var stepPos = totalSlideWidth / slidesLength;
      var rangeInteract = this.$el.querySelector('#range-interact').getBoundingClientRect();
      var posOnPan = e.center.x + (rangeInteract.left * -1) - rangeHandle.clientWidth / 2;
      if (posOnPan >= totalSlideWidth) {
        posOnPan = totalSlideWidth;
      }

      if (posOnPan <= 0) {
        posOnPan = 0;
      }
      this.currentHandleLeft = posOnPan;
      var _curSlideIndex = Math.round(posOnPan / stepPos);
      this.setLineRotate();
      if (this.activeSlide != _curSlideIndex) {
        this.activeSlide = _curSlideIndex;
      } else {
        return false
      }
    },
    showModalMsg: function () {
      this.showModal = true
    },
    toggleDetail: function () {
      if (!this.isDetailVisible) {
        // eslint-disable-next-line no-undef
        gtag('config', this.uaId, {'page_path': this.$route.path + '-i-detail'});
      } else {
        // eslint-disable-next-line no-undef
        gtag('config', this.uaId, {'page_path': this.$route.path});

        var audio = this.$el.querySelectorAll('audio')[0];
        if (!audio.paused) {
          audio.pause();
        }
      }

      var _this = this;
      this.isDetailVisible = !this.isDetailVisible
      var _scale = 1;
      var workHTo = window.innerHeight - this.$el.querySelector('.nav-bottom').clientHeight;
      var translateScaledWork = 0;
      if (this.isDetailVisible) {
        document.body.classList.add('detail-opened');
        _scale = 0.7;
        this.resetWorkZoom();
        workHTo = this.rangeWidthLive;
        translateScaledWork = 140;

        if (window.innerWidth < 1025) {
          translateScaledWork = 120;
          workHTo = this.rangeWidthLive + this.$el.querySelector('.page-top__title').clientHeight;
        }
      } else {
        document.body.classList.remove('detail-opened');
      }

      anime({
        targets: [this.$el.querySelectorAll('.scale-item')],
        scale: _scale,
        translateY: translateScaledWork,
        duration: 500,
        easing: 'easeOutQuart',
        complete: function () {
          _this.getRangeWidth();
        }
      });
      anime({
        targets: [this.$el.querySelector('.work-content')],
        height: workHTo,
        duration: 500,
        easing: 'easeOutQuart',
      });
    },
    resizeWorkDetail: function () {
      var work = this.$el.querySelector(".work-content");
      if (this.isDetailVisible) {
        if (window.innerWidth < 1025) {
          work.style.height = this.rangeWidthLive + this.$el.querySelector('.page-top__title').clientHeight;
        } else {
          work.style.height = this.rangeWidthLive + 'px';
        }
      }
    },
    getRangeWidth: function () {
      // const legend = this.$el.querySelector(".moon-passing__legend-span");

      const range = this.$el.querySelector(".range-slider--horizontal");
      //let legendH = legend.clientHeight;
      let legendH = 20;
      var pageTopPaddingTop = parseInt(getComputedStyle(this.$el.querySelector(".page__top")).paddingTop);
      var winW = window.innerWidth;
      var winH = window.innerHeight;
      var workH = winH - this.$el.querySelector(".nav-bottom").clientHeight;
      var imageRatio = 2000 / 1758;

      var availH = workH - this.$el.querySelector(".page__top").clientHeight - legendH - 60;

      if (winW > 1200 & winH < 750) {
        availH = workH - this.$el.querySelector(".page-top__title").clientHeight - pageTopPaddingTop * 2 - legendH - winH * 0.045 - 20;
      }

      var _bottomNav = parseInt(window.getComputedStyle(this.$el.querySelector(".nav-bottom")).getPropertyValue("bottom"))
      if (_bottomNav > 0) {
        availH = availH - _bottomNav
      }
      if (availH < 200) {
        availH = 200
      }

      var maxAailH = Math.min(winW, availH)
      this.slideHeightLive = maxAailH;
      this.slideWidthLive = this.slideHeightLive * imageRatio;

      if (winH > 1150) {
        this.slideWidthLive = maxAailH;
        this.slideHeightLive = this.slideWidthLive / imageRatio;
      }


      if (winW < 900 || this.slideWidthLive > this.$el.querySelector('.work-content').clientWidth) {
        var maxAailW = Math.min((winW - 40), availH - 12)

        this.slideWidthLive = maxAailW;
        this.slideHeightLive = this.slideWidthLive / imageRatio;
      }

      if (winW > winH && winW <= 926 && this.isMobile) {
        this.slideWidthLive = this.slideWidthLive * 0.9;
      }

      this.rangeWidthLive = this.slideWidthLive * 0.8256;

      if (winW < 768) {
        this.rangeWidthLive = this.slideWidthLive * 0.96538461538461538;
      }

      this.legendBottom = this.slideWidthLive * 0.02;

      if (!this.isDetailVisible) {
        this.$el.querySelector('.work-content').style.height = workH + 'px';
      }

      if (winW > 1200 && winH < 750) {
        range.style.bottom = this.legendBottom + legendH + 16 + "px";
      } else {
        range.style.bottom = this.legendBottom + legendH + range.clientHeight - 12 + "px";
      }

      if (winW < 1025) {
        this.legendBottom = this.slideWidthLive * 0.012;
        //this.marginTop = parseInt(getComputedStyle(this.$el.querySelector(".page-top__text")).marginBottom);
        range.style.bottom = parseInt(getComputedStyle(range).bottom) + "px";

        if (winW < 900) {
          this.marginTop = parseInt(getComputedStyle(this.$el.querySelector(".page-top__text")).marginBottom);
        }

        if (winW < 768) {
          this.marginTop = parseInt(getComputedStyle(this.$el.querySelector(".page-top__text")).marginBottom) + 10;
          this.legendBottom = this.slideWidthLive * 0.044;
          range.style.bottom = this.legendBottom + legendH + range.clientHeight - 2 + "px"
        }

        if (winW > winH && winW <= 926 && this.isMobile) {
          this.marginTop = 0;
          range.style.bottom = this.legendBottom + range.clientHeight - 6 + "px"
          this.legendBottom = this.slideWidthLive * 0.03;

        }
        if(winH <= 500 || winW <= 500) {
          range.style.bottom = _bottomNav + 14 + "px";
        }
      } else {
        this.marginTop = 0;
      }

    },
    resetWorkZoom: function () {
      var transform = "";
      this.zoomEl.style.transform = transform;
      this.zoomEl.style.webkitTransform = transform;
    },
    pageLoad: function () {
      this.isPageLoaded = true
      var _this = this
      this.pageLoadTimer = setTimeout(function () {
        _this.$el.querySelector(".work-intro-ani-in").classList.remove("work-intro-ani-in")
      }, 1600)
    },
    setLineRotate: function () {
      let moonWrap = this.$el.querySelector(".moon-passing__moon-img");
      let jupiterH = this.$el.querySelector(".moon-passing__jupiter").clientHeight / 2;
      let moonImg = this.$el.querySelector(".moon-passing__moon-img img");
      let workAreaHalf = this.slideWidthLive / 2;
      let moonImgH = moonImg.getBoundingClientRect().height;
      let moonImgW = moonImg.getBoundingClientRect().width;
      let moonCenterX = moonWrap.offsetLeft + moonImgW / 2;
      let moonCenterY = moonWrap.offsetTop + moonImgH / 2;
      let _w = workAreaHalf - moonCenterX;
      let _h = moonCenterY - jupiterH;
      this.lineRotate = Math.round(Math.atan2(_w, _h) * (180 / Math.PI), 2);
    },
  },
  beforeRouteLeave(to, from, next) {
    this.clearIntervalInstAni()
    if (this.pageLoadTimer) {
      clearTimeout(this.pageLoadTimer);
    }
    document.body.classList.remove('detail-opened');
    localStorage.setItem('moonPassingIndex', '')
    this.aniMenuPageLeave(next());
  },

  mounted() {
    this.saveCurrentPlanetToLocalStorage();
    this.zoomEl = this.$el.querySelector(".work__scaleable");
    const rangeInteract = this.$el.querySelector("#range-interact");
    const mc = new Hammer.Manager(rangeInteract);
    this.windowWidth = window.innerWidth

    mc.domEvents = true
    mc.add(new Hammer.Pan({direction: Hammer.DIRECTION_ALL, threshold: 0}));
    mc.add(new Hammer.Tap());
    mc.on('panmove', (e) => {
      var old = this.pinchTimmer
      var now = new Date().getTime(),
        delta = now - old;

      this.clearIntervalInstAni()

      //avoid pinch end -> pan
      if (delta > 100) {
        this.pinchTimmer = 0
        this.rangeHandleOnPan(e)
      }
    });

    mc.on('tap', (e) => {
      this.clearIntervalInstAni();
      let rangeHandle = this.$el.querySelector('.range-slider__handle--horizontal');
      let rangeHandlePosX = rangeHandle.getBoundingClientRect().left - (rangeHandle.getBoundingClientRect().width / 2);
      let posX = e.srcEvent.pageX;
      if (posX > rangeHandlePosX) {
        this.stepUp()
      } else {
        this.stepDown()
      }
    });

    this.mc = mc;
    this.getRangeWidth();
    this.pageLoad();
    this.setWorkBgPos();
    window.addEventListener("load", () => this.setLineRotate());
    if (localStorage.getItem('moonPassingIndex')) {
      var _this = this;
      _this.activeSlide = localStorage.getItem('moonPassingIndex');
      setTimeout(function () {
        _this.getHandlePos();
      }, 10);
    } else {
      this.instAniLoadTimer = setTimeout(this.instractionAni, 2000)
    }

    window.onresize = () => {
      if (this.isMobile && this.windowWidth == window.innerWidth) {
        return false
      }
      this.getRangeWidth();
      this.getHandlePos();
      this.resizeWorkDetail();
      this.setLineRotate();
      this.windowWidth = window.innerWidth
    }


    window.onorientationchange = () => {
      this.getRangeWidth();
      this.getHandlePos();
      this.setLineRotate();
    }
  }
}
</script>
