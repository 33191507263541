<script>
export default {
  name: 'venusStates',
  data: function () {
    return {
      slides: [
        {
          legend: '01.01.1710',
          posX: '208',
          posY: '1206',
          earthPosX: '773.3',
          earthPosY: '583.5',
          venusPosX: '882.3',
          venusPosY: '663.5',
          beamDeg: '36'
        },
        {
          legend: '01.01.1710',
          posX: '208',
          posY: '1206',
          earthPosX: '773.3',
          earthPosY: '583.5',
          venusPosX: '882.3',
          venusPosY: '663.5',
          beamDeg: '36'
        },
        {
          legend: '05.01.1710',
          posX: '206',
          posY: '1169',
          earthPosX: '764.3',
          earthPosY: '585.5',
          venusPosX: '874.6',
          venusPosY: '653.7',
          beamDeg: '31'
        },
        {
          legend: '12.01.1710',
          posX: '204',
          posY: '1111',
          earthPosX: '748.7',
          earthPosY: '590.3',
          venusPosX: '860',
          venusPosY: '638.6',
          beamDeg: '22'
        },
        {
          legend: "19.01.1710",
          posX: '201',
          posY: '1052',
          earthPosX: '734',
          earthPosY: '597',
          venusPosX: '842.7',
          venusPosY: '628.3',
          beamDeg: '14'
        },
        {
          legend: "26.01.1710",
          posX: '194',
          posY: '995',
          earthPosX: '718.5',
          earthPosY: '606',
          venusPosX: '824.7',
          venusPosY: '621.3',
          beamDeg: '5'
        },
        {
          legend: "02.02.1710",
          posX: '189',
          posY: '939',
          earthPosX: '704.5',
          earthPosY: '617',
          venusPosX: '803.7',
          venusPosY: '618.5',
          beamDeg: '-2'
        },
        {
          legend: "09.02.1710",
          posX: '183',
          posY: '887',
          earthPosX: '692.5',
          earthPosY: '630',
          venusPosX: '783',
          venusPosY: '620',
          beamDeg: '-10'
        },
        {
          legend: "16.02.1710",
          posX: '175.5',
          posY: '839.5',
          earthPosX: '681.5',
          earthPosY: '644.5',
          venusPosX: '763.5',
          venusPosY: '625.7',
          beamDeg: '-15'
        },
        {
          legend: "25.02.1710",
          posX: '167',
          posY: '794.5',
          earthPosX: '673.5',
          earthPosY: '659.5',
          venusPosX: '744',
          venusPosY: '635',
          beamDeg: '-23'
        },
        {
          legend: "02.03.1710",
          posX: '159.5',
          posY: '760.5',
          earthPosX: '665.5',
          earthPosY: '677',
          venusPosX: '728.5',
          venusPosY: '647.5',
          beamDeg: '-30'
        },
        {
          legend: "09.03.1710",
          posX: '151.5',
          posY: '732.5',
          earthPosX: '660.5',
          earthPosY: '694',
          venusPosX: '715',
          venusPosY: '664',
          beamDeg: '-33'
        },
        {
          legend: "16.03.1710",
          posX: '144',
          posY: '717.5',
          earthPosX: '658.5',
          earthPosY: '709',
          venusPosX: '705.5',
          venusPosY: '682',
          beamDeg: '-34'
        },
        {
          legend: "23.03.1710",
          posX: '138',
          posY: '714',
          earthPosX: '658.5',
          earthPosY: '726.5',
          venusPosX: '700',
          venusPosY: '702',
          beamDeg: '-33'
        },
        {
          legend: "30.03.1710",
          posX: '132.5',
          posY: '728',
          earthPosX: '660',
          earthPosY: '743',
          venusPosX: '698',
          venusPosY: '723',
          beamDeg: '-30'
        },
        {
          legend: "06.04.1710",
          posX: '133',
          posY: '758.5',
          earthPosX: '662.6',
          earthPosY: '758.7',
          venusPosX: '701',
          venusPosY: '744',
          beamDeg: '-29'
        },
        {
          legend: "13.04.1710",
          posX: '142',
          posY: '792',
          earthPosX: '667.6',
          earthPosY: '775.7',
          venusPosX: '707',
          venusPosY: '762',
          beamDeg: '-21'
        },
        {
          legend: "20.04.1710",
          posX: '154.5',
          posY: '824',
          earthPosX: '674.3',
          earthPosY: '790.3',
          venusPosX: '716.5',
          venusPosY: '779.5',
          beamDeg: '-22'
        },
        {
          legend: "27.04.1710",
          posX: '169',
          posY: '841',
          earthPosX: '683.3',
          earthPosY: '804.3',
          venusPosX: '728.5',
          venusPosY: '794',
          beamDeg: '-14'
        },
        {
          legend: "04.05.1710",
          posX: '179',
          posY: '844',
          earthPosX: '693',
          earthPosY: '818',
          venusPosX: '744.7',
          venusPosY: '807',
          beamDeg: '-15'
        },
        {
          legend: "11.05.1710",
          posX: '188.5',
          posY: '832',
          earthPosX: '704.5',
          earthPosY: '829',
          venusPosX: '761.3',
          venusPosY: '816.5',
          beamDeg: '-15'
        },
        {
          legend: "18.05.1710",
          posX: '201.3',
          posY: '808.5',
          earthPosX: '717',
          earthPosY: '839.5',
          venusPosX: '780.5',
          venusPosY: '821.5',
          beamDeg: '-18'
        },
        {
          legend: "25.05.1710",
          posX: '210',
          posY: '776',
          earthPosX: '731',
          earthPosY: '848.5',
          venusPosX: '798.5',
          venusPosY: '823',
          beamDeg: '-24'
        },
        {
          legend: "01.06.1710",
          posX: '215',
          posY: '738',
          earthPosX: '747',
          earthPosY: '855',
          venusPosX: '818',
          venusPosY: '820',
          beamDeg: '-30'
        },
        {
          legend: "08.06.1710",
          posX: '218.5',
          posY: '690.3',
          earthPosX: '762',
          earthPosY: '860',
          venusPosX: '838.5',
          venusPosY: '815',
          beamDeg: '-31'
        },
        {
          legend: "15.06.1710",
          posX: '220',
          posY: '644',
          earthPosX: '779',
          earthPosY: '864',
          venusPosX: '856',
          venusPosY: '806.3',
          beamDeg: '-38'
        },
        {
          legend: "22.06.1710",
          posX: '221.5',
          posY: '595.5',
          earthPosX: '795.3',
          earthPosY: '864.5',
          venusPosX: '869.5',
          venusPosY: '794',
          beamDeg: '-44'
        },
        {
          legend: "29.06.1710",
          posX: '223.5',
          posY: '540',
          earthPosX: '811.3',
          earthPosY: '862.5',
          venusPosX: '884',
          venusPosY: '779.6',
          beamDeg: '-50'
        },
        {
          legend: "06.07.1710",
          posX: '223.5',
          posY: '480',
          earthPosX: '828.3',
          earthPosY: '859.5',
          venusPosX: '893',
          venusPosY: '763.3',
          beamDeg: '-58'
        },
        {
          legend: "13.07.1710",
          posX: '223.5',
          posY: '426',
          earthPosX: '843.6',
          earthPosY: '855.5',
          venusPosX: '898.5',
          venusPosY: '746.5',
          beamDeg: '-64'
        },
        {
          legend: "20.07.1710",
          posX: '222.5',
          posY: '365',
          earthPosX: '859',
          earthPosY: '848.5',
          venusPosX: '898.7',
          venusPosY: '728.3',
          beamDeg: '-72'
        },
        {
          legend: "27.07.1710",
          posX: '219.3',
          posY: '304.7',
          earthPosX: '873.5',
          earthPosY: '839.5',
          venusPosX: '900',
          venusPosY: '708.5',
          beamDeg: '-80'
        },
        {
          legend: "03.08.1710",
          posX: '210',
          posY: '241.5',
          earthPosX: '887',
          earthPosY: '829',
          venusPosX: '897',
          venusPosY: '689.5',
          beamDeg: '-87'
        },
        {
          legend: "10.08.1710",
          posX: '210',
          posY: '178.5',
          earthPosX: '898.7',
          earthPosY: '817.5',
          venusPosX: '886',
          venusPosY: '670.3',
          beamDeg: '-95'
        },
      ]
    }
  }
};
</script>
